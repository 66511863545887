import React, { useState } from 'react';
import { useFormProps, useMediaQuery } from 'hooks';
import { DialogComponent, AuthError } from 'components/Modals';
import { Div, colors, weight } from 'globalStyle';
import { InputConfirmPassword, InputPassword } from 'components/Form';
import { Hollow } from 'components/Buttons';
import { TextLight } from 'components/Texts';
import TabPanel from 'components/TabPanel';
import { changePasswordUserService } from 'services';
import { Goal } from './Goal';

const noop = () => {};

export const ChangePassword = ({ isOpen, onClick }) => (
  <Tabs onClick={onClick} isOpen={isOpen} />
);

function Tabs({ onClick, isOpen }) {
  const [data, setData] = useState(0);
  const { formProps, stateFormProps } = useFormProps({
    errorToolTip: true,
    required: true,
  });

  const onSubmit = ({ value }) => {
    setData(value);
  };

  if (stateFormProps.errorMessage) {
    return (
      <AuthError
        errorMessage={stateFormProps.errorMessage}
        handleBack={() => {
          stateFormProps.setError(false);
        }}
        isOpen={isOpen}
        onClick={onClick}
      />
    );
  }

  return (
    <>
      <TabPanel value={data} index={0} title>
        <DialogComponent
          isOpen={isOpen}
          handleClose={onClick}
          handleBack={onClick}
        >
          <ForgotPasswordEmailSend
            onSubmit={onSubmit}
            formProps={formProps}
            stateFormProps={stateFormProps}
          />
        </DialogComponent>
      </TabPanel>
      <TabPanel value={data} index={1}>
        <DialogComponent
          isOpen={isOpen}
          handleClose={onClick}
          handleBack={onClick}
          hasContactUs={false}
        >
          <Goal onClick={onClick} />
        </DialogComponent>
      </TabPanel>
    </>
  );
}

function ForgotPasswordEmailSend({
  onSubmit = noop,
  formProps,
  stateFormProps,
}) {
  const submit = (form) => {
    stateFormProps.setLoading(true);
    changePasswordUserService({
      oldPassword: form.oldPassword,
      password1: form.password,
      password2: form.password,
    })
      .then(() => {
        stateFormProps.setLoading(false);
        onSubmit({ value: 1 });
      })
      .catch(({ messageFormat }) => {
        stateFormProps.setLoading(false);
        stateFormProps.setError(true, messageFormat);
      });
  };
  const isDesktop = useMediaQuery(1023);
  return (
    <Div>
      <TextLight
        mb={20}
        size={isDesktop ? 20 : 16}
        color={colors.primary}
        weight={weight.BOLD}
      >
        Cambio de contraseñas
      </TextLight>
      <TextLight number={4}>
        Tu contraseña debe tener mínimo ocho (8) caracteres.
      </TextLight>
      <TextLight number={4} mb={20}>
        Al menos una mayuscula, un caracter especial y numeros.
      </TextLight>
      <form onSubmit={formProps.handleSubmit(submit)}>
        <InputPassword
          label="Contraseña actual"
          name="oldPassword"
          style={{
            textAlign: 'start',
            width: `${isDesktop ? '330px' : '100%'}`,
          }}
          toolTip={false}
          {...formProps}
        />
        <InputPassword
          label="Nueva contraseña"
          name="password"
          style={{
            textAlign: 'start',
            width: `${isDesktop ? '330px' : '100%'}`,
          }}
          toolTip={false}
          {...formProps}
        />
        <InputConfirmPassword
          {...formProps}
          style={{
            textAlign: 'start',
            width: `${isDesktop ? '330px' : '100%'}`,
          }}
        />
        <Hollow
          size="14"
          isSubmit
          isLoading={stateFormProps.isLoading}
          disabled={formProps.valid}
          style={{
            width: `${isDesktop ? '220px' : '100%'}`,
            margin: '0 0 10px',
            padding: '10px 0',
            height: '45px',
          }}
          onColorType="primary"
        >
          Guardar cambios
        </Hollow>
      </form>
    </Div>
  );
}
