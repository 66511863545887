import { Fetch } from 'lib/Fetch';

export function postulateProjectForm(info) {
  return new Fetch('api_2')
    .url('/portal/register/company/save-info-postulate-project')
    .method('post')
    .data(info)
    .portalToken()
    .send();
}
