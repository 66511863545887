/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useFormProps, useMediaQuery, useUser } from 'hooks';
import { DialogComponent, AuthError } from 'components/Modals';
import { Div, colors, weight } from 'globalStyle';
import { InputConfirmPassword, InputPassword } from 'components/Form';
import { Hollow } from 'components/Buttons';
import { TextLight } from 'components/Texts';
import TabPanel from 'components/TabPanel';
import { passwordChangeCompany } from 'services';
import { Code } from './Code';
import { Goal } from '../ChangePassword/Goal';

export const ChangePasswordCompany = ({ isOpen, onClick }) => (
  <Tabs onClick={onClick} isOpen={isOpen} />
);
function Tabs({ onClick, isOpen }) {
  const { logout } = useUser();
  const [tab, setTab] = useState(0);
  const {
    formProps,
    stateFormProps: {
      isLoading, setLoading,
    },
  } = useFormProps({
    errorToolTip: true,
    required: true,
  });
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');

  const handleError = (err) => {
    setError(err);
  };

  const handleOTP = (code) => {
    const otpConverted = code.toUpperCase();
    setOtp(otpConverted);
  };

  if (error) {
    return (
      <AuthError
        errorMessage={error}
        handleBack={() => {
          setError(false, '');
        }}
        isOpen={isOpen}
        onClick={onClick}
        code={otp}
      />
    );
  }

  return (
    <>
      <TabPanel value={tab} index={0} title>
        <Code
          isOpen={isOpen}
          handleOtp={handleOTP}
          onClick={onClick}
          setLoading={setLoading}
          isLoading={isLoading}
          setError={handleError}
          formProps={formProps}
          handleTabs={() => setTab(tab + 1)}
        />
      </TabPanel>
      <TabPanel value={tab} index={1} title>
        <NewPasswordForm
          isOpen={isOpen}
          setOtp={handleOTP}
          onClick={onClick}
          setLoading={setLoading}
          isLoading={isLoading}
          setError={handleError}
          handleTabs={() => setTab(tab + 1)}
          formProps={formProps}
          otp={otp}
        />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <DialogComponent
          isOpen={isOpen}
          handleClose={logout}
          handleBack={logout}
          hasContactUs={false}
        >
          <Goal onClick={logout} />
        </DialogComponent>
      </TabPanel>
    </>
  );
}

function NewPasswordForm({
  handleTabs,
  formProps,
  setLoading,
  setError,
  isLoading,
  otp,
  isOpen,
  onClick,
}) {
  const submit = (form) => {
    setLoading(true);
    const changePassword = {
      password: form.oldPassword,
      newPassword: form.password,
      newPasswordValid: form.password,
      otp,
    };
    passwordChangeCompany({
      changePassword,
    })
      .then(() => {
        setLoading(false);
        handleTabs();
      })
      .catch(({ messageFormat }) => {
        setLoading(false);
        setError(messageFormat);
      });
  };
  const isDesktop = useMediaQuery(1023);
  return (
    <DialogComponent isOpen={isOpen} handleClose={onClick} handleBack={onClick}>
      <TextLight
        mb={20}
        size={isDesktop ? 20 : 16}
        color={colors.primary}
        weight={weight.BOLD}
      >
        Cambio de contraseñas
      </TextLight>
      <TextLight number={4}>
        Tu contraseña debe tener mínimo ocho (8) caracteres.
      </TextLight>
      <TextLight number={4} mb={20}>
        Al menos una mayuscula, un caracter especial y numeros.
      </TextLight>
      <form onSubmit={formProps.handleSubmit(submit)}>
        <InputPassword
          label="Contraseña actual"
          name="oldPassword"
          style={{
            textAlign: 'start',
            width: `${isDesktop ? '330px' : '100%'}`,
          }}
          toolTip={false}
          {...formProps}
        />
        <InputPassword
          label="Nueva contraseña"
          name="password"
          style={{
            textAlign: 'start',
            width: `${isDesktop ? '330px' : '100%'}`,
          }}
          toolTip={false}
          {...formProps}
        />
        <InputConfirmPassword
          {...formProps}
          style={{
            textAlign: 'start',
            width: `${isDesktop ? '330px' : '100%'}`,
          }}
        />
        <Hollow
          size="14"
          isSubmit
          isLoading={isLoading}
          disabled={formProps.valid}
          style={{
            width: `${isDesktop ? '330px' : '100%'}`,
            margin: '0 0 10px',
            padding: '10px 0',
            height: '45px',
          }}
          onColorType="primary"
        >
          Guardar cambios
        </Hollow>
      </form>
    </DialogComponent>
  );
}
