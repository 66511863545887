import { Fetch } from 'lib/Fetch';

export function userNotificationsService({ limit, source }) {
  return new Fetch()
    .url(`/user/notification/info-notification-user/${limit}`)
    .method('get')
    .userToken()
    .setCancelToken({ source })
    .send();
}

export function userNotificationsRead({ ids }) {
  return new Fetch('api_2')
    .url('/user/notification/used')
    .method('patch')
    .userToken()
    .data({ ids })
    .send();
}
