/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'wouter';
import {
  colors,
} from 'globalStyle';
import styled, { css } from 'styled-components';
import { Loading } from 'components/Loading';
import { basicButton, anatomy } from './styled';

export const Button = ({
  href = '/', isLoading, children, isSubmit, ...rest
}) => {
  if (isLoading) return <Loading />;

  return (isSubmit)
    ? (
      <ButtonSubmit {...rest}>
        {children}
      </ButtonSubmit>
    ) : (
      <Link href={href} {...rest}>
        <ButtonLink {...rest}>
          {children}
        </ButtonLink>
      </Link>
    );
};

const button = css`
    border: none;
    color: ${colors.WHITE};
    background-color: ${(props) => (props.background ? props.background : colors.primary)};
    ${basicButton}
    ${anatomy}
`;

const ButtonLink = styled.a`
    ${button}
`;

const ButtonSubmit = styled.button`
    ${button}
`;
