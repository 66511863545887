import React, { useState } from 'react';
import { CloseIcon } from 'icons';
import { colors } from 'globalStyle';
import { useMediaQuery } from 'hooks';
import { Box, Grid } from '@material-ui/core';
import { Title, Text } from 'components/Texts';
import MenuIcon from 'assets/icons/menu-blue.svg';
import { Hollow, Ghost, hollowColorType } from 'components/Buttons';
import {
  REGLAMENTO_BLOOM,
  TERMINOS_CONDICIONES_BLOOM,
  BCC_REGLAMENTO_DEPOSITO_BAJO_MONTO,
} from 'types/fileType';

import { ItemsContainer, ListHeader, MainButton } from './SelectChat';

const NAME = 'Documentos';

export default function TermsAndConditions({ update }) {
  const isDesktop = useMediaQuery(1023);
  const [show, setShow] = useState(false);
  const hide = () => setShow(false);
  const openLink = (event, link) => {
    event.stopPropagation();
    window.open(link);
  };
  return (
    <>
      <MainButton
        isSubmit
        fullWidth
        onClick={() => setShow(true)}
        borderRadius="12px"
        onColorType={hollowColorType.WhiteBorder}
      >
        <Text isCenter size={12} color="inherit">
          <Box
            alt=""
            width="12px"
            src={MenuIcon}
            component="img"
            marginRight="8px"
          />
          {NAME}
        </Text>
      </MainButton>
      {show && (
        <ItemsContainer
          onClick={hide}
          isDesktop={isDesktop}
        >
          <ListHeader onClick={(event) => event.stopPropagation()}>
            <Text isCenter size={14} color={colors.primary}>
              {NAME}
            </Text>
            <CloseIcon
              width="20px"
              height="20px"
              onClick={hide}
              style={{
                top: '20px',
                right: '20px',
                cursor: 'pointer',
                position: 'absolute',
              }}
            />
          </ListHeader>
          <Grid
            style={{
              paddingLeft: '30px',
              paddingRight: '30px',
              background: colors.WHITE,
            }}
          >
            <Grid
              container
              alignItems="center"
              style={{
                rowGap: '43px',
                paddingTop: '56px',
              }}
            >
              <ListItem
                text="Términos y condiciones"
                onClick={(event) => openLink(
                  event, TERMINOS_CONDICIONES_BLOOM,
                )}
              />
              <ListItem
                text="El Reglamento de Bloom Crowdfunding S.A. y sus anexos"
                onClick={(event) => openLink(event, REGLAMENTO_BLOOM)}
              />
              <ListItem
                text="Los Reglamentos, Términos y contrato del Banco Cooperativo Coopcentral para realizar la apertura de un depósito electrónico de bajo monto."
                onClick={(event) => openLink(event, BCC_REGLAMENTO_DEPOSITO_BAJO_MONTO)}
              />
            </Grid>
            <Grid
              container
              style={{
                rowGap: '9px',
                paddingTop: '51px',
                paddingBottom: '29px',
              }}
            >
              <Hollow
                isSubmit
                fullWidth
                onColorType={hollowColorType.primary}
                onClick={() => update({
                  value: true,
                  textValue: 'Acepto',
                })}
              >
                Aceptar
              </Hollow>
              <Hollow
                isSubmit
                fullWidth
                onColorType={hollowColorType.WhiteBorder}
                onClick={() => update({
                  value: false,
                  textValue: 'No acepto',
                })}
              >
                Rechazar
              </Hollow>
            </Grid>
          </Grid>
        </ItemsContainer>
      )}
    </>
  );
}

const ListItem = ({ text, onClick }) => (
  <>
    <Grid item xs={10} onClick={(e) => e.stopPropagation()}>
      <Title size={14}>
        {text}
      </Title>
    </Grid>
    <Grid item container xs={2} justify="flex-end">
      <Ghost isSubmit onClick={onClick} isCenter>
        <Title size={14} color={colors.primary} isCenter>Ver</Title>
      </Ghost>
    </Grid>
  </>
);
