import React from 'react';
import { colors } from 'globalStyle';
import { useMediaQuery } from 'hooks';
import { Box } from '@material-ui/core';
import MainImage from 'assets/coding.png';
import { Title, Text } from 'components/Texts';

import CustomDialog from './CustomDialog';

export default function MaintenanceModal({ open = true }) {
  const isDesktop = useMediaQuery(1023);
  return (
    <CustomDialog
      open={open}
      px={isDesktop ? '33.5px' : '4.5px'}
      pt={isDesktop ? '12px' : '16.78px'}
      width={isDesktop ? '614px' : '242px'}
      height={isDesktop ? '444px' : '494px'}
    >
      <Title
        color={colors.primary}
        size={isDesktop ? 20 : 20}
      >
        Plataforma en Mantenimiento
      </Title>
      <Box
        alt=""
        component="img"
        src={MainImage}
        width={isDesktop ? '167px' : '174px'}
        height={isDesktop ? '146px' : '161px'}
        marginTop={isDesktop ? '22px' : '22.94px'}
        marginBottom={isDesktop ? '45px' : '23px'}
      />
      <Text
        size={isDesktop ? 15 : 11}
      >
        Estamos haciendo mejoras en la plataforma para que puedas
        disfrutar de todos los beneficios de invertir en Financiación Colaborativa.
      </Text>
      <Text
        mt={isDesktop ? 20 : 20}
        size={isDesktop ? 15 : 11}
      >
        Por el momento nuestros servicios no están disponibles,
        lamentamos las molestias que este mantenimiento pueda ocasionar.
      </Text>
    </CustomDialog>
  );
}
