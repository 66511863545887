import { Fetch } from 'lib/Fetch';

export function transactionBankCompany({
  initialDate,
  finalDate,
}) {
  return new Fetch()
    .url('/company/financial/movements-inquiry-company-front')
    .method('post')
    .userToken()
    .data({
      initialDate,
      finalDate,
    })
    .send();
}
