import { Fetch } from 'lib/Fetch';

export function companyListProjects() {
  return new Fetch()
    .url('/company/company-info/company-list-projects')
    .method('get')
    .userToken()
    .send();
}

export function getLastPayment(projectId) {
  return new Fetch('api_2')
    .url(`/company/payments/get-payment-periods/${projectId}`)
    .method('get')
    .userToken()
    .send();
}

export function companyPayment(data) {
  return new Fetch()
    .url('/company/payments/pay')
    .method('put')
    .userToken()
    .data(data)
    .send();
}

export function retentionPaymentDoc(projectId, period) {
  return new Fetch('api_2')
    .url(`/company/payments/retention-payment/${period}/${projectId}`)
    .method('get')
    .userToken()
    .send();
}

export function companyCommissionPayment({
  idProject,
}) {
  return new Fetch()
    .url('/company/payments/commission')
    .method('post')
    .userToken()
    .data({
      idProject,
    })
    .send();
}

export function confirmViewCommission({ projectId }) {
  return new Fetch()
    .url(`/company/company-info/confirm-view-commission/${projectId}`)
    .method('get')
    .userToken()
    .send();
}

export function projectToApprove() {
  return new Fetch()
    .url('/company/company-info/project-to-approve')
    .method('get')
    .userToken()
    .send();
}

export function legalRepProjectWaitingConfirmation({ isAccepted, idProject }) {
  return new Fetch()
    .url('/company/company-info/legal-rep-project-waiting-confirmation')
    .method('post')
    .userToken()
    .data({ isAccepted, idProject })
    .send();
}
