import { Fetch } from 'lib/Fetch';

export function registerUserColombiaInvestor({ user }) {
  return new Fetch()
    .url('/portal/register/user/investor/col')
    .method('post')
    .data(user)
    .portalToken()
    .send();
}

export function registerUserInvestor({ user }) {
  return new Fetch()
    .url('/portal/register/user/investor')
    .method('post')
    .data(user)
    .portalToken()
    .send();
}
