/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { QuestionIcon, Alert } from 'icons';
import { colors, spaces } from 'globalStyle';
import { Text } from 'components/Texts';
import { withStyles } from '@material-ui/core/styles';
import Tool from '@material-ui/core/Tooltip';
import styled from 'styled-components';

export const ToolTip = ({
  color,
  children,
  placement,
  isCenter,
  maxWidth,
  arrow = true,
  ...rest
}) => (
  <LightTooltip
    border={`1px solid ${colors.GRAY_2}`}
    maxWidth={maxWidth}
    size={14}
    title={(
      <Text number={4} isCenter={isCenter}>
        {children}
      </Text>
    )}
    arrow={arrow}
    placement={placement || 'right'}
    enterTouchDelay={0}
    leaveTouchDelay={20000}
    {...rest}
  >
    <Button {...rest}>
      <QuestionIcon
        color={color || colors.GRAY_3}
        size="20px"
        style={{ cursor: 'pointer' }}
        {...rest}
      />
    </Button>
  </LightTooltip>
);

export const ToolTipError = ({
  isCenter,
  maxWidth,
  open,
  children,
  placement,
  text,
}) => (
  <LightTooltip
    maxWidth={maxWidth}
    border={`2px solid ${colors.error}`}
    size={14}
    open={open}
    disableFocusListener
    disableHoverListener
    disableTouchListener
    title={(
      <Text number={4} isCenter={isCenter}>
        {text}
      </Text>
    )}
    arrow
    placement={placement || 'right'}
  >
    {children}
  </LightTooltip>
);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: colors.WHITE,
    boxShadow: theme.shadows[1],
    fontSize: (props) => props.size,
    border: (props) => props.border,
    maxWidth: (props) => props.maxWidth,
    display: 'inline-block',
  },
  arrow: {
    fontSize: 20,
    color: colors.WHITE,
    '&::before': {
      border: (props) => props.border,
    },
  },
}))(Tool);

export const AlertTooltip = ({
  color,
  children,
  placement,
  isCenter,
  maxWidth,
  arrow = true,
  ...rest
}) => (
  <IconAlertTooltip
    border={`1px solid ${colors.GRAY_2}`}
    maxWidth={maxWidth}
    size={14}
    title={(
      <Text number={4} isCenter={isCenter}>
        {children}
      </Text>
    )}
    arrow={arrow}
    placement={placement || 'right'}
    enterTouchDelay={0}
    leaveTouchDelay={20000}
    {...rest}
  >
    <Button {...rest}>
      <Alert
        color={color || colors.GRAY_3}
        size="20px"
        style={{ cursor: 'pointer' }}
        {...rest}
      />
    </Button>
  </IconAlertTooltip>
);

const IconAlertTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: colors.WHITE,
    borderRadius: '0px 15px 15px 15px',
    top: '55px',
    left: '-10px',
    border: `1px solid ${colors.GRAY_10}`,
    maxWidth: (props) => props.maxWidth,
    padding: '10px',
  },
  arrow: {
    display: 'none',
  },
}))(Tool);

const Button = styled.button`
  margin: 0;
  padding: 0;
  width: auto;
  ${spaces}
  display: inline-block;

  svg {
    margin: 0;
  }
`;
