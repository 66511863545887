const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
const REMOVE_USER_INFO = 'REMOVE_USER_INFO';

const initialState = {
  userInfo: {},
};

const userInfo = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case REMOVE_USER_INFO:
      return {
        state: {},
      };

    default:
      return state;
  }
};

export default userInfo;

export const updateUserInformation = (userInformation) => ({
  type: UPDATE_USER_INFO,
  payload: userInformation,
});

export const removeUserInformation = () => ({
  type: REMOVE_USER_INFO,
});
