/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Input } from './Input';

export const InputOnlyNumbers = ({
  validate,
  ...rest
}) => (
  <Input
    type="number"
    regexPattern={{ value: /^\d+$/, message: 'No se aceptan caracteres diferentes a números' }}
    validate={validate}
    {...rest}
  />
);
