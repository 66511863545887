import { Fetch } from 'lib/Fetch';

export function completeProfileColombia({ profileInfo }) {
  return new Fetch()
    .url('/user/register/user/forms/colombia')
    .method('post')
    .data(profileInfo)
    .userToken()
    .send();
}

export function completeProfilePeru({ profileInfo }) {
  return new Fetch()
    .url('/user/register/user/forms/peru')
    .method('post')
    .data(profileInfo)
    .userToken()
    .send();
}

export function preRegister({ email, name }) {
  return new Fetch('api_2')
    .url('/external/global/pre-register/register')
    .method('post')
    .data({ email, name })
    .userToken()
    .send();
}
