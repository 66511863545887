import React from 'react';
import { spaces } from 'globalStyle';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export default function Container({ children, ...rest }) {
  return (
    <StyledGrid container direction="column" alignItems="center" {...rest}>
      {children}
    </StyledGrid>
  );
}

const StyledGrid = styled(Grid)`
  ${spaces}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.right && `right: ${props.right};`}
  ${(props) => props.height && `height: ${props.height};`}
  ${(props) => props.position && `position: ${props.position};`}
  ${(props) => props.notClickable && 'pointer-events:none; touch-action:none;'}
`;
