import React, { useState } from 'react';
import { colors } from 'globalStyle';
import { openPopUp } from 'store/popup';
import { useDispatch } from 'react-redux';
import { Ghost } from 'components/Buttons';
import { Title, Text } from 'components/Texts';
import snowman from 'assets/snowman.svg';
import childSantaHat from 'assets/childSantaHat.svg';
import companyIcon from 'assets/icons/company.svg';
import building from 'assets/buildingChristmas.svg';
import { Box, Grid, styled } from '@material-ui/core';
import ModalContent from 'components/Modals/ModalContent';
import { CLIENT_TYPE } from '../../components/MobileHeader';

export default function LoginType({ isDesktop, onClose, onFinish }) {
  const dispatch = useDispatch();
  const [ishoverInvestor, setIsHoverInvestor] = useState(false);
  const [ishoverCompany, setIsHoverCompany] = useState(false);
  const handleHoverInvestor = (value) => {
    setIsHoverInvestor(value);
  };
  const handleHoverCompany = (value) => {
    setIsHoverCompany(value);
  };
  const chooseType = (type) => {
    onFinish(type);
  };
  return (
    <ModalContent
      pt="10px"
      onClose={onClose}
      fullSize={!isDesktop}
      pb={!isDesktop && '80px'}
      px={isDesktop ? '94px' : '40px'}
      width={isDesktop ? '666px' : '100%'}
      height={isDesktop ? '530px' : '100%'}
      borderRadius={isDesktop ? '20px' : '0px'}
    >
      <Grid container direction="column">
        <Title size={isDesktop ? 24 : 20} isCenter>
          ¡Bienvenido de nuevo!
        </Title>
        <Text
          mt={16}
          isCenter
          mb={isDesktop ? 62 : 74}
          size={isDesktop ? 18 : 14}
        >
          Inicia sesión como inversionista
        </Text>
        <RCardContainer container justify="space-between">
          <RTypeCard
            color={ishoverInvestor || !isDesktop ? colors.error : colors.secondary}
            img={ishoverInvestor || !isDesktop ? childSantaHat : snowman}
            isDesktop={isDesktop}
            title="Inversionista"
            onClick={() => chooseType(CLIENT_TYPE.INVESTOR)}
            description="Quieres invertir y hacer que tu dinero crezca"
            handleHover={handleHoverInvestor}
          />
          <RTypeCard
            color={ishoverCompany ? colors.error : colors.primary}
            img={ishoverCompany ? building : companyIcon}
            isDesktop={isDesktop}
            title="Receptor"
            onClick={() => chooseType(CLIENT_TYPE.COMPANY)}
            description="Empresa con NIT colombiano"
            handleHover={handleHoverCompany}
          />
        </RCardContainer>
        <Text mt={36} mb={35} size={14} color={colors.GRAY_2} isCenter>
          ¿No tienes una cuenta?
          {' '}
          <Ghost
            ml={5}
            isSubmit
            size={14}
            underline
            color={colors.primary}
            onClick={() => dispatch(openPopUp({ type: 'register' }))}
          >
            Créala aquí
          </Ghost>
        </Text>
      </Grid>
    </ModalContent>
  );
}

const RTypeCard = ({
  img, color, title, description, isDesktop, onClick, handleHover,
}) => (
  <RTypeCardRoot
    container
    color={color}
    onClick={onClick}
    alignItems="center"
    isDesktop={isDesktop}
    direction={isDesktop ? 'column' : 'row'}
    onMouseOver={() => handleHover(true, title)}
    onMouseOut={() => handleHover(false, title)}
  >
    <Box
      width={isDesktop ? '108px' : '100px'}
      height="100px"
      display="flex"
      bgcolor={color}
      marginBottom={isDesktop ? '20px' : '0px'}
      borderRadius={isDesktop ? '0px 0px 20px 20px' : '0px 20px 20px 0px'}
    >
      <Box
        src={img}
        width="64px"
        height="64px"
        margin="auto"
        component="img"
        alignSelf="center"
      />
    </Box>
    <RCardTextContainer
      container
      direction="column"
      alignItems="center"
      isDesktop={isDesktop}
    >
      <Title size={16} isCenter={isDesktop} mb={13}>
        {title}
      </Title>
      <Text
        size={14}
        isCenter={isDesktop}
        width="160px"
        color="#667080"
        fullWidth
      >
        {description}
      </Text>
    </RCardTextContainer>
  </RTypeCardRoot>
);

const RCardContainer = styled(Grid)({
  rowGap: '53px',
});

const RTypeCardRoot = styled(Grid)((props) => ({
  cursor: 'pointer',
  width: props.isDesktop ? '200px' : '310px',
  height: props.isDesktop ? '260px' : '150px',
  boxShadow: '0px 4px 25px 0px rgba(0, 0, 0, 0.06)',
  borderRadius: '15px',
  border: props.isDesktop ? '0px' : `1px solid ${props.color}`,
  '&:hover': {
    border: `1px solid ${props.color}`,
  },
}));

const RCardTextContainer = styled(Grid)((props) => ({
  width: props.isDesktop ? '100%' : 'auto',
  marginLeft: props.isDesktop ? '0px' : '20px',
  maxWidth: !props.isDesktop && 'calc(100% - 130px)',
  '& div': {
    maxWidth: '100%',
  },
}));
