/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Input } from './Input';

export const InputNumber = ({
  validate,
  ...rest
}) => (
  <Input
    type="number"
    regexPattern={{ value: /^\d{1,}$/i, message: 'Solo debes ingresar números válidos' }}
    validate={validate}
    {...rest}
  />
);
