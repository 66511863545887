/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { colors } from 'globalStyle';
import { HiddenIcon, ShowIcon } from 'icons';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { ToolTip } from 'components/ToolTip';
import { BasicInput } from './Basic';

export const InputPassword = ({
  noControls, getValues, name, noTooltip, toolTip = true, ...rest
}) => {
  const [state, setState] = React.useState(false);
  return (
    <>
      <Grid container display="flex" justify="space-between">
        <InputContainer item $noTooltip={noTooltip}>
          <BasicInput
            label="Contraseña"
            name={name || 'password'}
            autocomplete="on"
            type={state ? 'text' : 'password'}
            validate={{
              length: (value) => value.length >= 8
                || 'Tu contraseña debe tener mínimo 8 caracteres.',
              digits: (value) => (value && /\d|\W/.test(value))
                || 'Debe contener números o caracteres especiales',
              capitalLetter: (value) => (value && /[A-Z]/.test(value))
                || 'Debes tener al menos una letra mayúscula',
              lowercaseLetter: (value) => (value && /[a-z]/.test(value))
                || 'Debes tener al menos una letra minúscula',
            }}
            {...rest}
          >
            {noControls ? null : (
              <PasswordButton onClick={() => setState(!state)}>
                {state ? (
                  <ShowIcon color={colors.primary} size="1.5em" />
                ) : (
                  <HiddenIcon color={colors.GRAY_3} size="1.5em" />
                )}
              </PasswordButton>
            )}
          </BasicInput>
        </InputContainer>
        {!noTooltip && toolTip && (
          <Grid item>
            <ToolTip mt={35} onClick={(event) => event.preventDefault()}>
              Tu contraseña debe contener mínimo 8 caracteres. Entre ellos números
              y/o caracteres especiales. Al menos una mayúscula y una
              minúscula.
            </ToolTip>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export const InputConfirmPassword = ({
  getValues,
  noControls,
  name,
  ...rest
}) => {
  const [state, setState] = React.useState(false);
  return (
    <Grid container display="flex" justify="space-between">
      <InputContainer item>
        <BasicInput
          label="Confirmar contraseña "
          name={name || 'confirmPassword'}
          autocomplete="on"
          type={state ? 'text' : 'password'}
          validate={{
            length: (value) => value.length >= 8 || 'Tu contraseña debe tener mínimo 8 caracteres.',
            digits: (value) => (value && /\d|\W/.test(value))
          || 'Debe contener números o caracteres especiales',
            capitalLetter: (value) => (value && /[A-Z]/.test(value))
          || 'Debes tener al menos una letra mayúscula',
            lowercaseLetter: (value) => (value && /[a-z]/.test(value))
          || 'Debes tener al menos una letra minúscula',
            samePassword: (value) => value === getValues('password') || 'Las contraseñas no coinciden',
          }}
          {...rest}
        >
          {noControls ? null : (
            <PasswordButton onClick={() => setState(!state)}>
              {state ? (
                <ShowIcon color={colors.primary} size="1.5em" />
              ) : (
                <HiddenIcon color={colors.GRAY_3} size="1.5em" />
              )}
            </PasswordButton>
          )}
        </BasicInput>
      </InputContainer>
    </Grid>
  );
};

const PasswordButton = styled.span`
  position: absolute;
  top: 6px;
  right: 10px;
  cursor: pointer;
`;

const InputContainer = styled(Grid)`
  width: ${(props) => (props.$noTooltip ? '100%' : 'calc(100% - 30px)')};
`;
