/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { SubTitle, Text } from 'components/Texts';
import {
  colors, Div,
} from 'globalStyle';
import { Ghost, Submit } from 'components/Buttons';
import {
  InputPassword, Input, InputOnlyNumbers,
} from 'components/Form';
import { useLocation } from 'wouter';
import { useLocalStorage, useFormProps } from 'hooks';
import { getNavigator } from 'util/getNavigatorBrowser';
import { userLoginService } from 'services/other/loginService';
import { userType } from 'types/userType';
import { MetaMapModal } from 'components/Modals';

import { useDispatch } from 'react-redux';
import { openPopUp, closePopUp } from 'store/popup';

export const LoginInversionist = ({
  login,
}) => {
  const dispatch = useDispatch();
  const [, setLocation] = useLocation();
  const [userId, setUserId] = useState();
  const { localValue, setLocalStorage } = useLocalStorage('seed');
  const [openOtp, setOpenOtp] = useState(false);
  const [browserId, setBrowserId] = useState('');
  const [isOpenDialog, setOpenDialog] = useState(false);
  const {
    formProps,
    stateFormProps: {
      isLoading,
      setLoading,
      setError,
      errorMessage,
    },
  } = useFormProps({ errorToolTip: true });

  useEffect(() => {
    setBrowserId(getNavigator());
  }, [openOtp]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onSubmit = async (form) => {
    setLoading(true);
    const otpConverted = form?.otp?.toUpperCase() || '';
    userLoginService({
      dni: form.dni,
      password: form.password,
      seed: localValue() || '',
      browserId,
      otp: otpConverted,
    })
      .then(({ data, message }) => {
        setLoading(false);

        if (data.sessionItem.requester.seed) {
          setLocalStorage(data.sessionItem.requester.seed);
        }

        if (data?.info?.status === 'PENDING_METAMAP') {
          setError(true, 'Estamos validando tus datos, te informaremos cuando puedas acceder a tu cuenta.');
          return;
        }
        if (data?.info?.status === 'WAITING_EXTERNAL_PROVIDER') {
          setError(true, 'Aún no hemos recibido tus documentos, te invitamos a realizar el proceso de envío');
          return;
        }

        login({
          data,
          userType: userType.USER,
        });
        dispatch(closePopUp());
        setLocation('/perfil');
      }).catch(({ data, message, messageFormat }) => {
        setLoading(false);
        if (message === 'NEW_DEVICE_EMAIL_SENT') {
          setLocalStorage('');
          setOpenOtp(true);
          setError(true, 'Se ha enviado un OTP a su correo electrónico, por favor revísalo.');
        } else if (message === 'MAC_IS_NOT_REGISTERED_TRIES_LEFT') {
          dispatch(openPopUp({ type: 'recover-device' }));
        } else {
          setError(true, messageFormat);
        }
      });
  };
  const handleUserFinishedSdk = () => {
    dispatch(openPopUp({
      type: 'login',
      props: {
        successfulRegistration: true,
      },
    }));
    setOpenDialog(false);
  };

  return (
    <Div mt={30}>
      <form onSubmit={formProps.handleSubmit(onSubmit)}>
        <SubTitle
          number={2}
          mb={30}
          weight="500"
        >
          Inicia sesión como
          {' '}
          <b>inversionista</b>
        </SubTitle>
        <InputOnlyNumbers
          label="Número de documento"
          name="dni"
          placeholder="9897673858"
          {...formProps}
          required
        />
        <InputPassword {...formProps} />
        {openOtp && (
          <Input
            label="Código de validación"
            name="otp"
            placeholder="Escribe aquí el código que te llegó al correo"
            {...formProps}
          />
        )}
        {errorMessage && (
          <Text number={3} color="#ff4343" mb={10}>
            {errorMessage}
          </Text>
        )}
        <Submit
          isLoading={isLoading}
          width={100}
          disabled={formProps.valid}
          mb={20}
          padding="5px"
        >
          Iniciar sesión
        </Submit>
      </form>

      <Ghost
        mb={10}
        isSubmit
        onClick={() => dispatch(openPopUp({ type: 'recover-password' }))}
        underline
        color={colors.GRAY_2}
        size={13}
      >
        ¿Has olvidado tu contraseña?
      </Ghost>
      <Text mb={30} number={4} color={colors.GRAY_2}>
        ¿No tienes una cuenta?
        {' '}
        <Ghost
          underline
          color={colors.primary}
          isSubmit
          onClick={() => dispatch(openPopUp({ type: 'register' }))}
          size={13}
        >
          Créala aquí
        </Ghost>
      </Text>
      <MetaMapModal
        userId={userId}
        isOpen={isOpenDialog}
        onClose={handleCloseDialog}
        userFinishedSdk={handleUserFinishedSdk}
      />
    </Div>
  );
};
