/* eslint-disable no-console */
import { useEffect, useCallback } from 'react';
import { useMountedState } from 'react-use';
import axios from 'axios';

export const useCancelToken = () => {
  let isMounted = useMountedState();
  const source = axios.CancelToken.source();

  useEffect(() => () => {
    isMounted = false;
    source.cancel();
  }, []);

  const log = useCallback(({ message }) => {
    if (isMounted) { console.log(message); }
  }, []);

  const logCallback = useCallback((err, cb) => {
    if (isMounted) {
      const { message } = err;
      console.log(message);
      cb(message);
    }
  }, []);

  return {
    isMounted,
    log,
    logCallback,
    source,
  };
};
