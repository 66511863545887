import { useState, useEffect, useCallback } from 'react';
import { getProjectsCompany } from 'services';
import { useDispatch, useSelector } from 'react-redux';
import { useMountedState } from 'react-use';
import { updateProjectsCompany } from 'store/projectsCompany';
import { useCancelToken } from '../useCancelToken';

export const useprojectsCompanyCache = () => {
  const isMounted = useMountedState();
  const dispatch = useDispatch();
  const projectsCompanyCache = useSelector(
    (store) => store.projectsCompany.projectsCompany,
  );
  const setProjectsCompanyInfo = useCallback((projectsCompany) => {
    if (isMounted()) {
      dispatch(updateProjectsCompany(projectsCompany));
    }
  }, []);

  return {
    projectsCompanyCache: projectsCompanyCache || [],
    setProjectsCompanyInfo,
  };
};

export const useProjectsCompany = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState([]);
  const { projectsCompanyCache, setProjectsCompanyInfo } = useprojectsCompanyCache();
  const { isMounted, source, logCallback } = useCancelToken();

  const fetch = () => {
    if (projectsCompanyCache?.length === 0) {
      setLoading(true);
      getProjectsCompany({ source })
        .then(({ data }) => {
          if (isMounted) {
            /* eslint-disable-next-line no-param-reassign */
            data = data?.map((v) => {
              if (v?.projectInfo?.projectStatus === 'OPEN'
              && v.projectInfo.maxAmount === v.projectInfo.currentAmount
              ) {
                const newValue = { ...v.projectInfo, currentInvestorsAmount: v?.investors };
                newValue.projectStatus = 'COLLECTING';
                return newValue;
              }
              return { ...v?.projectInfo, currentInvestorsAmount: v?.investors };
            });

            setProjectsCompanyInfo(data);
            setValue(data);
            setLoading(false);
          }
        })
        .catch((err) => {
          logCallback(err, () => {
            setLoading(false);
          });
        });
    } else {
      setValue(projectsCompanyCache);
    }
  };
  useEffect(() => {
    fetch();
  }, []);

  return {
    projectCompany: value || [],
    isLoadingProject: loading,
  };
};
