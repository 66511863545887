/* eslint-disable no-unused-vars */
import React, { Suspense } from 'react';

export const withSuspense = (
  Component,
  fallback = <div style={{ height: '100vh' }} />,
) => () => (
  <Suspense fallback={fallback}>
    <Component />
  </Suspense>
);
