import React, { useState, useEffect } from 'react';
import {
  Div, Figure, weight, colors,
} from 'globalStyle';
import { useFormProps, useUser } from 'hooks';
import { InputFormatNumber, ErrorText } from 'components/Form';
import { Dialog, DialogContent } from '@material-ui/core';
import { TextLight } from 'components/Texts';
import { Hollow } from 'components/Buttons';
import { rechargeNowService, rechargeCompany } from 'services';
import { PseForm } from 'components/PseForm';
import { getAmount } from 'util/getAmount';
import arrowLeft from 'assets/icons/arrowLeftHeader.svg';
import rechangeWallet from 'assets/persons/rechangeWallet.svg';
import { RechageMoneySimpleAccount } from 'components/RechargeMoneySimpleAccount';
import { routes } from 'routes';
import { capitalizeFirstLetterPhrase } from 'util/capitalizeFirstLetter';
import closeIcon from 'assets/icons/closeIcon.svg';
import { userType } from 'types';
import RegisterChecker from 'Layout/components/Navbar/components/Register/components/RegisterChecker';

const Container = ({
  children, onBack, onClose, isOpen,
}) => (
  <RegisterChecker check={isOpen} onClose={onClose}>
    <Dialog
      open={isOpen}
      PaperProps={{
        style: { borderRadius: '20px' },
      }}
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
    >
      <DialogContent
        style={{
          width: '500px', maxWidth: '100%', padding: '30px', height: '460px',
        }}
        align="center"
      >
        <Div isFlex justify="space-between" margin="0 0 10px">
          <Figure
            style={{ width: '25px', height: '25px', cursor: 'pointer' }}
            onClick={() => {
              onBack();
            }}
          >
            <img src={arrowLeft} alt="arrowLeft" />
          </Figure>
          <Figure
            style={{ width: '15px', height: '15px', cursor: 'pointer' }}
            onClick={() => {
              onClose();
            }}
          >
            <img
              src={closeIcon}
              alt="cerrar"

            />
          </Figure>
        </Div>
        {children}
      </DialogContent>
    </Dialog>
  </RegisterChecker>
);

export const Recharge = ({ isOpen, onClick }) => {
  const [pse, setPse] = useState(null);
  const { origin, pathname } = new URL(window.location.href);
  const [step, setStep] = useState(0);
  const url = pathname !== '/completar-perfil-colombia'
    ? `${origin}${pathname}`
    : `${origin}${routes.profile.route}`;

  const {
    formProps,
    stateFormProps: {
      isLoading, setLoading, setError, errorMessage,
    },
  } = useFormProps({ errorToolTip: true, required: true });
  const props = { formProps };
  const { user, type } = useUser();

  useEffect(() => {
    console.log('');
    return () => {
      setError(false, null);
      setStep(0);
    };
  }, []);

  const backModal = () => {
    setStep(0);
  };

  const handleStep = () => {
    setStep(step + 1);
  };
  const onSubmit = async (form) => {
    setLoading(true);
    if (type === userType.USER) {
      const amount = getAmount(form.amount);
      rechargeNowService({
        amount: parseInt(form.amount.replaceAll('.', ''), 10),
        countryIso: 'COP',
        countryId: 'COLOMBIA',
        userId: user?.info?.userId,
        vads_return_mode: 'GET',
        vads_url_return: url,
        vads_url_success: `${url}?recharge=true&amount=${amount}`,
        vads_url_refused: url,
        vads_url_cancel: url,
      })
        .then(({ data }) => {
          setPse(data);
        })
        .catch(({ message, messageFormat }) => {
          setLoading(false);
          if (
            message === 'MORE_THAN_ELECTRONIC_DEPOSIT_CAPACITY'
            || message === 'MAX_MONTHLY_RECHARGES_REACHED'
          ) {
            setStep(1);
            return;
          }

          setError(true, messageFormat);
        });
    } else {
      rechargeCompany({
        amount: parseInt(form.amount.replaceAll('.', ''), 10),
        companyId: user?.companyId,
        countryIso: 'COP',
        countryId: 'COLOMBIA',
        vads_return_mode: 'GET',
        vads_url_return: url,
        vads_url_success: url,
        vads_url_refused: url,
        vads_url_cancel: url,
      })
        .then(({ data }) => {
          setPse(data);
        })
        .catch(({ messageFormat }) => {
          setLoading(false);
          setError(true, messageFormat);
        });
    }
  };

  const name = type === userType.USER
    ? `${user?.info?.firstName} ${user?.info?.firstLastName}`
    : user?.info?.name;

  if (step !== 0) {
    return (
      <Container
        isOpen={isOpen}
        onBack={backModal}
        onClose={onClick}
        onClick={(e) => e.stopPropagation()}
      >
        <RechageMoneySimpleAccount
          step={step}
          onClose={onClick}
          nextStep={handleStep}
          {...props}
        />
      </Container>
    );
  }

  return (
    <Container isOpen={isOpen} onBack={onClick} onClose={onClick}>
      <Figure width="170px" height="150px">
        <img
          src={rechangeWallet}
          alt="persona con un computador"
          style={{ width: '100%', height: '100%' }}
        />
      </Figure>
      <TextLight size={20} mb={5} weight={weight.BOLD} color={colors.primary}>
        Recargar en mi cartera
      </TextLight>
      <TextLight mb={20} size={14}>
        Nombre:
        <span style={{ fontWeight: weight.SEMI_BOLD, marginLeft: '3px' }}>
          {capitalizeFirstLetterPhrase(name)}
        </span>
      </TextLight>

      <form>
        <InputFormatNumber
          title="¿Cuánto quieres recargar?"
          name="amount"
          placeholder="Desde 50.000 COP"
          validate={{
            max: (value) => getAmount(value) >= 50000
              || 'El monto ingresado debe ser mayor a  50.000',
            1000: (value) => getAmount(value) % 1000 === 0
              || 'El monto ingreso debe ser múltiplo de mil.',
          }}
          styles={{ textAlign: 'start', maxWidth: '330px', fontSize: '15px' }}
          {...formProps}
        />
        <ErrorText text={errorMessage} />
        <Div width="100%">
          <Hollow
            isLoading={isLoading}
            isSubmit
            size={14}
            onColorType="primary"
            style={{ borderRadius: '20px', padding: '10px 0', width: '220px' }}
            onClick={formProps.handleSubmit(onSubmit)}
          >
            Recargar
          </Hollow>
        </Div>
      </form>

      <PseForm pse={pse} />
    </Container>
  );
};
