import React from 'react';
import {
  FacebookIcon,
  LinkedinInIcon,
  YoutubeIcon,
  InstagramIcon,
  WhatsappIcon,
} from 'icons';
import styled from 'styled-components';
import { FlexCenter, colors, spaces } from 'globalStyle';

export const Box = ({
  children, height, width, direction, content, color,
}) => (
  <Icons
    direction={direction}
    content={content}
    width={width}
    height={height}
    color={color}
  >
    {children}
  </Icons>
);

export const NetworksShare = ({
  url,
  title,
  description,
  direction,
  content,
  height,
  width,
  iconProps,
  itemMargin,
  marginTop,
}) => (
  <Icons
    direction={direction}
    content={content}
    height={height}
    width={width}
    itemMargin={itemMargin}
    marginTop={marginTop}
  >
    <IconAnchor
      {...iconProps}
      target="_blank"
      rel="noopener noreferrer"
      href={`https://www.facebook.com/sharer/sharer.php?&u=${url}`}
    >
      <FacebookIcon />
    </IconAnchor>
    <IconAnchor
      {...iconProps}
      target="_blank"
      rel="noopener noreferrer"
      href={`https://api.whatsapp.com/send?text=${title} - ${description} Visita ${url}`}
    >
      <WhatsappIcon />
    </IconAnchor>
    <IconAnchor
      {...iconProps}
      target="_blank"
      rel="noopener noreferrer"
      href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${description}`}
    >
      <LinkedinInIcon />
    </IconAnchor>
  </Icons>
);

export const SocialNetworks = ({
  mb,
  mr,
  direction,
  content,
  color,
  marginTop,
}) => (
  <Icons
    mb={mb}
    mr={mr}
    direction={direction}
    content={content}
    color={color}
    marginTop={marginTop}
  >
    <a
      href="https://www.facebook.com/inversiones.bloom"
      target="_blank"
      rel="noreferrer"
    >
      <FacebookIcon />
    </a>
    <a
      href="https://www.instagram.com/inversionesbloom/"
      target="_blank"
      rel="noreferrer"
    >
      <InstagramIcon />
    </a>
    <a
      href="https://www.linkedin.com/company/bloom-crowdfunding/"
      target="_blank"
      rel="noreferrer"
    >
      <LinkedinInIcon />
    </a>
    <a
      href="https://www.youtube.com/@Inversionesbloom"
      target="_blank"
      rel="noreferrer"
    >
      <YoutubeIcon />
    </a>
  </Icons>
);

export const Icons = styled.div`
  ${FlexCenter};
  flex-direction: ${(props) => props.direction || 'column'};
  justify-content: ${(props) => props.content && props.content};
  margin-top: ${(props) => props.marginTop || '5px'};

  svg {
    width: ${(props) => props.width || '2em'};
    height: ${(props) => props.height || '2em'};
    color: ${(props) => props.color || colors.GRAY_1};
    margin: ${(props) => props.itemMargin || '5px'};
  }
`;

const IconAnchor = styled.a`
  svg {
    ${spaces}
  }
`;
