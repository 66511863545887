/* eslint-disable no-shadow */
import {
  FlexCenter, spaces, colors, Flex,
} from 'globalStyle';
import styled, { css } from 'styled-components';

export const SyledText = styled.div`


    color: ${(props) => props.color && props.color};
    text-transform: ${(props) => (props.isUpper ? 'uppercase' : 'unset')};
    text-decoration: ${(props) => props.decoration && props.decoration};
    text-transform: ${(props) => props.transform && props.transform};
    font-family: "Montserrat", sans-serif;
    font-size: ${(props) => props.size && props.size}px;
    font-weight: ${(props) => props.weight && props.weight};
    text-align: ${(props) => props.align && props.align};
    font-style: ${(props) => props.fontStyle && props.fontStyle};
    overflow-wrap: ${(props) => props.overflowWrap || 'break-word'};
    line-height:${(props) => props.lineHeight && props.lineHeight};
    align-self:${(props) => props.alignSelf && props.alignSelf};
    max-width:${(props) => props.maxWidth && props.maxWidth};

    ${(props) => props.isCenter && css`
        ${FlexCenter}
        text-align: center;
    `}

    ${(props) => props.isFlex && css`
        ${Flex}
    `}

    height: ${(props) => props.height && props.height};
    ${(props) => props.isOverflowy && css`
        
        overflow-y: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            background: ${(props) => props.scrollColor || colors.WHITE};
            width: ${(props) => props.width || 8}px;     
            height: ${(props) => props.height && 8}px;
        }
        &::-webkit-scrollbar-track {
            background: ${(props) => props.scrollColor || colors.WHITE};
        }
        &::-webkit-scrollbar-thumb {
            background: ${(props) => props.thumbColor || colors.GRAY_4};
            border-radius: ${(props) => props.radius || 20}px;
        }
    `}

    ${(props) => props.isOverflow && css`
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `}

    ${spaces}

    width: ${(props) => props.width || '100%'};

    ${(props) => props.isInline && css`
        display: inline-block;
        width: auto;
    `};


    @media (max-width: 1023px) {
        font-size: ${(props) => props.mdsize};
        font-weight: ${(props) => props.weight && props.weight};
    }
`;
