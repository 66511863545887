/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'hooks';
import styled, { css } from 'styled-components';
import { Title, SubTitle } from 'components/Texts';
import { colors, spaces } from 'globalStyle';
import { Box } from '@material-ui/core';
import { Input } from './Input';

export const InputEmail = ({ noLabel, placeholder, ...rest }) => (
  <Input
    label={noLabel ? null : 'Correo electrónico'}
    placeholder={placeholder || 'example@hello.com'}
    regexPattern={{ value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Correo invalido' }}
    name="email"
    type="email"
    {...rest}
  />
);
export const InputEmailFooter = ({ noLabel, placeholder, ...rest }) => {
  const isDesktop = useMediaQuery();
  const [disabled, setDisabled] = useState(false);
  const [send, setSend] = useState(false);
  const handleSend = () => {
    setSend(true);
  };

  useEffect(() => {
    setInterval(() => {
      setSend(false);
    }, 10000);
  }, [send]);
  return (
    <>
      {send ? (
        <Box marginBottom="19px">
          <Title isCenter color="white" size={19}>
            ¡Gracias por suscribirte!
          </Title>
          <SubTitle isCenter color="white" size={12}>
            Nos encontramos en nuestro siguiente newsletter
          </SubTitle>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection={!isDesktop && 'column'}
          alignItems="center"
          width="100%"
          justifyContent="space-between"
        >
          <Box
            width={isDesktop ? '65%' : '80%'}
          >
            <Input
              label={noLabel ? null : 'Correo electrónico'}
              placeholder={placeholder || 'example@hello.com'}
              regexPattern={{ value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Correo invalido' }}
              name="email"
              type="email"
              {...rest}
            />
          </Box>
          <Button
            mb={!isDesktop && 30}
            isSubmit
            onClick={handleSend}
            {...rest}
          >
            Enviar
          </Button>
        </Box>
      )}
    </>
  );
};

export const InputConfirmEmail = ({ getValues, placeholder, ...rest }) => (
  <Input
    label="Confirma tu correo electrónico"
    placeholder="example@hello.com"
    validate={(value) => value === getValues('email') || 'Los correos deben coincidir'}
    name="confirmEmail"
    type="email"
    getValues={getValues}
    {...rest}
  />
);

const Button = styled.button`
      ${spaces};
      color: ${colors.WHITE};
      border: 2px solid ${colors.WHITE};
      border-radius: 25px;
      font-size: 15px;
      padding: 8px 31px;

      ${(props) => (props.actived && !props.disabled) && css`
        background-color: ${colors.WHITE};
        border: 1px solid ${colors.WHITE};
        color: ${colors.GRAY_1};
      `}
    

      &:hover:not([disabled]){
        background-color: ${colors.WHITE};
        border: 1px solid ${colors.WHITE};
        color: ${colors.GRAY_1};
    }
`;
