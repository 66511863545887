/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { routes } from 'routes';
import { userType } from 'types';
import { colors } from 'globalStyle';
import { ErrorText } from 'components/Form';
import { Box, Grid } from '@material-ui/core';
import ReactCodeInput from 'react-code-input';
import { Title, Text } from 'components/Texts';
import { useUser, useLocalStorage } from 'hooks';
import { getNavigator } from 'util/getNavigatorBrowser';
import CustomDialog from 'components/Modals/CustomDialog';
import {
  useRegisterInvestor, registerCompany as rC, useSendOTP, registerCompanySendOtp,
} from 'services';
import { Ghost, Hollow, hollowColorType } from 'components/Buttons';
import { sanitizeSingleFields } from 'util/objects';

import RegisterMobileHeader from './RegisterMobileHeader';
import { squareSmStyle, squareStyle } from '../../Login/components/LoginOTP';

export default function RegisterOTP({
  isDesktop, onBack, onClose, onFinish, open, formValues, type = userType.USER, login: companyLogin,
  sendOTP: customSendOTP, checkOTP, title, isLoading: customIsLoading, error: customError, fullSize,
  headerProps, modalTitle, showMobileHeader = true, modalHeader, hideControls,
}) {
  const { login } = useUser();
  const browserId = getNavigator();
  const [time, setTime] = useState();
  const [code, setCode] = useState('');
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();
  const { setLocalStorage: setSeed } = useLocalStorage('seed');
  const registerCompany = useMemo(() => () => {
    setIsLoading(true);
    const body = JSON.parse(JSON.stringify(formValues));
    body.otp = code;
    body.browserId = browserId;
    rC(body)
      .then(({ data }) => {
        companyLogin({ data, userType: userType.COMPANY });
        if (data.sessionItem.requester.seed) {
          setSeed(data.sessionItem.requester.seed);
        }
        onFinish();
      })
      .catch((v) => {
        setError(v.messageFormat);
      })
      .finally(() => setIsLoading(false));
  }, [formValues, code]);
  const sendOTP = () => {
    const { email, documentType, documentNumber } = (formValues || {});
    setTime(300000);
    (customSendOTP || useSendOTP)({
      email, documentType, documentNumber, phoneNumber: '',
    })
      .catch(({ messageFormat }) => {
        setTime(0);
        setError(messageFormat);
      });
  };
  const sendOTPCompany = useMemo(() => () => {
    const body = sanitizeSingleFields(formValues,
      ['email', 'phoneNumber', 'legalRepEmail']);
    body.documentType = formValues.legalRepDocType;
    body.nit = `${formValues.nit}${formValues.verificationDigit || ''}`;
    setTime(300000);
    setIsLoading(true);
    registerCompanySendOtp(body)
      .catch((v) => {
        setTime(0);
        setError(v.messageFormat);
      })
      .finally(() => setIsLoading(false));
  }, [formValues]);
  useEffect(() => {
    if (time) {
      const timer = setInterval(() => {
        setTime((currentTime) => (currentTime > 0 ? currentTime - 1000 : 0));
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
    return undefined;
  }, [time]);
  const register = () => {
    setIsLoading(true);
    const { ...values } = formValues;
    useRegisterInvestor({
      ...values,
      otp: code,
      browserId,
      beingRegister: 'COLOMBIA',
      isQualifiedInvestor: values.isQualifiedInvestor === 'true',
    })
      .then(({ data, message }) => {
        if (message === 'QUALIFIED_INVESTOR') {
          onFinish('QUALIFIED');
        } else {
          if (data.sessionItem.requester.seed) {
            setSeed(data.sessionItem.requester.seed);
          }
          login({
            data,
            userType: userType.USER,
          });
          onFinish();
        }
      })
      .catch(({ messageFormat, message }) => {
        setError(messageFormat || message);
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <CustomDialog
      pt="28px"
      px="20px"
      open={open}
      header={modalHeader}
      fullSizeNavBar={fullSize}
      onBack={!hideControls && onBack}
      onClose={!hideControls && onClose}
      height={isDesktop ? '530px' : '466px'}
      width={isDesktop ? '615px' : (!fullSize && '284px')}
    >
      {(fullSize && showMobileHeader)
        ? (
          <RegisterMobileHeader
            {...headerProps}
          />
        )
        : (
          <Title isCenter>
            {modalTitle || (isDesktop ? 'Ingresa código de validación' : 'Ingresa OTP')}
          </Title>
        )}
      <Box
        width="435px"
        margin="auto"
        maxWidth="100%"
        marginTop="19px"
        marginBottom={isDesktop ? '36px' : '26px'}
      >
        {title || (
          <Text isCenter size={isDesktop ? 14 : 12}>
            Estas a un paso de crear tu cuenta,
            a continuación digita el código que te enviamos a tu correo electrónico.
          </Text>
        )}
      </Box>
      <Box
        margin="auto"
        width="fit-content"
      >
        <ReactCodeInput
          autoFocus
          fields={6}
          value={code}
          forceUppercase
          inputStyle={isDesktop ? squareStyle : squareSmStyle}
          onChange={(e) => setCode(e)}
        />
        {(customError || error) && (<ErrorText text={(customError || error)} size={15} align="start" mt="9" />)}
      </Box>
      <Text
        mb={9}
        mt={isDesktop ? 59 : 40}
        isCenter
        size={14}
      >
        {time > 0 ? moment.utc(time).format('mm:ss') : '¿No te llegó ningún código?'}
      </Text>
      <Ghost
        isCenter
        isSubmit
        size={14}
        underline
        type="button"
        align="center"
        disabled={time}
        width="fit-content"
        color={time ? colors.input.inactive : colors.primary}
        onClick={!time && (type === userType.USER ? sendOTP : sendOTPCompany)}
      >
        Reenviar código
      </Ghost>
      <Box
        width="372px"
        margin="auto"
        maxWidth="100%"
        marginTop={isDesktop ? '17px' : '30px'}
        marginBottom={isDesktop ? '25px' : '22px'}
      >
        <Hollow
          isSubmit
          fullWidth
          disabled={code.length < 6}
          onColorType={hollowColorType.primary}
          isLoading={customIsLoading || isLoading}
          onClick={
            checkOTP ? () => checkOTP(code) : (type === userType.USER ? register : registerCompany)
          }
        >
          Continuar
        </Hollow>
      </Box>
      <Grid container justify="center">
        <Ghost
          isCenter
          size={13}
          underline
          type="button"
          width="fit-content"
          color={colors.GRAY_11}
          href={routes.message.contactUs}
        >
          ¿Necesitas ayuda?
        </Ghost>
      </Grid>
    </CustomDialog>
  );
}
