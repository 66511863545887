export const percent = (currentAmount, minAmount) => {
  if (typeof currentAmount !== 'number' || typeof minAmount !== 'number') return 0;
  if (currentAmount < 0 || minAmount < 0) {
    return 0;
  }
  return Math.round(((currentAmount * 100) / minAmount) * 100) / 100;
};

export const calculatePercentage = (
  numberToCalculate,
  numberOfDecimals = 2,
) => {
  if (typeof numberToCalculate === 'number') {
    return +(numberToCalculate * 100).toFixed(numberOfDecimals);
  }
  return 0;
};

export const handlePorcentage = (value, decimals = 4) => {
  const newData = parseFloat(value);
  if (newData >= 1) {
    const division = newData / 100;
    return Number(division.toFixed(decimals));
  }
  return newData;
};
