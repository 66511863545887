import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { mapTowns } from 'services';
import { useFormProps } from 'hooks';
import { Title } from 'components/Texts';
import Container from 'components/Container';
import {
  Select, Input, InputRadio, InputOnlyNumbers,
} from 'components/Form';
import { Box, Grid, styled } from '@material-ui/core';
import { documentsTypeMinimized } from 'types';

import RegisterModalNextButton from './RegisterModalNextButton';

const getBool = (v) => {
  if (v) {
    if (v === 'N') {
      return 'false';
    }
    return 'true';
  }
  return undefined;
};

const toBool = (value) => !!(value === 'true' || value === true);

export default function RegisterLRLegal({
  isDesktop, info, logout, isLoading, sendData, onInfoCompletedValidation, dropdownsInfo, error,
}) {
  const {
    formProps,
  } = useFormProps({ errorToolTip: false, errorText: true, required: true });
  const [cities, setCities] = useState([]);
  const otherLR = formProps.watch('hasAnotherLegalRep');
  const familyExposed = formProps.watch('hasRelativePublicExposed');
  const hasPoliticalExposition = formProps.watch('hasPoliticalExposition');
  const hasPublicRecognition = formProps.watch('hasPublicRecognition');
  const financialReferenceDepartment = formProps.watch('pepFinancialReferenceDepartment');
  const familyExposedRef = useRef();
  const anotherLegalRepRef = useRef();
  const endSecondLRFormRef = useRef();
  useEffect(() => {
    if (financialReferenceDepartment) {
      mapTowns(financialReferenceDepartment)
        .then(({ data }) => {
          setCities(data);
          if (info.legalRepresentative.legalRepPepsFinancialReferenceCity) {
            formProps.setValue('pepFinancialReferenceCity', info.legalRepresentative.legalRepPepsFinancialReferenceCity);
          }
        });
    }
  }, [financialReferenceDepartment]);
  useEffect(() => {
    if (info) {
      formProps.setValue('hasForeignLegalRep', getBool(info.legalRepresentative.foreignLegalRep));
      formProps.setValue('hasPoliticalExposition', getBool(info.legalRepresentative.politicallyExposedLegalRep));
      formProps.setValue('hasPublicRecognition', getBool(info.legalRepresentative.publicRecognitionLegalRep));
      formProps.setValue('hasRelativePublicExposed', getBool(info.legalRepresentative.legalRepFamiliarPeps));
      if (info.secondLegalRepresentative) {
        formProps.setValue('hasAnotherLegalRep', `${info.secondLegalRepresentative.secondLegalRepReport}`);
      }

      setTimeout(() => {
        formProps.setValue('relativeNames', info.legalRepresentative.legalRepFamPepsNames);
        formProps.setValue('relativeFirstLastName', info.legalRepresentative.legalRepFamPepsFirstLastName);
        formProps.setValue('relativeSecondLastName', info.legalRepresentative.legalRepFamPepsSecondLastName);
        formProps.setValue('relativeRelation', info.legalRepresentative.legalRepFamPepsParent);
        formProps.setValue('pepFinancialReferenceAddress', info.legalRepresentative.legalRepPepsFinancialReferenceAddress);
        formProps.setValue('pepFinancialReferenceDepartment', info.legalRepresentative.legalRepPepsFinancialReferenceDepartment);
        formProps.setValue('pepFinancialReferencePhone', info.legalRepresentative.legalRepPepsFinancialReferencePhone);
        formProps.setValue('pepFinancialEstablishmentName', info.legalRepresentative.legalRepPepsFinancialReferenceEstablishmentName);
        if (info.secondLegalRepresentative) {
          formProps.setValue('anotherLegalRepDocumentType', info?.secondLegalRepresentative?.documentType);
          formProps.setValue('anotherLegalRepDocumentNumber', info?.secondLegalRepresentative?.documentNumber);
          formProps.setValue('anotherLegalRepNames', info?.secondLegalRepresentative?.names);
          formProps.setValue('anotherLegalRepFirstLastName', info?.secondLegalRepresentative?.lastNameOne);
          formProps.setValue('anotherLegalRepSecondLastName', info?.secondLegalRepresentative?.lastNameTwo);
        }
      }, 1000);
    }
  }, [info]);
  useEffect(() => {
    if (isDesktop && hasPublicRecognition) {
      familyExposedRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [hasPublicRecognition]);
  useEffect(() => {
    if (isDesktop && familyExposed) {
      anotherLegalRepRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [familyExposed]);
  useEffect(() => {
    if (isDesktop && otherLR === 'true') {
      endSecondLRFormRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [otherLR]);
  useEffect(() => {
    if (info && onInfoCompletedValidation) {
      let completed = true;
      const fields = ['foreignLegalRep', 'politicallyExposedLegalRep', 'publicRecognitionLegalRep', 'legalRepFamiliarPeps', 'secondLegalRepresentative'];
      completed = fields.every((v) => info.legalRepresentative[v] !== null);
      if (completed && info.legalRepresentative.legalRepFamiliarPeps === 'S') {
        const foreignOpsFields = ['legalRepFamPepsNames', 'legalRepFamPepsFirstLastName', 'legalRepFamPepsSecondLastName', 'legalRepFamPepsParent'];
        completed = foreignOpsFields.every((v) => info.legalRepresentative[v] !== null);
      }
      if (completed && info.legalRepresentative.secondLegalRepReport !== null) {
        const foreignOpsFields = ['documentType', 'documentNumber', 'names', 'lastNameOne', 'lastNameTwo'];
        completed = foreignOpsFields.every((v) => info.secondLegalRepresentative[v] !== null);
      }
      onInfoCompletedValidation(completed);
    }
  }, [info, onInfoCompletedValidation]);
  const send = useCallback((data) => {
    const newData = { ...data };
    newData.hasForeignLegalRep = toBool(newData.hasForeignLegalRep);
    newData.hasAnotherLegalRep = toBool(newData.hasAnotherLegalRep);
    newData.hasPoliticalExposition = toBool(newData.hasPoliticalExposition);
    newData.hasPublicRecognition = toBool(newData.hasPublicRecognition);
    newData.hasRelativePublicExposed = toBool(newData.hasRelativePublicExposed);
    sendData(newData);
  }, []);
  return (
    <Container>
      <FormContainer
        id="LFLegalForm"
        component="form"
        isDesktop={isDesktop}
        onSubmit={formProps.handleSubmit(send)}
      >
        <Question
          isDesktop={isDesktop}
          formProps={formProps}
          name="hasForeignLegalRep"
          question="¿Eres extranjero?"
        />
        <Question
          isDesktop={isDesktop}
          formProps={formProps}
          name="hasPoliticalExposition"
          question="¿Eres una Persona Políticamente Expuesta?"
        />
        <Question
          isDesktop={isDesktop}
          formProps={formProps}
          name="hasPublicRecognition"
          question="¿Gozas de reconocimiento público?"
        />
        {(hasPublicRecognition === 'true' || hasPoliticalExposition === 'true') && (
          <Grid container justify="space-between" style={{ textAlign: 'start' }}>
            <InputContainer isDesktop={isDesktop}>
              <Input
                name="pepFinancialEstablishmentName"
                label="Nombre de entidad de referencia financiera "
                {...formProps}
              />
            </InputContainer>
            <InputContainer isDesktop={isDesktop}>
              <Input
                name="pepFinancialReferenceAddress"
                label="Dirección de entidad de referencia financiera"
                {...formProps}
              />
            </InputContainer>
            <InputContainer isDesktop={isDesktop}>
              <Select
                defaultValue=""
                name="pepFinancialReferenceDepartment"
                label="Departamento de entidad de referencia financiera"
                {...formProps}
              >
                {Object.entries(dropdownsInfo?.departments).map(([key]) => (
                  <option
                    key={key}
                    value={key}
                  >
                    {key}
                  </option>
                ))}
              </Select>
            </InputContainer>
            <InputContainer isDesktop={isDesktop}>
              <Input
                name="pepFinancialReferencePhone"
                label="Teléfono de entidad de referencia financiera"
                {...formProps}
              />
            </InputContainer>
            <InputContainer isDesktop={isDesktop}>
              <Select
                defaultValue=""
                name="pepFinancialReferenceCity"
                label="Ciudad de entidad de referencia financiera"
                {...formProps}
              >
                {cities.map((key) => (
                  <option
                    key={key}
                    value={key}
                  >
                    {key}
                  </option>
                ))}
              </Select>
            </InputContainer>
          </Grid>
        )}
        <Question
          isDesktop={isDesktop}
          formProps={formProps}
          innerRef={familyExposedRef}
          name="hasRelativePublicExposed"
          question="¿Eres familiar de una Persona Publicamente Expuesta?"
        />
        {familyExposed === 'true' && (
          <Grid container justify="space-between" style={{ textAlign: 'start' }}>
            <InputContainer isDesktop={isDesktop}>
              <Input
                name="relativeNames"
                label="Nombre(s)"
                {...formProps}
              />
            </InputContainer>
            <InputContainer isDesktop={isDesktop}>
              <Input
                name="relativeFirstLastName"
                label="Primer Apellido"
                {...formProps}
              />
            </InputContainer>
            <InputContainer isDesktop={isDesktop}>
              <Input
                name="relativeSecondLastName"
                label="Segundo Apellido"
                {...formProps}
              />
            </InputContainer>
            <InputContainer isDesktop={isDesktop}>
              <Select
                defaultValue=""
                label="Parentesco"
                name="relativeRelation"
                {...formProps}
              >
                {Object.entries(dropdownsInfo?.bccFamilyRelationshipType)?.map(([value, name]) => (
                  <option key={value} value={value}>
                    {name}
                  </option>
                ))}
              </Select>
            </InputContainer>
          </Grid>
        )}
        <Question
          isDesktop={isDesktop}
          formProps={formProps}
          name="hasAnotherLegalRep"
          innerRef={anotherLegalRepRef}
          mb={otherLR !== 'true' && familyExposed !== 'true' && '100px'}
          question="¿La empresa cuenta con otro representante legal?"
        />
        {otherLR === 'true' && (
          <Grid container justify="space-between" style={{ textAlign: 'start' }}>
            <InputContainer isDesktop={isDesktop}>
              <Select
                defaultValue=""
                label="Tipo de documento"
                name="anotherLegalRepDocumentType"
                {...formProps}
              >
                {documentsTypeMinimized.map((document) => (
                  <option key={document.idType} value={document.name}>
                    {document.detail}
                  </option>
                ))}
              </Select>
            </InputContainer>
            <InputContainer isDesktop={isDesktop}>
              <InputOnlyNumbers
                label="Número de documento"
                name="anotherLegalRepDocumentNumber"
                {...formProps}
              />
            </InputContainer>
            <InputContainer isDesktop={isDesktop}>
              <Input
                label="Nombre(s)"
                name="anotherLegalRepNames"
                {...formProps}
              />
            </InputContainer>
            <InputContainer isDesktop={isDesktop}>
              <Input
                label="Primer Apellido"
                name="anotherLegalRepFirstLastName"
                {...formProps}
              />
            </InputContainer>
            <InputContainer isDesktop={isDesktop} ref={endSecondLRFormRef}>
              <Input
                label="Segundo Apellido"
                name="anotherLegalRepSecondLastName"
                {...formProps}
              />
            </InputContainer>
          </Grid>
        )}
      </FormContainer>
      <RegisterModalNextButton
        error={error}
        logout={logout}
        form="LFLegalForm"
        isLoading={isLoading}
        isDesktop={isDesktop}
        disabled={
          !hasPoliticalExposition || !hasPublicRecognition || !familyExposed || !otherLR
        }
      />
    </Container>
  );
}

const Question = ({
  isDesktop, formProps, question, name, mt, innerRef,
}) => (
  <QuestionContainer
    item
    mt={mt}
    container
    ref={innerRef}
    isDesktop={isDesktop}
    justify={isDesktop ? 'space-between' : 'center'}
  >
    <Title
      size={14}
      mb={!isDesktop && 17}
      isCenter={!isDesktop}
      width={isDesktop && 'fit-content'}
    >
      {question}
    </Title>
    <Grid
      container
      style={{
        width: 'fit-content',
      }}
    >
      <InputRadio
        label="Sí"
        name={name}
        value
        internalProps={{
          pl: isDesktop ? 17 : 7,
        }}
        {...formProps}
      />
      <div style={{ marginRight: '27px' }} />
      <InputRadio
        label="No"
        name={name}
        value={false}
        internalProps={{
          pl: isDesktop ? 17 : 7,
        }}
        {...formProps}
      />
    </Grid>
  </QuestionContainer>
);

const QuestionContainer = styled(Grid)(({ isDesktop, mt, mb }) => ({
  marginTop: mt,
  marginBottom: mb || (isDesktop ? '46px' : '40px'),
}));

const FormContainer = styled(Box)(({ isDesktop }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: isDesktop && 'auto',
  maxHeight: isDesktop && '327px',
  marginBottom: isDesktop && '40px',
  padding: isDesktop ? '43px 30px 0 20px' : '34px 0',
}));

const InputContainer = styled(Grid)(({ isDesktop }) => ({
  width: isDesktop ? '300px' : '100%',
}));
