import React from 'react';
import { colors } from 'globalStyle';
import { Box } from '@material-ui/core';
import Container from 'components/Container';
import { Title, Text } from 'components/Texts';
import MainImage from 'assets/persons/computer-2.svg';
import CustomDialog from 'components/Modals/CustomDialog';
import { Hollow, hollowColorType } from 'components/Buttons';

export default function RecoverPasswordError({
  open, isDesktop, onClose, error, onBack,
}) {
  return (
    <CustomDialog
      open={open}
      onBack={onBack}
      fullSizeNavBar={!isDesktop}
      width={isDesktop && '615px'}
      height={isDesktop && '530px'}
      onClose={isDesktop && onClose}
      px={isDesktop ? '85px' : '30px'}
      pt={isDesktop ? '18px' : '100px'}
    >
      <Container>
        <Box
          alt=""
          component="img"
          src={MainImage}
          width={isDesktop ? '177px' : '149px'}
          height={isDesktop ? '180px' : '152px'}
        />
        <Container pr={!isDesktop && 25} pl={!isDesktop && 25}>
          <Title
            color={colors.primary}
            mt={isDesktop ? 27 : 64}
            mb={isDesktop ? 19 : 18}
            size={isDesktop ? 15 : 14}
          >
            ¡UPS!
            <br />
            Tenemos un problema.
          </Title>
          <Text
            size={isDesktop ? 16 : 15}
          >
            {error}
          </Text>
        </Container>
        <Box
          width="100%"
          display="flex"
          alignContent="center"
          justifyContent="center"
          maxWidth={isDesktop ? '330px' : '100%'}
          marginTop={isDesktop ? '40px' : '96px'}
        >
          <Hollow
            isSubmit
            fullWidth
            size={14}
            padding="12.5px"
            onClick={onBack}
            onColorType={hollowColorType.primary}
          >
            Regresar
          </Hollow>
        </Box>
      </Container>
    </CustomDialog>
  );
}
