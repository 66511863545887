import React from 'react';
import { routes } from 'routes';
import { colors } from 'globalStyle';
import { Box, Grid } from '@material-ui/core';
import { Text, Title, Bold } from 'components/Texts';
import Image from 'assets/persons/goal-3.svg';
import ModalContent from 'components/Modals/ModalContent';
import { Hollow, hollowColorType } from 'components/Buttons';

export default function RegisterCompletionStep1Qualified({ onClose, isDesktop }) {
  const textProps = {
    isCenter: true,
    color: colors.WHITE,
  };
  return (
    <ModalContent
      width="615px"
      height="560px"
      onClose={onClose}
      bg={colors.primary}
      color={colors.WHITE}
      pt={isDesktop && '48px'}
      iconColor={colors.WHITE}
    >
      <Grid container direction="column" alignItems="center">
        <Box
          src={Image}
          component="img"
          width="162.869px"
          height="156.765px"
        />
        <Title mt={isDesktop ? 7.23 : 23.83} mb={24} size={24} {...textProps}>¡Felicidades!</Title>
        <Text size={isDesktop ? 16 : 15} lineHeight={!isDesktop && '166.667%'} maxWidth="449px" {...textProps}>
          <p>
            Has concluido la primera fase del registro,
            cada vez estás más cerca de completar tu perfil y
            <Bold>{' poder invertir.'}</Bold>
          </p>
        </Text>
        <Text mt={30} size={isDesktop ? 16 : 15} lineHeight={!isDesktop && '166.667%'} maxWidth="414px" {...textProps}>
          <p>
            Uno de nuestros agentes se comunicará contigo para completar el proceso de registro,
            una vez completado tu perfil,
            podrás acceder a todos los proyectos e invertir en el que desees.
          </p>
        </Text>
      </Grid>
      <Box
        display="flex"
        flexWrap="wrap"
        marginTop="30px"
        gridColumnGap="19px"
        alignItems="baseline"
        justifyContent="center"
        flexDirection={isDesktop ? 'row' : 'column-reverse'}
      >
        <Box
          width={isDesktop ? '220px' : '100%'}
        >
          <Hollow
            fullWidth
            onClick={onClose}
            href={routes.invert}
            onColorType={hollowColorType.primaryBorder}
          >
            Explorar proyectos
          </Hollow>
        </Box>
      </Box>
    </ModalContent>
  );
}
