import { weight } from 'globalStyle';
import styled from 'styled-components';

export const AButton = styled.a`
    background: none!important;
    border: none;
    padding: 0!important;
    color: ${(props) => props.color || '#069'};
    text-decoration: underline;
    cursor: pointer;
    font-weight: ${(props) => props.weight || weight.REGULAR};
    size: ${(props) => props.size}
`;
