import React from 'react';
import {
  Figure, Div, weight,
  colors,
} from 'globalStyle';
import { TextLight } from 'components/Texts';
import { Hollow } from 'components/Buttons';
import goal from 'assets/persons/goal.svg';
import { useMediaQuery } from 'hooks';

export const Goal = ({ onClick }) => {
  const isDesktop = useMediaQuery(1023);
  return (
    <Div isFlex direction="column" height="340px" justify="space-between">
      <Figure width="190px" height="190px">
        <img src={goal} alt="cambio realizado con exito" />
      </Figure>

      <TextLight size={isDesktop ? 20 : 16} weight={weight.BOLD} color={colors.primary}>
        Cambio de contraseña
      </TextLight>
      <TextLight size={isDesktop ? 16 : 14}>
        El cambio de contraseña se ha realizado exitosamente.
      </TextLight>
      <Hollow
        medium
        isSubmit
        onClick={onClick}
        style={{
          padding: '10px 0', width: '220px', height: '45px', borderRadius: '20px',
        }}
        onColorType="primary"
      >
        Aceptar
      </Hollow>
    </Div>
  );
};
