import { Fetch } from 'lib/Fetch';

export function interbankCompany({
  nameBank,
  amount,
  idTypeDocument,
  destinationDni,
  destinationName,
  destinationAccount,
  accountType,
  isFavorite,
}) {
  return new Fetch()
    .url('/company/interbank/send-interbank-transfer-company')
    .method('put')
    .userToken()
    .data({
      nameBank,
      amount,
      idTypeDocument,
      destinationDni,
      destinationName,
      destinationAccount,
      accountType,
      isFavorite,
    })
    .send();
}

export function interbankInversionist({
  nameBank,
  amount,
  destinationDni,
  destinationName,
  destinationAccount,
  accountType,
  idTypeDocument,
  isFavorite,
}) {
  return new Fetch()
    .url('/user/interbank/send-interbank-transfer-user')
    .method('put')
    .userToken()
    .data({
      nameBank,
      amount,
      destinationDni,
      destinationName,
      destinationAccount,
      accountType,
      idTypeDocument,
      isFavorite,
    })
    .send();
}

export function transactionVoucher(voucherNumber) {
  return new Fetch()
    .url(`/user/interbank/transaction-voucher/${voucherNumber}`)
    .method('get')
    .userToken()
    .send();
}

export function transactionVoucherCompany(voucherId) {
  return new Fetch()
    .url(`/company/interbank/transaction-voucher/${voucherId}`)
    .method('get')
    .userToken()
    .send();
}

export * from './lists';
