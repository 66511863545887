import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMountedState } from 'react-use';
import { updateNotifications } from 'store/notification';

export const useNotificationCache = () => {
  const isMounted = useMountedState();
  const dispatch = useDispatch();
  const notificationCache = useSelector(
    (store) => store.notifications.notifications,
  );

  const setNotificationInfo = useCallback((notificationInfo) => {
    if (isMounted()) {
      dispatch(updateNotifications(notificationInfo));
    }
  }, []);

  return {
    notificationCache,
    setNotificationInfo,
  };
};
