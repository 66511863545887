import React from 'react';
import {
  Div, weight, Figure, colors,
} from 'globalStyle';
import { TextLight } from 'components/Texts';
import { Loading } from 'components/Loading';
import { favoritesDelete, favoritesDeleteCompany } from 'services';
import { Submit, Hollow } from 'components/Buttons';
import { useUser, useMediaQuery } from 'hooks';
import { userType } from 'types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import closeIcon from 'assets/icons/closeIcon.svg';
import arrowLeft from 'assets/icons/arrowLeftHeader.svg';

const Container = ({
  children, isDesktop, handleClose, goToback, open,
}) => (
  <Dialog
    open={open}
    PaperProps={{
      style: {
        borderRadius: '20px',
        padding: `${isDesktop ? '30px' : '20px'}`,
      },
    }}
    maxWidth="md"
    onClick={(e) => e.stopPropagation()}
  >
    <DialogContent
      style={{
        width: `${isDesktop ? 'calc(320px - 60px)' : 'auto'}`,
        padding: '0',
        overflowY: 'initial',
      }}
      align="center"
    >
      <Div isFlex justify="space-between" margin="0 0 20px">
        <Figure
          style={{
            width: '25px',
            height: '25px',
            cursor: 'pointer',
          }}
          onClick={() => {
            goToback();
          }}
        >
          <img src={arrowLeft} alt="arrowLeft" />
        </Figure>
        <Div>
          <TextLight size={18} color={colors.primary} weight={weight.BOLD}>
            Favoritos
          </TextLight>
        </Div>
        <Figure
          style={{
            width: '18px',
            height: '18px',
            cursor: 'pointer',
          }}
          onClick={() => {
            handleClose();
          }}
        >
          <img src={closeIcon} alt="cerrar" />
        </Figure>
      </Div>
      <Div>{children}</Div>
    </DialogContent>
  </Dialog>
);

export default ({
  isOpen,
  handleClose,
  handleDataFavorite,
  setDataFavoriteEdit,
  goToback,
  goToEdit,
  fetch,
  favoriteAccounts,
  favoriteAccountsLoading,
  ...props
}) => {
  const { isLoading, setLoading } = props;
  const isDesktop = useMediaQuery(1023);
  const { type } = useUser();

  const deleteFavoriteAccount = (data) => {
    setLoading(true);
    if (type === userType.COMPANY) {
      favoritesDeleteCompany(data)
        .then(() => {
          setLoading(false);
          fetch();
        })
        .catch(() => {
          setLoading(false);
        });
    }
    if (type === userType.USER) {
      favoritesDelete(data)
        .then(() => {
          setLoading(false);
          fetch();
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const FavoriteItem = ({ account }) => (
    <Div
      width="265px"
      radius="15px"
      border="1px solid #E0E0E0"
      p="10px"
      mb={10}
    >
      <Div
        onClick={() => {
          handleDataFavorite(account);
          goToback();
        }}
        style={{ cursor: 'pointer' }}
      >
        <TextLight size={14} weight={weight.BOLD} align="start">
          {account?.name}
        </TextLight>
        <TextLight size={12} align="start">
          {account?.bankName}
        </TextLight>
        <TextLight size={12} align="start">
          {account?.accountNumber}
        </TextLight>
      </Div>

      <Div isFlex justify="space-between" margin="10px 0 0">
        <Hollow
          style={{ width: `${isDesktop ? '100px' : '100%'}` }}
          padding="7px 0"
          isSubmit
          onClick={() => deleteFavoriteAccount(account)}
          size="14px"
          isLoading={isLoading}
          onColorType="error"
        >
          Eliminar
        </Hollow>
        <Hollow
          style={{ width: `${isDesktop ? '100px' : '100%'}` }}
          isSubmit
          onClick={() => {
            goToEdit();
            setDataFavoriteEdit(account);
          }}
          size="14px"
          onColorType="white"
          color={colors.primary}
          isLoading={isLoading}
          padding="7px 0"
        >
          Editar
        </Hollow>
      </Div>
    </Div>
  );

  if (isLoading || favoriteAccountsLoading) {
    return (
      <Container
        isDesktop={isDesktop}
        handleClose={handleClose}
        goToback={goToback}
        open={isOpen}
      >
        <Loading />
      </Container>
    );
  }

  if (favoriteAccounts?.length === 0 || !favoriteAccounts) {
    return (
      <Container
        isDesktop={isDesktop}
        handleClose={handleClose}
        goToback={goToback}
        open={isOpen}
      >
        <TextLight number={4} mb={15} isCenter>
          No tienes ninguna cuenta guardada.
        </TextLight>
        <TextLight number={4} mb={15} isCenter>
          Si quieres agregar una, puedes hacerlo ingresando los datos de la
          cuenta donde vas a enviar el dinero y marcar la opción de
        </TextLight>
        <TextLight number={4} mb={15} isCenter weight={weight.BLACK}>
          “Recordar en favoritos”
        </TextLight>
        <TextLight number={4} mb={15} isCenter>
          Una vez agregada podrás eliminarla o editar la información.
        </TextLight>

        <Submit
          isSubmit
          padding="7px 0"
          width={100}
          onClick={() => {
            goToback();
          }}
        >
          Regresar
        </Submit>
      </Container>
    );
  }

  return (
    <Container
      isDesktop={isDesktop}
      handleClose={handleClose}
      goToback={goToback}
      open={isOpen}
    >
      {favoriteAccounts?.map((account) => (
        <FavoriteItem account={account} key={account.accountNumber} />
      ))}
    </Container>
  );
};
