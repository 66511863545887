import React from 'react';
import { colors } from 'globalStyle';
import { useMediaQuery } from 'hooks';
import { Box, Grid } from '@material-ui/core';
import { Title, Text, Bold } from 'components/Texts';
import CustomDialog from 'components/Modals/CustomDialog';
import { Hollow, hollowColorType } from 'components/Buttons';
import ChecklistIcon from 'assets/persons/filling-out-form.svg';

export default function RegisterWait({ open, onClose }) {
  const isDesktop = useMediaQuery();
  return (
    <CustomDialog
      px="30px"
      pt="54px"
      open={open}
      onClose={onClose}
      width={isDesktop ? '390px' : '284px'}
    >
      <Grid container direction="column" alignItems="center">
        <Box
          width="119px"
          height="112px"
          component="img"
          src={ChecklistIcon}
        />
        <Title size={16} mt={14} mb={14} color={colors.primary}>
          Validación de información
        </Title>
        <Text size={14}>
          <p>
            Por el momento estamos verificando tus datos, cuando tu cuenta esté lista
            <Bold>{' te notificaremos por medio de tu correo electrónico, '}</Bold>
            mientras tanto puedes explorar nuestra plataforma
            y familiarizarte con nuestros servicios
          </p>
        </Text>
        <Hollow
          mt={40}
          isSubmit
          fullWidth
          onClick={onClose}
          onColorType={hollowColorType.primary}
        >
          Entendido
        </Hollow>
      </Grid>
    </CustomDialog>
  );
}
