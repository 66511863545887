import React, { useCallback, useState, useMemo } from 'react';
import { routes } from 'routes';
import { userType } from 'types';
import { openPopUp, closePopUp } from 'store/popup';
import { useDispatch } from 'react-redux';
import { Modal } from 'components/Modals';
import { Line, colors } from 'globalStyle';
import { Box, Grid } from '@material-ui/core';
import { Title, Text } from 'components/Texts';
import { BasicInput } from 'components/Form/Basic';
import { getNavigator } from 'util/getNavigatorBrowser';
import ModalContent from 'components/Modals/ModalContent';
import { InputPassword, ErrorText } from 'components/Form';
import { useFormProps, useLocalStorage, useUser } from 'hooks';
import { userLoginService, userLoginCompanyService } from 'services';
import { companyStatusType, companyIsInStatus } from 'types/companyStateType';
import { InputNitValidation } from 'components/Form/InputNitValidation';
import {
  Hollow, Ghost, hollowColorType,
} from 'components/Buttons';

import LoginHeader from './LoginHeader';
import LoginOTP from './LoginOTP';
import { CLIENT_TYPE } from '../../components/MobileHeader';
import WaitQualifiedInvestor from './WaitQualifiedInvestor';
import RegisterWaitingVerificationModal from '../../Register/components/company/modals/RegisterWaitingVerificationModal';
import RegisterCompanySuccessful from '../../Register/components/company/modals/RegisterCompanySuccessful';

const TitleHeader = ({ isDesktop, clientType, dispatch }) => {
  if (isDesktop) {
    return (
      <>
        <Title isCenter size={24}>
          ¡Bienvenido de nuevo!
        </Title>
        <Text mt={7} mb={41} size={14} color={colors.GRAY_2} isCenter>
          ¿No tienes una cuenta?
          {' '}
          <Ghost
            ml={5}
            isSubmit
            size={14}
            underline
            color={colors.primary}
            onClick={() => dispatch(openPopUp({ type: 'register' }))}
          >
            Créala aquí
          </Ghost>
        </Text>
      </>
    );
  }
  return (
    <Box marginBottom="56px">
      <Title size={16} isCenter color={colors.primary}>
        {`${clientType === CLIENT_TYPE.COMPANY ? 'Receptor' : 'Inversionista'}`}
      </Title>
      <Text size={14} mt={12} isCenter>
        ¡Bienvenido de nuevo!
      </Text>
      <Line color={colors.primary} />
    </Box>
  );
};

function getBody(data, isCompany, seed, browserId) {
  const body = {
    password: data.password,
    seed: seed || '',
    browserId,
    otp: data?.otp || '',
  };
  if (isCompany) {
    body.nit = data.nitAlone + data.verificationDigit;
  } else {
    body.userIdentifier = data.id;
  }
  return body;
}

export default function LoginForm({
  isDesktop, onClose, onBack, onFinish, clientType,
}) {
  const { login } = useUser();
  const dispatch = useDispatch();
  const browserId = getNavigator();
  const [openOTP, setOpenOTP] = useState(false);
  const closeOTPModal = () => setOpenOTP(false);
  const [openQualified, setOpenQualified] = useState(false);
  const closeQualified = () => setOpenQualified(false);
  const [waitingValidation, setWaitingValidation] = useState(false);
  const closeWaitingValidation = useCallback(() => {
    setWaitingValidation(false);
    dispatch(closePopUp());
  }, []);
  const [waitingExternalHelp, setWaitingExternalHelp] = useState(false);
  const closeWaitingExternalHelp = useCallback(() => {
    setWaitingExternalHelp(false);
    dispatch(closePopUp());
  }, []);
  const { localValue, setLocalStorage } = useLocalStorage('seed');
  const isCompany = useMemo(() => clientType === CLIENT_TYPE.COMPANY, [clientType]);
  const sendFunction = useMemo(
    () => (clientType === CLIENT_TYPE.COMPANY
      ? userLoginCompanyService : userLoginService), [clientType],
  );
  const type = useMemo(() => {
    if (isCompany) {
      return userType.COMPANY;
    }
    return userType.USER;
  }, [clientType]);
  const commonModalProps = useMemo(() => ({
    fullSize: !isDesktop,
    width: isDesktop ? '666px' : '100%',
    height: isDesktop ? '530px' : '100%',
    borderRadius: isDesktop ? '20px' : '0px',
  }), [isDesktop]);
  const {
    formProps,
    stateFormProps: {
      isLoading,
      setLoading,
      errorMessage,
      setError,
    },
  } = useFormProps({ errorToolTip: true, required: true });
  const commonInputProps = {
    errorToolTip: false,
    errorText: true,
    fullWidth: true,
    width: '100%',
  };
  const onSuccess = ({ data }) => {
    // store login seed
    if (data.sessionItem.requester.seed) {
      setLocalStorage(data.sessionItem.requester.seed);
    }
    // store user data
    if (isCompany) {
      login({ data, userType: userType.COMPANY });
    } else {
      login({
        data,
        userType: userType.USER,
      });
    }
    onFinish();
  };
  const sendAuthData = useCallback((form) => {
    setLoading(true);
    const body = getBody(form, isCompany, localValue(), browserId);
    sendFunction(body)
      .then(({ data, message }) => {
        onSuccess({ data, message, type });
      }).catch(({ message, messageFormat }) => {
        if (message === 'NEW_DEVICE_EMAIL_SENT') {
          setLocalStorage('');
          setOpenOTP(true);
        } else if (message === 'MAC_IS_NOT_REGISTERED_TRIES_LEFT') {
          dispatch(openPopUp({ type: 'recover-device' }));
        } else if (message === 'QUALIFIED_INVESTOR') {
          setOpenQualified(true);
        } else if (companyIsInStatus(companyStatusType.WAITING_IDENTITY_VALIDATION, message)) {
          setWaitingValidation(true);
        } else if (companyIsInStatus(companyStatusType.WAITING_EXTERNAL_HELP, message)) {
          setWaitingExternalHelp(true);
        } else {
          setError(true, messageFormat);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isCompany]);
  return (
    <>
      <ModalContent
        pt="12.72px"
        onBack={onBack}
        onClose={onClose}
        px={isDesktop ? '127px' : '40px'}
        header={isDesktop && <LoginHeader title={`${clientType === CLIENT_TYPE.INVESTOR ? 'Inversionista' : 'Receptor'}`} />}
        {...commonModalProps}
      >
        <TitleHeader isDesktop={isDesktop} dispatch={dispatch} clientType={clientType} />
        <form onSubmit={formProps.handleSubmit(sendAuthData)}>
          <Grid container direction="column">
            <Grid container justify="space-between">
              {isCompany
                ? (
                  <InputNitValidation
                    label="NIT y Dígito de Verificación"
                    name="nitAlone"
                    isDesktop={isDesktop}
                    {...formProps}
                    {...commonInputProps}
                  />
                )
                : (
                  <BasicInput
                    mb={34}
                    required
                    name="id"
                    label="No. documento o correo electrónico"
                    {...formProps}
                    {...commonInputProps}
                  />
                )}
              <InputPassword {...formProps} {...commonInputProps} validate={false} />
              <ErrorText text={errorMessage} showIcon />
              {!isDesktop && (
                <>
                  <Ghost
                    isSubmit
                    size={12}
                    underline
                    color={colors.primary}
                    mt={!errorMessage && -10}
                    onClick={() => dispatch(openPopUp({ type: 'recover-password' }))}
                  >
                    ¿Has olvidado tu contraseña?
                  </Ghost>
                  <Text mt={44} size={14} color={colors.GRAY_2} isCenter>
                    ¿No tienes una cuenta?
                  </Text>
                  <Ghost
                    mt={10}
                    isSubmit
                    size={14}
                    isCenter
                    underline
                    fullWidth
                    color={colors.primary}
                    onClick={() => dispatch(openPopUp({ type: 'register' }))}
                  >
                    Créala aquí
                  </Ghost>
                </>
              )}
            </Grid>
          </Grid>
          <Grid container direction="column" justify="center" alignItems="center">
            <Box
              width="372px"
              maxWidth="100%"
              marginTop={isDesktop ? '0px' : '28px'}
              marginBottom={isDesktop ? '0px' : '50px'}
            >
              <Hollow
                mb={10}
                isSubmit
                isCenter
                fullWidth
                size={14}
                type="submit"
                weigth="372px"
                isLoading={isLoading}
                mt={isDesktop ? 40 : 22}
                onColorType={hollowColorType.primary}
              >
                Continuar
              </Hollow>
              {!isDesktop && (
                <Ghost
                  mt={25}
                  isCenter
                  size={14}
                  underline
                  fullWidth
                  type="button"
                  color="#B9B9B9"
                  width="fit-content"
                  href={routes.message.contactUs}
                >
                  ¿Necesitas ayuda?
                </Ghost>
              )}
            </Box>
          </Grid>
        </form>
        {isDesktop && (
          <Ghost
            isSubmit
            size={14}
            underline
            fullWidth
            color={colors.primary}
            onClick={() => dispatch(openPopUp({ type: 'recover-password' }))}
          >
            ¿Has olvidado tu contraseña?
          </Ghost>
        )}
      </ModalContent>
      <Modal
        wrap
        isBackground
        zIndex={1001}
        isOpen={openOTP}
        handleClick={closeOTPModal}
      >
        <ModalContent
          pt="42px"
          onBack={closeOTPModal}
          onClose={closeOTPModal}
          px={isDesktop ? '114px' : '40px'}
          {...commonModalProps}
        >
          <LoginOTP
            onSuccess={onSuccess}
            isDesktop={isDesktop}
            sendData={sendFunction}
            getBody={() => getBody(formProps.getValues(), isCompany, localValue(), browserId)}
          />
        </ModalContent>
      </Modal>
      {openQualified && (
        <WaitQualifiedInvestor
          isDesktop={isDesktop}
          onClose={closeQualified}
        />
      )}
      {waitingValidation && <RegisterWaitingVerificationModal onClose={closeWaitingValidation} />}
      {waitingExternalHelp && (
        <RegisterCompanySuccessful
          isDesktop={isDesktop}
          onClose={closeWaitingExternalHelp}
        />
      )}
    </>
  );
}
