import { NAVBAR_HEIGHT } from 'globalStyle';
import styled, { keyframes, css } from 'styled-components';

const opacity = keyframes`
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const Layout = styled.div`   
    position: fixed;
    top: ${(props) => props.top || NAVBAR_HEIGHT};
    left: 0;
    bottom: 0;
    right: 0;
    z-index: ${(props) => props.zIndex && props.zIndex};
    overflow: auto;
    ${(props) => props.isTransparent && css` background-color: rgba(0, 0, 0, 0);`}

    ${(props) => props.isBackground && css`
        animation: ${opacity} .1s ease-in;
        background-color: rgba(0, 0, 0, .5);
    `}
`;
