import React from 'react';
import { colors } from 'globalStyle';
import { Ghost } from 'components/Buttons';
import { Box, CircularProgress, Grid } from '@material-ui/core';

export default function RegisterLoading({ isDesktop, logout }) {
  return (
    <Grid
      container
      justify="center"
      alignContent="center"
      style={{ height: '200px' }}
    >
      <CircularProgress style={{ color: colors.primary }} />
      {isDesktop && (
        <Box
          bottom="25px"
          position="absolute"
        >
          <Ghost
            isCenter
            isSubmit
            underline
            fullWidth
            size={14}
            padding="12px"
            onClick={logout}
            color={colors.primary}
          >
            Cerrar sesión
          </Ghost>
        </Box>
      )}
    </Grid>
  );
}
