import React, { useState, useEffect } from 'react';
import { TextLight } from 'components/Texts';
import { InputFormatNumber, ErrorText } from 'components/Form';
import { getAmount } from 'util/getAmount';
import { Hollow } from 'components/Buttons';
import money from 'assets/icons/money.svg';
import {
  Figure, colors, Div, weight,
} from 'globalStyle';
import { informToCommercialArea } from 'services';

export const RechageMoneySimpleAccount = ({
  onClose,
  step,
  nextStep,
  ...props
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { formProps } = props;
  const amount = formProps.watch('amount');

  useEffect(() => {
    formProps.setValue('amountTotal', amount, { shouldValidate: false });
  }, []);

  const onSubmit = (form) => {
    setLoading(true);
    informToCommercialArea({
      amount: parseInt(form.amountTotal.replaceAll('.', ''), 10),
    })
      .then(() => {
        setLoading(false);
        nextStep();
      })
      .catch(({ messageFormat }) => {
        setErrorMessage(messageFormat);
      });
  };

  if (step === 2) {
    return (
      <Div isFlex direction="column">
        <TextLight
          size={20}
          weight={weight.BOLD}
          color={colors.primary}
          mb={30}
        >
          ¡Eres un gran inversionista!
        </TextLight>

        <Figure style={{ width: '120px', height: '120px' }}>
          <img src={money} alt="imagen dinero" />
        </Figure>

        <TextLight size={15} align="center" mb={30} mt={30}>
          Gracias por proporcionarnos tus datos. Un asesor comercial se pondrá
          en contacto contigo lo antes posible. Ten en cuenta que estás a un
          paso de convertirte en un gran inversionista.
        </TextLight>

        <Hollow
          isSubmit
          size={14}
          onColorType="primary"
          style={{ borderRadius: '20px', padding: '10px 0', width: '220px' }}
          onClick={onClose}
        >
          Regresar
        </Hollow>
      </Div>
    );
  }

  return (
    <>
      <Div isFlex direction="column">
        <TextLight
          size={20}
          color={colors.primary}
          weight={weight.BOLD}
          mb={30}
        >
          ¡Eres un gran inversionista!
        </TextLight>
        <Div p="0 10px">
          <TextLight size={15} align="center" mb={50}>
            Por el momento no es posible realizar recargas e inversiones
            superiores a 210.05 UVT a través de la plataforma. Sin embargo, uno de
            nuestros Asesores Comerciales puede guiarte y apoyarte en este
            proceso. Por favor, indícanos cuánto deseas invertir y te
            contactaremos lo antes posible.
          </TextLight>
        </Div>

        <form onSubmit={formProps.handleSubmit(onSubmit)}>
          <InputFormatNumber
            title="¿Cuánto quieres invertir?"
            name="amountTotal"
            validate={{
              max: (value) => getAmount(value) >= 50000
                || 'El monto ingresado debe ser mayor a  50.000',
              1000: (value) => getAmount(value) % 1000 === 0
                || 'El monto ingresado debe ser múltiplo de mil.',
            }}
            style={{ width: '330px' }}
            styles={{ textAlign: 'start', marginBottom: '25px' }}
            {...formProps}
          />
          <ErrorText isCenter text={errorMessage} />

          <Hollow
            isSubmit
            size={14}
            onColorType="primary"
            style={{ borderRadius: '20px', padding: '10px 0', width: '220px' }}
            isLoading={loading}
          >
            Enviar
          </Hollow>
        </form>
      </Div>
    </>
  );
};
