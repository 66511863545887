import { Fetch } from 'lib/Fetch';

export function documentTypeColombiaService() {
  return new Fetch()
    .url('/portal/register/user/colombia/doc-type')
    .method('get')
    .portalToken()
    .send();
}

export function documentTypePeruService() {
  return new Fetch()
    .url('/portal/register/user/peru/doc-type')
    .method('get')
    .portalToken()
    .send();
}
