/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Input } from './Input';

export const InputPhone = ({
  noLabel, label, name, ...rest
}) => (
  <Input
    label={noLabel ? null : label || 'Número telefónico'}
    name={name || 'phone'}
    type="number"
    validate={{
      min: (value) => value.length >= 8 || 'Debe contener mínimo 8 dígitos',
      max: (value) => value.length <= 12 || 'Debe contener máximo 12 dígitos',
    }}
    minlength="10"
    maxlength="10"
    {...rest}
  />
);
