const UPDATE_USER_INVESTMENTS = 'UPDATE_USER_INVESTMENTS';
const REMOVE_USER_INVESTMENTS = 'REMOVE_USER_INVESTMENTS';

const UPDATE_USER_BONDS = 'UPDATE_USER_BONDS';
const REMOVE_USER_BONDS = 'REMOVE_USER_BONDS';

const REMOVE_ALL = 'REMOVE_ALL';

const initialState = {
  investments: [],
  bonds: [],
  nodesBonds: [],
  consultedInvestments: false,
  consultedBonds: false,
};

const investmentsAndBondsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_INVESTMENTS:
      return {
        ...state,
        consultedInvestments: true,
        investments: action.payload,
      };
    case REMOVE_USER_INVESTMENTS:
      return {
        ...state,
        investments: [],
        consultedInvestments: false,
      };
    case UPDATE_USER_BONDS:
      return {
        ...state,
        consultedBonds: true,
        bonds: action.payload.bonds,
        nodesBonds: action.payload.nodesBonds,
      };
    case REMOVE_USER_BONDS:
      return {
        ...state,
        bonds: [],
        nodesBonds: [],
        consultedBonds: false,
      };

    case REMOVE_ALL:
      return initialState;

    default:
      return state;
  }
};

export default investmentsAndBondsReducer;

export const updateUserInvestments = (investments) => ({
  type: UPDATE_USER_INVESTMENTS,
  payload: investments,
});

export const removeUserInvestments = () => ({
  type: REMOVE_USER_INVESTMENTS,
});

export const updateUserBonds = (bonds) => ({
  type: UPDATE_USER_BONDS,
  payload: bonds,
});

export const removeUserBonds = () => ({
  type: REMOVE_USER_BONDS,
});

export const removeAllInvestments = () => ({
  type: REMOVE_ALL,
});
