import React from 'react';
import { colors } from 'globalStyle';
import { Title, Text } from 'components/Texts';
import CheckIcon from 'assets/icons/checkBlue.svg';
import {
  Box, Grid, Step, StepIcon, StepLabel, Stepper, styled,
} from '@material-ui/core';

export const StepCard = ({
  active, index, title, items = [], onFinish,
}) => (
  <ContainerStepperSm
    container
    wrap="nowrap"
    direction="column"
    next={active === index}
    active={active > index}
    onClick={active === index && onFinish}
  >
    <Title size={16} isCenter pt={30} pb={10} width="150px" margin="auto" className="stepper-card-title">
      {title}
    </Title>
    <Box
      height="190px"
      display="flex"
      flexWrap="wrap"
      alignContent="center"
      justifyContent="center"
    >
      <VerticalStepper
        items={items}
        isActive={active > index}
      />
    </Box>
  </ContainerStepperSm>
);

export const VerticalStepper = ({
  items = [], isActive, mt, lineStyle,
}) => (
  <StyledStepperSm
    mt={mt}
    orientation="vertical"
    activeStep={items.length}
    {...lineStyle}
  >
    {items.map((v) => (
      <Step completed={isActive} key={`step-${v}`}>
        <StepLabel
          StepIconComponent={(props) => (
            props.completed
              ? (
                <Box
                  height="9.5px"
                  src={CheckIcon}
                  component="img"
                  width="11.875px"
                />
              )
              : (
                <StepIcon
                  icon={props.icon}
                />
              )
          )}
        >
          <Text>{v}</Text>
        </StepLabel>
      </Step>
    ))}
  </StyledStepperSm>
);

export const ContainerStepperSm = styled(Grid)({
  width: '240px',
  height: '249px',
  padding: '20px',
  paddingTop: '0px',
  borderRadius: '20px',
  cursor: (props) => props.next && 'pointer',
  border: (props) => `1px solid ${props.active ? colors.primary : colors.input.inactive}`,
  '&:hover': {
    '& .MuiStepIcon-root': {
      fill: (props) => props.next && colors.primary,
    },
    '& .stepper-card-title': {
      borderRadius: '0px 0px 20px 20px',
      color: (props) => props.next && colors.WHITE,
      background: (props) => props.next && colors.primary,
    },
    '& .MuiStepConnector-lineVertical': {
      borderColor: (props) => props.next && colors.primary,
    },
    border: (props) => props.next && `1px solid ${colors.primary}`,
  },
});

const StyledStepperSm = styled(Stepper)({
  padding: '0px',
  marginTop: (props) => props.mt || '0px',
  '& .MuiStepIcon-text': {
    fontSize: '0px',
  },
  '& .MuiStepIcon-root': {
    width: '11px',
    height: '11px',
    fill: colors.input.inactive,
  },
  '& .MuiStepConnector-lineVertical': {
    borderColor: colors.GRAY,
  },
  '& .MuiStepIcon-root.MuiStepIcon-disabled': {
    color: colors.input.inactive,
  },
  '& .MuiStepIcon-root.MuiStepIcon-completed': {
    color: colors.primary,
  },
  '& .MuiStepConnector-line': {
    marginTop: '-4px',
    borderColor: colors.input.inactive,
    marginLeft: (props) => props.marginLeft || '-7px',
    marginBottom: (props) => props.marginBottom || '-13px',
    minHeight: (props) => props.minHeight || 'calc(20px + 0.5rem)',
  },
  '& .MuiStepConnector-completed .MuiStepConnector-line': {
    borderColor: colors.WHITE,
  },
});

export const StyledStepLabel = styled(StepLabel)({
  '& .MuiStepIcon-text': {
    fontSize: '13px',
  },
  '& .MuiStepIcon-root': {
    width: '40px',
    height: '40px',
    color: colors.input.inactive,
  },
  '& .MuiStepIcon-root.MuiStepIcon-active': {
    color: colors.primary,
  },
  '& .MuiStepIcon-root.MuiStepIcon-completed': {
    color: colors.primary,
  },
});
