import React from 'react';
import { colors } from 'globalStyle';
import { CheckInputIcon } from 'icons';
import styled from 'styled-components';

export const Check = ({ name, getValues, errors }) => (
  getValues && (getValues(name) && !errors[name]?.message)
    ? (
      <CheckBox>
        <CheckInputIcon color={colors.success} width="1.5em" height="1.5em" />
      </CheckBox>
    ) : null
);

const CheckBox = styled.span`
    position: absolute;
    top: 6px;
    right: 15px;
`;
