export function getAmount(amount = 0) {
  if (typeof amount === 'string') {
    return parseInt(amount.replaceAll('.', ''), 10);
  }
  return amount;
}

export function getAmountEA(amount = 0) {
  if (typeof amount === 'string') {
    return Number(amount.replaceAll('% EA', ''));
  }
  return amount;
}

export const searchZero = (num) => {
  const numIndex = num.search(/[1-9]/);
  if (numIndex !== -1) {
    return num.substring(numIndex);
  }
  return num;
};
