import React, { useState, useEffect } from 'react';
import { Div, colors, weight } from 'globalStyle';
import { useNotifications, useUser } from 'hooks';
import Skeleton from 'react-loading-skeleton';
import { Title, TextLight } from 'components/Texts';
import { Ghost } from 'components/Buttons';
import { routes } from 'routes';
import { userType } from 'types';
import { v4 as uuidv4 } from 'uuid';

export const NotificationCard = () => {
  const { notifications, isLoadingNotifications } = useNotifications({
    limit: 30,
  });
  const [notificationsSlice, setNotificationSlice] = useState([]);
  const { type } = useUser();

  useEffect(() => {
    if (notifications?.length > 0) {
      const sliceNotifications = notifications?.slice(0, 3);
      setNotificationSlice(sliceNotifications);
    }
  }, [notifications]);

  const href = type === userType.USER
    ? routes.profile.notifications
    : routes.profileCompany.notifications;

  const Unread = ({ read }) => {
    if (read) {
      return <Div width="2px" />;
    }
    return <Div backgroundColor={colors.primary} height="100%" width="2px" />;
  };

  if (isLoadingNotifications) {
    return <Skeleton height="100%" />;
  }

  return (
    <Div
      isFlex
      direction="column"
      justify="space-between"
      align="start"
      height="100%"
      p="12px 15px"
      width="100%"
      backgroundColor={colors.background.GRAY_3}
      radius="15px"
    >
      <Div width="100%" height="10%" margin="0 0 5px">
        <Title size={14} isCenter>
          Notificaciones
        </Title>
      </Div>

      <Div
        height="80%"
        isFlex
        direction="column"
        justify="center"
        align="center"
        width="100%"
      >
        {notificationsSlice?.length > 0 ? (
          <Div
            height="100%"
            isFlex
            direction="column"
            justify="space-evenly"
            width="100%"
          >
            {notificationsSlice.map((notification, index) => (
              <Div width="100%" isFlex justify="space-between" key={uuidv4()}>
                <Unread read={notification?.viewed} />
                <Div
                  isFlex
                  style={{
                    borderBottom: `${
                      index !== 2 && `1px solid ${colors.GRAY_4}`
                    } `,
                    height: '25px',
                    width: 'calc(100% - 2px)',
                  }}
                >
                  <TextLight
                    size={12}
                    weight={!notification?.viewed && weight.MEDIUM}
                    style={{
                      padding: '0 0 0 5px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {notification?.title}
                  </TextLight>
                </Div>
              </Div>
            ))}
          </Div>
        ) : (
          <Div>
            <TextLight size={13} isCenter>
              No tienes notificaciones.
            </TextLight>
          </Div>
        )}
      </Div>

      {notifications.length !== 0 && (
        <Div height="10%" style={{ alignSelf: 'center' }}>
          <Ghost
            size={12}
            underline
            href={href}
            style={{ color: `${colors.primary}` }}
          >
            Ver más
          </Ghost>
        </Div>
      )}
    </Div>
  );
};
