const UPDATE_CATEGORIZATIONS = 'UPDATE_CATEGORIZATIONS';

const initialState = {};

const categorizationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CATEGORIZATIONS:
      return {
        ...state,
        list: action.categorizations,
        updateDate: action.updateDate,
        defaultCategorization: action.defaultCategorization,
      };
    default:
      return state;
  }
};

export default categorizationsReducer;

export const updateCategorizationsList = ({ list, defaultCategorization }) => ({
  categorizations: list,
  defaultCategorization,
  updateDate: new Date(),
  type: UPDATE_CATEGORIZATIONS,
});
