import { Fetch } from 'lib/Fetch';

export function statusCompanyService({ source }) {
  return new Fetch()
    .url('/company/project/application/project-creation-status')
    .method('get')
    .setCancelToken(source)
    .userToken()
    .send();
}
