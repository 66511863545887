import { Box, Grid } from '@material-ui/core';
import { useMediaQuery } from 'hooks';
import { CloseIcon } from 'icons';
import React from 'react';
import styled from 'styled-components';
import { Figure, colors } from 'globalStyle';
import arrowLeft from 'assets/icons/arrowLeftHeader.svg';

export default function ModalContent({
  children, onClose, width, height, px, pt, pb, header, onBack, fullSize,
  bg, color, iconColor,
}) {
  const bigScreen = useMediaQuery();
  return (
    <Container
      container
      bg={bg}
      color={color}
      width={width}
      height={height}
      fullSize={fullSize}
      bigScreen={bigScreen}
    >
      <Grid container direction="column">
        <Grid container justify="space-between">
          <Figure size={20} onClick={onBack} style={{ cursor: 'pointer' }}>
            {onBack && <img src={arrowLeft} alt="arrowLeft" style={{ fill: iconColor }} />}
          </Figure>
          {header}
          {onClose
            ? (
              <CloseIcon
                width="20px"
                height="20px"
                style={{ cursor: 'pointer', fill: iconColor }}
                onClick={onClose}
              />
            )
            : (
              <Box
                width="0px"
                height="20px"
              />
            )}
        </Grid>
        <ChildrenContainer
          px={px}
          pt={pt}
          pb={pb}
        >
          {children}
        </ChildrenContainer>
      </Grid>
    </Container>
  );
}

const Container = styled(Grid)({
  left: '50%',
  position: 'absolute',
  transform: 'translate(-50%,-50%)',
  top: (props) => (props.bigScreen ? '50%' : '50%'),
  width: (props) => props.width || (props.bigScreen ? '543px' : '100%'),
  height: (props) => props.height,
  overflow: 'auto',
  color: (props) => props.bg || colors.WHITE,
  background: (props) => props.bg || colors.WHITE,
  maxWidth: (props) => (props.fullSize ? '100%' : '90%'),
  maxHeight: (props) => (props.fullSize ? '100%' : '95%'),
  borderRadius: (props) => (props.fullSize ? '0px' : '15px'),
  paddingTop: '20.64px',
  paddingRight: '20.67px',
  paddingLeft: '20.65px',
});

const ChildrenContainer = styled(Grid)({
  maxWidth: '100%',
  paddingTop: (props) => (props.pt || '0px'),
  paddingBottom: (props) => (props.pb || '0px'),
  paddingLeft: (props) => (props.px ? `calc(${props.px} - 20.65px)` : '0px'),
  paddingRight: (props) => (props.px ? `calc(${props.px} - 20.67px)` : '0px'),
});
