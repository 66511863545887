import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ImageLazyLoad = ({
  alt, src, width, height, style,
}) => (
  <LazyLoadImage
    alt={alt}
    height={height || '100%'}
    src={src}
    width={width || '100%'}
    style={style}
  />
);

export default ImageLazyLoad;
