import React from 'react';
import { colors, weight, Figure } from 'globalStyle';
import { TextLight } from 'components/Texts';
import styled from 'styled-components';
import computer from 'assets/persons/computer-2.svg';
import { useMediaQuery } from 'hooks';
import { Hollow } from 'components/Buttons';
import { DialogComponent } from 'components/Modals';

export const AuthError = ({
  errorMessage, isOpen, handleBack, onClick,
}) => {
  const isDesktop = useMediaQuery(1023);
  return (
    <DialogComponent
      isOpen={isOpen}
      handleClose={onClick}
      handleBack={handleBack}
    >
      <Figure
        width={isDesktop ? '170px' : '120px'}
        height={isDesktop ? '150px' : '120px'}
      >
        <img src={computer} alt="persona con un computador" />
      </Figure>
      <TextLight
        mb={isDesktop ? 20 : 15}
        mt={isDesktop ? 20 : 15}
        size={isDesktop ? 20 : 16}
        color={colors.primary}
        weight={weight.BOLD}
      >
        ¡UPS!
        <br />
        Tenemos un problema.
      </TextLight>
      <TextLight
        size={isDesktop ? 15 : 12}
        mb={isDesktop ? 20 : 15}
        weight={weight.MEDIUM}
      >
        {errorMessage}
      </TextLight>
      <Hollow
        medium
        isSubmit
        onClick={handleBack}
        style={{
          padding: '10px 0',
          width: isDesktop ? '330px' : '100%',
          height: '45px',
          borderRadius: '20px',
        }}
        onColorType="primary"
      >
        Regresar
      </Hollow>
    </DialogComponent>
  );
};

export const Back = styled.div`
  position: absolute;
  top: 50px;
  left: 22px;
`;
