import React, { useCallback } from 'react';
import { useUser } from 'hooks';
import { colors } from 'globalStyle';
import { Box } from '@material-ui/core';
import { Title, Text, TextLight } from 'components/Texts';
import CustomDialog from 'components/Modals/CustomDialog';
import MainImage from 'assets/persons/filling-out-form.svg';
import { Hollow, hollowColorType } from 'components/Buttons';

export default function RegisterWaitingVerificationModal({ onClose }) {
  const { logout } = useUser();
  const close = useCallback(() => {
    logout();
    if (onClose) {
      onClose();
    }
  }, [onClose]);
  return (
    <CustomDialog
      open
      px="32px"
      pt="-10px"
      width="290px"
      height="520px"
      onClose={onClose && close}
    >
      <Box
        width="160px"
        height="136px"
        component="img"
        src={MainImage}
        marginBottom={onClose && '25px'}
      />
      {!onClose && (
        <Title size={16} color={colors.primary} mt={20} isCenter>
          ¡Felicitaciones!
        </Title>
      )}
      <Title size={14} color={colors.primary} isCenter>
        Haz completado la primera parte del registro
      </Title>
      <Text size={13} mt={10.52} mb={20} lineHeight="18px">
        Por el momento estamos verificando tus datos,
        cuando tu cuenta esté lista te notificaremos por medio de tu
        correo electrónico o puedes intentar iniciar sesión más tarde.
      </Text>
      <Box
        width="220px"
      >
        <Hollow
          isSubmit
          fullWidth
          padding="13px"
          onClick={close}
          onColorType={hollowColorType.primary}
        >
          Explorar
        </Hollow>
      </Box>
      <TextLight
        mt={9.66}
        size={12}
        color={colors.GRAY_11}
        style={{ fontStyle: 'italic' }}
      >
        Tu sesión se cerrará automáticamente
      </TextLight>
    </CustomDialog>
  );
}
