import React from 'react';
import { Link } from 'wouter';
import { colors, Div, Figure } from 'globalStyle';
import { TextLight } from 'components/Texts';
import { Routes } from 'Layout/components/profile.routes';
import { OtherDropdown } from './OtherDropdown';

export const Dropdown = ({ type }) => (
  <>
    <Routes type={type}>
      {({ name, href, icon }) => (
        <Link href={href}>
          <Div isFlex style={{ columnGap: '25px' }}>
            <Figure width="20px" height="20px">
              <img
                src={icon}
                alt={name}
                style={{ width: '100%', height: '100%' }}
              />
            </Figure>
            <TextLight color={colors.WHITE} size={14}>
              {name}
            </TextLight>
          </Div>
          <Div
            backgroundColor={colors.input.inactive}
            height="1px"
            width="100%"
            margin="20px 0"
          />
        </Link>
      )}
    </Routes>
    <OtherDropdown />
  </>
);
