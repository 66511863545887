import { Fetch } from 'lib/Fetch';

export function listBanksForUser() {
  return new Fetch()
    .url('/user/interbank/list-banks-user')
    .method('get')
    .userToken()
    .send();
}

export function listBanksForCompany() {
  return new Fetch()
    .url('/company/interbank/list-banks-company')
    .method('get')
    .userToken()
    .send();
}

export function listAccountTypesForUser() {
  return new Fetch()
    .url('/user/interbank/list-account-types-user')
    .method('get')
    .userToken()
    .send();
}

export function listAccountTypesForCompany() {
  return new Fetch()
    .url('/company/interbank/list-account-types-company')
    .method('get')
    .userToken()
    .send();
}

export function listBankFavorites() {
  return new Fetch()
    .url('/user/interbank/favorites')
    .method('get')
    .userToken()
    .send();
}

export function listBankFavoritesCompanies() {
  return new Fetch()
    .url('/company/interbank/favorites')
    .method('get')
    .userToken()
    .send();
}

export function favoritesDelete({
  bankName,
  documentType,
  accountNumber,
  name,
  accountType,
  dni,
}) {
  return new Fetch()
    .url('/user/interbank/favorites-delete')
    .method('patch')
    .userToken()
    .data({
      bankName,
      documentType,
      accountNumber,
      name,
      accountType,
      dni,
    })
    .send();
}

export function favoritesUpdate({
  bankName,
  documentType,
  accountNumber,
  name,
  accountType,
  dni,
  newAccountType,
  newAccountNumber,
  newName,
  newBankName,
  newDocumentType,
}) {
  return new Fetch()
    .url('/user/interbank/favorites-modify')
    .method('patch')
    .userToken()
    .data({
      bankName,
      documentType,
      accountNumber,
      name,
      accountType,
      dni,
      newAccountType,
      newAccountNumber,
      newName,
      newBankName,
      newDocumentType,
    })
    .send();
}

export function favoritesDeleteCompany({
  bankName,
  documentType,
  accountNumber,
  name,
  accountType,
  dni,
}) {
  return new Fetch()
    .url('/company/interbank/favorites-delete')
    .method('patch')
    .userToken()
    .data({
      bankName,
      documentType,
      accountNumber,
      name,
      accountType,
      dni,
    })
    .send();
}

export function favoritesUpdateCompany({
  bankName,
  documentType,
  accountNumber,
  name,
  accountType,
  dni,
  newAccountType,
  newAccountNumber,
  newName,
  newBankName,
  newDocumentType,
}) {
  return new Fetch()
    .url('/company/interbank/favorites-modify')
    .method('patch')
    .userToken()
    .data({
      bankName,
      documentType,
      accountNumber,
      name,
      accountType,
      dni,
      newAccountType,
      newAccountNumber,
      newName,
      newBankName,
      newDocumentType,
    })
    .send();
}
