import {
  spaces, weight,
} from 'globalStyle';

import { css } from 'styled-components';

export const basicButton = css`

    ${spaces}
    
    outline: none;
    display: inline-block;
    cursor: pointer;

    text-align: center;
    color: ${(props) => props.color && props.color};
    text-transform: ${(props) => (props.isUpper ? 'uppercase' : 'unset')};
    text-decoration: ${(props) => props.decoration && props.decoration};
    text-transform: ${(props) => props.transform && props.transform};
    font-size: ${(props) => props.size && props.size}px;
    font-weight: ${(props) => props.weight && props.weight};
    text-align: ${(props) => props.align && props.align};
    font-style: ${(props) => props.fontStyle && props.fontStyle};

    padding: ${(props) => props.padding && props.padding};
    transition: all .5s ease;

    width: ${(props) => props.width && props.width}%;
    height: ${(props) => props.height && props.height}%;

    display: ${(props) => props.displayBlock && 'block'};
    display: ${(props) => props.display && props.display};
    position: ${(props) => props.sticky && 'fixed'};
    bottom: ${(props) => (props.sticky && props.bottom) && props.bottom};
    z-index: ${(props) => props.sticky && 10};
`;

export const anatomy = css`
    padding: ${(props) => (props.padding ? props.padding : '1em 1.813em')};
    border-radius: 1.734em;
    
    font-size: ${({ size }) => (size || '16px')};
    border:${(props) => props.border && props.border};
    font-weight: ${weight.SEMI_BOLD};

    ${(props) => props.medium && css`
        font-size: 13px;
        padding: 0.781em 1.438em;
        border-radius: 1.109em;
    `}
    ${(props) => props.mobile && css`
        padding: 4px 20px;
        border-radius: 30px;
    `}
`;
