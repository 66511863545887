import React from 'react';
import {
  Container,
  spaces,
  colors,
  weight,
  Div,
} from 'globalStyle';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { CloseIcon } from 'icons';
import { Modal } from 'components/Modals';
import { routes } from 'routes';
import { Ghost } from 'components/Buttons';
import styled, { keyframes } from 'styled-components';
import semiCircle from 'assets/shapes/semi-circle-yellow-1.svg';
import { Grid } from '@material-ui/core';

export const Auth = ({
  isOpen,
  children,
  onClick,
}) => {
  const isDesktop = useMediaQuery();
  return (
    <Modal isBackground isOpen={isOpen} zIndex={100} wrap handleClick={onClick}>
      <Grid container>
        <Content isDesktop={isDesktop}>
          <Div pt={16} pr={20} pl={20} isFlex justify="flex-end">
            {/* <Figure width="27px" onClick={onClickArrow}>
              <img src={arrow} alt="arrowLeft" />
            </Figure> */}
            <CloseIcon onClick={onClick} style={{ cursor: 'pointer' }} width="20px" height="20px" />
          </Div>
          <Container width={isDesktop ? '80%' : '80%'}>
            {children}
          </Container>
          <Div isCenter width="100%">
            <Ghost
              size={12}
              italic
              weight={weight.MEDIUM}
              underline
              href={routes.message.contactUs}
              mt={15}
              mb={15}
            >
              ¿Necesitas ayuda?
            </Ghost>
          </Div>
        </Content>
      </Grid>
    </Modal>
  );
};
const moveUp = keyframes`
    from {
        top: -50%;
    }
    to {
        top: 50%;
    }
`;

const Content = styled.div`
    ${spaces}
    position: absolute;
    left: 50%;
    top: ${(props) => (props.isDesktop ? '50%' : '50%')};
    transform: translate(-50%,-50%);
    background-color: ${colors.WHITE};
    width: ${(props) => (props.isDesktop ? '543px' : '100%')};
    height: ${(props) => (props.isDesktop ? '530px' : '100%')};
    height:${(props) => (!props.isDesktop && '100%')};
    -webkit-box-shadow: 0px 0px 23px -3px rgba(0,0,0,0.49);
    -moz-box-shadow: 0px 0px 23px -3px rgba(0,0,0,0.49);
    box-shadow: 0px 0px 23px -3px rgba(0,0,0,0.49);
    background-image: url(${semiCircle});
    overflow: hidden;
    animation: ${moveUp} .3s ease-in;
    background-size: 68px 53px;
    background-repeat: no-repeat;
    background-position:  right bottom;
    overflow-y: auto;
    border-radius: ${(props) => (props.isDesktop ? '10px' : '0')};
`;

export const BoxStep = styled.div`

    & > div {
        position: absolute;
        top: 20px;
        left: 27px;
        right: 0;
        z-index: -1;
    }
`;
