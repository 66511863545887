import React, { useState, useEffect } from 'react';
import { TextLight } from 'components/Texts';
import { Hollow } from 'components/Buttons';
import { favoritesUpdate, favoritesUpdateCompany } from 'services';
import { documentsType, userType, accountTypes } from 'types';
import {
  ErrorText, Select, Input, InputOnlyNumbers,
} from 'components/Form';
import { useFormProps, useUser, useMediaQuery } from 'hooks';
import {
  colors, Div, Figure, weight,
} from 'globalStyle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import styled from 'styled-components';
import arrowLeft from 'assets/icons/arrowLeftHeader.svg';
import closeIcon from 'assets/icons/closeIcon.svg';

const stylesInput = {
  textAlign: 'start',
  fontSize: '14px',
};

export default ({
  defaultValue,
  listBanks,
  goToback,
  handleClose,
  isOpen,
  fetch,
}) => {
  const {
    formProps,
    stateFormProps: {
      isLoading, setLoading, setError, errorMessage,
    },
  } = useFormProps({ errorToolTip: true, required: true });

  const [filterBankType, setFilterBankType] = useState(
    Object.values(accountTypes),
  );
  const idNewBankName = formProps.watch('newBankName');

  useEffect(() => {
    const bank = listBanks?.find(
      (item) => item.name === defaultValue?.bankName,
    );
    if (bank) {
      const accountType = bank?.accountTypes.map((item) => accountTypes[item]);
      setFilterBankType(accountType || []);
    }
  }, []);

  useEffect(() => {
    const bank = listBanks.find((item) => item.name === idNewBankName);
    if (bank) {
      const accountType = bank.accountTypes.map((item) => accountTypes[item]);
      setFilterBankType(accountType || []);
    }
  }, [idNewBankName]);

  const isDesktop = useMediaQuery(1023);
  const { type } = useUser();
  const updateAccountFavorite = (data) => {
    setLoading(true);
    if (type === userType.USER) {
      favoritesUpdate({ ...data, ...defaultValue })
        .then(() => {
          setLoading(false);
          goToback();
          fetch();
        })
        .catch((messageFormat) => {
          setLoading(false);
          setError(messageFormat);
        });
    }
    if (type === userType.COMPANY) {
      favoritesUpdateCompany({ ...data, ...defaultValue })
        .then(() => {
          setLoading(false);
          goToback();
          fetch();
        })
        .catch((messageFormat) => {
          setLoading(false);
          setError(messageFormat);
        });
    }
  };

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        style: { borderRadius: '20px', padding: '30px' },
      }}
      maxWidth="md"
      onClick={(e) => e.stopPropagation()}
    >
      <DialogContent
        style={{
          width: `${isDesktop ? 'calc(960px - 60px)' : 'auto'}`,
          padding: '0',
          overflowY: 'initial',
        }}
        align="center"
      >
        <Div isFlex justify="space-between" margin="0 0 20px">
          <Figure
            style={{
              width: '25px',
              height: '25px',
              cursor: 'pointer',
            }}
            onClick={() => {
              goToback();
            }}
          >
            <img src={arrowLeft} alt="arrowLeft" />
          </Figure>
          <Div>
            <TextLight size={20} color={colors.primary} weight={weight.BOLD}>
              Favoritos
            </TextLight>
          </Div>
          <Figure
            style={{
              width: '18px',
              height: '18px',
              cursor: 'pointer',
            }}
            onClick={() => {
              handleClose();
            }}
          >
            <img src={closeIcon} alt="cerrar" />
          </Figure>
        </Div>
        <Div p={`${isDesktop ? '0 90px' : '0 10px'}`}>
          <TextLight size={13}>
            Edita la información de la cuenta, recuerda antes de salir hacer
            <span style={{ fontWeight: weight.BOLD, margin: '0 3px' }}>
              clic en botón de guardar
            </span>
          </TextLight>

          <form>
            <Div isFlex direction="column" margin="40px 0 0">
              <DivForm isDesktop={isDesktop}>
                <Select
                  label="Banco"
                  name="newBankName"
                  defaultValue={defaultValue?.bankName}
                  style={{
                    width: `${isDesktop ? '330px' : '100%'}`,
                    ...stylesInput,
                  }}
                  {...formProps}
                >
                  {listBanks?.map((bank) => (
                    <option key={bank} value={bank.name}>
                      {bank.name}
                    </option>
                  ))}
                </Select>
                <Select
                  label="Tipo de cuenta"
                  name="newAccountType"
                  defaultValue={defaultValue?.accountType}
                  style={{
                    width: `${isDesktop ? '330px' : '100%'}`,
                    ...stylesInput,
                  }}
                  {...formProps}
                >
                  {filterBankType?.map((typeBank) => (
                    <option key={typeBank} value={typeBank}>
                      {typeBank}
                    </option>
                  ))}
                </Select>
              </DivForm>
              <DivForm isDesktop={isDesktop}>
                <InputOnlyNumbers
                  title="Número de cuenta"
                  name="newAccountNumber"
                  defaultValue={defaultValue?.accountNumber}
                  style={{
                    width: `${isDesktop ? '330px' : '100%'}`,
                    ...stylesInput,
                  }}
                  {...formProps}
                />

                <Input
                  name="newName"
                  type="text"
                  placeholder="Nombre (s) y Apellidos"
                  label="Nombre completo del titular"
                  defaultValue={defaultValue?.name}
                  validate={{
                    max: (value) => /^[a-zA-Z ]+$/g.test(value)
                      || 'No puede contener caracteres diferentes a letras',
                  }}
                  style={{
                    width: `${isDesktop ? '330px' : '100%'}`,
                    ...stylesInput,
                  }}
                  {...formProps}
                />
              </DivForm>
              <DivForm isDesktop={isDesktop}>
                <Select
                  label="Tipo de documento"
                  name="newDocumentType"
                  defaultValue={defaultValue?.documentType}
                  style={{
                    width: `${isDesktop ? '330px' : '100%'}`,
                    ...stylesInput,
                  }}
                  {...formProps}
                >
                  {documentsType.map((document) => (
                    <option key={document.idType} value={document.name}>
                      {document.detail}
                    </option>
                  ))}
                </Select>
                <InputOnlyNumbers
                  label="Número de documento"
                  name="newDni"
                  placeholder="9897673858"
                  defaultValue={defaultValue?.dni}
                  style={{
                    width: `${isDesktop ? '330px' : '100%'}`,
                    ...stylesInput,
                  }}
                  {...formProps}
                />
              </DivForm>

              <ErrorText mt={30} mb={10} text={errorMessage} />
              <Div
                isFlex
                justify="space-between"
                width="100%"
                p="0 15px"
                margin={`${!errorMessage && '40px 0 0'}`}
              >
                <Hollow
                  style={{ width: `${isDesktop ? '330px' : '100%'}` }}
                  padding="7px 0"
                  isSubmit
                  onColorType="primary"
                  disabled={formProps.valid}
                  onClick={() => {
                    goToback();
                  }}
                  size="14px"
                  isLoading={isLoading}
                >
                  Cancelar
                </Hollow>
                <Hollow
                  style={{ width: `${isDesktop ? '330px' : '100%'}` }}
                  isSubmit
                  onClick={formProps.handleSubmit(updateAccountFavorite)}
                  size="14px"
                  onColorType="white"
                  color={colors.primary}
                  isLoading={isLoading}
                  padding="7px 0"
                >
                  Guardar
                </Hollow>
              </Div>
            </Div>
          </form>
        </Div>
      </DialogContent>
    </Dialog>
  );
};

export const DivForm = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isDesktop ? 'row' : 'column')};
  justify-content: space-between;
  width: 100%;
  column-gap: 60px;
`;
