import React, { useCallback } from 'react';
import {
  userPasswordChangeForgetService,
  companyPasswordChangeForgetService,
} from 'services';
import { routes } from 'routes';
import { colors } from 'globalStyle';
import { useFormProps } from 'hooks';
import { Box } from '@material-ui/core';
import Container from 'components/Container';
import { Title, Text } from 'components/Texts';
import { InputPassword } from 'components/Form';
import CustomDialog from 'components/Modals/CustomDialog';
import { Hollow, Ghost, hollowColorType } from 'components/Buttons';
import MobileHeader, { CLIENT_TYPE } from 'Layout/components/Navbar/components/components/MobileHeader';

export default function RecoverPasswordNew({
  open, isDesktop, onClose, clientType, onBack, onNext, otp, email, onError,
}) {
  const { formProps, stateFormProps } = useFormProps(
    { errorToolTip: false, errorText: true, required: true },
  );
  const password = formProps.watch('password1');
  const sendNewPassword = useCallback((values) => {
    stateFormProps.setLoading(true);
    const sendFunction = clientType === CLIENT_TYPE.INVESTOR
      ? userPasswordChangeForgetService : companyPasswordChangeForgetService;
    sendFunction({
      ...values,
      email,
      token: otp,
    }).then(() => {
      onNext();
    }).catch(({ messageFormat }) => {
      onError(messageFormat);
    }).finally(() => {
      stateFormProps.setLoading(false);
    });
  });
  return (
    <CustomDialog
      pt="10px"
      open={open}
      onBack={onBack}
      fullSizeNavBar={!isDesktop}
      width={isDesktop && '615px'}
      height={isDesktop && '530px'}
      onClose={isDesktop && onClose}
      px={isDesktop ? '72px' : '30px'}
      header={!isDesktop && <MobileHeader clientType={clientType} />}
    >
      <Container>
        <Title size={isDesktop ? 20 : 18} isCenter>
          ¿Olvidaste tu contraseña?
        </Title>
        <Text
          isCenter
          mt={isDesktop ? 7 : 8}
          mb={isDesktop ? 47 : 43}
          size={isDesktop ? 15 : 13}
        >
          Ingresa tu nueva contraseña. Recuerda que tu contraseña debe contar con mínimo
          (8) caracteres, al menos una mayúscula, un caracter especial y números.
        </Text>
        <Box
          width="100%"
          display="flex"
          component="form"
          textAlign="left"
          flexDirection="column"
          maxWidth={isDesktop ? '330px' : '100%'}
          onSubmit={formProps.handleSubmit(sendNewPassword)}
        >
          <InputPassword
            noTooltip
            name="password1"
            label="Nueva contraseña"
            mb={isDesktop ? 20 : 19}
            {...formProps}
          />
          <InputPassword
            noTooltip
            name="password2"
            label="Confirmar nueva contraseña"
            validate={{
              samePassword: (value) => value === password || 'Las contraseñas no coinciden',
            }}
            {...formProps}
          />
          <Hollow
            isSubmit
            isCenter
            size={14}
            fullWidth
            type="submit"
            padding="12.5px"
            mt={isDesktop ? 38 : 96}
            isLoading={stateFormProps.isLoading}
            onColorType={hollowColorType.primary}
          >
            Guardar cambios
          </Hollow>
        </Box>
        <Ghost
          mt={35}
          isCenter
          underline
          fullWidth
          type="button"
          width="fit-content"
          color={colors.GRAY_11}
          size={isDesktop ? 14 : 12}
          href={routes.message.contactUs}
        >
          ¿Necesitas ayuda?
        </Ghost>
      </Container>
    </CustomDialog>
  );
}
