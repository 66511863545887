import React from 'react';
import { CloseIcon } from 'icons';
import {
  spaces,
  colors,
  Row,
  Div,
  Figure,
  weight,
} from 'globalStyle';
import { useMediaQuery } from 'hooks/useMediaQuery';
import styled, { css } from 'styled-components';
import arrowLeft from 'assets/icons/arrowLeftHeader.svg';
import { routes } from 'routes';
import { Ghost } from 'components/Buttons';
import { Modal } from './Modal';

export const Basic = ({
  isOpen,
  width,
  height,
  isBackground,
  radius,
  onClick,
  scroll,
  children,
  zIndex,
  havePreview,
  modalType,
}) => {
  const isDesktop = useMediaQuery();
  return (
    <Modal
      isOpen={isOpen}
      zIndex={zIndex}
      modalType={modalType}
      handleClick={onClick}
      isBackground={isBackground}
    >
      <Row fluid isCenter hCenter>
        <Box
          isBackground={isBackground}
          width={width}
          height={height}
          radius={radius}
          isDesktop={isDesktop}
          onClick={(e) => e.stopPropagation()}
        >
          <Content radius={radius} scroll={scroll}>
            <Div
              isFlex
              justify={havePreview ? 'space-between' : 'flex-end'}
              pl={20}
              pt={15}
              pb={15}
              pr={20}
            >
              {
                havePreview && (
                  <Figure size={20}>
                    <img src={arrowLeft} alt="arrowLeft" />
                  </Figure>
                )
              }
              <CloseIcon onClick={onClick} style={{ cursor: 'pointer' }} width="20px" height="20px" />
            </Div>
            <Div mr={20} ml={20} isScroll={scroll}>
              {children}
            </Div>
            <Div isCenter width="100%">
              <Ghost
                size={12}
                italic
                weight={weight.MEDIUM}
                underline
                href={routes.message.contactUs}
                mt={15}
                mb={15}
              >
                ¿Necesitas ayuda?
              </Ghost>
            </Div>
          </Content>
        </Box>
      </Row>
    </Modal>
  );
};
const Box = styled.div`
    ${spaces}
    background-color: ${colors.WHITE};
    &::-webkit-scrollbar {
        background: ${colors.GRAY_3};
        width: 8px;     
        height: 8px;  
    }
    &::-webkit-scrollbar-thumb {
        background: ${colors.GRAY_3};
        border-radius: ${(props) => props.radius || 20}px;
    }
    &::-webkit-scrollbar-track {
        background: ${colors.WHITE};
    }
    ${(props) => props.scroll && css`
        height: 100%;
        border-radius: 8px;
        overflow-y: scroll;
        overflow-x: hidden;
    `}
    
    width: ${(props) => {
    if (props.width) {
      return `${props.width}px`;
    }
    if (props.isDesktop) {
      return '543px';
    }
    return '100%';
  }};
 
    max-height: ${(props) => (props.isDesktop ? '700px' : '100%')};
    height:${(props) => (!props.isDesktop && (props.height ? props.height : '100vh'))};
    border-radius: ${(props) => props.radius && (`${props.radius}` || 20)}px;
    margin-left: auto;
    margin-right: auto;
    ${(props) => !props.isBackground && css`
        -webkit-box-shadow: 0px 0px 23px -3px rgba(0,0,0,0.49);
        -moz-box-shadow: 0px 0px 23px -3px rgba(0,0,0,0.49);
        box-shadow: 0px 0px 23px -3px rgba(0,0,0,0.49);
    `}
    overflow: auto;
`;

export const Content = styled.div`
    &::-webkit-scrollbar {
        background: ${colors.GRAY_3};
        width: 8px;     
        height: 8px;  
    }
    &::-webkit-scrollbar-thumb {
        background: ${colors.GRAY_3};
        border-radius: ${(props) => props.radius || 20}px;
    }
    &::-webkit-scrollbar-track {
        background: ${colors.WHITE};
    }
    ${(props) => props.scroll && css`
        height: 100%;
        border-radius: 8px;
        overflow-y: scroll;
        overflow-x: hidden;
    `}

`;
