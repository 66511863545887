import { Fetch } from 'lib/Fetch';

export function getBondHistory(projectId) {
  return new Fetch()
    .url(`/company/bonds/project-investors-history/${projectId}`)
    .method('get')
    .userToken()
    .send();
}

export function getCompanyBonds() {
  return new Fetch()
    .url('/company/bonds')
    .method('get')
    .userToken()
    .send();
}
export function getHistoryBond(investorId, projectQLDBId) {
  return new Fetch()
    .url(`/company/bonds/project-investor-history/${projectQLDBId}/${investorId} `)
    .method('get')
    .userToken()
    .send();
}
