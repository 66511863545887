import { Fetch } from 'lib/Fetch';

export function generateCodeTransfer(id) {
  return new Fetch()
    .url(`/user/bonds/generate-code-transfer/${id}`)
    .method('post')
    .userToken()
    .send();
}

export function confirmTransfer({ bond }) {
  return new Fetch()
    .url('/user/bonds/confirm-transfer')
    .method('PATCH')
    .userToken()
    .data(bond)
    .send();
}

export function cancelTransfer(id) {
  return new Fetch()
    .url(`/user/bonds/cancel-transfer/${id}`)
    .method('PATCH')
    .userToken()
    .send();
}

export function acceptTransfer(bondInfo) {
  return new Fetch()
    .url('/user/bonds/accept-transfer')
    .method('PATCH')
    .userToken()
    .data(bondInfo)
    .send();
}

export function rejectTransfer(bondId, notificationId) {
  return new Fetch()
    .url(`/user/bonds/reject-transfer/${bondId}?notificationId=${notificationId}`)
    .method('PATCH')
    .userToken()
    .send();
}
