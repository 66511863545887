const UPDATE_PROJECTS = 'UPDATE_UPDATE_PROJECTS';

const initialState = {
  projects: [],
};

const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };

    default:
      return state;
  }
};

export default projectsReducer;

export const updateProjects = (projects) => ({
  type: UPDATE_PROJECTS,
  payload: projects,
});
