/* eslint-disable no-unused-vars */
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { userRecoverDeviceValidateCodeService, userRecoverDeviceAddMacService } from 'services';
import { useLocalStorage } from 'hooks';
import { Title } from 'components/Texts';
import { CodeInput } from 'components/Form';
import { Submit } from 'components/Buttons';
import { Row } from 'globalStyle';

export const Code = ({
  isLoading, setLoading, next, data: stepData,
}) => {
  const [code, setCode] = React.useState('');
  const [error, setError] = React.useState(false);
  const { setLocalStorage } = useLocalStorage('seed');
  const handleSubmit = () => {
    setLoading(true);
    userRecoverDeviceValidateCodeService({ email: stepData.email, code })
      .then(({ data }) => {
        const seed = uuidv4();
        setLocalStorage(seed);
        return userRecoverDeviceAddMacService({ token: data.token, userId: data.userId, seed });
      })
      .then(() => {
        setLoading(false);
        next();
      })
      .catch(({ messageFormat }) => {
        setLoading(false);
        setError(true, messageFormat);
      });
  };
  return (
    <div>
      <Title isCenter number={5} mb={30}>
        {' '}
        Te enviamos un código de seguridad al correo ******
        {stepData.email?.slice(stepData.email?.indexOf('@'), stepData.email?.length)}
      </Title>
      <CodeInput
        fields={5}
        valid={error}
        value={code}
        onChange={(e) => setCode(e)}
      />

      <Row mt={25} isCenter>
        <Submit
          onClick={() => handleSubmit()}
          isLoading={isLoading}
          medium
          disabled={code.length !== 5}
        >
          Continuar
        </Submit>
      </Row>

    </div>
  );
};
