import React from 'react';
import ReactCodeInput from 'react-code-input';
import { Div, Row } from 'globalStyle';
import { Label } from './Label';
import { ErrorText } from './ErrorText';

export const CodeInput = ({
  mb,
  text,
  type = 'text',
  fields = 4,
  valid,
  value,
  onChange,
  styleInput,
  forceUppercase,
  error = 'El código ingresado no es válido. Por favor verifícalo e intenta de nuevo.',
  hasTitle,
}) => (
  <Div mb={mb || 18}>
    <Label
      isCenter
      title={!hasTitle && 'Ingresa aquí tu código '}
      subTitle={text}
    />
    <Row isCenter>
      <ReactCodeInput
        isValid={!valid}
        value={value}
        onChange={onChange}
        type={type}
        fields={fields}
        forceUppercase={forceUppercase}
        {...styleInput}
      />
    </Row>
    {valid && <ErrorText isCenter text={error} />}
  </Div>
);
