import React, { useState } from 'react';
import { Submit } from 'components/Buttons/Submit';
import { Div, weight, colors } from 'globalStyle';
import { useFormProps, useMediaQuery } from 'hooks';
import { InputNumber, InputFormatNumber, ErrorText } from 'components/Form';
import { modifyMaximumsPerChannel, getMaximumsPerChannel } from 'services';
import { formatNumber } from 'util/formatNumber';
import { Button } from 'components/Buttons';
import { Loading } from 'components/Loading';
import { TextLight } from 'components/Texts';
import { DialogComponent } from 'components/Modals';

export default ({ open, onClose }) => {
  const { formProps } = useFormProps({ errorToolTip: true });
  const [disabled, setDisabled] = useState(true);
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    processingChannel: '04',
    maxDailyTransaction: 0,
    maxDailyAmount: 0,
    maxMonthlyTransaction: 0,
    maxMonthlyAmount: 0,
  });
  const [loading, setLoading] = useState(false);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [error, setError] = useState(false);
  const maxAmountEdition = 1800000000;

  const handleFormChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleFormChangeNumber = (values, sourceInfo, name) => {
    setFormData({ ...formData, [name]: values.value });
  };

  const fetch = () => {
    setLoadingFetch(true);
    setError(false);
    getMaximumsPerChannel({
      processingChannel: '04',
    })
      .then(({ data }) => {
        setFormData({
          ...formData,
          maxDailyTransaction: data.Web.CANTDIA,
          maxMonthlyAmount: data.Web.MONTOMEN,
          maxDailyAmount: data.Web.MONTODIA,
          maxMonthlyTransaction: data.Web.CANTMEN,
        });
        setLoadingFetch(false);
      })
      .catch(({ messageFormat }) => {
        setLoadingFetch(false);
        setError(true);
        setMessage(messageFormat);
      });
  };
  useState(() => {
    fetch();
  }, []);

  const handleSend = () => {
    setLoading(true);
    modifyMaximumsPerChannel(formData)
      .then(() => {
        setDisabled(true);
        setLoading(false);
        setError(true);
        setMessage('¡Topes modificados de manera Exitosa!');
        setTimeout(() => {
          fetch();
        }, 700);
      })
      .catch(({ messageFormat }) => {
        setLoading(false);
        setError(true);
        setDisabled(true);
        setMessage(messageFormat);
      });
  };

  const isDesktop = useMediaQuery(1023);

  const styles = {
    color: `${disabled ? colors.GRAY_3 : colors.BLACK}`,
    width: isDesktop ? '330px' : '100%',
    textAlign: 'start',
  };

  const HandleButton = () => {
    if (disabled) {
      return (
        <Button
          style={{ width: isDesktop ? '330px' : '100%', padding: '10px 0' }}
          isSubmit
          onClick={() => {
            setDisabled(false);
            setError(false);
          }}
        >
          Modificar
        </Button>
      );
    }
    return (
      <Submit
        style={{ width: isDesktop ? '330px' : '100%', padding: '10px 0' }}
        isSubmit
        onClick={() => {
          handleSend();
        }}
        isLoading={loading}
      >
        Guardar
      </Submit>
    );
  };
  if (loadingFetch) {
    return (
      <DialogComponent
        isOpen={open}
        handleClose={onClose}
        handleBack={onClose}
        width="600px"
        hasContactUs={false}
      >
        <TextLight
          size={isDesktop ? 18 : 16}
          mb={20}
          weight={weight.BOLD}
          color={colors.primary}
        >
          Configuración de topes
        </TextLight>
        <Div height="200px" isCenter>
          <Loading />
        </Div>
      </DialogComponent>
    );
  }

  return (
    <DialogComponent
      isOpen={open}
      handleClose={onClose}
      handleBack={onClose}
      width="600px"
      hasContactUs={false}
    >
      <TextLight
        size={isDesktop ? 18 : 16}
        mb={20}
        weight={weight.BOLD}
        color={colors.primary}
      >
        Configuración de topes
      </TextLight>
      <form>
        <InputNumber
          defaultValue={formData.maxDailyTransaction}
          title="Cantidad de transacciones diarias"
          disabled={disabled}
          name="maxDailyTransaction"
          required
          style={styles}
          onChange={(e) => {
            handleFormChange(e);
          }}
        />
        <InputFormatNumber
          defaultValue={formData.maxDailyAmount}
          required
          label="Máximo monto diario"
          name="maxDailyAmount"
          placeholder="$ 8'000.000 COP"
          disabled={disabled}
          styles={styles}
          onChange={(values, sourceInfo, name = 'maxDailyAmount') => {
            handleFormChangeNumber(values, sourceInfo, name);
          }}
          {...formProps}
        />
        <InputNumber
          defaultValue={formData.maxMonthlyTransaction}
          title="Cantidad de transacciones mensuales"
          disabled
          name="maxDailyTransaction"
          required
          onChange={(e) => {
            handleFormChange(e);
          }}
          style={{ width: isDesktop ? '330px' : '100%', textAlign: 'start' }}
        />
        <InputFormatNumber
          defaultValue={formData.maxMonthlyAmount}
          required
          name="maxDailyAmount"
          label="Máximo monto mensual"
          placeholder="$ 8'000.000 COP"
          disabled
          onChange={(values, sourceInfo, name = 'maxDailyAmount') => {
            handleFormChangeNumber(values, sourceInfo, name);
          }}
          styles={{ width: isDesktop ? '330px' : '100%', textAlign: 'start' }}
          {...formProps}
        />
      </form>
      {error && <ErrorText isCenter text={message} />}

      {formData.maxMonthlyAmount < maxAmountEdition ? (
        <HandleButton />
      ) : (
        <TextLight size={15} weight={weight.BOLD} mt={15} mb={10} isCenter>
          {`Los topes no pueden ser modificados porque el monto mensual es
                  igual o superior a los ${formatNumber(maxAmountEdition)}`}
        </TextLight>
      )}
    </DialogComponent>
  );
};
