import React, { useState } from 'react';
import { Link } from 'wouter';
import { colors, Div, Figure } from 'globalStyle';
import { TextLight } from 'components/Texts';
import { routes } from 'routes';
import helpIcon from 'assets/icons/history-white.svg';
import letter from 'assets/icons/letter-white.svg';
import bloom from 'assets/icons/bloom-white.svg';
import users from 'assets/icons/users-white.svg';
import book from 'assets/icons/book-white.svg';
import file from 'assets/icons/file-white.svg';
import arrowDropdown from 'assets/icons/arrow-dropdown-white.svg';
import money from 'assets/icons/money-bag-white.svg';

const aboutBloom = [
  { name: 'Acerca de nosotros', href: routes.aboutUs, icon: users },
  {
    name: 'Información de productos y servicios',
    href: routes.resources,
    icon: book,
  },
  { name: 'Financia tu proyecto', href: routes.financeProject, icon: money },
  { name: 'Nuestros corresponsales', href: routes.correspondents, icon: file },
];

const help = [
  { name: 'Contáctanos', href: routes.message.contactUs, icon: letter },
  {
    name: 'Preguntas frecuentes',
    href: routes.frequentQuestions,
    icon: helpIcon,
  },
];

const ArrowDown = ({ expanded }) => (
  <Figure
    style={{
      width: '15px',
      height: '11px',
      cursor: 'pointer',
      transform: expanded && 'rotate(180deg)',
    }}
  >
    <img src={arrowDropdown} alt="arrowDropdown" />
  </Figure>
);
export const OtherDropdown = () => {
  const [aboutBloomDropdown, setBloomDropdown] = useState(false);
  const [helpDropdowmn, setHelpDropdowmn] = useState(false);

  return (
    <>
      <Div
        isFlex
        justify="space-between"
        onClick={() => {
          setBloomDropdown(!aboutBloomDropdown);
        }}
      >
        <Div
          isFlex
          style={{ columnGap: '25px' }}

        >
          <Figure width="20px" height="20px">
            <img
              src={bloom}
              alt="Sobre Bloom"
              style={{ width: '100%', height: '100%' }}
            />
          </Figure>
          <TextLight color={colors.WHITE} size={14}>
            Sobre Bloom
          </TextLight>
        </Div>
        <ArrowDown expanded={aboutBloomDropdown} />
      </Div>

      <Div
        backgroundColor={colors.input.inactive}
        height="1px"
        width="100%"
        margin="20px 0"
      />
      {aboutBloomDropdown && (
        <>
          {aboutBloom.map(({ href, icon, name }) => (
            <Link href={href}>
              <Div isFlex style={{ columnGap: '25px' }}>
                <Figure width="20px" height="20px">
                  <img
                    src={icon}
                    alt={name}
                    style={{ width: '100%', height: '100%' }}
                  />
                </Figure>
                <TextLight color={colors.WHITE} size={14}>
                  {name}
                </TextLight>
              </Div>
              <Div
                backgroundColor={colors.input.inactive}
                height="1px"
                width="100%"
                margin="20px 0"
              />
            </Link>
          ))}
        </>
      )}
      <Div
        isFlex
        justify="space-between"
        onClick={() => {
          setHelpDropdowmn(!helpDropdowmn);
        }}
      >
        <Div
          isFlex
          style={{ columnGap: '25px' }}
          width="100%"

        >
          <Figure width="20px" height="20px">
            <img
              src={helpIcon}
              alt="Ayuda"
              style={{ width: '100%', height: '100%' }}
            />
          </Figure>
          <TextLight color={colors.WHITE} size={14}>
            Ayuda
          </TextLight>
        </Div>
        <ArrowDown expanded={helpDropdowmn} />
      </Div>

      <Div
        backgroundColor={colors.input.inactive}
        height="1px"
        width="100%"
        margin="20px 0"
      />
      {helpDropdowmn && (
        <>
          {help.map(({ href, icon, name }) => (
            <Link href={href}>
              <Div isFlex style={{ columnGap: '25px' }}>
                <Figure width="20px" height="20px">
                  <img
                    src={icon}
                    alt={name}
                    style={{ width: '100%', height: '100%' }}
                  />
                </Figure>
                <TextLight color={colors.WHITE} size={14}>
                  {name}
                </TextLight>
              </Div>
              <Div
                backgroundColor={colors.input.inactive}
                height="1px"
                width="100%"
                margin="20px 0"
              />
            </Link>
          ))}
        </>
      )}
    </>
  );
};
