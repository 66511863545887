import { Fetch } from 'lib/Fetch';

export function useRequestQR(url) {
  return new Fetch('api_2')
    .url(`/user/login/qr?url=${url}`)
    .method('get')
    .userToken()
    .send();
}

export function useRequestQRCompany(url) {
  return new Fetch()
    .url(`/company/login/generate-qr?url=${url}`)
    .method('get')
    .userToken()
    .send();
}

export function useLoginQR({ receiver, otp, browserId }) {
  return new Fetch('api_2')
    .url('/portal/login/user/qr')
    .method('post')
    .portalToken()
    .data({ receiver, otp, browserId })
    .send();
}

export function useLoginQRCompany({ receiver, otp, browserId }) {
  return new Fetch()
    .url('/portal/login/company/qr')
    .method('post')
    .portalToken()
    .data({ receiver, otp, browserId })
    .send();
}
