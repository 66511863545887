import { Fetch } from 'lib/Fetch';

export function contactEthicalLine({ email, subject, message }) {
  return new Fetch()
    .url('/portal/notification/ethics-hotline')
    .method('post')
    .portalToken()
    .data({ email, subject, message })
    .send();
}
