import React from 'react';
import { Title, TextLight } from 'components/Texts';
import { colors, Figure, weight } from 'globalStyle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Hollow, Ghost } from 'components/Buttons';
import arrowLeft from 'assets/icons/arrowLeftHeader.svg';
import closeIcon from 'assets/icons/closeIcon.svg';
import { routes } from 'routes';
import manWithLoudspeaker from 'assets/persons/talking-2.svg';
import { Box } from '@material-ui/core';

export const PopUpInvestmentWithdrawal = ({ open, handleOpen }) => (
  <Dialog
    open={open}
    style={{ radius: '40px' }}
    align="center"
    PaperProps={{
      style: { borderRadius: '10px' },
    }}
    maxWidth="md"
    onClose={handleOpen}
  >
    <Box
      display="flex"
      justifyContent="space-between"
      padding="15px 15px 0"
      alignContent="center"
    >
      <Figure
        style={{
          width: '30px',
          height: '25px',
          cursor: 'pointer',
        }}
        onClick={() => handleOpen()}
      >
        <img src={arrowLeft} alt="arrowLeft" />
      </Figure>

      <Figure
        style={{
          width: '15px',
          height: '15px',
          cursor: 'pointer',
          'margin-top': '5px',
        }}
        onClick={() => handleOpen()}
      >
        <img src={closeIcon} alt="arrowLeft" />
      </Figure>
    </Box>
    <DialogContent style={{ 'max-width': '360px', padding: '0 30px 30px' }}>
      <Box display="flex" justifyContent="center" mb="20px" mt="20px">
        <img
          src={manWithLoudspeaker}
          alt="metaValidate"
          style={{ maxWidth: '250px' }}
        />
      </Box>

      <Title size={18} color={colors.GRAY_1} mb={12}>
        Recuerda
      </Title>
      <Box width="90%">
        <TextLight mb={20} size={15} isCenter>
          Estamos recolectando la totalidad de recursos para tu proyecto. Por el
          momento no puedes realizar ningún retiro.
        </TextLight>
        <Hollow
          fullWidth
          size={15}
          mb={10}
          isSubmit
          onClick={() => {
            handleOpen();
          }}
          actived
          style={{ padding: '7px 0' }}
        >
          Entendido
        </Hollow>
        <Ghost
          href={routes.message.contactUs}
          underline
          italic
          size={15}
          color={colors.primary}
          weight={weight.SEMI_BOLD}
        >
          Contáctanos
        </Ghost>
      </Box>
    </DialogContent>
  </Dialog>
);
