import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { CheckBox } from 'components/Form';
import { Line, colors } from 'globalStyle';
import { useUser, useFormProps } from 'hooks';
import { Title, Text, Bold } from 'components/Texts';
import CustomDialog from 'components/Modals/CustomDialog';
import { Hollow, hollowColorType, Ghost } from 'components/Buttons';
import { useSendPhoneOtpCompany, useAcceptDocumentsCompany } from 'services';
import {
  REGLAMENTO_BLOOM,
  TERMINOS_CONDICIONES_BLOOM,
  BCC_REGLAMENTO_DEPOSITO_ORDINARIO,
} from 'types/fileType';

import RegisterOTP from '../../RegisterOTP';
import RegisterHeader from '../../RegisterHeader';
import useUpdateUserStatus from '../../../util/useUpdateUserStatus';

export default function RegisterAcceptDocsModal({ isDesktop, onClose }) {
  const { user, logout } = useUser();
  const [data, setData] = useState({});
  const [error, setError] = useState();
  const [showOTP, setShowOTP] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const goBack = useCallback(() => setShowOTP(false), []);
  const { formProps } = useFormProps({ errorToolTip: false, errorText: true, required: true });
  const sendOTP = useCallback((newData) => {
    setData(newData);
    setShowOTP(true);
    useSendPhoneOtpCompany();
  }, []);
  const { updateUserStatus } = useUpdateUserStatus();
  const checkOTP = useCallback((otp) => {
    setIsLoading(true);
    useAcceptDocumentsCompany({ otp, ...data })
      .then(() => {
        updateUserStatus()
          .then(() => {
            document.location.reload();
          })
          .catch(({ messageFormat }) => {
            setError(messageFormat);
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch(({ messageFormat }) => {
        setError(messageFormat);
        setIsLoading(false);
      });
  }, [data]);
  if (showOTP) {
    return (
      <RegisterOTP
        open
        error={error}
        onBack={goBack}
        checkOTP={checkOTP}
        isDesktop={isDesktop}
        isLoading={isLoading}
        fullSize={!isDesktop}
        hideControls={!isDesktop}
        sendOTP={useSendPhoneOtpCompany}
        headerProps={{
          title: 'Receptor',
          subtitle: 'Código de validación',
        }}
        title={(
          <Text size={14}>
            Ingresa el código que hemos enviado al número de celular del
            <Bold>{' representante legal'}</Bold>
          </Text>
        )}
      />
    );
  }
  return (
    <CustomDialog
      open
      onClose={onClose}
      fullSize={!isDesktop}
      zIndex={!isDesktop && 1}
      height={isDesktop && '665px'}
      width={isDesktop && '1012px'}
      px={isDesktop ? '89px' : '30px'}
      pt={isDesktop ? '34.4px' : '57.5px'}
      header={isDesktop && <RegisterHeader title="Documentos por aceptar" />}
    >
      {!isDesktop && (
        <>
          <Title size={16} color={colors.primary} isCenter>
            Receptor
          </Title>
          <Text size={14} mt={12} mb={15} isCenter>
            Documentos por aceptar
          </Text>
          <Line
            isCenter
            width="100%"
            height="0px"
            color={colors.primary}
          />
        </>
      )}
      <Text
        maxWidth="700px"
        mt={!isDesktop && 20}
        mb={!isDesktop && 44}
        size={isDesktop ? 16 : 14}
      >
        <span>
          Para finalizar el registro de la empresa identificada con el
          <Bold>{` NIT: ${user.info.document} `}</Bold>
          el
          <Bold>{' representante legal '}</Bold>
          debe leer y aceptar los siguientes documentos:
        </span>
      </Text>
      <form onSubmit={formProps.handleSubmit(sendOTP)}>
        <Grid
          container
          style={{
            marginTop: isDesktop && '90px',
            paddingLeft: isDesktop ? '59px' : '11px',
          }}
        >
          <DocumentCheckBox
            formProps={formProps}
            name="termsAndConditions"
            mb={isDesktop ? '66px' : '40px'}
          >
            <TextContainer>
              {'He leído y acepto los '}
            </TextContainer>
            <CustomGhost
              src={TERMINOS_CONDICIONES_BLOOM}
            >
              Términos y condiciones
            </CustomGhost>
          </DocumentCheckBox>
          <DocumentCheckBox
            name="bloomRegulation"
            formProps={formProps}
            mb={isDesktop ? '75px' : '40px'}
          >
            <TextContainer>
              {'He leído y acepto el '}
            </TextContainer>
            <CustomGhost
              src={REGLAMENTO_BLOOM}
            >
              Reglamento de Bloom Crowdfunding S.A. y sus anexos
            </CustomGhost>
          </DocumentCheckBox>
          <DocumentCheckBox
            name="coopcentralRegulation"
            formProps={formProps}
            mb={isDesktop ? '75px' : '50px'}
          >
            <span>
              <TextContainer>
                {'He leído y acepto los '}
              </TextContainer>
              <CustomGhost
                src={BCC_REGLAMENTO_DEPOSITO_ORDINARIO}
              >
                Reglamentos y términos y condiciones del Banco CoopCentral
              </CustomGhost>
              <TextContainer>
                {' para realizar la apertura de un Depósito electrónico de bajo monto.'}
              </TextContainer>
            </span>
          </DocumentCheckBox>
        </Grid>
        <Grid container justify="center">
          <Hollow
            isSubmit
            type="submit"
            width={isDesktop ? 32.6 : 100}
            onColorType={hollowColorType.primary}
            disabled={!Object.values(formProps.getValues()).reduce((pv, v) => pv && v, true)}
          >
            Validar
          </Hollow>
          {isDesktop && (
            <Ghost
              isCenter
              isSubmit
              underline
              fullWidth
              size={14}
              padding="12px"
              onClick={logout}
              color={colors.primary}
            >
              Cerrar sesión
            </Ghost>
          )}
        </Grid>
      </form>
    </CustomDialog>
  );
}

const DocumentCheckBox = ({
  children, formProps, name, mb,
}) => (
  <CustomCheckBox
    mb={mb}
    size={14}
    name={name}
    {...formProps}
    errorText={false}
    lineHeight="22px"
    textPlacement="right"
    contentCheckBoxPadding="0 0 0 60px"
  >
    {children}
  </CustomCheckBox>
);

const CustomGhost = ({ children, src }) => (
  <Ghost
    isSubmit
    size={14}
    underline
    type="button"
    align="start"
    color={colors.primary}
    onClick={() => window.open(src)}
  >
    {children}
  </Ghost>
);

const CustomCheckBox = styled(CheckBox)`
  font-size: 14px;
  cursor: pointer;
  text-align: start;
  width: fit-content;
  ${(props) => props.mb && `margin-bottom: ${props.mb};`}
`;

const TextContainer = styled.span`
  font-size: 14px;
`;
