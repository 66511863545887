import { Fetch } from 'lib/Fetch';

export function getVerificationLinks() {
  return new Fetch('api_2')
    .url('/user/register/user/files/link')
    .method('get')
    .userToken()
    .send();
}

export function getVerificationLinksCompany() {
  return new Fetch('api_2')
    .url('/company/register/company/files/link')
    .method('get')
    .userToken()
    .send();
}

export function verifyVerificationDocs() {
  return new Fetch('api_2')
    .url('/user/register/user/verify/identity')
    .method('post')
    .userToken()
    .send();
}

export function verifyVerificationDocsCompany() {
  return new Fetch('api_2')
    .url('/company/register/company/verify/identity')
    .method('post')
    .userToken()
    .send();
}
