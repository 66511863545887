import React, { useState } from 'react';
import { Figure, Div, weight } from 'globalStyle';
import { useSearchParam, useMediaQuery, useUser } from 'hooks';
import { TextLight } from 'components/Texts';
import { Hollow } from 'components/Buttons';
import rechangeSuccess from 'assets/persons/goal-3.svg';
import { useLocation } from 'wouter';
import { formatNumber } from 'util/formatNumber';
import { Dialog, DialogContent } from '@material-ui/core';
import arrowLeft from 'assets/icons/arrowLeftHeader.svg';
import closeIcon from 'assets/icons/closeIcon.svg';
import { routes } from 'routes';
import { userType } from 'types';

const transactionSuccessful = ['CAPTURED', 'WAITING_FOR_PAYMENT'];

export const RechargeStatus = () => {
  const [, setLocation] = useLocation();
  const transactionStatus = useSearchParam('vads_trans_status');
  const amount = useSearchParam('vads_amount');
  const amountCut = amount?.slice(0, -2);
  const { type } = useUser();
  const [rechargeStatus, setRechargeStatus] = useState(() => {
    if (!transactionStatus) return null;
    return transactionSuccessful.includes(transactionStatus);
  });

  const handleClose = () => {
    setRechargeStatus(null);
    setLocation('?');
  };
  const isDesktop = useMediaQuery();
  return (
    <Dialog
      open={rechargeStatus}
      PaperProps={{
        style: { borderRadius: '25px', padding: isDesktop ? '30px' : '20px' },
      }}
      onClose={handleClose}
      maxWidth="md"
    >
      <DialogContent
        style={{
          width: isDesktop ? '500px' : '100%',
          padding: '0',
        }}
        align="center"
      >
        <Div isFlex justify="space-between">
          <Figure
            style={{
              width: '25px',
              height: '25px',
              cursor: 'pointer',
            }}
            onClick={() => {
              handleClose();
            }}
          >
            <img src={arrowLeft} alt="arrowLeft" />
          </Figure>
          <Figure
            style={{ width: '15px', height: '15px', cursor: 'pointer' }}
            onClick={() => {
              handleClose();
            }}
          >
            <img
              src={closeIcon}
              alt="cerrar"
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </Figure>
        </Div>
        <Div>
          <Figure
            width={isDesktop ? '190px' : '150px'}
            height={isDesktop ? '190px' : '150px'}
            style={{ marginBottom: '30px' }}
          >
            <img src={rechangeSuccess} alt="Recarga éxitosa" />
          </Figure>
          <TextLight size={isDesktop ? 24 : 16} mb={20} weight={weight.BOLD}>
            Recarga éxitosa
          </TextLight>
          <TextLight size={isDesktop ? 16 : 13}>
            Tu recarga por
            {' '}
            <strong>{formatNumber(amountCut)}</strong>
            {' '}
            fue
            exitosa, podrás ver tu saldo actualizado en tu perfil, si tienes
            algún problema puedes comunicarte con servicio al cliente
          </TextLight>

          <Div isFlex margin="30px 0 0">
            {type === userType.USER && (
              <Hollow
                href={routes.home}
                onColorType="primary"
                padding="10px 0"
                size="14"
                style={{
                  borderRadius: '20px',
                  width: isDesktop ? '330px' : '100%',
                  height: isDesktop && '45px',
                }}
              >
                Invertir
              </Hollow>
            )}
            {type === userType.COMPANY && (
              <Hollow
                isSubmit
                onClick={handleClose}
                onColorType="primary"
                padding="10px 0"
                size="14"
                style={{
                  borderRadius: '20px',
                  width: isDesktop ? '330px' : '100%',
                  height: isDesktop && '45px',
                }}
              >
                Ir al perfil
              </Hollow>
            )}
          </Div>
        </Div>
      </DialogContent>
    </Dialog>
  );
};
