import React, { useState, useCallback } from 'react';
import { useFormProps } from 'hooks';
import TabPanel from 'components/TabPanel';
import { Title, Text } from 'components/Texts';
import {
  validateOTP,
  userForgotPasswordEmailSendService,
  companyForgotPasswordEmailSendService,
} from 'services';
import MobileHeader, { CLIENT_TYPE } from 'Layout/components/Navbar/components/components/MobileHeader';
import RecoverPasswordEmail from 'Layout/components/Navbar/components/Recover/components/Password/RecoverPasswordEmail';

import RecoverPasswordNew from './RecoverPasswordNew';
import RecoverPasswordError from './RecoverPasswordError';
import RecoverPasswordSuccess from './RecoverPasswordSuccess';
import RegisterOTP from '../../../Register/components/RegisterOTP';

export default function RecoverPassword({
  open, isDesktop, onClose, clientType,
}) {
  const [step, setStep] = useState(0);
  const [error, setError] = useState(false);
  const [formValues, setFormValues] = useState({});
  const back = useCallback(() => setStep((currentStep) => currentStep - 1), []);
  const next = useCallback(() => setStep((currentStep) => currentStep + 1), []);
  const saveValues = useCallback((v) => {
    setFormValues((currentFormValues) => ({ ...currentFormValues, ...v }));
    next();
  }, [next]);
  const { formProps, stateFormProps } = useFormProps({ errorToolTip: true, required: true });
  const sendEmail = useCallback((v, resend) => {
    const email = v?.email || formValues.email;
    const reSend = typeof resend === 'boolean' ? resend : false;
    stateFormProps.setLoading(true);
    if (clientType === CLIENT_TYPE.INVESTOR) {
      userForgotPasswordEmailSendService({ email, resend: reSend })
        .then(() => {
          if (!reSend) {
            saveValues(v);
            stateFormProps.setError(false);
          }
        })
        .catch(({ messageFormat }) => {
          stateFormProps.setError(true, messageFormat);
        })
        .finally(() => {
          stateFormProps.setLoading(false);
        });
    } else {
      companyForgotPasswordEmailSendService({ email, resend: reSend })
        .then(() => {
          if (!reSend) {
            saveValues(v);
            stateFormProps.setError(false);
          }
        })
        .catch(({ messageFormat }) => {
          stateFormProps.setError(true, messageFormat);
        })
        .finally(() => {
          stateFormProps.setLoading(false);
        });
    }
  }, [formProps, stateFormProps]);
  const verifyOtp = useCallback((otp) => {
    stateFormProps.setLoading(true);
    validateOTP({ otp, receiver: formValues.email })
      .then(() => {
        saveValues({ otp });
      })
      .catch(({ messageFormat }) => {
        stateFormProps.setError(true, messageFormat);
      })
      .finally(() => {
        stateFormProps.setLoading(false);
      });
  }, [formValues]);
  if (error) {
    return (
      <RecoverPasswordError
        open={open}
        error={error}
        onClose={onClose}
        isDesktop={isDesktop}
        onBack={() => setError(false)}
      />
    );
  }
  return (
    <>
      <TabPanel value={step} index={0}>
        <RecoverPasswordEmail
          open={open}
          onClose={onClose}
          onNext={saveValues}
          isDesktop={isDesktop}
          formProps={formProps}
          sendEmail={sendEmail}
          clientType={clientType}
          stateFormProps={stateFormProps}
        />
      </TabPanel>
      <TabPanel value={step} index={1}>
        <RegisterOTP
          open={open}
          onNext={next}
          onBack={back}
          checkOTP={verifyOtp}
          fullSize={!isDesktop}
          isDesktop={isDesktop}
          clientType={clientType}
          sendOTP={(v) => sendEmail(v, true)}
          error={stateFormProps.errorMessage}
          isLoading={stateFormProps.isLoading}
          modalHeader={!isDesktop && (
            <MobileHeader
              clientType={clientType}
            />
          )}
          showMobileHeader={false}
          modalTitle={(
            <Title size={20}>
              ¿Olvidaste tu contraseña?
            </Title>
          )}
          title={(
            <Text size={16}>
              Te enviamos un código de seguridad al correo
              <b>{` *****@${formValues.email?.split('@')[1]}`}</b>
            </Text>
          )}
        />
      </TabPanel>
      <TabPanel value={step} index={2}>
        <RecoverPasswordNew
          open={open}
          onBack={back}
          onClose={onClose}
          onNext={saveValues}
          otp={formValues.otp}
          isDesktop={isDesktop}
          formProps={formProps}
          sendEmail={sendEmail}
          clientType={clientType}
          email={formValues.email}
          stateFormProps={stateFormProps}
          onError={(newError) => setError(newError)}
        />
      </TabPanel>
      <TabPanel value={step} index={3}>
        <RecoverPasswordSuccess
          open={open}
          onBack={back}
          onClose={onClose}
          onNext={saveValues}
          isDesktop={isDesktop}
          formProps={formProps}
          sendEmail={sendEmail}
          clientType={clientType}
          stateFormProps={stateFormProps}
        />
      </TabPanel>
    </>
  );
}
