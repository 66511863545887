import React, {
  useState, useCallback, useEffect,
} from 'react';
import { routes } from 'routes';
import { useLocation } from 'wouter';
import { colors } from 'globalStyle';
import Container from 'components/Container';
import { useFormProps, useUser } from 'hooks';
import { getNameLetter } from 'util/userName';
import BloomLogo from 'assets/icons/bloom-circle.svg';
import SendIcon from 'assets/icons/paper-airplane.svg';
import { Input, InputFormatNumber } from 'components/Form';
import { hideChatWhatsApp, showChatWhatsApp } from 'util/hubspot';
import { getUserData, addFinancialInfo, createBankAccount } from 'services';
import {
  Box, Grid, IconButton, styled,
} from '@material-ui/core';

import FinancialChatMessage from './FinancialChatMessage';
import FinancialChatDelayer from './FinancialChatDelayer';
import { FINANCIAL_ORDER, FINANCIAL_SAVE, financialChatScript } from './financialChatScript';

const INITIAL_FIELD = 'test';

export function missingFinancialData(info) {
  const financialFields = [...FINANCIAL_ORDER];
  let next;
  let field;
  let isMissing = false;
  let lastOptional;
  let recoveryObj;
  financialFields.forEach((v, _, array) => {
    if (info[v.field] === false || info[v.field] === null) {
      if (v.optional && !lastOptional) {
        lastOptional = {
          field: v.field,
          step: v.step || v.field,
          recoveryObj: v,
        };
      } else if (!v.skip || !v.skip(info)) {
        if (lastOptional) {
          next = lastOptional.step;
          field = lastOptional.field;
          recoveryObj = lastOptional.recoveryObj;
        } else {
          field = v.field;
          next = v.step || v.field;
          recoveryObj = v;
        }
        isMissing = true;
        array.splice(0);
      }
    } else {
      lastOptional = undefined;
    }
    if (v.dependencies && info[v.field]) {
      const financialFields2 = [...v.dependencies];
      financialFields2.forEach((v2, _2, array2) => {
        if (!info[v2.field]) {
          field = v2.field;
          next = v2.step || v2.field;
          recoveryObj = v;
          array.splice(0);
          array2.splice(0);
          isMissing = true;
        }
      });
    }
  });
  return {
    field, next, isMissing, recoveryObj,
  };
}

export default function FinancialChat({ onNext, heigth }) {
  const { user, update } = useUser();
  const [chat, setChat] = useState([]);
  const [value, setValue] = useState();
  const [, setLocation] = useLocation();
  const [values, setValues] = useState({});
  const [active, setActive] = useState(INITIAL_FIELD);
  const onFinish = useCallback(() => {
    setActive('loading');
    addFinancialInfo({
      country: 'COLOMBIA',
      regulationBloom: true,
      termsAndConditions: true,
      coopcentralRegulation: true,
    })
      .then(() => {
        createBankAccount()
          .then(({ data }) => {
            getUserData()
              .then(({ data: userData }) => {
                const { financialInfo, status } = userData;
                const financialInfoCompleted = !missingFinancialData(financialInfo).isMissing;
                update({ key: 'status', value: status });
                update({ key: 'bankAccount', value: data });
                update({ key: 'financialInfoCompleted', value: financialInfoCompleted });
                onNext('account created');
              });
            window.lintrk('track', { conversion_id: 18375290 });
          })
          .catch(({ message }) => {
            if (message === 'ACCOUNT_CREATION_ERROR') {
              onNext('ACCOUNT_CREATION_ERROR');
            } else {
              onNext(false);
            }
          });
      });
  }, []);
  const script = financialChatScript(values, onFinish);
  const { formProps } = useFormProps({ errorToolTip: false, errorText: true });
  const addMessage = (newMessages) => setChat((messages) => [...messages, newMessages]);
  useEffect(() => {
    if (user.info.status !== 'ACTIVE') {
      hideChatWhatsApp();
    }
    return showChatWhatsApp;
  });
  useEffect(() => {
    getUserData()
      .then(({ data }) => {
        const info = data.financialInfo;
        const { next, isMissing, recoveryObj } = missingFinancialData(info);
        if (isMissing) {
          setValues(info);
          setActive(next);
          if (recoveryObj.message) {
            addMessage({
              received: true,
              key: 'recovery-message',
              messages: recoveryObj.message,
            });
          }
        } else {
          setLocation(routes.home);
        }
      });
  }, []);
  useEffect(() => {
    if (typeof active === 'function') {
      active();
    }
  }, [active]);
  const setInputValue = (v) => {
    if (v.input !== '') {
      setValue(v.input);
      formProps.reset();
      setValue();
    }
  };
  const updateChat = useCallback((selectedValue) => {
    let newValue;
    if (typeof selectedValue === 'object') {
      const v = { ...selectedValue };
      delete v.textValue;
      [newValue] = Object.values(v);
    } else {
      newValue = selectedValue;
    }
    setActive((currentValue) => {
      const activeValue = script[currentValue];
      const next = typeof activeValue.next === 'function' ? activeValue.next(newValue) : activeValue.next;
      setValues((oldValues) => ({ ...oldValues, [activeValue.field]: newValue }));
      if (FINANCIAL_SAVE.includes(activeValue.field)) {
        let v;
        if (activeValue.getValue && typeof activeValue.getValue === 'function') {
          v = activeValue.getValue(newValue);
        } else {
          v = activeValue.type === 'currency'
            ? newValue.replaceAll(' ', '').replaceAll('.', '')
            : newValue;
        }
        if (v !== undefined) {
          addFinancialInfo(typeof v === 'object' ? v : { [activeValue.field]: v });
        }
        setValue();
      }
      return next;
    });
  }, [script, active]);
  return (
    <Container
      justify="flex-end"
      style={{
        height: heigth || 'calc(100dvh - 57.5px)',
      }}
    >
      { Object.keys(values).length === 0
        ? (
          <div />
        )
        : (
          <ChatContainer pr={20} pl={20}>
            {chat.map(((v) => (
              <>
                <RootContainer key={v.key}>
                  {v.received
                    ? (
                      <Box
                        component="img"
                        src={BloomLogo}
                      />
                    )
                    : <div />}
                  <Grid container justify={v.received ? 'flex-start' : 'flex-end'}>
                    <FinancialChatMessage messages={v.messages} received={v.received} />
                  </Grid>
                  {v.received
                    ? <div />
                    : (
                      <Box
                        component="img"
                        maxWidth="50px"
                        maxHeight="50px"
                        src={getNameLetter(user?.info?.firstName || user?.info?.name)}
                      />
                    )}
                </RootContainer>
              </>
            )))}
            {active && (
              <RootContainer container>
                <Box
                  component="img"
                  src={BloomLogo}
                />
                <FinancialChatDelayer
                  history={chat}
                  update={updateChat}
                  addMessage={addMessage}
                  data={value === undefined ? script[active] : { ...script[active], value }}
                />
                <div />
              </RootContainer>
            )}
          </ChatContainer>
        )}
      <form style={{ width: '100%' }} onSubmit={formProps.handleSubmit(setInputValue)}>
        <Container
          pr={20}
          pl={20}
          pt={20}
          pb={15}
          direction="row"
          style={{
            boxShadow: '0px -4px 10px 0px rgba(0, 0, 0, 0.06)',
          }}
        >
          <Grid item xs={10}>
            <FinancialInput
              {...formProps}
              {...script[active]}
              name="input"
              inputHeight="45px"
            />
          </Grid>
          <Grid
            item
            xs={2}
            container
            justify="flex-end"
            style={{
              marginTop: '-6px',
            }}
          >
            <IconButton
              type="submit"
              style={{
                width: '45px',
                height: '45px',
                background: colors.primary,
              }}
            >
              <Box
                width="45px"
                src={SendIcon}
                component="img"
                paddingTop="3px"
                paddingRight="3px"
              />
            </IconButton>
          </Grid>
        </Container>
      </form>
    </Container>
  );
}

const FinancialInput = ({ type, getInputRef, ...props }) => {
  if (type === 'text') {
    return (
      <Input
        {...props}
        autoFocus
      />
    );
  }
  if (type === 'currency') {
    return (
      <InputFormatNumber
        {...props}
        autoFocus
        name="input"
        type="number"
        defaultValue=""
        getInputRef={getInputRef}
      />
    );
  }
  return (
    <Input
      disabled
      {...props}
    />
  );
};

const ChatContainer = styled(Container)({
  overflow: 'auto',
  paddingTop: '10px',
  flexDirection: 'row',
  paddingBottom: '20px',
  maxHeight: 'calc(100% - 108px)',
});

const RootContainer = styled(Grid)({
  width: '100%',
  display: 'grid',
  columnGap: '5px',
  gridTemplateColumns: '25px 1fr 25px',
});
