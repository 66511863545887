import React from 'react';
import { Title } from 'components/Texts';
import { Line, colors } from 'globalStyle';
import { Box, Grid } from '@material-ui/core';

export default function RegisterMobileHeader({ title, subtitle }) {
  return (
    <Grid container direction="column" alignItems="center">
      <Title mt={-20} mb={12} size={16} color={colors.primary} isCenter>
        {title}
      </Title>
      <Title size={14} isCenter>
        {subtitle}
      </Title>
      <Box
        width="100%"
        marginTop="4px"
        marginBottom="25px"
      >
        <Line color={colors.primary} />
      </Box>
    </Grid>
  );
}
