/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { BasicInput } from './Basic';
import { Check } from './Check';

export const Input = ({
  dirtyFields,
  getValues,
  name,
  errors,
  required = false,
  showCheck = true,
  ...rest
}) => (
  <BasicInput
    dirtyFields={dirtyFields}
    name={name}
    errors={errors}
    required={required}
    {...rest}
  >
    {showCheck && <Check name={name} getValues={getValues} errors={errors} /> }
  </BasicInput>
);
