import { useState, useEffect, useCallback } from 'react';
import { getInvestors } from 'services';
import { useDispatch, useSelector } from 'react-redux';
import { useMountedState } from 'react-use';
import { updateInvestors } from 'store/investors';
import { useCancelToken } from '../useCancelToken';

export const useInvestorsCache = () => {
  const isMounted = useMountedState();
  const dispatch = useDispatch();
  const investorsCache = useSelector((store) => store.investors.investors);

  const setInvestorsInfo = useCallback((investorsInfo) => {
    if (isMounted()) {
      dispatch(updateInvestors(investorsInfo));
    }
  }, []);

  return {
    investorsCache: investorsCache || [],
    setInvestorsInfo,
  };
};

export const useInversionist = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState([]);
  const { investorsCache, setInvestorsInfo } = useInvestorsCache();
  const { isMounted, logCallback } = useCancelToken();

  const fetch = () => {
    setLoading(true);
    if (investorsCache?.length === 0) {
      getInvestors()
        .then(({ data }) => {
          if (isMounted) {
            setValue(data);
            setInvestorsInfo(data);
            setLoading(false);
          }
        })
        .catch((err) => {
          logCallback(err, () => {
            setValue([]);
            setLoading(false);
          });
        });
    } else {
      setValue(investorsCache);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return {
    investors: value || [],
    isLoadingInvestors: loading,
  };
};
