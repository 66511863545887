/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-curly-newline */
import React, {
  useEffect, useCallback, useState, useRef,
} from 'react';
import { useFormProps } from 'hooks';
import { Text } from 'components/Texts';
import Container from 'components/Container';
import { getBCCOperationTypes } from 'services';
import { Box, Grid, styled } from '@material-ui/core';
import {
  Input, InputFormatNumber, InputRadio, InputOnlyNumbers, Select,
} from 'components/Form';
import RegisterModalNextButton from './RegisterModalNextButton';

const fromCurrencyInput = (v) => String(v).replaceAll(/\s|[.]/g, '').replaceAll(/\s|[.]/g, '');

export default function RegisterLRFinancialInfo({
  isDesktop, info, logout, sendData, isLoading,
  dropdownsInfo = {}, onInfoCompletedValidation, error,
}) {
  const {
    formProps,
  } = useFormProps({ errorToolTip: false, errorText: true, required: true });
  const internationalOperations = formProps.watch('hasForeignOperation');
  const foreignCurrency = formProps.watch('hasForeignCurrency');
  const [operationTypes, setOperationTypes] = useState({});
  useEffect(() => {
    getBCCOperationTypes()
      .then(({ data }) => {
        setOperationTypes(data);
      });
  }, []);
  const firstItemRef = useRef();
  const middleItemRef = useRef();
  const lastItemRef = useRef();
  useEffect(() => {
    if (isDesktop) {
      if (middleItemRef.current) {
        middleItemRef?.current?.scrollIntoView({ behavior: 'smooth' });
      } else {
        firstItemRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }
    if (internationalOperations === 'true') {
      setTimeout(() => {
        formProps.setValue('foreignOperationType', info.legalRepresentative.internationalOperationsType);
        formProps.setValue('internationalEntityName', info.legalRepresentative.legalRepInternationalOperationEntityName);
        formProps.setValue('accountNumber', info.legalRepresentative.legalRepInternationalOperationAccount);
        formProps.setValue('country', info.legalRepresentative.legalRepInternationalOperationCountry);
        formProps.setValue('city', info.legalRepresentative.legalRepInternationalOperationCity);
        formProps.setValue('otherInternationalOperationDescription', info.legalRepresentative.legalRepOtherInternationalOperationDescription);
        formProps.setValue('legalRepInternationalAmount', info.legalRepresentative.legalRepInternationalAmount);
        if (info.legalRepresentative.legalRepForeignCurency === 'S') {
          formProps.setValue('hasForeignCurrency', 'true');
          setTimeout(() => {
            formProps.setValue('coin', info.legalRepresentative.internationalCurrencyType);
          });
        } else if (info.legalRepresentative.legalRepForeignCurency === 'N') {
          formProps.setValue('hasForeignCurrency', 'false');
        }
      }, 1000);
    }
  }, [internationalOperations, isDesktop]);
  useEffect(() => {
    if (info) {
      formProps.setValue('income', info.legalRepresentative.monthlyIncome);
      formProps.setValue('outcome', info.legalRepresentative.monthlyExpenses);
      formProps.setValue('assets', info.legalRepresentative.assetsValue);
      formProps.setValue('liabilities', info.legalRepresentative.liabilitiesValue);
      formProps.setValue('anotherIncome', info.legalRepresentative.monthlyOtherIncome);
      formProps.setValue('anotherIncomeDescription', info.legalRepresentative.otherIncomeDescription);
      if (info.legalRepresentative.otherIncomeDescription) {
        if (info.legalRepresentative.legalRepInternationalOperation === 'S') {
          formProps.setValue('hasForeignOperation', 'true');
        } else if (info.legalRepresentative.legalRepInternationalOperation === 'N') {
          formProps.setValue('hasForeignOperation', 'false');
        }
      }
    }
  }, [info]);
  useEffect(() => {
    if (info && onInfoCompletedValidation) {
      let completed = true;
      const fields = ['monthlyIncome', 'monthlyExpenses', 'assetsValue', 'liabilitiesValue', 'monthlyOtherIncome', 'otherIncomeDescription'];
      completed = fields.every((v) => info.legalRepresentative[v] !== null);
      if (completed && info.legalRepresentative.bccFieldsResource) {
        const foreignOpsFields = ['internationalOperationsType', 'internationalEntityName', 'internationalAccountNumber', 'internationalCountry', 'internationalCity', 'internationalMonetary'];
        completed = foreignOpsFields.every((v) => info.bccFieldsResource[v] !== null);
      }
      onInfoCompletedValidation(completed);
    }
  }, [info, onInfoCompletedValidation]);
  const send = useCallback((data) => {
    const newData = { ...data };
    newData.income = fromCurrencyInput(newData.income);
    newData.outcome = fromCurrencyInput(newData.outcome);
    newData.assets = fromCurrencyInput(newData.assets);
    newData.liabilities = fromCurrencyInput(newData.liabilities);
    newData.anotherIncome = fromCurrencyInput(newData.anotherIncome);
    newData.hasForeignOperation = !!(newData.hasForeignOperation === 'true' || newData.hasForeignOperation === true);
    sendData(newData);
  }, []);
  return (
    <Container>
      <Box component="form" onSubmit={formProps.handleSubmit(send)} id="mainForm">
        <FormContainer container isDesktop={isDesktop}>
          <InputContainer isDesktop={isDesktop} ref={firstItemRef}>
            <InputFormatNumber
              name="income"
              label="Ingresos mensuales"
              {...formProps}
            />
          </InputContainer>
          <InputContainer isDesktop={isDesktop}>
            <InputFormatNumber
              name="outcome"
              label="Egresos mensuales"
              {...formProps}
            />
          </InputContainer>
          <InputContainer isDesktop={isDesktop}>
            <InputFormatNumber
              name="assets"
              label="Activos"
              {...formProps}
            />
          </InputContainer>
          <InputContainer isDesktop={isDesktop}>
            <InputFormatNumber
              name="liabilities"
              label="Pasivos"
              {...formProps}
            />
          </InputContainer>
          <InputContainer isDesktop={isDesktop}>
            <InputFormatNumber
              name="anotherIncome"
              label="Otros ingresos mensuales"
              {...formProps}
            />
          </InputContainer>
          <InputContainer isDesktop={isDesktop}>
            <Input
              name="anotherIncomeDescription"
              label="Descripción de otros ingresos"
              {...formProps}
            />
          </InputContainer>
          {/*
          <Grid item container justify={isDesktop ? 'space-between' : 'center'}>
            <Text
              size={14}
              width="fit-content"
              mr={isDesktop && 40}
              mb={!isDesktop && 30}
              isCenter={!isDesktop}
            >
              ¿Realizan operaciones con el exterior?
            </Text>
            <Grid
              container
              style={{
                width: 'fit-content',
                marginBottom: isDesktop ? '32px' : '30px',
              }}
              ref={middleItemRef}
            >
              <InputRadio
                label="Sí"
                name="hasForeignOperation"
                value
                internalProps={{
                  pl: 18,
                }}
                {...formProps}
              />
              <div style={{ marginRight: '56px' }} />
              <InputRadio
                label="No"
                name="hasForeignOperation"
                value={false}
                internalProps={{
                  pl: 18,
                }}
                {...formProps}
              />
            </Grid>
          </Grid>
          {internationalOperations === 'true' && (
            <>
              <InputContainer isDesktop={isDesktop}>
                <Select
                  label="Tipo de operación en el exterior"
                  name="foreignOperationType"
                  {...formProps}
                >
                  {Object.entries(operationTypes)
                    ?.map(([value, name]) => (
                      <option key={name} value={value}>
                        {name}
                      </option>
                    ))}
                </Select>
              </InputContainer>
              <InputContainer isDesktop={isDesktop}>
                <Input
                  name="internationalEntityName"
                  label="Nombre de entidad internacional"
                  {...formProps}
                />
              </InputContainer>
              <InputContainer isDesktop={isDesktop}>
                <InputOnlyNumbers
                  name="accountNumber"
                  label="Número de cuenta"
                  {...formProps}
                />
              </InputContainer>
              <InputContainer isDesktop={isDesktop}>
                <Select
                  label="País"
                  name="country"
                  {...formProps}
                >
                  {Object.entries(dropdownsInfo?.countries)?.map(([value, name]) => (
                    <option key={name} value={value}>
                      {name}
                    </option>
                  ))}
                </Select>
              </InputContainer>
              <InputContainer isDesktop={isDesktop}>
                <Input
                  name="city"
                  label="Ciudad"
                  onBlur={() => lastItemRef?.current?.scrollIntoView({ behavior: 'smooth' })}
                  {...formProps}
                />
              </InputContainer>
              <InputContainer isDesktop={isDesktop}>
                <Input
                  label="Saldo de la Cuenta"
                  name="legalRepInternationalAmount"
                  {...formProps}
                />
              </InputContainer>
              <InputContainer
                fullWidth
                isDesktop={isDesktop}
              >
                <Input
                  mb={0}
                  name="otherInternationalOperationDescription"
                  label="Descripción de Otras Operaciones en el Exterior"
                  {...formProps}
                />
              </InputContainer>
              <Grid
                item
                container
                style={{
                  width: isDesktop && '50%',
                  marginTop: isDesktop ? '40px' : '10px',
                }}
                ref={lastItemRef}
                justify={isDesktop ? 'space-between' : 'center'}
              >
                <Text
                  size={14}
                  width="fit-content"
                  mr={isDesktop && 40}
                  mb={!isDesktop && 30}
                  isCenter={!isDesktop}
                >
                  ¿Usas moneda extranjera?
                </Text>
                <Grid
                  container
                  style={{
                    width: 'fit-content',
                    marginBottom: isDesktop ? '32px' : '30px',
                  }}
                >
                  <InputRadio
                    label="Sí"
                    name="hasForeignCurrency"
                    value
                    internalProps={{
                      pl: 18,
                    }}
                    {...formProps}
                  />
                  <div style={{ marginRight: '43px' }} />
                  <InputRadio
                    label="No"
                    name="hasForeignCurrency"
                    value={false}
                    internalProps={{
                      pl: 18,
                    }}
                    {...formProps}
                  />
                </Grid>
              </Grid>
              {foreignCurrency === 'true' && (
                <InputContainer isDesktop={isDesktop}>
                  <Select
                    label="Moneda"
                    name="coin"
                    mb={0}
                    {...formProps}
                  >
                    {Object.entries(dropdownsInfo?.currencies)?.map(
                      ([value, name]) => (
                        <option key={name} value={value}>
                          {name}
                        </option>
                      ),
                    )}
                  </Select>
                </InputContainer>
              )}
            </>
          )}
          */}
        </FormContainer>
        <RegisterModalNextButton
          form="mainForm"
          error={error}
          logout={logout}
          isLoading={isLoading}
          isDesktop={isDesktop}
          disabled={false /* internationalOperations === undefined
            || (internationalOperations === 'true' && foreignCurrency === undefined) */
          }
        />
      </Box>
    </Container>
  );
}

const FormContainer = styled(Grid)(({ isDesktop }) => ({
  rowGap: '5px',
  padding: '0 20px',
  textAlign: 'initial',
  overflow: isDesktop && 'auto',
  justifyContent: 'space-between',
  minHeight: isDesktop && '315px',
  maxHeight: isDesktop && '315px',
  marginTop: isDesktop ? '30px' : '32px',
  marginBottom: isDesktop ? '23px' : '30px',
}));

const InputContainer = styled(Grid)(({ isDesktop, fullWidth }) => ({
  width: isDesktop && !fullWidth ? '300px' : '100%',
}));
