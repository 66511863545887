import { Fetch } from 'lib/Fetch';

export function legalRepresentativeAcceptTerms({ terms }) {
  return new Fetch()
    .url('/user/legal-representative/accept-terms')
    .method('post')
    .userToken()
    .data(terms)
    .send();
}
