import React, { useCallback } from 'react';
import { routes } from 'routes';
import { useUser } from 'hooks';
import { colors } from 'globalStyle';
import { Box } from '@material-ui/core';
import { Title, Text } from 'components/Texts';
import ImageDesktop from 'assets/persons/goal-3.svg';
import CustomDialog from 'components/Modals/CustomDialog';
import { Hollow, hollowColorType } from 'components/Buttons';
import ImageMobile from 'assets/persons/filling-out-form.svg';

export default function RegisterCompanySuccessful({ isDesktop, onClose }) {
  const { logout } = useUser();
  const close = useCallback(() => {
    logout();
    if (onClose) {
      onClose();
    }
  }, [onClose]);
  return (
    <CustomDialog
      open
      bg={colors.primary}
      color={colors.WHITE}
      onClose={onClose && close}
      pt={isDesktop ? '17px' : '3.66px'}
      px={isDesktop ? '101px' : '28px'}
      width={isDesktop ? '615px' : '290px'}
      height={isDesktop ? '530px' : '520px'}
    >
      <Box
        alt=""
        component="img"
        width={isDesktop ? '168.1px' : '160px'}
        height={isDesktop ? '160.8px' : '136px'}
        src={isDesktop ? ImageDesktop : ImageMobile}
      />
      <Title
        color={colors.WHITE}
        mb={isDesktop ? 15 : 0}
        mt={isDesktop ? 26.2 : 42}
        size={isDesktop ? 24 : 16}
        maxWidth={isDesktop ? '385px' : ''}
      >
        {isDesktop ? '¡La empresa ha sido registrada con éxito!' : '¡Felicitaciones!'}
      </Title>
      {!isDesktop && (
        <Text size={14} color={colors.WHITE} maxWidth="210px" mt={0.11} mb={17.72}>
          La empresa ha sido registrada con éxito
        </Text>
      )}
      <Text
        color={colors.WHITE}
        size={isDesktop ? 15 : 14}
      >
        {isDesktop
          ? `Te enviaremos a tu correo electrónico los siguientes pasos para
          enviar todos los documentos necesarios para publicar tu proyecto en la plataforma`
          : `Te enviaremos a tu correo electrónico los siguientes
          pasos para subir todos los documentos necesarios para publicar tu empresa en la plataforma`}
      </Text>
      <Box
        width={isDesktop ? '330px' : '220px'}
        marginTop={isDesktop ? '41px' : '39.46px'}
      >
        <Hollow
          size={14}
          fullWidth
          onClick={close}
          href={routes.home}
          color={isDesktop && colors.WHITE}
          onColorType={!isDesktop && hollowColorType.WHITE}
        >
          Explorar
          {isDesktop && ' plataforma'}
        </Hollow>
      </Box>
    </CustomDialog>
  );
}
