const UPDATE = 'UPDATE_ALL_LOCATIONS';

const initialState = {
  current: '',
  previous: '',
};

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default locationReducer;

export const update = (payload) => ({
  type: UPDATE,
  payload,
});
