/* eslint-disable no-param-reassign */
import { Fetch } from 'lib/Fetch';

export function getCategorizations() {
  return new Fetch('api_2')
    .url('/external/global/categorizations')
    .method('get')
    .portalToken()
    .send()
    .then((data) => {
      const i = data.data.findIndex(
        (categorization) => categorization?.isDefaultCategorization,
      );
      data.data = [data.data[i], ...data.data.slice(0, i), ...data.data.slice(i + 1)];
      return data;
    });
}

export function getCategorizationsNames() {
  return new Fetch('api_2')
    .url('/external/global/categorizations/names')
    .method('get')
    .portalToken()
    .send()
    .then((data) => {
      const i = data.data.findIndex(
        (categorization) => categorization?.isDefaultCategorization,
      );
      data.data = [data.data[i], ...data.data.slice(0, i), ...data.data.slice(i + 1)];
      return data;
    });
}
