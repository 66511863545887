import React from 'react';
import { colors } from 'globalStyle';
import { Grid } from '@material-ui/core';
import styled, { css, keyframes } from 'styled-components';

export default function LoadingDots() {
  return (
    <RootContainer container spacing={3}>
      <Grid item xs={4}>
        <FirstDot />
      </Grid>
      <Grid item xs={4}>
        <SecondDot />
      </Grid>
      <Grid item xs={4}>
        <ThirdDot />
      </Grid>
    </RootContainer>
  );
}

const RootContainer = styled(Grid)({
  height: '100%',
});

const loadingAnimation = (start = 0, end = 30) => keyframes`
  0% {
    transform: translateY(100%);
  }
  ${start}% {
    transform: translateY(100%);
  }
  ${start + ((end - start) / 2)}% {
    transform: translateY(0px);
  }
  ${end}% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(100%);
  }
`;

const commonStyle = css`
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 100%;
  animation-iteration-count: infinite;
`;

const ANIMATION_DURATION = '0.8s';

const FirstDot = styled(Grid)`
  background: ${colors.primary};
  animation: ${ANIMATION_DURATION} ${loadingAnimation()};
  ${commonStyle}
`;

const SecondDot = styled(Grid)`
  background: ${colors.secondary};
  animation: ${ANIMATION_DURATION} ${loadingAnimation(20, 50)};
 ${commonStyle}
`;

const ThirdDot = styled(Grid)`
  background: ${colors.tertiary};
  animation: ${ANIMATION_DURATION} ${loadingAnimation(40, 70)};
  ${commonStyle}
`;
