import { Fetch } from 'lib/Fetch';

export function mapDropdowns() {
  return new Fetch()
    .url('/portal/front/map-dropdowns')
    .method('get')
    .portalToken()
    .send();
}

export function mapTowns(department) {
  return new Fetch()
    .url('/portal/front/towns')
    .method('post')
    .data(department)
    .portalToken()
    .send();
}
