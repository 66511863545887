import { useUser } from 'hooks';
import { userType } from 'types';
import { getUserData, useGetCompanyStatus } from 'services';
import { missingFinancialData } from 'page/FinancialInfoCompletion/FinancialChat';

export default function useUpdateUserStatus() {
  const { update, type } = useUser();
  const updateUserStatus = () => {
    if (type === userType.USER) {
      return getUserData()
        .then(({ data }) => {
          const { financialInfo, status } = data;
          const financialInfoCompleted = !missingFinancialData(financialInfo).isMissing;
          update({ key: 'status', value: status });
          update({ key: 'financialInfoCompleted', value: financialInfoCompleted });
        });
    }
    return useGetCompanyStatus()
      .then(({ data }) => {
        update({ key: 'status', value: data.status });
      });
  };
  return {
    updateUserStatus,
  };
}
