/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import { routes } from 'routes';
import { useLocation } from 'wouter';
import React, { useState } from 'react';
import { closePopUp } from 'store/popup';
import { useDispatch } from 'react-redux';
import { Auth, Modal } from 'components/Modals';
import { useFormProps, useMediaQuery } from 'hooks';
import { Sections, AntTab, TabPanel } from 'components/Sections';
import { colors } from 'globalStyle';
import { Text } from 'components/Texts';
import { Grid, makeStyles } from '@material-ui/core';
import CheckIcon from 'assets/icons/check-green.svg';
import ModalContent from 'components/Modals/ModalContent';
import { Hollow, hollowColorType } from 'components/Buttons';
import { LoginCompany } from './LoginCompany';
import LoginType from './components/LoginType';
import LoginForm from './components/LoginForm';
import { LoginInversionist } from './LoginInversionist';

const LOGIN_STEPS = Object.freeze({
  TYPE: 0,
  LOGIN: 1,
});

const useStyles = makeStyles({
  img: {
    width: '90px',
    height: '90px',
    margin: 'auto',
    marginTop: '88.64px',
    marginBottom: '40px',
  },
  text: {
    fontSize: '15px',
    color: colors.GRAY_1,
  },
  contentContainer: {
    paddingRight: '16px',
    paddingLeft: '17px',
  },
});

export const Login = ({
  isOpen, onClick, login, value, setValue, CLIENT_TYPE,
}) => {
  const [, setRoute] = useLocation();
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => setValue(newValue);
  const [step, setStep] = useState(LOGIN_STEPS.TYPE);
  const goTo = (newStep) => setStep(newStep);
  const setClientType = (v) => {
    setValue(v);
    goTo(LOGIN_STEPS.LOGIN);
  };
  const finishLogin = () => {
    setRoute(value === CLIENT_TYPE.COMPANY
      ? routes.profileCompany.route : routes.profile.route);
    dispatch(closePopUp());
  };
  const {
    formProps,
    stateFormProps: {
      isLoading,
      setLoading,
      setError,
      errorMessage,
    },
  } = useFormProps({ errorToolTip: true, required: true });
  const isDesktop = useMediaQuery();
  const props = {
    formProps,
    isLoading,
    setLoading,
    setError,
    errorMessage,
  };

  if (step >= 0) {
    return (
      <Modal
        wrap
        isBackground
        zIndex={1001}
        isOpen={isOpen}
        handleClick={onClick}
      >
        <TabPanel value={step} index={LOGIN_STEPS.TYPE}>
          <LoginType
            onClose={onClick}
            isDesktop={isDesktop}
            onFinish={setClientType}
          />
        </TabPanel>
        <TabPanel value={step} index={LOGIN_STEPS.LOGIN}>
          <LoginForm
            onClose={onClick}
            clientType={value}
            isDesktop={isDesktop}
            onFinish={finishLogin}
            onBack={() => goTo(LOGIN_STEPS.TYPE)}
          />
        </TabPanel>
      </Modal>
    );
  }

  return (
    <>
      <Auth
        isOpen={isOpen}
        onClick={onClick}
        fields={5}
      >
        <Sections handleChange={handleChange} value={value}>
          <AntTab fontSize={!isDesktop && 13} label="Inversionista" number={0} />
          <AntTab fontSize={!isDesktop && 13} label="Empresa" number={1} />
        </Sections>
        <TabPanel value={value} index={0}>
          <LoginInversionist login={login} isDesktop={isDesktop} {...props} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <LoginCompany login={login} isDesktop={isDesktop} />
        </TabPanel>
      </Auth>
    </>
  );
};
