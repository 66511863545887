import { Fetch } from 'lib/Fetch';

export function phonePrefixServiceNoLogin() {
  return new Fetch('api_2')
    .url('/external/global/phone-prefix')
    .method('get')
    .portalToken()
    .send();
}

export function phonePrefixService() {
  return new Fetch()
    .url('/user/register/user/prefix')
    .method('get')
    .userToken()
    .send();
}
