import { Fetch } from 'lib/Fetch';

export function companyNotificationService({ limit, source }) {
  return new Fetch()
    .url(`/company/notification/${limit}`)
    .method('get')
    .userToken()
    .setCancelToken({ source })
    .send();
}

export function companyNotificationsRead({ ids }) {
  return new Fetch('api_2')
    .url('/company/notification/used')
    .method('patch')
    .userToken()
    .data({ ids })
    .send();
}
