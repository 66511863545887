/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from 'react';

export const PseForm = ({ pse = null }) => {
  if (!pse) return null;
  useEffect(() => {
    if (pse) {
      setTimeout(() => {
        document.getElementById('goPSE').click();
      }, 1000);
    }
  }, [pse]);

  return pse && (
    <form method="POST" action="https://secure.payzen.lat/vads-payment/">
      {pse.vads.map((v, key) => <input key={key} type="hidden" name={v.name} value={v.value} readOnly />)}
      <input type="hidden" name="signature" value={pse.signature} readOnly />
      <button style={{ display: 'none' }} type="submit" id="goPSE" />
    </form>
  );
};
