import { useEffect, useState } from 'react';
import { phonePrefixServiceNoLogin } from 'services';

export const usePhonePrefixes = () => {
  const [state, setState] = useState({ isLoading: false, data: {} });

  useEffect(() => {
    setState({ isLoading: true, data: {} });
    phonePrefixServiceNoLogin()
      .then(({ data }) => {
        setState({ isLoading: false, data });
      })
      .catch(() => {
        setState({ isLoading: false, data: {} });
      });
  }, []);

  return {
    prefixes: state.data,
    isLoadingPhonePrefix: state.isLoading,
  };
};
