import React from 'react';
import { colors } from 'globalStyle';
import { Box } from '@material-ui/core';
import { openPopUp } from 'store/popup';
import { useDispatch } from 'react-redux';
import Container from 'components/Container';
import { Title, Text } from 'components/Texts';
import { InputEmail, ErrorText } from 'components/Form';
import CustomDialog from 'components/Modals/CustomDialog';
import {
  Hollow, hollowColorType, Ghost, colorType,
} from 'components/Buttons';
import MobileHeader from 'Layout/components/Navbar/components/components/MobileHeader';

export default function RecoverPasswordEmail({
  open, isDesktop, onClose, clientType, formProps, stateFormProps, sendEmail,
}) {
  const dispatch = useDispatch();
  return (
    <CustomDialog
      pt="10px"
      open={open}
      fullSizeNavBar={!isDesktop}
      width={isDesktop && '615px'}
      height={isDesktop && '530px'}
      onBack={!isDesktop && onClose}
      onClose={isDesktop && onClose}
      header={!isDesktop && <MobileHeader clientType={clientType} />}
      px={isDesktop ? '131.5px' : '30px'}
    >
      <Container>
        <Title size={isDesktop ? 20 : 18} isCenter>
          ¿Olvidaste tu contraseña?
        </Title>
        <Text
          isCenter
          mt={isDesktop ? 17 : 9}
          mb={isDesktop ? 65 : 118}
          size={isDesktop ? 15 : 13}
        >
          Ingresa el correo que registraste en nuestra plataforma para poder continuar.
        </Text>
      </Container>
      <Box
        display="flex"
        component="form"
        textAlign="justify"
        alignItems="center"
        flexDirection="column"
        onSubmit={formProps.handleSubmit(sendEmail)}
      >
        <Box
          width="330px"
          maxWidth="100%"
        >
          <InputEmail
            required
            fullWidth
            name="email"
            label="Correo electrónico"
            {...formProps}
          />
          <ErrorText mb={-29} size={isDesktop ? 15 : 13} text={stateFormProps.errorMessage} />
          <Box
            width="100%"
            marginTop={isDesktop ? '96px' : '126px'}
            marginBottom={isDesktop ? '19px' : '20px'}
          >
            <Hollow
              isSubmit
              isCenter
              fullWidth
              type="submit"
              size={!isDesktop && 14}
              isLoading={stateFormProps.isLoading}
              onColorType={hollowColorType.primary}
            >
              {isDesktop ? 'Recuperar contraseña' : 'Continuar'}
            </Hollow>
          </Box>
        </Box>
      </Box>
      <Text
        mb={!isDesktop && 20}
        mt={isDesktop ? 19 : 20}
        size={isDesktop ? 14 : 12}
        lineHeigth={!isDesktop && '20.5px'}
      >
        ¿Ya tienes una cuenta?
        {!isDesktop && <br />}
        <Ghost
          ml={5}
          isSubmit
          size={14}
          underline
          type="button"
          align="start"
          color={colors.primary}
          style={{ fontStyle: 'italic' }}
          onColorType={colorType.primary}
          onClick={() => dispatch(openPopUp({ type: 'login' }))}
        >
          Inicia sesión
        </Ghost>
        <span style={{ fontSize: '14px' }}>
          {' o '}
        </span>
        <Ghost
          mt={10}
          isSubmit
          size={14}
          isCenter
          underline
          color={colors.primary}
          style={{ fontStyle: 'italic' }}
          onClick={() => dispatch(openPopUp({ type: 'register' }))}
        >
          créala aquí
        </Ghost>
      </Text>
    </CustomDialog>
  );
}
