const UPDATE_INVESTORS = 'UPDATE_INVESTORS';
const REMOVE_INVESTORS = 'REMOVE_INVESTORS';

const initialState = {
  investors: [],
};

const investorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_INVESTORS:
      return {
        ...state,
        investors: action.payload,
      };
    case REMOVE_INVESTORS:
      return {
        investors: [],
      };

    default:
      return state;
  }
};

export default investorsReducer;

export const updateInvestors = (investors) => ({
  type: UPDATE_INVESTORS,
  payload: investors,
});

export const removeInvestors = () => ({
  type: REMOVE_INVESTORS,
});
