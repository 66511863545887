import React, { useState, useEffect, useCallback } from 'react';
import { routes } from 'routes';
import { useFormProps } from 'hooks';
import { openPopUp } from 'store/popup';
import { useDispatch } from 'react-redux';
import { Line, colors } from 'globalStyle';
import { Title, Text, Bold } from 'components/Texts';
import {
  Box, Grid, styled,
} from '@material-ui/core';
import {
  Ghost, colorType, Hollow, hollowColorType,
} from 'components/Buttons';
import TabPanel from 'components/TabPanel';
import ModalContent from 'components/Modals/ModalContent';
import { documentsTypeLegalRepresentative, userType } from 'types';
import { InputPrefixPhone } from 'components/Form/InputPrefixPhone';
import { InputNitValidation } from 'components/Form/InputNitValidation';
import {
  InputEmail, InputPassword, CheckBox, Input, Select, InputNumber,
} from 'components/Form';
import { POLITICA_TRATAMIENTO_AND_PROTECCION_DATOS_PERSONALES_BLOOM } from 'types/fileType';

import RegisterOTP from '../RegisterOTP';
import RegisterHeader from '../RegisterHeader';
import RegisterVerificationCompany from './RegisterVerificationCompany';

const MODALS = Object.freeze({
  NONE: 0,
  VERIFICATION: 1,
  OTP: 2,
});

export default function RegisterFormCompany({
  onBack, onClose, onFinish, isDesktop, info, login,
}) {
  const dispatch = useDispatch();
  const [modalValue, setModalValue] = useState(MODALS.NONE);
  const [customValidationErrors, setCustomValidationErrors] = useState();
  const { formProps } = useFormProps({ errorToolTip: false, errorText: true, required: true });
  const password = formProps.watch('password');
  const [formValues, setFormValues] = React.useState({});
  const verify = useCallback((v) => {
    setFormValues(v);
    setModalValue(MODALS.VERIFICATION);
  }, []);
  const nit = formProps.watch('nit');
  const verificationDigit = formProps.watch('verificationDigit');
  const email = formProps.watch('email');
  const phoneNumber = formProps.watch('phoneNumber');
  const name = formProps.watch('name');
  useEffect(() => {
    setCustomValidationErrors();
  }, [nit, verificationDigit, email, phoneNumber, name]);
  const onRegisterError = useCallback(({ message }) => {
    switch (message) {
      case 'NIT_IN_USE':
        setCustomValidationErrors({ nit: { message: '' }, verificationDigit: { message: '' } });
        break;
      case 'EMAIL_IN_USE':
        setCustomValidationErrors({ email: { message: '' } });
        break;
      case 'PHONE_IN_USE':
        setCustomValidationErrors({ phoneNumber: { message: '' } });
        break;
      case 'COMPANY_NAME_IN_USE':
        setCustomValidationErrors({ name: { message: '' } });
        break;
      default:
        setCustomValidationErrors();
    }
  });
  const hideModal = () => setModalValue();
  const goBack = () => setModalValue((v) => v - 1);
  const goForward = () => setModalValue((v) => v + 1);

  useEffect(() => {
    if (info) {
      Object.entries(info).forEach(([key, value]) => {
        formProps.setValue(key, value);
      });
    }
  }, []);
  return (
    <ModalContent
      pt="5px"
      onBack={onBack}
      onClose={onClose}
      fullSize={!isDesktop}
      px={isDesktop ? '111.5px' : '40px'}
      width={isDesktop ? '1012px' : '100%'}
      height={isDesktop ? '665px' : '100%'}
      borderRadius={isDesktop ? '20px' : '0px'}
      header={isDesktop && <RegisterHeader title="Receptor" />}
    >
      <form onSubmit={formProps.handleSubmit(verify)}>
        <Grid container direction="column" alignItems="center" alignContent="center">
          {!isDesktop && (
            <>
              <Title size={16} color={colors.primary} isCenter>
                Receptor
              </Title>
              <Text size={14} isCenter mt={10} mb={10}>
                Crea una cuenta para tu empresa y busca financiación
              </Text>
              <Line height="0px" color={colors.primary} width="100%" isCenter />
            </>
          )}
          {isDesktop
            ? (
              <Text
                mb={26}
                isCenter
                size={15}
              >
                Recuerda que esta información son los datos de contacto directo con la empresa
                y representante legal
              </Text>

            )
            : (
              <Text
                mb={32}
                mt={22}
                isCenter
                size={14}
                color={colors.GRAY_11}
                style={{ fontStyle: 'italic' }}
              >
                Recuerda que esta información son los datos de contacto directo con la empresa
              </Text>
            )}
          <Grid container justify="space-between" style={{ rowGap: '0px' }}>
            <InputContainer>
              <InputNitValidation
                name="nit"
                isDesktop={isDesktop}
                label="NIT y Dígito de Verificación"
                digitProps={!isDesktop && { pl: '1em' }}
                style={{ textWrap: isDesktop ? 'nowrap' : 'balance' }}
                {...formProps}
                errors={customValidationErrors || formProps.errors}
              />
            </InputContainer>
            <InputContainer>
              <Input
                label="Nombre de la empresa"
                name="name"
                {...formProps}
                errors={customValidationErrors || formProps.errors}
              />
            </InputContainer>
            <InputContainer>
              <InputEmail
                name="email"
                label="Correo electrónico oficial de la empresa"
                {...formProps}
                errors={customValidationErrors || formProps.errors}
              />
            </InputContainer>
            <InputContainer>
              <InputPrefixPhone
                required
                prefixName="prefix"
                phoneName="phoneNumber"
                label="Número de celular de la empresa"
                {...formProps}
                errors={customValidationErrors || formProps.errors}
              />
            </InputContainer>
            <InputContainer>
              <InputPassword
                name="password"
                label="Contraseña"
                {...formProps}
              />
            </InputContainer>
            <InputContainer>
              <InputPassword
                noTooltip
                name="passwordConfirmation"
                label="Confirmar contraseña"
                validate={{
                  samePassword: (value) => value === password || 'Las contraseñas no coinciden',
                }}
                {...formProps}
              />
            </InputContainer>
          </Grid>
          <Title
            isCenter
            color={colors.primary}
            mb={isDesktop ? 5 : 10}
          >
            Representante legal
          </Title>
          <Line
            isCenter
            height="0px"
            color={colors.primary}
            width={isDesktop ? '815px' : '100%'}
          />
          <Grid Grid container justify="space-between" style={{ marginTop: isDesktop ? '21px' : '32px' }}>
            <InputContainer>
              <Select
                required
                defaultValue=""
                name="legalRepDocType"
                label="Tipo de documento"
                {...formProps}
              >
                {documentsTypeLegalRepresentative.map((document) => (
                  <option key={document.idType} value={document.name}>
                    {document.detail}
                  </option>
                ))}
              </Select>
            </InputContainer>
            <InputContainer>
              <InputNumber
                required
                name="legalRepDni"
                label="Número de documento"
                {...formProps}
              />
            </InputContainer>
            <InputContainer>
              <InputPrefixPhone
                label="Número de celular"
                prefixName="legalRepPrefix"
                phoneName="legalRepPhoneNumber"
                {...formProps}
              />
            </InputContainer>
            <InputContainer>
              <InputEmail
                name="legalRepEmail"
                label="Correo electrónico"
                {...formProps}
              />
            </InputContainer>
          </Grid>
          <Grid
            container
            style={{
              height: '22px',
              marginLeft: '-13px',
              marginTop: isDesktop && '-10px',
              marginBottom: isDesktop ? '10px' : '18px',
            }}
          >
            <CheckBox
              size={14}
              lineHeight="22px"
              textPlacement="right"
              name="dataProcessingPolicy"
              contentCheckBoxPadding="0 0 0 60px"
              style={{ cursor: 'pointer', textAlign: 'start' }}
              {...formProps}
              errorText={false}
            >
              <span style={{ fontSize: '14px' }}>
                {'Al seleccionar, aceptas nuestra '}
              </span>
              <Ghost
                isSubmit
                size={14}
                underline
                type="button"
                align="start"
                color={colors.primary}
                onColorType={colorType.primary}
                onClick={() => window.open(
                  POLITICA_TRATAMIENTO_AND_PROTECCION_DATOS_PERSONALES_BLOOM,
                )}
              >
                Política de tratamiento de datos
              </Ghost>
            </CheckBox>
          </Grid>
          <Box
            width="453px"
            maxWidth="100%"
            marginTop={isDesktop ? '5px' : '32px'}
            marginBottom={isDesktop ? '0px' : '10px'}
          >
            <Hollow
              mb={5}
              isSubmit
              isCenter
              fullWidth
              size={14}
              type="submit"
              weigth="330px"
              onColorType={hollowColorType.primary}
              disabled={!formProps.getValues().dataProcessingPolicy}
            >
              Crear cuenta
            </Hollow>
          </Box>
          <Text
            isCenter
            mb={isDesktop ? 21 : 10}
            size={isDesktop ? 14 : 12}
          >
            ¿Ya tienes una cuenta?
            <Ghost
              ml={5}
              isSubmit
              size={14}
              underline
              type="button"
              align="start"
              color={colors.primary}
              onColorType={colorType.primary}
              onClick={() => dispatch(openPopUp({ type: 'login' }))}
            >
              Inicia sesión
            </Ghost>
          </Text>
          {!isDesktop && (
            <Ghost
              mb={40}
              isCenter
              size={12}
              underline
              type="button"
              width="fit-content"
              color={colors.GRAY_11}
              href={routes.message.contactUs}
            >
              ¿Necesitas ayuda?
            </Ghost>
          )}
        </Grid>
      </form>
      <TabPanel value={modalValue} index={MODALS.VERIFICATION}>
        <RegisterVerificationCompany
          open
          onBack={goBack}
          onClose={hideModal}
          onFinish={goForward}
          isDesktop={isDesktop}
          formValues={formValues}
          onError={onRegisterError}
        />
      </TabPanel>
      <TabPanel value={modalValue} index={MODALS.OTP}>
        <RegisterOTP
          open
          login={login}
          onBack={goBack}
          onClose={hideModal}
          onFinish={onFinish}
          isDesktop={isDesktop}
          fullSize={!isDesktop}
          formValues={formValues}
          type={userType.COMPANY}
          hideControls={!isDesktop}
          headerProps={{
            title: 'Tu proceso de registro',
            subtitle: 'Código de validación',
          }}
          title={(
            <Text size={15}>
              A continuación digita el código que enviamos al correo electrónico de la
              <Bold>{` empresa ${formProps.getValues().name}`}</Bold>
            </Text>
          )}
        />
      </TabPanel>
    </ModalContent>
  );
}

const InputContainer = styled(Grid)({
  width: '330px',
  marginTop: '-6px',
});
