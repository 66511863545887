import React from 'react';
import { CloseIcon } from 'icons';
import styled from 'styled-components';
import arrowLeft from 'assets/icons/arrowLeftHeader.svg';
import {
  Box, Dialog, DialogContent, Grid, makeStyles, styled as MUIStyled,
} from '@material-ui/core';

function colorToFilter() {
  return 'invert(59%) sepia(17%) saturate(1240%) hue-rotate(135deg) brightness(102%) contrast(100%) ';
}

const useStyles = makeStyles({
  paper: {
    margin: '0px',
    background: (props) => props.bg || '#fff',
    height: (props) => props.height || (props.fullSize && '100%'),
    maxWidth: (props) => (props.fullSize ? '100%' : 'calc(100% - 20px)'),
    width: (props) => props.width || (props.bigScreen ? '543px' : '100%'),
    maxHeight: (props) => (props.fullSize ? '100%' : 'calc(100% - 20px)'),
    borderRadius: (props) => (props.borderRadius || (props.fullSize ? '0px' : '15px')),
  },
  backIcon: {
    cursor: (props) => props.onBack && 'pointer',
    filter: (props) => props.color && colorToFilter(props.color),
  },
});

export default function CustomDialog({
  children, open, onClose, onBack, header, color, zIndex, fullSizeNavBar,
  bg, width, height, fullSize, bigScreen, borderRadius, containerRef, ...props
}) {
  const styles = useStyles({
    width, bigScreen, fullSize: (fullSize || fullSizeNavBar), bg, color, onBack, ...props,
  });
  return (
    <CDialog
      open={open}
      maxWidth="md"
      width={width}
      align="center"
      zIndex={zIndex}
      onClose={onClose}
      bigScreen={bigScreen}
      borderRadius={borderRadius}
      fullSizeNavBar={fullSizeNavBar}
      PaperProps={{
        className: styles.paper,
      }}
    >
      <Content
        height={height}
        ref={containerRef}
        fullSize={fullSize || fullSizeNavBar}
      >
        <Grid container direction="column">
          <Grid container justify="space-between">
            <Box
              height="20px"
              onClick={onBack}
              className={styles.backIcon}
              src={onBack ? arrowLeft : ''}
              component={onBack ? 'img' : ''}
              width={onBack ? '30px' : '0px'}
            />
            {header}
            {onClose
              ? (
                <CloseIcon
                  width="20px"
                  height="20px"
                  color={color}
                  onClick={onClose}
                  style={{ cursor: 'pointer' }}
                />
              )
              : (
                <Box
                  width="0px"
                  height="20px"
                />
              )}
          </Grid>
          <InnerContainer {...props}>
            {children}
          </InnerContainer>
        </Grid>
      </Content>
    </CDialog>
  );
}

const CDialog = MUIStyled(Dialog)(({ zIndex, fullSizeNavBar }) => ({
  zIndex: zIndex && `${zIndex} !important`,
  marginTop: fullSizeNavBar && '57.5px',
  '& .MuiBackdrop-root': {
    display: fullSizeNavBar && 'none',
  },
}));

const Content = styled(DialogContent)({
  overflow: 'auto',
  height: (props) => props.height,
  padding: (props) => props.padding || '20.65px',
  borderRadius: (props) => (props.borderRadius || '15px'),
});

const InnerContainer = styled(Grid)({
  maxWidth: '100%',
  paddingTop: (props) => (props.pt ? props.pt : '0px'),
  borderRadius: (props) => (props.borderRadius || '15px'),
  paddingLeft: (props) => (props.px ? `calc(${props.px} - 20.65px)` : '0px'),
  paddingRight: (props) => (props.px ? `calc(${props.px} - 20.67px)` : '0px'),
  paddingBottom: (props) => (props.pb ? `calc(${props.pb} - 20.67px)` : '0px'),
});
