import styled from 'styled-components';
import { colors, div, FlexCenter } from 'globalStyle';

export const SidebarContainer = styled.div`
    ${div};
    background-color: ${colors.primary};
    width: 100vw;
    height: 100vh;
    z-index: 1400;
    position:fixed;
    top:0;
    padding: 20px;
    transition: 0.3s ease-in-out;
    overflow-y: scroll;
    right: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`;

export const ElementsContainer = styled.div`
    ${FlexCenter}
    flex-direction: column;
    width: 75%;
`;

export const UserSection = styled.div`
    ${FlexCenter}
    width: 100%;
    justify-content: flex-start;
    transition: 0.3s ease-in-out;
`;

export const UserContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-height: ${({ isOpen }) => (isOpen ? '100%' : '80px')};

    transition: max-height 0.2s ease;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 25px;
  position: relative;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  background-color: ${colors.WHITE};
`;
