import axios from 'axios';
import { store } from 'store';
import { config } from 'config';
import { signOut } from 'store/auth';
import { openPopUp } from 'store/popup';
import { ACCEPTED_PORTAL_ERRORS } from 'hooks';
import { usePortalAuthenticate } from 'services';
import { setPortalToken, deletePortalToken } from 'store/portalToken';
import { getMessageType } from 'types/getMessageType';

const active = {};

function getActive(data) {
  if (active[data.url]
    && active[data.url][data.method]
    && active[data.url][data.method].data === JSON.stringify(data)) {
    return active[data.url][data.method].promise;
  }
  return undefined;
}

function registerActive(data, promise) {
  if (!active[data.url]) {
    active[data.url] = {};
  }
  active[data.url][data.method] = {
    data: JSON.stringify(data),
    promise,
  };
}

function deleteActive(data) {
  delete active[data.url][data.method];
}

const DEFAULT_API = config.api;
export class Fetch {
  constructor(apiType) {
    this.json = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: '',
      },
    };
    const API = config[apiType];
    this.api = API ?? DEFAULT_API;
  }

  method(type = 'get') {
    this.json.method = type;
    return this;
  }

  url(url) {
    this.json.url = this.api + url;
    return this;
  }

  data(json) {
    const data = JSON.stringify(json);
    this.json.data = data;
    return this;
  }

  formData(formData) {
    this.json.headers = {
      ...this.json.headers,
      'Content-Type': 'multipart/form-data',
    };
    this.json.data = formData;
    return this;
  }

  static onPortalExpired() {
    usePortalAuthenticate()
      .then((value) => {
        store.dispatch(setPortalToken(value));
      }).catch((e) => {
        if (ACCEPTED_PORTAL_ERRORS.has(e?.message)) {
          store.dispatch(deletePortalToken());
        }
      }).finally(() => {
        setTimeout(() => {
          document.location.reload();
        }, 300);
      });
  }

  portalToken() {
    const { token, expirationDate } = store.getState().portalToken;
    if (new Date(new Date().toUTCString().split('GMT')[0]) >= new Date(expirationDate)) {
      Fetch.onPortalExpired();
    }
    this.isUsingPortal = true;
    this.json.headers = {
      ...this.json.headers,
      Authorization: token,
    };
    return this;
  }

  userToken(token = undefined) {
    const userToken = Fetch.getUserTokenInStore() || token;
    this.json.headers = {
      ...this.json.headers,
      Authorization: userToken,
    };
    return this;
  }

  setCancelToken({ token } = { source: { token: null } }) {
    if (!token) { return this; }

    this.json.cancelToken = token;
    return this;
  }

  async send() {
    if (!('url' in this.json)) throw new Error('You need a url');
    const data = this.json;
    if (!data.cancelToken) {
      const activeFetch = getActive(this.json);
      if (activeFetch) {
        return activeFetch;
      }
    }
    const promise = axios(data)
      .then(Fetch.transformResponse)
      .catch((error) => Fetch.transformError(error, this))
      .finally(() => {
        if (!data.cancelToken) {
          deleteActive(data);
        }
      });
    if (!data.cancelToken) {
      registerActive(data, promise);
    }
    return promise;
  }

  static getUserTokenInStore() {
    const { user } = store.getState().auth;
    return user?.sessionItem?.token || undefined;
  }

  static transformError(error, request) {
    if (error.message === 'UNAUTHORIZED' || error.response?.status === 401) {
      store.dispatch(signOut());
      if (request.isUsingPortal) {
        Fetch.onPortalExpired();
      } else {
        store.dispatch(openPopUp({ type: 'session-expires' }));
      }
      return;
    }

    if (error.message === 'INVALID_PORTAL') {
      Fetch.onPortalExpired();
      return;
    }

    if (error.messageFormat) {
      throw error;
    }

    const errorBloom = {
      message: error.message,
      messageFormat: getMessageType(error.message),
    };

    throw errorBloom;
  }

  static transformResponse(response) {
    const {
      status, data, list, message,
    } = response.data;

    if (!status) {
      const errorBloom = {
        message,
        messageFormat: getMessageType(message, data),
        data: (data || list) || null,
      };

      throw errorBloom;
    }

    return {
      status: status || false,
      data: (data || list) || null,
      message: message || null,
    };
  }
}
