/* eslint-disable no-shadow */
/* eslint-disable no-undef */
import { userType } from 'types/userType';

const SUCCESS = 'SUCCESS_AUTH';
const UPDATE_AUTH = 'UPDATE_AUTH';
const SIGN_OUT = 'SIGN_OUT';

function getUpdateAuth(state, { key, value }) {
  const { user } = state;
  user.info[key] = value;
  return user;
}

const initialState = {
  isLogin: false,
  userType: userType.NONE,
  user: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS:
      return {
        ...state,
        isLogin: true,
        userType: action.payload.userType,
        user: action.payload.user,
      };
    case UPDATE_AUTH:
      return {
        ...state,
        user: getUpdateAuth(state, action.payload),
      };
    case SIGN_OUT:
      return {
        ...state,
        isLogin: false,
        userType: userType.NONE,
        user: null,
      };
    default:
      return state;
  }
};

export default reducer;

export const signIn = ({ user, userType }) => ({
  type: SUCCESS,
  payload: { user, userType },
});

export const signOut = () => ({ type: SIGN_OUT });

export const updateState = ({ key, value }) => ({
  type: UPDATE_AUTH,
  payload: { key, value },
});
