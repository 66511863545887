import React from 'react';

import SelectChat from './components/SelectChat';
import BooleanChat from './components/BooleanChat';

export default function FinancialChatInput({ type, customComponent: Component, ...props }) {
  if (type === 'bool') {
    return <BooleanChat {...props} />;
  }
  if (type === 'list') {
    return <SelectChat {...props} />;
  }
  if (type === 'custom') {
    return <Component {...props} />;
  }
  return <></>;
}
