/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Div, colors } from 'globalStyle';
import { ToolTipError } from 'components/ToolTip';
import { Text } from 'components/Texts';
import { useMediaQuery } from 'hooks';
import { Label } from './Label';
import { ErrorText } from './ErrorText';
import { StyledInput } from './styled';

export const Basic = ({
  mb,
  component: Component,
  label,
  required,
  text,
  errorText,
  errors = [],
  name,
  errorToolTip,
  placeholder,
  minLength,
  max,
  register,
  regexPattern,
  validate,
  validations,
  dirtyFields,
  children,
  noLabel,
  ...rest
}) => {
  const bigScreen = useMediaQuery(1023);
  return (
    <Div mb={mb ?? 18} {...rest}>
      {!noLabel
      && (
        <Label
          title={label}
          subTitle={text}
          required={required}
          {...rest}
        />
      )}
      <ToolTipError
        maxWidth="120px"
        open={errorToolTip ? (!!errors[name]) : false}
        text={errors[name] && errors[name]?.message}
        placement={bigScreen ? 'right' : 'bottom-end'}
      >
        <div style={{ position: 'relative' }}>
          <Component
            name={name}
            placeholder={placeholder}
            isError={errors[name] && errors[name]}
            max={max}
            ref={register && register({
              required: required ? 'Este campo es obligatorio' : false,
              minLength,
              pattern: regexPattern,
              validate,
            })}
            {...rest}
          />
          {children}
        </div>
      </ToolTipError>
      {max && (
        <Text size={10} color="#B4B4B4" mt={5} mb={5}>
          Máximo
          {` ${max} `}
          caracteres
        </Text>
      )}
      {validations && <Text number={4} mt={5} mb={5} color={colors.GRAY_3}>{validations}</Text>}
      {errorText && <ErrorText text={errors[name]?.message} />}
    </Div>
  );
};

export const BasicInput = ({ ...rest }) => <Basic component={StyledInput} {...rest} />;
