import React from 'react';
import { colors } from 'globalStyle';
import { Box } from '@material-ui/core';
import { ErrorText } from 'components/Form';
import Container from 'components/Container';
import { Hollow, hollowColorType, Ghost } from 'components/Buttons';

export default function RegisterModalNextButton({
  text, onClick, isDesktop, logout, isLoading, disabled, form, error,
}) {
  return (
    <Container>
      <Box
        width={isDesktop ? '330px' : '100%'}
      >
        {error && <ErrorText text={error} isCenter mb={5} />}
        <Hollow
          isCenter
          isSubmit
          fullWidth
          form={form}
          type="submit"
          onClick={onClick}
          disabled={disabled}
          isLoading={isLoading}
          onColorType={hollowColorType.primary}
          padding={isDesktop ? '12px' : '14.5px'}
        >
          {text || 'Continuar'}
        </Hollow>
      </Box>
      {isDesktop && (
        <Box
          marginTop="6px"
        >
          <Ghost
            isCenter
            isSubmit
            underline
            fullWidth
            size={14}
            onClick={logout}
            color={colors.primary}
          >
            Cerrar sesión
          </Ghost>
        </Box>
      )}
    </Container>
  );
}
