import { useState, useEffect, useCallback } from 'react';
import { userInvestmentsService } from 'services';
import { useDispatch, useSelector } from 'react-redux';
import { useMountedState } from 'react-use';
import { updateUserInvestments, updateUserBonds } from 'store/investments';
import { isAStockOrTokenProject } from 'types/projectType';
import { useUser } from '../useUser';
import { useCancelToken } from '../useCancelToken';

export const useInvestmentsAndBondsCache = () => {
  const isMounted = useMountedState();
  const dispatch = useDispatch();
  const investmentsCache = useSelector(
    (store) => store.investments.investments,
  );
  const bondsCache = useSelector((store) => store.investments.bonds);
  const stocksBondsCache = useSelector(
    (store) => store.investments.nodesBonds,
  );

  const setInvestmentsInfo = useCallback((investmentsInfo) => {
    if (isMounted()) {
      dispatch(updateUserInvestments(investmentsInfo));
    }
  }, []);

  const setBondsInfo = useCallback((bondsInfo) => {
    if (isMounted()) {
      dispatch(updateUserBonds(bondsInfo));
    }
  }, []);

  return {
    investmentsCache: investmentsCache || [],
    bondsCache: bondsCache || [],
    stocksBondsCache: stocksBondsCache || [],
    setInvestmentsInfo,
    setBondsInfo,
  };
};

export const useInvestmentsUser = () => {
  const { isLogin } = useUser();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState([]);
  const { isMounted, logCallback } = useCancelToken();
  const {
    investmentsCache,
    setInvestmentsInfo,
  } = useInvestmentsAndBondsCache();
  const consultedInvestments = useSelector(
    (store) => store.investments.consultedInvestments,
  );

  const forceUpdateInvestments = () => {
    setLoading(true);
    if (isLogin) {
      userInvestmentsService('ACTIVO')
        .then(({ data }) => {
          if (isMounted) {
            setLoading(false);
            /* eslint-disable-next-line no-param-reassign */
            data.investment = data.investment.map((v) => {
              if (
                v.projectItem.projectStatus === 'OPEN'
                && v.projectItem.maxAmount === v.projectItem.currentAmount
              ) {
                const newValue = { ...v };
                newValue.projectItem.projectStatus = 'COLLECTING';
                return newValue;
              }
              return v;
            });

            setValue(data.investment);
            setInvestmentsInfo(data.investment);
          }
        })
        .catch((err) => {
          logCallback(err, () => {
            setLoading(false);
          });
        });
    }
  };

  const fetch = () => {
    setLoading(true);
    if (!consultedInvestments) {
      forceUpdateInvestments();
    } else {
      setValue(investmentsCache);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return {
    investments: value || [],
    isLoadinginvestmentsUser: loading,
    forceUpdateInvestments,
  };
};

export const useBondsUser = () => {
  const { isLogin } = useUser();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({ bonds: [], nodesBonds: [] });
  const { isMounted, logCallback } = useCancelToken();
  const {
    bondsCache,
    stocksBondsCache,
    setBondsInfo,
  } = useInvestmentsAndBondsCache();
  const consultedBonds = useSelector(
    (store) => store.investments.consultedBonds,
  );

  const forceUpdateBonds = () => {
    setLoading(true);
    if (isLogin) {
      userInvestmentsService('CIERRE_POSITIVO')
        .then(({ data }) => {
          if (isMounted) {
            setLoading(false);
            const bondsFIltered = data.bonds.filter(
              (bond) => !isAStockOrTokenProject(bond?.projectItem?.projectType),
            );
            setValue({ bonds: bondsFIltered, nodesBonds: data?.nodesBonds });
            setBondsInfo({
              bonds: bondsFIltered,
              nodesBonds: data?.nodesBonds,
            });
          }
        })
        .catch((err) => {
          logCallback(err, () => {
            setLoading(false);
          });
        });
    }
  };

  const fetch = () => {
    setLoading(true);
    if (!consultedBonds) {
      forceUpdateBonds();
    } else {
      setValue({ nodesBonds: stocksBondsCache, bonds: bondsCache });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return {
    bonds: value?.bonds || [],
    nodesBonds: value?.nodesBonds || [],
    isLoadingBondsUser: loading,
    forceUpdateBonds,
  };
};
