/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { InputEmail } from 'components/Form';
import { Submit } from 'components/Buttons';
import { Row } from 'globalStyle';
import { userRecoverDeviceSendEmailService } from 'services';

export const Email = ({
  formProps, next, isLoading, setLoading, setError, addData,
}) => {
  const onSubmit = (form) => {
    setLoading(true);
    userRecoverDeviceSendEmailService({ email: form.email })
      .then(() => {
        setLoading(false);
        addData({ email: form.email });
        next();
      })
      .catch(({ messageFormat }) => {
        setLoading(false);
        setError(true, messageFormat);
      });
  };
  return (
    <div>
      <form onSubmit={formProps.handleSubmit(onSubmit)}>
        <InputEmail {...formProps} />
        <Row mt={30} mb={30}>
          <Submit
            medium
            isLoading={isLoading}
            disabled={formProps.valid}
          >
            Recuperar mi dispositivo
          </Submit>
        </Row>
      </form>
    </div>
  );
};
