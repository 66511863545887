import { useState, useEffect } from 'react';
import { userNotificationsService, companyNotificationService } from 'services';
import { userType } from 'types';
import { useUser } from 'hooks';
import { useNotificationCache } from './useNotificationCache';
import { useCancelToken } from './useCancelToken';

export const useNotifications = ({ limit = 2 }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState([]);
  const { notificationCache, setNotificationInfo } = useNotificationCache();
  const { isMounted, source, logCallback } = useCancelToken();
  const { type } = useUser();

  const fetch = () => {
    const service = type === userType.USER
      ? userNotificationsService
      : companyNotificationService;
    setLoading(true);
    service({ limit, source })
      .then(({ data }) => {
        if (isMounted) {
          setLoading(false);
          setNotificationInfo(data);
          setValue(data);
        }
      })
      .catch((err) => {
        logCallback(err, () => {
          setLoading(false);
        });
      });
  };

  useEffect(() => {
    if (notificationCache?.length === 0) {
      fetch();
    } else {
      setValue(notificationCache);
      setLoading(false);
    }
  }, []);

  return {
    notifications: value,
    isLoadingNotifications: loading,
    fetch,
  };
};
