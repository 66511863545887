import React, { useState } from 'react';
import { colors } from 'globalStyle';
import { ErrorText } from 'components/Form';
import ReactCodeInput from 'react-code-input';
import useCountdown from 'hooks/useCountdown';
import { Box, Grid } from '@material-ui/core';
import { Title, Text } from 'components/Texts';
import { Hollow, hollowColorType, Ghost } from 'components/Buttons';
import { routes } from 'routes';

export const squareStyle = {
  margin: '4px',
  width: '60px',
  color: 'black',
  height: '66px',
  fontSize: '32px',
  textAlign: 'center',
  marginRight: '8px',
  borderRadius: '7px',
  boxSizing: 'border-box',
  fontFamily: 'monospace',
  backgroundColor: 'white',
  border: '0.5px solid #B4B4B4',
};

export const squareSmStyle = {
  ...squareStyle,
  width: '40px',
  height: '50px',
  marginLeft: '0px',
  marginRight: '4px',
  fontSize: '20px',
};

const CODE_LENGTH = 6;

export default function LoginOTP({
  isDesktop, sendData, getBody, onSuccess,
}) {
  const [code, setCode] = useState('');
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState();
  const {
    setTime,
    cancelTimer,
    formattedTime,
  } = useCountdown();
  const sendOTP = ({ timer, sendCode = true }) => {
    const withOTP = sendCode && code.length === CODE_LENGTH;
    if (timer) {
      setTime(300000);
    } else {
      setLoading(true);
    }
    sendData({ ...getBody(), otp: withOTP ? code : '' })
      .then(({ data, message }) => {
        onSuccess({ data, message });
      })
      .catch(({ message, messageFormat }) => {
        if (message !== 'NEW_DEVICE_EMAIL_SENT') {
          if (timer) {
            cancelTimer();
          }
          setError(messageFormat);
        }
      })
      .finally(() => {
        if (!timer) {
          setLoading(false);
        }
      });
  };
  return (
    <Grid container direction="column" alignItems="center">
      <Title size={18} isCenter>
        Ingresa código de validación
      </Title>
      <Text size={14} maxWidth="100%" width="375px" isCenter mt={7} mb={42}>
        Ingresa el código de validación que hemos enviado a tu correo electrónico
      </Text>
      <ReactCodeInput
        autoFocus
        fields={6}
        value={code}
        forceUppercase
        inputStyle={isDesktop ? squareStyle : squareSmStyle}
        onChange={(e) => setCode(e)}
      />
      {error && (<ErrorText text={error} size={15} align="start" mt="9" mb={-19} />)}
      {formattedTime && (
        <Text
          isCenter
          size={14}
          mt={isDesktop ? 36 : 40}
        >
          {formattedTime}
        </Text>
      )}
      {isDesktop && (
        <Text
          mb={9}
          isCenter
          size={14}
          mt={formattedTime ? 14 : 65}
        >
          ¿No te llegó ningún código?
        </Text>
      )}
      <Ghost
        isCenter
        isSubmit
        size={14}
        fullWidth
        underline
        type="button"
        align="center"
        width="fit-content"
        disabled={formattedTime}
        onClick={!formattedTime && (() => sendOTP({ timer: true, sendCode: false }))}
        color={formattedTime ? colors.input.inactive : colors.primary}
      >
        Reenviar código
      </Ghost>
      <Box
        width="372px"
        margin="auto"
        maxWidth="100%"
        marginTop={isDesktop ? '17px' : '30px'}
        marginBottom={isDesktop ? '22.95px' : '22px'}
      >
        <Hollow
          isSubmit
          isCenter
          fullWidth
          onClick={sendOTP}
          isLoading={isLoading}
          disabled={code.length < CODE_LENGTH}
          onColorType={hollowColorType.primary}
        >
          Iniciar sesión
        </Hollow>
      </Box>
      <Ghost
        italic
        mb={15}
        isCenter
        underline
        href={routes.message.contactUs}
      >
        ¿Necesitas ayuda?

      </Ghost>
    </Grid>
  );
}
