/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { getProjectsService } from 'services';
import { useCancelToken } from '../useCancelToken';

export const useProjects = ({ type }) => {
  const [value, setValue] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const {
    isMounted,
    source,
    logCallback,
  } = useCancelToken();

  useEffect(() => {
    setLoading(true);
    getProjectsService({ type, source })
      .then((data) => {
        if (isMounted) {
          setLoading(false);
          setValue(data);
        }
      }).catch((err) => {
        logCallback(err, () => {
          setLoading(false);
        });
      });
  }, [type]);

  return {
    projects: value?.projects || [],
    projectsBanner: value?.projectsBanner || [],
    isLoadingProjects: isLoading,
  };
};
