const UPDATE_BONDS = 'UPDATE_BONDS';
const REMOVE_BONDS = 'REMOVE_BONDS';

const initialState = {
  bonds: [],
};

const bondsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BONDS:
      return {
        ...state,
        bonds: action.payload,
      };
    case REMOVE_BONDS:
      return {
        bonds: [],
      };

    default:
      return state;
  }
};

export default bondsReducer;

export const updateBonds = (bonds) => ({
  type: UPDATE_BONDS,
  payload: bonds,
});

export const removeBonds = () => ({
  type: REMOVE_BONDS,
});
