import React, { useEffect, useCallback, useState } from 'react';
import { mapTowns } from 'services';
import { useFormProps } from 'hooks';
import Container from 'components/Container';
import { Box, Grid, styled } from '@material-ui/core';
import { Input, InputPhone, Select } from 'components/Form';
import RegisterModalNextButton from './RegisterModalNextButton';

export default function RegisterLRReference({
  isDesktop, logout, isLoading, sendData, onInfoCompletedValidation, error, dropdownsInfo,
}) {
  const [cities, setCities] = useState([]);
  const { formProps } = useFormProps({ errorToolTip: false, errorText: true, required: true });
  const referenceDepartment = formProps.watch('referenceDepartment');
  useEffect(() => {
    if (onInfoCompletedValidation) {
      onInfoCompletedValidation(false);
    }
  }, [onInfoCompletedValidation]);
  const saveData = useCallback((data) => {
    sendData({
      ...data,
      hasReference: true,
    });
  });
  useEffect(() => {
    if (referenceDepartment) {
      mapTowns(referenceDepartment)
        .then(({ data }) => {
          setCities(data);
        });
    }
  }, [referenceDepartment]);
  return (
    <Container>
      <FormContainer component="form" isDesktop={isDesktop} onSubmit={formProps.handleSubmit(saveData)}>
        <InputContainer isDesktop={isDesktop}>
          <Input
            name="referenceNames"
            label="Nombre de referencia personal"
            {...formProps}
          />
        </InputContainer>
        <InputContainer isDesktop={isDesktop}>
          <InputPhone
            name="referencePhone"
            label="Número de teléfono"
            {...formProps}
          />
        </InputContainer>
        <InputContainer isDesktop={isDesktop}>
          <Select
            label="Departamento"
            name="referenceDepartment"
            {...(formProps || {})}
          >
            {Object.entries(dropdownsInfo?.departments).map(([key]) => (
              <option
                key={key}
                value={key}
              >
                {key}
              </option>
            ))}
          </Select>
        </InputContainer>
        <InputContainer isDesktop={isDesktop} mb="184px">
          <Select
            label="Ciudad"
            name="referenceCity"
            {...formProps}
          >
            {cities.map((city) => (
              <option
                key={city}
                value={city}
              >
                {city}
              </option>
            ))}
          </Select>
        </InputContainer>
        <Container>
          <RegisterModalNextButton
            error={error}
            logout={logout}
            isDesktop={isDesktop}
            isLoading={isLoading}
          />
        </Container>
      </FormContainer>
    </Container>
  );
}

const FormContainer = styled(Box)(({ isDesktop }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  textAlign: 'start',
  justifyContent: 'space-between',
  padding: isDesktop ? '30px 20px 0 20px' : '25px 0',
}));

const InputContainer = styled(Grid)(({ mb, isDesktop }) => ({
  marginBottom: mb,
  width: isDesktop ? '300px' : '100%',
}));
