const UPDATE_PROJECT = 'UPDATE_PROJECT';

const initialState = {
  project: {},
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROJECT:
      return {
        ...state,
        project: action.payload,
      };

    default:
      return state;
  }
};

export default projectReducer;

export const updateProject = (project) => ({
  type: UPDATE_PROJECT,
  payload: project,
});
