const UPDATE_PROJECTS_COMPANY = 'UPDATE_PROJECTS_COMPANY';
const REMOVE_PROJECTS_COMPANY = 'REMOVE_PROJECTS_COMPANY';

const initialState = {
  projectsCompany: [],
};

const projectsCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROJECTS_COMPANY:
      return {
        ...state,
        projectsCompany: action.payload,
      };
    case REMOVE_PROJECTS_COMPANY:
      return {
        projectsCompany: [],
      };

    default:
      return state;
  }
};

export default projectsCompanyReducer;

export const updateProjectsCompany = (projectsCompany) => ({
  type: UPDATE_PROJECTS_COMPANY,
  payload: projectsCompany,
});

export const removeProjectsCompany = () => ({
  type: REMOVE_PROJECTS_COMPANY,
});
