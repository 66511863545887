import { colors } from 'globalStyle';
import { getContrastRatio } from '@material-ui/core';

export function getContrastTextColor(color, { defaultDark, defaultLight, reverseColors } = {}) {
  if (color) {
    return getContrastRatio(color, colors.WHITE) > 2
      ? defaultDark || (reverseColors ? colors.GRAY_1 : colors.WHITE)
      : defaultLight || (reverseColors ? colors.WHITE : colors.GRAY_1);
  }
  return undefined;
}
