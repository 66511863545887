import React from 'react';
import { spaces, colors } from 'globalStyle';
import styled from 'styled-components';
import { getNameLetter } from 'util/userName';

export const Letter = ({ size = 25, name = 'Bloom', ...rest }) => (
  <div>
    <Figure {...rest} size={size}>
      <img src={getNameLetter(name.trim())} alt="letter" />
    </Figure>
  </div>
);

export const Figure = styled.figure`
  width: ${(props) => props.size && props.size}px;
  height: ${(props) => props.size && props.size}px;
  border-radius: 50%;
  background-color: ${colors.GRAY_1};
  position: relative;
  ${spaces}
`;
