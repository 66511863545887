const UPDATE_FAVORITE_ACCOUNTS = 'UPDATE_FAVORITE_ACCOUNTS';
const REMOVE_FAVORITE_ACCOUNTS = 'REMOVE_FAVORITE_ACCOUNTS';

const initialState = {
  favoriteAccounts: [],
};

const favoriteAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FAVORITE_ACCOUNTS:
      return {
        ...state,
        favoriteAccounts: action.payload,
      };
    case REMOVE_FAVORITE_ACCOUNTS:
      return {
        favoriteAccounts: [],

      };
    default:
      return state;
  }
};

export default favoriteAccountsReducer;

export const updateFavoriteAccounts = (favoriteAccounts) => ({
  type: UPDATE_FAVORITE_ACCOUNTS,
  payload: favoriteAccounts,
});

export const removeFavoriteAccounts = () => ({
  type: REMOVE_FAVORITE_ACCOUNTS,
});
