import { useState, useEffect } from 'react';
import { userWallet, getInfoWalletCompany } from 'services';
import { userType } from 'types';
import { useWalletCache } from 'hooks/useWalletCache';
import { useCancelToken } from '../useCancelToken';
import { useUser } from '../useUser';

export const useWallet = ({ useCache } = {}) => {
  const { isLogin, type } = useUser();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const { walletCache, setWalletInfo } = useWalletCache();
  const { isMounted, source, logCallback } = useCancelToken();
  const { setValuesVisibility } = useWalletCache();

  const fetch = () => {
    if (isLogin) {
      const service = type === userType.USER ? userWallet : getInfoWalletCompany;
      setLoading(true);
      service({ source })
        .then(({ data }) => {
          if (isMounted) {
            setValue(data.bankAccounts);
            setLoading(false);
            setWalletInfo(data);
            setValuesVisibility(true);
          }
        })
        .catch((err) => {
          logCallback(err, () => {
            setLoading(false);
          });
        });
    }
  };

  useEffect(() => {
    if (useCache && useCache() && walletCache.wallets) {
      setValue(walletCache.bankAccounts);
    } else {
      fetch();
    }
  }, []);

  return {
    fetch,
    bankAccounts: value,
    isLoadingWallet: loading,
    defaultWallet: value ? value[0] : {},
  };
};
