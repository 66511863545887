import { Fetch } from 'lib/Fetch';

export function rechargeNowService(recarge) {
  return new Fetch()
    .url('/user/payzen')
    .method('post')
    .data(recarge)
    .userToken()
    .send();
}

export function rechargeCompany(recarge) {
  return new Fetch()
    .url('/company/payzen')
    .method('post')
    .userToken()
    .data(recarge)
    .send();
}

export function informToCommercialArea(amount) {
  return new Fetch('api_2')
    .url('/user/payzen/inform-to-commercial-area')
    .method('post')
    .userToken()
    .data(amount)
    .send();
}
