import { useState, useEffect, useCallback } from 'react';
import { userDataService, getCompanyInfo } from 'services';
import { useDispatch, useSelector } from 'react-redux';
import { useMountedState } from 'react-use';
import { updateUserInformation } from 'store/userInfo';
import { useUser } from 'hooks';
import { userType } from 'types';
import { useCancelToken } from '../useCancelToken';

export const useUserInfoCache = () => {
  const isMounted = useMountedState();
  const dispatch = useDispatch();
  const userInfoCache = useSelector((store) => store.userInfo.userInfo);

  const setUserInfo = useCallback((userInfo) => {
    if (isMounted()) {
      dispatch(updateUserInformation(userInfo));
    }
  }, []);

  return {
    userInfoCache: userInfoCache || {},
    setUserInfo,
  };
};

export const useUserInfo = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const { isMounted, logCallback, source } = useCancelToken();
  const { userInfoCache, setUserInfo } = useUserInfoCache();
  const { type } = useUser();

  const forceUpdate = () => {
    const service = type === userType.USER
      ? userDataService
      : getCompanyInfo;
    setLoading(true);
    service({ source })
      .then(({ data }) => {
        if (isMounted) {
          setLoading(false);
          setUserInfo(data);
          setValue(data);
        }
      })
      .catch((err) => {
        logCallback(err, () => {
          setLoading(false);
        });
      });
  };

  const fetch = () => {
    if (Object.keys(userInfoCache)?.length === 0) {
      forceUpdate();
    } else {
      setValue(userInfoCache);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return {
    userInfo: value,
    userInfoLoading: loading,
    fetch: forceUpdate,
  };
};
