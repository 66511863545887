import { Fetch } from 'lib/Fetch';

function fromApiResponseToBlogs({ data }) {
  return data.map((obj) => ({
    ...obj,
    photo: obj.photos[0],
  }));
}

export function blogService({ source }) {
  return new Fetch()
    .url('/blog-module/blog/find-all-active-blogs')
    .method('get')
    .setCancelToken(source)
    .send()
    .then(fromApiResponseToBlogs);
}
