export const documentsType = [
  {
    idType: 1,
    countryId: 1,
    name: 'CC',
    detail: 'Cédula de ciudadanía',
  },
  {
    idType: 2,
    countryId: 1,
    name: 'DNI',
    detail: 'Documento nacional de identidad',
  },
  {
    idType: 3,
    countryId: 1,
    name: 'CE',
    detail: 'Cédula de extranjeria',

  },
  {
    idType: 4,
    countryId: 1,
    name: 'PEP',
    detail: 'Permiso especial de permanencia',
  },
  {
    idType: 5,
    countryId: 1,
    name: 'DI',
    detail: 'Documento de identidad',
  },
  {
    idType: 6,
    countryId: 1,
    name: 'NIT',
    detail: 'Número de Identificación Tributario Colombiano NIT',
  },
  {
    idType: 7,
    countryId: 1,
    name: 'PASSPORT',
    detail: 'Pasaporte',
  },
];

export const documentsTypeMinimized = [
  {
    idType: 1,
    countryId: 1,
    name: 'CC',
    detail: 'Cédula de ciudadanía',
  },
  {
    idType: 2,
    countryId: 1,
    name: 'CE',
    detail: 'Cédula de extranjeria',
  },
  {
    idType: 3,
    countryId: 1,
    name: 'PASSPORT',
    detail: 'Pasaporte',
  },
];

export const documentsTypeUser = [
  {
    idType: 1,
    countryId: 1,
    name: 'CC',
    detail: 'Cédula de ciudadanía',
  },
];

export const documentsTypeLegalRepresentative = [
  {
    idType: 1,
    countryId: 1,
    name: 'CC',
    detail: 'Cédula de ciudadanía',
  },
];

export const findDocumentByID = (id) => documentsType
  .find((document) => document.idType === parseInt(id, 10));

export const getDocumentDetailByName = (name) => {
  let nameFind = '';
  if (name) {
    const documentName = documentsType.find((document) => document.name === name);
    nameFind = documentName?.detail;
  }
  return nameFind;
};

export const getDocumentTypeByName = (name) => {
  let nameFind = '';
  if (name) {
    const documentName = documentsType.find((document) => document.name === name);
    nameFind = documentName?.name;
  }
  return nameFind;
};

export function documentFieldType(type) {
  console.log(type);
  switch (type) {
    case 'PASSPORT':
      return 'text';
    default:
      return 'number';
  }
}
