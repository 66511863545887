import { useState, useEffect, useCallback } from 'react';
import { getProjectsByStatusService } from 'services';
import { useDispatch, useSelector } from 'react-redux';
import { useMountedState } from 'react-use';
import { updateProjects } from 'store/projects';

export const useProjectsCache = () => {
  const isMounted = useMountedState();
  const dispatch = useDispatch();
  const projectsCache = useSelector((store) => store.projects.projects);

  const setProjects = useCallback((projects) => {
    if (isMounted()) {
      dispatch(updateProjects(projects));
    }
  }, []);

  return {
    projectsCache,
    setProjects,
  };
};

export const useProjectsByStatus = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState([]);
  const { projectsCache, setProjects } = useProjectsCache();

  const forceFetch = () => {
    getProjectsByStatusService()
      .then((data) => {
        setLoading(false);
        setProjects(data);
        setValue(data);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const fetch = () => {
    if (projectsCache?.length === 0) {
      setLoading(true);
      forceFetch();
    } else {
      setValue(projectsCache);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return {
    projectsByStatus: value?.projects || [],
    isLoadingProjectsByStatus: loading,
    fetch: forceFetch,
  };
};
