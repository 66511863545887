import { routes } from 'routes';
import userIcon from 'assets/icons/user.svg';
import investment from 'assets/icons/investment-white.svg';
import information from 'assets/icons/information-white.svg';
import history from 'assets/icons/history-white.svg';
import notifications from 'assets/icons/notification-white.svg';
import lightning from 'assets/icons/lightning.svg';

export const profileRoutes = {
  USER: [
    {
      name: 'Mi perfil',
      href: routes.profile.route,
      icon: userIcon,
    },
    {
      name: 'Mis inversiones',
      href: routes.profile.invert,
      icon: investment,
    },
    {
      name: 'Mi información',
      href: routes.profile.information,
      icon: information,
    },
    {
      name: 'Mi historial',
      href: routes.profile.wallet,
      icon: history,
    },
    {
      name: 'Notificaciones',
      href: routes.profile.notifications,
      icon: notifications,
    },

  ],
  COMPANY: [
    {
      name: 'Mi perfil',
      href: routes.profileCompany.route,
      icon: userIcon,
    },
    {
      name: 'Mis inversionistas',
      href: routes.profileCompany.inversionists,
      icon: investment,
    },
    {
      name: 'Mi información',
      href: routes.profileCompany.information,
      icon: information,
    },
    {
      name: 'Mi historial',
      href: routes.profileCompany.transactionHistory,
      icon: history,
    },
    // {
    //   name: 'Actualizaciones',
    //   href: routes.profileCompany.updates,
    // },
    {
      name: 'Notificaciones',
      href: routes.profileCompany.notifications,
      icon: notifications,
    },
    {
      name: 'Mis emisiones',
      href: routes.profileCompany.bonds,
      icon: lightning,
    },

  ],
};

export const Routes = ({ children, type = 'USER' }) => profileRoutes[type]
  ?.map(({ name, href, icon }) => children({ name, href, icon })) || [];
