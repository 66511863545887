/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Basic } from './Basic';
import { StyledTextarea } from './styled';

export const TextArea = ({ ...rest }) => (
  <Basic
    component={StyledTextarea}
    {...rest}
  />
);
