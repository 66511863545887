/* eslint-disable no-shadow */
/* eslint-disable no-undef */

const OPEN = 'POPUP_OPEN';
const CLOSE = 'POPUP_CLOSE';

const initialState = {
  open: '',
  isOpen: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN: {
      return { open: action.payload.type, isOpen: true, popupProps: action.payload.props };
    }
    case CLOSE: {
      return { open: '', isOpen: false };
    }
    default: {
      return state;
    }
  }
}

export default reducer;

export const openPopUp = ({ type, props }) => ({
  type: OPEN,
  payload: { type, props },
});

export const closePopUp = () => ({ type: CLOSE });
