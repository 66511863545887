import { Fetch } from 'lib/Fetch';

export function contactUsService({
  name, email, message, type,
}) {
  return new Fetch()
    .url('/portal/notification/contact-us')
    .method('post')
    .data({
      name, email, message, type,
    })
    .portalToken()
    .send();
}
