/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useFormProps, useStep } from 'hooks';
import { AuthError } from 'components/Modals';
import { Title } from 'components/Texts';
import { Email } from './components/Email';
import { Code } from './components/Code';
import { Goal } from '../Goal';

export const Device = () => {
  const {
    next, current, data, addData,
  } = useStep();
  const {
    formProps,
    stateFormProps: {
      isLoading,
      setLoading,
      setError,
      errorMessage,
    },
  } = useFormProps({ errorToolTip: true, required: true });

  const props = {
    formProps, isLoading, setLoading, setError, errorMessage, next, data, addData,
  };

  if (errorMessage) {
    return (
      <AuthError
        errorMessage={errorMessage}
        handleClick={() => { setError(false); }}
      />
    );
  }

  if (current === 3) return <Goal />;

  const View = () => {
    switch (current) {
      case 1: return <Email {...props} />;
      case 2: return <Code {...props} />;
      default: return null;
    }
  };

  return (
    <div>
      <Title number={4} mb={40}>Recuperar dispositivo</Title>
      {View()}
    </div>
  );
};
