import React from 'react';
import { Figure, colors, Div } from 'globalStyle';
import userIcon from 'assets/icons/user.svg';
import { TextLight } from 'components/Texts';
import { useDispatch } from 'react-redux';
import { openPopUp } from 'store/popup';
import { OtherDropdown } from './OtherDropdown';

export const SiderbarIsNotLogged = ({ closeSidebar = () => {} }) => {
  const dispatch = useDispatch();

  const handleClick = (typePopUp) => {
    dispatch(openPopUp({ type: typePopUp }));
    closeSidebar();
  };

  return (
    <Div>
      <Div isFlex justify="flex-start" style={{ columnGap: '15px' }}>
        <Figure width="68px" height="68px ">
          <img src={userIcon} alt="logo" />
        </Figure>
        <Div isFlex height="68px" justify="space-around" direction="column" width="calc(100% - 70px)">
          <TextLight
            size={14}
            onClick={() => handleClick('login')}
            color={colors.WHITE}
          >
            Iniciar sesión
          </TextLight>
          <Div
            backgroundColor={colors.input.inactive}
            height="1px"
            width="100%"
          />
          <TextLight
            onClick={() => handleClick('register')}
            color={colors.WHITE}
            size={14}
          >
            Regístrate
          </TextLight>
        </Div>
      </Div>
      <Div p="0 0 0 15px" margin="20px 0 0">
        <OtherDropdown />
      </Div>
    </Div>
  );
};
