import { Fetch } from 'lib/Fetch';

export function userInvestmentService({ amount, idProject, isoName }) {
  return new Fetch()
    .url('/user/investment/create-investment')
    .method('put')
    .userToken()
    .data({
      amount,
      idProject,
      isoName,
    })
    .send();
}

export function deleteInvestmentService({ amount, idInvestment }) {
  return new Fetch()
    .url('/user/investment/withdraw-investment')
    .method('delete')
    .userToken()
    .data({
      amount,
      idInvestment,
    })
    .send();
}
