export const historyStateType = [
  {
    type: 'PENDING',
    state: 'En transito',
  },
  {
    type: 'COMPLETED',
    state: 'Completada',
  },
  {
    type: 'REJECTED',
    state: 'Rechazada',
  },
  {
    type: 'CANCELED',
    state: 'Cancelada',
  },
  {
    type: 'OTHER',
    state: 'Otra',
  },
];
