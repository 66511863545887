import React from 'react';
import { Redirect } from 'wouter';
import { useUser } from 'hooks/useUser';
import { routes } from 'routes';

export const withPrivateRoute = (Component) => () => {
  const { isLogin } = useUser();
  if (!isLogin) return <Redirect to={routes.home} />;
  return <Component />;
};
