import { Fetch } from 'lib/Fetch';

export function registerCompanySendOtp(data) {
  return new Fetch()
    .url('/portal/register/company/send-otp')
    .method('post')
    .data(data)
    .portalToken()
    .send();
}

export function registerCompany(data) {
  return new Fetch()
    .url('/portal/register/company')
    .method('post')
    .data(data)
    .portalToken()
    .send();
}

export function useAcceptDocumentsCompany(data) {
  return new Fetch('api_2')
    .url('/company/register/company/accept-documents')
    .method('put')
    .data(data)
    .userToken()
    .send();
}

export function registerCompanyColombia({ company }) {
  return new Fetch()
    .url('/portal/register/company/colombia')
    .method('post')
    .data(company)
    .portalToken()
    .send();
}

export function listCompaniesPendingForms() {
  return new Fetch()
    .url('/company/list-companies-pending-forms')
    .method('get')
    .userToken()
    .send();
}
