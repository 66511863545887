import { Fetch } from 'lib/Fetch';
import { getAmount } from 'util/getAmount';
import { config } from 'config';
import { getMessageType } from 'types/getMessageType';

function fromApiResponseToWithDraw({ data, message }) {
  if (message === 'PLEASE_FINISH_TOKEN') {
    return {
      data: {
        procesoConvenioGuid: data,
        url: `https://demorcs.olimpiait.com:6319/#/redirect/${data}`,
      },
      message,
      messageFormat: getMessageType(message, data),
    };
  }

  return { data };
}

export function userWithDrawService({ token, amount }) {
  return new Fetch()
    .url('/user-logged/withdraw')
    .method('post')
    .userToken(token)
    .data({ amount: getAmount(amount), isoMoney: config.isoMoney })
    .send()
    .then(fromApiResponseToWithDraw);
}
