const UPDATE_BCCDROPDOWNS = 'UPDATE_BCCDROPDOWNS';

const initialState = {
  bccDropdowns: {},
};

const bccDropdownsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BCCDROPDOWNS:
      return {
        ...state,
        bccDropdowns: action.payload,
      };

    default:
      return state;
  }
};

export default bccDropdownsReducer;

export const updatebccDropdowns = (bccDropdowns) => ({
  type: UPDATE_BCCDROPDOWNS,
  payload: bccDropdowns,
});
