/* eslint-disable no-unused-vars */
import { Fetch } from 'lib/Fetch';
import { projectStateType, findPaymentPeriodWithKey } from 'types';
import { getPhotos } from 'services/function/getPhoto';
import { percent } from 'util/percent';
import moment from 'moment';
import { documentType } from 'types/documentsProject';

function getTypes({ type }) {
  if (Array.isArray(type)) {
    return type;
  }
  return [type];
}

function getDocuments({ documents = [] }) {
  return documents
    .map((obj) => ({
      ...obj,
      name: documentType[obj.fileType],
      url: obj.file,
    }));
}

function fromApiResponseToProjects({ data }) {
  const projects = data.map((obj) => {
    const photos = getPhotos({ id: obj.projectId, images: obj?.images || [] });

    const photoPortrait = photos[photos.length - 1];
    const photoProduct = photos;

    return {
      ...obj,
      isOpen: obj.status === projectStateType.OPEN,
      shortDescription: obj.shortDescription,
      percent: percent(obj.currentAmount, obj.moneyGoal),
      photoPortrait,
      photoProduct,
    };
  });

  const projectsBanner = projects
    .filter((project) => project.banner === true);

  const newProjects = projects
    .filter((project) => moment().diff(moment(project.initialDate), 'days') <= 5);

  const finalProjects = projects
    .filter((project) => moment().diff(moment(project.endDate), 'days') <= 5);

  delete projects.multimedia;

  return {
    projects,
    projectsBanner,
    newProjects,
    finalProjects,
  };
}

function fromApiResponseToProject({ data }) {
  if (data.status === projectStateType.MONEY_GIVED
    || data.status === projectStateType.MONEY_RETURNED) throw new Error('Project not found');

  const documents = getDocuments({ documents: data.documents });

  const paymentPeriod = findPaymentPeriodWithKey(data.frequency);

  return {
    ...data,
    name: data?.name ?? data.projectName,
    isOpen: data.status === projectStateType.OPEN,
    goal: data.moneyGoal,
    paymentPeriod: paymentPeriod.value,
    videoUrl: data.videoUrl ? `${data.videoUrl.replace('watch?v=', 'embed/').replace(/&.*/, '')}?autoplay=0` : null,
    days: data.returnTime * 30,
    returnPercentage: data.returnPercentage * 100,
    shortDescription: data.shortDescription,
    percent: percent(data.currentAmount, data.moneyGoal),
    documents,
  };
}

export function getProjectsService({ type }) {
  return new Fetch('api_2')
    .url('/external/global/projects-by-status')
    .method('post')
    .data({ projectStatus: getTypes({ type }) })
    .portalToken()
    .send()
    .then(fromApiResponseToProjects);
}

export function getProjectsByStatusService() {
  return new Fetch('api_2')
    .url('/external/global/projects-by-status')
    .method('post')
    .portalToken()
    .data({
      projectStatus: [
        projectStateType.CLOSED,
        projectStateType.OPEN,
        projectStateType.PENDING_PAYMENT,
        projectStateType.POSTPONED,
        projectStateType.MONEY_RETURNED,
        projectStateType.MONEY_GIVE,
        projectStateType.PRE_MONEY_GIVE,
        projectStateType.PENDING_BOND,
        projectStateType.ADVERTISING_CAMPAIGN,
        projectStateType.PENDING_COMMISSION,
        projectStateType.ARREARS,
      ],
    })
    .send()
    .then(fromApiResponseToProjects);
}

function sortProjects(a, b) {
  if (a.status === b.status) {
    return 0;
  }
  if (a.status === projectStateType.OPEN) {
    return -1;
  }
  if (b.status === projectStateType.OPEN) {
    return 1;
  }
  if (a.status === projectStateType.ADVERTISING_CAMPAIGN) {
    return -1;
  }
  if (b.status === projectStateType.ADVERTISING_CAMPAIGN) {
    return 1;
  }
  return 0;
}

export function getProjectsList({
  projectStatus = [
    projectStateType.OPEN,
    projectStateType.PENDING_PAYMENT,
    projectStateType.POSTPONED,
    projectStateType.MONEY_RETURNED,
    projectStateType.MONEY_GIVE,
    projectStateType.PRE_MONEY_GIVE,
    projectStateType.PENDING_BOND,
    projectStateType.ADVERTISING_CAMPAIGN,
    projectStateType.PENDING_COMMISSION,
    projectStateType.ARREARS,
  ],
  categorizationIds,
  paginate = true,
  categoriesNames,
  projectTypes,
  pageSize = 500,
  sort = true,
} = {},
{ cancelToken } = {}) {
  return new Fetch('api_2')
    .url(`/external/global/projects-by-status?pageSize=${pageSize}`)
    .method('post')
    .portalToken()
    .data({
      projectStatus,
      categorizationIds,
      paginate,
      projectTypes,
      categoriesNames,
    })
    .setCancelToken(cancelToken)
    .send()
    .then((res) => {
      if (sort) {
        if (res.data.items) {
          res.data.items = res.data.items.sort(sortProjects);
        } else {
          res.data = res.data.sort(sortProjects);
        }
      }
      return res;
    });
}

export function getSingleProjectService({ tag = null, id = null }) {
  const url = id
    ? `/external/global/find/${id}`
    : `/external/global/find/tag/${tag}`;

  return new Fetch('api_2')
    .url(url)
    .method('get')
    .portalToken()
    .send()
    .then(fromApiResponseToProject);
}
