/* eslint-disable no-mixed-operators */
/* eslint-disable no-restricted-syntax */

import { config } from 'config';

const DATE_UNITS = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: 24 * 60 * 60 * 1000 * 365 / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
};

const getSecondsDiff = (endDate) => (Date.now() - endDate);

const getUnitAndValueDate = (secondsElapsed) => {
  for (const [unit, secondsInUnit] of Object.entries(DATE_UNITS)) {
    if (secondsElapsed >= secondsInUnit || unit === 'second') {
      const value = Math.floor(secondsElapsed / secondsInUnit) * -1;
      return { value, unit };
    }
  }

  return undefined;
};

const getTimeAgo = (date) => {
  const rtf = new Intl.RelativeTimeFormat(config.locale);

  const secondsElapsed = getSecondsDiff(date);

  const { value, unit } = getUnitAndValueDate(secondsElapsed);

  return rtf.format(value, unit);
};

export const useTimeAgo = (endDate = Date.now()) => {
  const timeago = getTimeAgo(endDate);

  const date = new Date(endDate);
  const formattedDate = new Intl.DateTimeFormat(config.locale, {
    month: 'long', day: 'numeric',
  }).format(date);

  return {
    dateTime: formattedDate,
    timeago,
  };
};
