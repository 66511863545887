import React, { useCallback } from 'react';
import { colors } from 'globalStyle';
import { Box } from '@material-ui/core';
import { openPopUp } from 'store/popup';
import { useDispatch } from 'react-redux';
import Container from 'components/Container';
import { Title, Text } from 'components/Texts';
import MainImage from 'assets/persons/goal-3.svg';
import CustomDialog from 'components/Modals/CustomDialog';
import { Hollow, hollowColorType } from 'components/Buttons';

export default function RecoverPasswordSuccess({
  open, isDesktop, onClose,
}) {
  const dispatch = useDispatch();
  const handleAccept = useCallback(() => {
    onClose();
    dispatch(openPopUp({ type: 'login' }));
  });
  return (
    <CustomDialog
      open={open}
      fullSizeNavBar={!isDesktop}
      width={isDesktop && '615px'}
      height={isDesktop && '530px'}
      onClose={isDesktop && onClose}
      px={isDesktop ? '85px' : '30px'}
      pt={isDesktop ? '18px' : '100px'}
    >
      <Container>
        <Box
          alt=""
          component="img"
          src={MainImage}
          width={isDesktop ? '177px' : '149px'}
          height={isDesktop ? '180px' : '152px'}
        />
        <Container pr={!isDesktop && 25} pl={!isDesktop && 25}>
          <Title
            color={colors.primary}
            mt={isDesktop ? 63 : 64}
            mb={isDesktop ? 20 : 20}
            size={isDesktop ? 20 : 18}
          >
            Nueva contraseña modificada
            {isDesktop ? <br /> : ' '}
            con éxito
          </Title>
          <Text
            size={isDesktop ? 16 : 15}
          >
            El cambio de contraseña se ha realizado exitosamente.
          </Text>
        </Container>
        <Box
          width="100%"
          display="flex"
          alignContent="center"
          justifyContent="center"
          maxWidth={isDesktop ? '330px' : '100%'}
          marginTop={isDesktop ? '40px' : '96px'}
        >
          <Hollow
            isSubmit
            fullWidth
            size={14}
            padding="12.5px"
            onClick={handleAccept}
            onColorType={hollowColorType.primary}
          >
            Aceptar
          </Hollow>
        </Box>
      </Container>
    </CustomDialog>
  );
}
