export function getNavigator() {
  if (navigator.userAgent.search('MSIE') >= 0) {
    return '4';
  } if (navigator.userAgent.search('Chrome') >= 0) {
    return '1';
  } if (navigator.userAgent.search('Firefox') >= 0) {
    return '2';
  } if (navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0) {
    return '3';
  } if (navigator.userAgent.search('Opera') >= 0) {
    return '5';
  }
  return 0;
}
