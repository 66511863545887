import React from 'react';
import { config } from 'config';
import { colors } from 'globalStyle';
import styled from 'styled-components';
import { WhatsappIcon } from 'icons';

export default function ButtonWhatsapp() {
  return (
    <Div
      href={`https://api.whatsapp.com/send?phone=${config.whatsappNumber}`}
      target="_blank"
      rel="noreferrer"
      id="whatsapp-iframe-container"
    >
      <WhatsappIcon />
    </Div>
  );
}

export const Div = styled.a`
display: flex;
align-items: center;
justify-content: center;
  position: fixed;
  width: 60px;
  height: 60px;
  z-index: 100;
  bottom: 10px;
  right: 10px;
  background: #25d366;
  border-radius: 50px;
  font-size: 30px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
  transition: transform .2s; 

  &:hover {
    transform: scale(1.2);      
     }

  svg{
    fill:  ${colors.WHITE};
  }

`;
