import { Fetch } from 'lib/Fetch';

export function userForgotPasswordEmailSendService({ email, resend }) {
  return new Fetch()
    .url('/portal/login/user/email-password-forget')
    .method('post')
    .portalToken()
    .data({ email, resend })
    .send();
}

export function companyForgotPasswordEmailSendService({ email, resend }) {
  return new Fetch()
    .url('/portal/login/company/email-password-forget')
    .method('post')
    .portalToken()
    .data({ email, resend })
    .send();
}

export function userPasswordChangeForgetService({
  email, token, password1, password2,
}) {
  return new Fetch()
    .url('/portal/login/user/password-change-forget')
    .method('post')
    .portalToken()
    .data({
      token, email, password1, password2,
    })
    .send();
}

export function companyPasswordChangeForgetService({
  email, token, password1, password2,
}) {
  return new Fetch()
    .url('/portal/login/company/password-change-forget')
    .method('post')
    .portalToken()
    .data({
      token, email, password1, password2,
    })
    .send();
}
