import React from 'react';
import {
  Dialog, Box, DialogContent,
} from '@material-ui/core';
import deniedMobile from 'assets/maintenance.png';
import bloomLogo from 'assets/logo-bloom-SI-white.png';
import { Figure } from 'globalStyle';

export const RestrictMobilePopUp = () => (
  <Dialog
    fullWidth
    maxWidth="sm"
    open
    PaperProps={{
      style: {
        borderRadius: '11px',
        background: '#41B7BF',
        color: 'white',
        fontSize: '14px',
        fontFamily: 'Montserrat',
        letterSpacing: 0,
        paddingTop: '63.3px',
        paddingBottom: '63.3px',
        fontWeight: 'lighter',
      },
    }}
  >
    <DialogContent>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Figure>
          <img src={deniedMobile} alt="denegado" />
        </Figure>
        <div style={{ marginBottom: '32.6px', textAlign: 'center' }}>
          <span style={{ display: 'block' }}>Te invitamos a ingresar desde</span>
          <span style={{ display: 'block' }}>
            un
            <span style={{ fontWeight: 'bold' }}> computador o portátil </span>
            para
          </span>
          poder comenzar a invertir.
        </div>
        <div style={{ marginBottom: '15px', textAlign: 'center' }}>
          www.inversionesbloom.com
        </div>
        <div style={{ marginBottom: '35.6px', textAlign: 'center' }}>
          <span style={{ display: 'block' }}>Estamos trabajando para que pronto </span>
          <span style={{ display: 'block' }}>puedas utilizar nuestros servicios </span>
          <span style={{ display: 'block' }}>desde tu dispositivo móvil.</span>
        </div>
        <Figure>
          <img src={bloomLogo} alt="" style={{ maxWidth: '80%', margin: 'auto' }} />
        </Figure>
      </Box>
    </DialogContent>
  </Dialog>
);
