import { Fetch } from 'lib/Fetch';
import { config } from 'config';

export function createBankAccount() {
  return new Fetch('api_2')
    .url('/user/register/user/bank-account')
    .method('post')
    .userToken()
    .data()
    .send();
}

export function addFinancialInfo(data) {
  return new Fetch('api_2')
    .url('/user/register/user/forms/complete')
    .method('put')
    .userToken()
    .data(data)
    .send();
}

export function getUserData() {
  return new Fetch('api_2')
    .url('/user/register/user')
    .method('get')
    .userToken()
    .send();
}

export function useRegisterInvestor(data) {
  return new Fetch()
    .url('/portal/register/user')
    .method('post')
    .portalToken()
    .data(data)
    .send();
}

export function userRegisterNewService({
  documentType,
  document,
  password,
  browserId,
}) {
  return new Fetch()
    .url('/portal/register/user')
    .method('post')
    .portalToken()
    .data({
      documentType, document, password, browserId,
    })
    .send();
}

export function userRegisterPasswordVerifyService({
  idUser, password, repeatPassword, mac,
}) {
  return new Fetch()
    .url('/register/password-verify')
    .method('post')
    .data({
      idUser,
      password,
      repeatPassword,
      mac,
      portal: config.name.toLowerCase(),
    })
    .send();
}

export function useRegisterPhone(data) {
  return new Fetch('api_2')
    .url('/user/register/user/phone-register')
    .method('put')
    .userToken()
    .data(data)
    .send();
}

export function useSendPhoneOtpCompany() {
  return new Fetch('api_2')
    .url('/company/register/company/send-otp')
    .method('put')
    .portalToken()
    .userToken()
    .send();
}

export function useGetCompanyStatus() {
  return new Fetch('api_2')
    .url('/company/register/company')
    .method('get')
    .portalToken()
    .userToken()
    .send();
}

export function useVerifyPhone(data) {
  return new Fetch('api_2')
    .url('/user/register/user/phone-register/verify')
    .method('put')
    .userToken()
    .data(data)
    .send();
}

export function useVerifyPhoneCompany({ otp }) {
  return new Fetch('api_2')
    .url('/company/register/company/verify-otp')
    .method('post')
    .userToken()
    .data({ otp })
    .send();
}

export function updateDocDate(data) {
  return new Fetch('api_2')
    .url('/user/register/user/document')
    .method('post')
    .userToken()
    .data(data)
    .send();
}

export function updateDocDateCompany(data) {
  return new Fetch('api_2')
    .url('/company/company-info/document')
    .method('patch')
    .userToken()
    .data(data)
    .send();
}
