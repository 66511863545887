/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { ToolTipError } from 'components/ToolTip';
import { useMediaQuery } from 'hooks';
import { Div } from 'globalStyle';
import { Check } from './Check';
import { Label } from './Label';
import { StyledInput } from './styled';
import { ErrorText } from './ErrorText';

export const InputFormatNumber = ({
  mb,
  validate,
  control,
  name,
  errorToolTip,
  errorText,
  prefix,
  required,
  errors,
  title,
  label,
  getValues,
  placeholder,
  onChange,
  defaultValue,
  displayType,
  disabled,
  style,
  modalPlacement,
  subTitle,
  number,
  styles,
  colorLabel,
}) => {
  const bigScreen = useMediaQuery(1023);
  return (
    <Div mb={mb || 18} style={styles}>
      <Label
        required
        subTitle={subTitle}
        title={title || label}
        number={number}
        color={colorLabel}
      />
      <ToolTipError
        maxWidth="120px"
        open={errorToolTip ? !!errors[name] : false}
        text={errors[name] && errors[name].message}
        placement={modalPlacement || bigScreen ? 'right' : 'bottom-end'}
      >
        <div style={{ position: 'relative' }}>
          <Controller
            customInput={StyledInput}
            as={(
              <NumberFormat
                thousandSeparator="."
                decimalSeparator={false}
                prefix={`${prefix || ''} `}
                suffix={prefix}
                onValueChange={onChange}
                defaultValue={defaultValue}
                displayType={displayType}
                disabled={disabled}
                style={style}
              />
            )}
            rules={{
              required: required ? 'Este campo es obligatorio' : false,
              validate,
            }}
            type="text"
            name={name}
            placeholder={placeholder}
            variant="outlined"
            control={control}
            defaultValue={defaultValue}
          />
          <Check name={name} getValues={getValues} errors={errors} />
        </div>
      </ToolTipError>
      {errorText && <ErrorText text={errors[name]?.message} />}
    </Div>
  );
};
