import { useState, useRef } from 'react';

export const useFile = () => {
  const [file, setFile] = useState(null);
  const [name, setName] = useState(null);
  const [url, setUrl] = useState(null);

  const ref = useRef(null);

  const handleChange = (cb = () => {}) => () => {
    const uploadFile = ref.current.files[0];
    const objectUrl = URL.createObjectURL(uploadFile);
    cb(uploadFile, uploadFile.name, objectUrl);
    setUrl(objectUrl);
    setFile(uploadFile);
    setName(uploadFile.name);
  };

  const removeFile = () => {
    URL.revokeObjectURL(url);
    setUrl(null);
    setFile(null);
    setName(null);
  };

  return {
    file,
    name,
    ref,
    url,
    setUrl,
    setName,
    removeFile,
    handleChange,
  };
};
