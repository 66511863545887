import { useState, useCallback, useEffect } from 'react';
import { useMountedState } from 'react-use';
import { getCategorizations } from 'services';
import { useDispatch, useSelector } from 'react-redux';
import { updateCategorizationsList } from 'store/categorizations';

const MAX_RETRY = 10;

export function useCategorizations() {
  const dispatch = useDispatch();
  const isMounted = useMountedState();
  const [retry, setRetry] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const categorizations = useSelector((store) => store.categorizations);
  const updateCategorizations = useCallback(() => {
    if (isMounted()) {
      setIsLoading(true);
      getCategorizations()
        .then(({ data }) => {
          dispatch(updateCategorizationsList({
            list: data,
            defaultCategorization: data.find((v) => v?.isDefaultCategorization),
          }));
          setIsLoading(false);
          setRetry(0);
        })
        .catch(() => {
          setRetry((tryCount) => {
            if (tryCount + 1 >= MAX_RETRY) {
              setIsLoading(false);
            }
            return tryCount + 1;
          });
        });
    }
  }, []);

  useEffect(() => {
    if (
      retry < MAX_RETRY
      && (!categorizations.list
      || new Date(categorizations.updateDate?.getTime() + 1800000) < new Date())
    ) {
      updateCategorizations();
    }
  }, [retry]);

  return {
    isLoading,
    updateCategorizations,
    categorizations: categorizations?.list,
    defaultCategorization: categorizations?.defaultCategorization,
  };
}
