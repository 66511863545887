/* eslint-disable no-unused-vars */
import React from 'react';

import { Title, TextLight } from 'components/Texts';
import {
  colors, Figure, Div, weight,
} from 'globalStyle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Hollow } from 'components/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { closePopUp, openPopUp } from 'store/popup';
import errorSvg from 'assets/persons/error-woman.svg';
import { useMediaQuery } from 'hooks';
import arrowLeft from 'assets/icons/arrowLeftHeader.svg';
import closeIcon from 'assets/icons/closeIcon.svg';

export const SessionExpiresModal = () => {
  const dispatch = useDispatch();
  const open = useSelector((store) => store.popup.open);
  const isOpen = useSelector((store) => store.popup.isOpen);
  const isDesktop = useMediaQuery(1023);

  const handleClose = () => {
    dispatch(closePopUp());
  };

  return (
    <Dialog
      open={open === 'session-expires' && isOpen}
      onClose={handleClose}
      PaperProps={{
        style: { borderRadius: '20px', padding: isDesktop ? '30px' : '20px 30px' },
      }}
      maxWidth="md"
    >
      <Div isFlex justify="space-between">
        <Figure
          style={{
            width: `${isDesktop ? '25px' : '22px'}`,
            height: '25px',
            cursor: 'pointer',
          }}
          onClick={() => {
            handleClose();
          }}
        >
          <img
            src={arrowLeft}
            alt="atrás"
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </Figure>

        <Figure
          style={{
            width: `${isDesktop ? '18px' : '15px'}`,
            height: '18px',
            cursor: 'pointer',
          }}
          onClick={() => {
            handleClose();
          }}
        >
          <img
            src={closeIcon}
            alt="cerrar"
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </Figure>
      </Div>
      <DialogContent
        style={{
          maxWidth: isDesktop ? '520px' : '290px',
          padding: isDesktop ? '0 20px' : '0',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: '30px',
          textAlign: 'center',
        }}
      >
        <Figure width={isDesktop ? '190px' : '130px'} height={isDesktop ? '190px' : '130px'}>
          <img src={errorSvg} alt="metaValidate" />
        </Figure>
        <Title size={20}>
          Tu sesión ha expirado
        </Title>
        <TextLight size={15} align>
          Tu sesión se ha cerrado por seguridad. Para continuar invirtiendo, por
          favor vuelve a iniciar sesión.
        </TextLight>

        <Hollow
          size={14}
          isSubmit
          onClick={() => dispatch(openPopUp({ type: 'login' }))}
          onColorType="primary"
          style={{
            width: isDesktop ? '330px' : '100%',
            height: '45px',
            padding: '10px 0',
            borderRadius: '30px',
          }}
        >
          Iniciar sesión
        </Hollow>

        <TextLight
          size={14}
          mb={!isDesktop && 30}
          weight={weight.BOLD}
          color={colors.primary}
          align
          onClick={() => dispatch(closePopUp())}
        >
          Continuar sin iniciar sesión
        </TextLight>
      </DialogContent>
    </Dialog>
  );
};
