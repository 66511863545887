import { colors } from 'globalStyle';

export const typesProjectType = {
  EQUITY: 'Rentabilidad Variable',
  LOAN: 'Rentabilidad Fija',
  STOCKS: 'Acciones',
  TOKENS: 'Tokens',
};

export const projectTypes = {
  EQUITY: 'EQUITY',
  LOAN: 'LOAN',
  STOCKS: 'STOCKS',
  TOKENS: 'TOKENS',
};

export const colorByProjectType = {
  EQUITY: colors.error,
  LOAN: colors.primary,
  STOCKS: colors.secondary,
  TOKENS: colors.success,
};

export const typeColorByProjectType = {
  EQUITY: 'error',
  LOAN: 'primary',
  STOCKS: 'YELLOW',
  TOKENS: 'success',
};

export const issuanceType = {
  INDIVIDUAL: 'Individuales',
  PACKAGES: 'Paquetes',
};

export const issuanceTypes = {
  INDIVIDUAL: 'INDIVIDUAL',
  PACKAGES: 'PACKAGES',
};

export const stocksTypeType = {
  PREFERRED: 'Preferencial',
  COMMON: 'Ordinario',
};
export const stocksTypes = {
  PREFERRED: 'PREFERRED',
  COMMON: 'COMMON',
};

export const isAStockOrTokenProject = (projectType) => {
  switch (projectType) {
    case projectTypes.STOCKS:
      return true;
    case projectTypes.TOKENS:
      return true;
    default:
      return false;
  }
};

export const isAEquityOrTokenProject = (projectType) => {
  switch (projectType) {
    case projectTypes.EQUITY:
      return true;
    case projectTypes.TOKENS:
      return true;
    default:
      return false;
  }
};

export const tokenOrStocksindividual = (projectType) => {
  switch (projectType) {
    case projectTypes.STOCKS:
      return 'de la Acción';
    case projectTypes.TOKENS:
      return 'del Token';
    default:
      return '';
  }
};

export const individualtokenOrStocks = {
  STOCKS: 'Acción',
  TOKENS: 'Token',
};
