import React, { useState, useEffect } from 'react';
import { routes } from 'routes';
import { userType } from 'types';
import { useLocation } from 'wouter';
import { getUserData } from 'services';
import Skeleton from 'react-loading-skeleton';
import { useUser, useMediaQuery } from 'hooks';
import CustomDialog from 'components/Modals/CustomDialog';
import { missingFinancialData } from 'page/FinancialInfoCompletion/FinancialChat';

import RegisterWait from './RegisterWait';
import RegisterFailed from '../modals/RegisterFailed';
import { REGISTER_NEXT_STEP, REGISTER_STEPS, Register } from '..';

export default function RegisterChecker({
  children, check = true, onClose, showSlider,
}) {
  const isDesktop = useMediaQuery(1023);
  const [location, setLocation] = useLocation();
  const [info, setInfo] = useState();
  const { user, update, type: typeOfUser } = useUser();
  useEffect(() => {
    if (check && user && typeOfUser === userType.USER && user.info.status !== 'ACTIVE') {
      getUserData()
        .then(({ data }) => {
          const { financialInfo, status } = data;
          const financialInfoCompleted = !missingFinancialData(financialInfo).isMissing;
          update({ key: 'status', value: status });
          update({ key: 'financialInfoCompleted', value: financialInfoCompleted });
          setInfo({ status, financialInfoCompleted });
        });
    }
  }, [check]);
  if (user && typeOfUser === userType.USER && check) {
    const { status, financialInfoCompleted } = info || user.info;
    if (status !== 'ACTIVE') {
      if (!info) {
        return (
          <CustomDialog
            open
            px="30px"
            onClose={onClose}
            width={isDesktop ? '390px' : '284px'}
          >
            <Skeleton width="100%" height="100px" />
          </CustomDialog>
        );
      }
      if (status === 'ACCOUNT_CREATION_FAILED') {
        return (
          <RegisterFailed
            open
            onClose={onClose}
          />
        );
      }
      if ((
        status === 'PENDING_METAMAP'
        || status === 'WAITING_VALIDATION'
        || status === 'COMPLETED_META_MAP_VALIDATION'
      ) && financialInfoCompleted) {
        return (
          <RegisterWait
            open
            onClose={onClose}
          />
        );
      }
      if (
        status === 'PENDING_METAMAP'
        || status === 'WAITING_VALIDATION'
        || status === 'VALIDATION_COMPLETED'
        || status === 'COMPLETED_META_MAP_VALIDATION'
      ) {
        return (
          <Register
            isOpen
            onClick={onClose}
            initialStep={REGISTER_STEPS.USER_NEXT_STEP}
            stepperProps={{
              nextStep: REGISTER_NEXT_STEP.FINANCIAL,
              onNext: () => {
                if (isDesktop) {
                  onClose();
                  if (showSlider) {
                    showSlider();
                  } else {
                    setLocation(`${routes.profile.route}#showFinancialChat`);
                    if (location === routes.profile.route) {
                      document.location.reload();
                    }
                  }
                } else {
                  setLocation(routes.dataCompletion.financialInfoCompletion);
                }
              },
            }}
          />
        );
      }
      return (
        <Register
          isOpen
          onClick={onClose}
          initialStep={REGISTER_STEPS.USER_NEXT_STEP}
        />
      );
    }
  }
  if (children) {
    return children;
  }

  document.location.reload();
  return '';
}
