export function hideChatWhatsApp() {
  const whatsappContainer = document.getElementById(
    'whatsapp-iframe-container',
  );

  if (whatsappContainer) {
    whatsappContainer.setAttribute(
      'style',
      'display:none !important;',
    );
  }
}

export function showChatWhatsApp() {
  const whatsappContainer = document.getElementById('whatsapp-iframe-container');
  whatsappContainer?.setAttribute(
    'style',
    whatsappContainer
      ?.getAttribute('style')
      ?.replaceAll('display:none !important;', ''),
  );
}
