/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
export const getNameLetter = (str = 'Bloom') => {
  const name = str.toUpperCase();
  if (name && name.length > 0) {
    return require(`assets/letters/${name.charAt(0)}.svg`).default;
  }

  return undefined;
};

export const getUserName = (name) => {
  if (name && name.length > 0) {
    const data = name.split(' ', 2);
    if (data.length === 1) {
      return data[0];
    }
    if (data.length === 2) {
      return `${data[0]} ${data[1]}`;
    }
  }

  return '';
};
