import React, { useState, useEffect } from 'react';
import { CloseIcon } from 'icons';
import { colors } from 'globalStyle';
import { Text } from 'components/Texts';
import Container from 'components/Container';
import SearchIcon from 'assets/icons/search.svg';
import MenuIcon from 'assets/icons/menu-blue.svg';
import { BasicInput } from 'components/Form/Basic';
import { Box, Grid, styled } from '@material-ui/core';
import { Hollow, hollowColorType } from 'components/Buttons';
import CircleIcon from 'assets/icons/circle-border-primary.svg';
import LoadingDots from 'components/LoadingDots';
import { useMediaQuery } from 'hooks';

const LENGTH_FOR_MANDATORY_SEARCH = 30;

export default function SelectChat({
  update, name, values,
}) {
  const isDesktop = useMediaQuery(1023);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState();
  const [showSearch, setShowSearch] = useState(false);
  const hide = () => setShow(false);
  useEffect(() => {
    if (Array.isArray(values)) {
      const data = values.reduce((prev, curr) => {
        /* eslint-disable-next-line no-param-reassign */
        prev[curr] = curr;
        return prev;
      }, {});
      setOptions(data);
      setShowSearch(values && values.length >= 10);
    } else {
      setOptions(values);
      setShowSearch(values && Object.keys(values).length >= 10);
    }
  }, [values]);
  return (
    <>
      <MainButton
        isSubmit
        fullWidth
        onClick={() => setShow(true)}
        borderRadius="12px"
        onColorType={hollowColorType.WhiteBorder}
      >
        <Text isCenter size={12} color="inherit">
          <Box
            alt=""
            width="12px"
            src={MenuIcon}
            component="img"
            marginRight="8px"
          />
          {name}
        </Text>
      </MainButton>
      {show && (
        <ItemsContainer
          onClick={hide}
          isDesktop={isDesktop}
        >
          <ListHeader onClick={(event) => event.stopPropagation()}>
            <Text isCenter size={14} color={colors.primary}>
              {name}
            </Text>
            {showSearch && (
              <BasicInput
                mt={20}
                inputPr="45px"
                placeholder={Object.keys(options).length > LENGTH_FOR_MANDATORY_SEARCH ? 'Escribe al menos tres letras' : 'Búsqueda'}
                onChange={(e) => setSearch(e.target.value)}
                name="search"
                inputHeight="45px"
              />
            )}
            <CloseIcon
              width="20px"
              height="20px"
              onClick={hide}
              style={{
                top: '20px',
                right: '20px',
                cursor: 'pointer',
                position: 'absolute',
              }}
            />
            {showSearch && (
              <Box
                width="24px"
                right="46px"
                height="24px"
                bottom="39px"
                component="img"
                src={SearchIcon}
                position="absolute"
              />
            )}
          </ListHeader>
          <ItemsList showSearch={showSearch}>
            {options
              ? ((Object.keys(options).length <= LENGTH_FOR_MANDATORY_SEARCH || search.length >= 3)
                && Object.entries(options).filter(([, value]) => `${value}`.toLowerCase().includes(search.toLowerCase()))
                  .map(([key, value]) => (
                    <ItemButton
                      key={key}
                      isSubmit
                      onClick={() => update(key)}
                      onColorType={hollowColorType.WHITE}
                    >
                      <InfoContainer>
                        <Text size={14} width="fit-content">{value}</Text>
                        <Box
                          alt=""
                          width="25px"
                          component="img"
                          src={CircleIcon}
                        />
                      </InfoContainer>
                    </ItemButton>
                  )))
              : (
                <LoadingRootContainer container justify="center" onClick={(event) => event.stopPropagation()}>
                  <LoadingContainer>
                    <LoadingDots />
                  </LoadingContainer>
                </LoadingRootContainer>
              )}
          </ItemsList>
        </ItemsContainer>
      )}
    </>
  );
}

export const ItemsContainer = styled(Container)({
  bottom: 0,
  right: 0,
  zIndex: 100,
  height: '100dvh',
  position: 'fixed',
  justifyContent: 'flex-end',
  background: 'rgba(0, 0, 0, 0.26)',
  left: (props) => !props.isDesktop && 0,
  width: (props) => props.isDesktop && '390px',
  borderRadius: (props) => props.isDesktop && '0px 0px 0px 30px',
});

const ItemsList = styled(Container)({
  width: '100%',
  overflowY: 'auto',
  flexDirection: 'row',
  maxHeight: (props) => (props.showSearch ? 'calc(60dvh - 132px)' : 'calc(60dvh - 49px)'),
});

const InfoContainer = styled(Grid)({
  display: 'grid',
  columnGap: '20px',
  textAlign: 'initial',
  gridTemplateColumns: '1fr 25px',
});

const ItemButton = styled(Hollow)({
  width: '100%',
  borderRadius: '0px !important',
});

export const MainButton = styled(Hollow)({
  borderRadius: '12px !important',
});

export const ListHeader = styled(Grid)({
  width: '100%',
  padding: '30px',
  paddingTop: '22px',
  paddingBottom: '9px',
  position: 'relative',
  background: colors.WHITE,
  borderRadius: '20px 20px 0px 0px',
});

const LoadingRootContainer = styled(Grid)({
  width: '100%',
  height: '150px',
  paddingTop: '25px',
  background: colors.WHITE,
});

const LoadingContainer = styled(Grid)({
  width: '160px',
});
