import React, { useEffect, useRef } from 'react';

import metaValidate from 'assets/metaValidate.svg';
import { Ghost, Hollow } from 'components/Buttons';
import { Text, Title } from 'components/Texts';
import { colors } from 'globalStyle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// eslint-disable-next-line no-unused-vars
import { useDispatch } from 'react-redux';
import { openPopUp } from 'store/popup';

// import { Box } from '@material-ui/core';
import { config } from 'config';

export const MetaMapModal = ({
  userId, isOpen, onClose, userFinishedSdk,
}) => {
  const dispatch = useDispatch();
  const button = useRef(null);
  useEffect(() => {
    if (button.current) {
      button.current.addEventListener('metamap:userFinishedSdk', () => userFinishedSdk());
    }
  }, [isOpen]);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        style={{ radius: '40px' }}
        align="center"
        maxWidth="md"
      >
        <DialogContent>
          <Title number={5} color={colors.GRAY_1}>
            Termina tu registro con una validación de identidad
          </Title>
          <DialogContentText>
            <div style={{ width: '250px' }}>
              <img src={metaValidate} alt="metaValidate" />
            </div>
            <div style={{ width: '85%', marginTop: '15px' }}>
              <Text number={4} color={colors.GRAY_2}>
                Para finalizar tu registro y por tu seguridad, vamos a validar tu
                identidad. Ten en cuenta que necesitas tener tu documento de
                identificación a la mano.
              </Text>
              <div style={{ width: '280px', marginTop: '20px' }}>
                <Hollow
                  fullWidth
                  size={11}
                  mt={20}
                  isSubmit
                  onClick={() => button.current.click()}
                >
                  Validar Identidad
                </Hollow>
              </div>
              <Text mt={20} mb={20} number={4} color={colors.GRAY_2}>
                ¿Ya tienes una cuenta?
                {' '}
                <Ghost
                  underline
                  color={colors.GRAY_2}
                  isSubmit
                  size={13}
                  onClick={() => dispatch(openPopUp({ type: 'login' }))}
                >
                  Inicia Sesión
                </Ghost>
              </Text>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {isOpen && (
        <metamap-button
          style={{ display: 'none' }}
          ref={button}
          clientid={config.metaMapClientId}
          flowid={config.metaMapFlowId}
          metadata={`{"userId": "${userId}", "fixedLanguage":"es"}`}
        />
      )}
    </>
  );
};
