import { Fetch } from 'lib/Fetch';

export function validateOTP({ otp, receiver }) {
  return new Fetch('api_2')
    .url('/external/global/validate-otp')
    .method('post')
    .userToken()
    .data({ otp, receiver })
    .send();
}

export function useSendOTP(body) {
  return new Fetch('api_2')
    .url('/portal/register/user/send-otp')
    .method('post')
    .portalToken()
    .data(body)
    .send();
}

export function useSendOTPCompanyPhone(body) {
  return new Fetch('api_2')
    .url('/portal/register/user/send-otp')
    .method('post')
    .portalToken()
    .data(body)
    .send();
}
