import { config } from 'config';

const errorsBCC = {
  BCC_INTERNAL_ERROR:
    'Lo sentimos, ha ocurrido un error. Por favor ponte en contacto con soporte, infórmanos que tuviste un error de tipo BCC',
  BCC_ERROR:
    'Lo sentimos, ha ocurrido un error. Por favor ponte en contacto con soporte, infórmanos que tuviste el error BNK_002',
  BCC_186_01: 'Modalidad no valida',
  BCC_186_02: 'Modalidad no implementada',
  BCC_186_03: 'Oficina no valida',
  BCC_186_04: 'El tipo de persona que registró no es correcto',
  BCC_186_05: 'El tipo de documento ingresado no es valido',
  BCC_186_06: 'Agregue su numero de documento',
  BCC_186_07: 'Agregue la fecha de expedición del documento',
  BCC_186_08: 'La ciudad de expedición no su documento no concuerda',
  BCC_186_09: 'Agregue su primer apellido y sin carácteres especiales',
  BCC_186_10: 'Agregue su primer nombre y sin carácteres especiales',
  BCC_186_11: 'El numero de celular ingresado no es valido',
  BCC_186_12: 'El correo electrónico ingresado no es valido',
  BCC_186_13: 'Datos ingresados ya es usado por otro usuario',
  BCC_186_14: 'Lo sentimos, no puedes usar nuestros servicios',
  BCC_186_15:
    'Los datos ingresados no concuerdan con los del banco. Por favor contáctese con soporte',
  BCC_186_16: 'El correo electrónico ingresado, ya esta en uso',
  BCC_186_17:
    'El numero de celular ingresado, ya esta en uso. Por favor contáctese con soporte',
  BCC_186_18:
    'Verifique su segundo apellido, no debe contener caracteres especiales',
  BCC_186_19: 'Exento no valida',
  BCC_186_20: 'Género no valido',
  BCC_186_21: 'Cliente no existe o los datos estan desactualizados',
  BCC_186_22: 'Llevamos meses sin saber de ti, por favor actualiza tus datos',
  BCC_186_23: 'El tipo de de documento no concuerda con el numero de documento',
  BCC_186_24: 'Lo sentimos, no puedes usar nuestros servicios',
};

const errorMessages = {
  INVALID_PORTAL: 'Lo sentimos, no puedes usar nuestros servicios',
  USER_EMAIL_NOT_FOUND: 'El correo no se encuentra.',
  INVALID_PASSWORD: 'Contraseña inválida.',
  MAC_IS_NOT_REGISTERED: 'El dispositivo no está registrado.',
  ACCOUNT_BLOCKED_DUE_LOGIN_TRIES:
    'La cuenta fue bloqueada debido a intentos de inicio de sesión fallidos.',
  INVALID_PASSWORD_TRIES_LEFT: 'Contraseña incorrecta, tienes %data intentos.',
  MAC_IS_NOT_REGISTERED_TRIES_LEFT:
    'El dispositivo no se encuentra registrado, intenta de nuevo.',
  USER_NOT_FOUND_OR_INACTIVE: 'El usuario no se encuentra o esta inactivo.',
  USER_NOT_FOUND: 'El usuario no se encuentra.',
  COMPANY_NOT_FOUND: 'La compañía no se encuentra.',
  USER_NOT_EXISTS:
    'El usuario ingresado no existe. Para hacer tu primera inversión te invitamos a crear una cuenta',
  USER_DONT_EXISTS: 'El usuario no existe.',
  EMAIL_EXISTS: 'El correo %data ya existe.',
  ERROR_SENDING_MAIL: 'Error al enviar el correo',
  BAD_TOKEN: 'El tiempo de registro ha expirado.',
  PHONE_EXISTS: 'El teléfono ya existe',
  DOCUMENT_EXISTS:
    'Este documento ya existe. Por favor colocarse en contacto con bloom@bloomcrowdfunding.co para revisar el problema que aparece.',
  DOCUMENT_IN_BLACKLIST:
    'Este documento no es válido. Por favor colocarse en contacto con bloom@bloomcrowdfunding.co para revisar el problema que aparece.',
  PLEASE_FINISH_REGISTER_PROCESS:
    'Tienes que terminar el registro para poder cambiar la contraseña',
  ERROR_SENDING_PASSWORD_CHANGE_CONFIRMATION_EMAI:
    'Ocurrió un error al enviar el código de confirmación.',
  INVALID_CODE: 'Ocurrió un error al enviar el código de confirmación.',
  NEW_PASSWORD_IS_TOO_SHORT: 'La nueva contraseña es muy corta.',
  PASSWORD_AND_CONFIRMATION_NOT_MATCH: 'Las contraseñas no coinciden.',
  BAD_VALIDATION: 'El código que estás ingresando no es válido.',
  PROCCESS_NOT_FINISHED:
    'Aún no validamos que eres tú. Activa tu cámara para saber quien eres.',
  OLD_PASSWORD_INCORRECT:
    'Tu contraseña actual es incorrecta, inténtalo de nuevo.',
  BAD_INVESTMENT_VALUE: 'El monto ingresado no es valido, intentelo de nuevo.',
  INVESTMENT_BEATS_USER_FINANCES:
    'La inversión supera las finanzas del usuario.',
  USER_DOES_NOT_HAVE_ENOUGH_FUNDS: 'No tienes suficientes fondos.',
  AMOUNT_EXCEEDS_MAXIMUM_OF_THE_PROJECT:
    'La cantidad excede el máximo del proyecto.',
  ACCOUNT_INVITATION_SEENDED: 'Se envio la invitacion',
  ALREADY_HAS_OWNERSHIP: 'Ya tiene propiedad.',
  BAD_PHONE: 'El teléfono es incorrecto.',
  PLEASE_FINISH_TOKEN: 'Por favor termine el token.',
  PLEASE_VALIDATE_AGAIN: 'Por favor intenta de nuevo.',
  YOU_HAVE_INVESTED_TOO_MUCH_IN_THIS_PROJECT:
    'Has invertido mucho en este proyecto.',
  ALREADY_IN_TRANSFER_PROCESS:
    'Este valor de financiación colaborativa se encuentra ya en proceso de transferencia.',
  MAXIMUM_DAILY_TRANSFERS_EXCEEDED:
    'Solo puedes realizar una transferencia diaria.',
  MAXIMUM_MONTHLY_TRANSFERS_EXCEEDED:
    'Sólo puedes realizar tres transferencia al mes.',
  DOCUMENT_IS_9_DIGITS: 'El documento debe tener 9 dígitos.',
  'VERIFICATION_DIGIT_IS_0-9':
    'El dígito de verificación debe ser un numero entre 0-9',
  COMPANY_NOT_HAVE_REQUEST:
    'Debes iniciar el proceso para continuar con la creación de la compañia.',
  BAD_TYPE_DOCUMENT: 'El tipo de documento no existe.',
  DATA_ALREADY_FILLED: 'Ya se registro el representante legal.',
  BAD_EMAIL: 'El email no es invalido.',
  TOO_MANY_STAKEHOLDERS: 'Más de 20 accionistas registrados.',
  BAD_STAKEHOLDERS_AMOUNT:
    'Los nuevos accionistas a registrar sumados con los viejos da mas de 20 accionistas.',
  BAD_REQUEST_STATUS: 'Tu estado no es correcto.',
  COMPANY_NOT_HAVE_ALL_FILES: 'Aún no agregas todos los archivos.',
  COMPANY_NOT_HAVE_FILES: 'Aún no agregas ninguno de los archivos.',
  EMPTY_STAKEHOLDERS: 'Aún no agregas ningún accionista.',
  NEED_LEGAL_REPRESENT: 'Necesitas agregar un representante legal.',
  GOAL_AND_MIN_GOAL_HAVE_TO_BE_1000_MULTIPLE:
    'La meta de financiación y el monto mínimo para cierre debe ser multiplo de mil.',
  INVALID_MIN_GOAL: 'El monto mínimo debe ser de $ %data.',
  INVALID_GOAL: 'La meta de financiación debe ser de $ %data.',
  MONTHSGRACEPERIOD_INVALID:
    'El período de gracia no es válido, debe ser un valor entre (0-6) meses.',
  PCTRETURN_INVALID: 'La tasas de retorno.',
  MISSING_CONF_INVEST_AMOUNTS: 'Intenta más tarde.',
  BAD_PROJECT_FREQUENCY: 'El tipo de frecuencia no es el correcto.',
  FILE_IS_REQUIRED: 'El archivo es obligatorio.',
  BAD_TYPE: 'Error de tipo.',
  BAD_FILE_TYPE: 'El tipo de archivo no es válido.',
  DOES_NOT_COMPLIED_REQUIREMENTS: 'No cumple con los requerimientos.',
  'MORE_THAN_20%_OF_INCOME_OR_HERITAGE':
    'El monto de inversión no puede superar el 20% de su ingreso anual o patrimonio, el que resulte mayor. Por favor, intente con un monto menor.',
  EXCEEDS_MAX_PROJECT_AMOUNT:
    'El monto de inversión que intenta hacer, supera el valor del proyecto. Por favor, intente con un valor menor.',
  INSUFFICIENT_FUNDS:
    'No cuentas con fondos suficientes para realizar esta inversión. Por favor, intente con un valor menor',
  'MORE_THAN_90%_OF_PROJECT':
    'No es posible invertir más del 90 % del monto total del proyecto. Por favor, intente con un valor menor',
  USER_BLOCKED:
    'Lo sentimos, ha ocurrido un error. Por favor ponte en contacto con soporte',
  PROJECT_CLOSED:
    'Lo sentimos, ha ocurrido un error. Por favor ponte en contacto con soporte',
  AMOUNT_LOWER_THAN_50000_OR_NOT_DIVISIBLE_BY_10000:
    'El monto que intenta invertir es menor de $ 50.000 o no es valor cerrado',
  CONTACT_SUPPORT: 'Por favor pongase en contacto con soporte',
  IT_IS_NOT_POSSIBLE_TO_MAKE_THE_INVESTMENT_REVIEW_THE_VALUES_OF_THE_PROJECT:
    'No fue posible realizar la inversión por favor validar los valores del proyecto.',
  NO_YEARLYS_CREATED:
    'Ups ha ocurrido un error, por favor ponte en contacto con soporte',
  BCC_ERROR:
    'Lo sentimos, ha ocurrido un error. Por favor ponte en contacto con soporte',
  FAILURE_MAPPING:
    'Lo sentimos, ha ocurrido un error interno. Por favor ponte en contacto con soporte',
  REGISTER_BEING_REVIEWED:
    'Su cuenta se esta revisando, por favor intente más tarde.',
  PENDING_METAMAP:
    'Estamos validando tus datos, te informaremos cuando puedas acceder a tu cuenta.',
  INCORRECT_CREDENTIALS: 'Usuario o contraseña no coinciden',
  INCORRECT_NIT_LENGTH: 'Longitud de NIT incorrecto.',
  NIT_ALREADY_REGISTERED: 'El NIT ya se encuentra registrado.',
  NIT_IN_USE: 'El NIT ya se encuentra registrado, por favor inicia sesión.',
  DNI_ALREADY_REGISTERED:
    'El usuario ya se encuentra registrado. Por favor inicie sesión.',
  USER_ALREADY_REGISTERED: 'El usuario ya se encuentra registrado',
  PHONE_IN_USE:
    'El número de teléfono ya se encuentra registrado, por favor inicia sesión o ingresa un número de teléfono diferente.',
  UNMAPPED_BCC_ERROR:
    'Lo sentimos, ha ocurrido un error. Por favor contacte soporte',
  INVALID_OTP: 'Código de validación ha expirado o no es válido',
  COMPANY_BLOCKED: 'Por favor, póngase en contacto con soporte.',
  EMAIL_ALREADY_REGISTERED: 'El email ya se encuentra registrado.',
  EMAIL_IN_USE:
    'El correo ya se encuentra registrado, por favor inicia sesión.',
  WITHDRAW_AMOUNT_EXCEEDED:
    'El monto de retiro supera el monto total de la inversión.',
  TOTAL_INVEST_WITH_WITHDRAW_LOWER_THAN_50000:
    'El monto de retiro es menor a 50.000.',
  WITHDRAW_AMOUNT_NOT_DIVISIBLE_BY_10000:
    'El monto de retiro debe ser divisible por 10.000.',
  WITHDRAW_TOTAL_INVEST_NOT_DIVISIBLE_BY_10000:
    'La inversión total realizada debe ser divisible por 10.000.',
  INVALID_NIT: 'El NIT no es válido.',
  USER_ALREADY_BLOCKED_BY_LOGIN_TRIES:
    'Su usuario ha excedido el número de intentos autorizados, para desbloquearlo, solicite recuperar contraseña seleccionando la pregunta ¿Has olvidado tu contraseña?',
  EMAIL_ALREADY_REGISTERED_FOR_ANOTHER_COMPANY:
    'El email ya se encuentra registrado para otra empresa.',
  POLICY_NOT_ACCEPTED: 'Debe aceptar las políticas de privacidad.',
  INSUFFICIENT_WALLET_AMOUNT:
    'No cuentas con fondos suficientes para realizar esta transacción.',
  RECEIVING_USER_NOT_FOUND:
    'El correo del usuario al que intenta trasferir su valor de financiación colaborativa, no se encuentra registrado',
  PASSWORDS_DO_NOT_MATCH: 'Las contraseñas no coinciden.',
  INVALID_TOKEN: 'Ha ocurrido un error. Inicie sesión nuevamente',
  USER_BLOCKED_LOGIN_TRIES:
    'Su usuario ha excedido el número de intentos autorizados, para desbloquearlo, solicite recuperar contraseña seleccionando la pregunta ¿Has olvidado tu contraseña?',
  WAITING_EXTERNAL_PROVIDER:
    'Aún no hemos recibido tus documentos, te invitamos a realizar el proceso de envío',
  INVALID_OTP_DATA: 'Código incorrecto, intente nuevamente',
  NEW_DEVICE_EMAIL_SENT:
    'Ups ha ocurrido un error, por favor ponte en contacto con soporte',
  PASSWORDS_DOES_NOT_MATCH: 'Las contraseñas no coinciden',
  BOND_ID_NOT_FOUND: 'El valor de financiación colaborativa no se encuentra',
  BOND_NOT_FOUND: 'El valor de financiación colaborativa no se encuentra',
  BOND_ALREADY_TRANSFERRED:
    'El valor de financiación colaborativa ya fue transferido',
  USER_IS_NOT_ACTIVE: 'El usuario no se encuentra activo',
  CURRENT_OWNER_NOT_FOUND: 'El usuario actual no se encuentra',
  LAST_OWNER_NOT_FOUND: 'El usuario actual no se encuentra',
  EXPIRED_OTP_OR_INVALID_OTP: 'Código de validación ha expirado o no es válido',
  DIVIDEND_NOT_FOUND: 'El dividendo no se encuentra',
  COMPANY_STATUS_NOT_FOR_LOGIN:
    'Estamos procesando el registro, por favor intenta más tarde',
  USER_NOT_PRESENT_IN_TOKEN:
    'Lo sentimos a ocurrido un error, vuelve a iniciar sesión',
  DESTINATION_USER_NOT_MATCH:
    'Recuerda que el número de documento a la que deseas transferir, debe ser el mismo que el de tu usuario',
  USER_CANNOT_WITHDRAW:
    'No podemos procesar esta transferencia, por favor contacte a soporte',
  USER_THREE_WITHDRAWALS_SAME_DAY_CONTACT_SUPPORT:
    'No puede realizar más de 3 retiros en el mismo día',
  WALLET_NOT_FOUND: 'Por favor póngase en contacto con soporte',
  FAILURE_UVT_ANALYSIS:
    'Lo sentimos, ocurrió un error interno al calcular las UVT',
  COLOMBIAN_WALLET_NOT_FOUND: 'Por favor póngase en contacto con soporte',
  BCC_VALIDATION_FAILED:
    'Ocurrió un error interno en el banco CoopCentral, Por favor póngase en contacto con soporte',
  BCC_INSUFFICIENT_AMOUNT:
    'No cuenta con dinero suficiente para realizar esta transferencia',
  BANK_NOT_FOUND:
    'Lo sentimos, no encontramos el banco al que intenta realizar la transferencia',
  ACCOUNT_TYPE_NOT_VALID:
    'El tipo de cuenta para esta transferencia, no es valido',
  TRANSACTION_NOT_FOUND:
    'Lo sentimos, no hemos encontrado el soporte de pago de tu transacción, por favor, contacta soporte',
  VOUCHER_PDF_NOT_GENERATED:
    'Lo sentimos, no pudimos generar el soporte de pago',
  DESTINATION_COMPANY_NIT_NOT_MATCH:
    'Recuerda que el número de documento a la que deseas transferir, debe ser el mismo que el de tu usuario',
  COMPANY_CANNOT_WITHDRAW:
    'No podemos procesar esta transferencia, por favor contacte a soporte',
  COMPANY_THREE_WITHDRAWALS_SAME_DAY_CONTACT_SUPPORT:
    'No puede realizar más de 3 retiros en el mismo día',
  UPDATES_NEW_PROJECT_NOT_FOUND:
    'Aún no tienes un proyecto cerrado, ¡Postulalo!',
  UPDATES_NEW_INVESTMENTS_NOT_FOUND:
    'Este proyecto no cuenta con inversionistas',
  INVESTMENTS_NOT_FOUND_BOND: 'Este proyecto no cuenta con inversionistas',
  UPDATES_NEW_INVESTMENTS_NOT_FOUND_BOND:
    'Aún no puedes generar actualizaciones de tu proyecto, te estaremos avisando por correo',
  PORTAL_UNDEFINED: 'No se encontró el mayorista, contacte a soporte',
  SMMLV_COLOMBIA_NOT_FOUND:
    'Ocurrio un problema interno al intentar encontrar el SMMLV',
  NO_COLOMBIAN_WALLET_FOUND: 'Ocurrio un problema interno',
  MORE_THAN_ELECTRONIC_DEPOSIT_CAPACITY:
    'Has superado la capacidad de recarga en depósito electrónico',
  AMOUNT_NOT_PERMITTED: 'El monto ingresado, no está permitido',
  INVALID_DOCUMENT_TYPE:
    'El tipo de documento ingresado no corresponde al del documento registrado a la cuenta de la que intenta hacer la transferencia',
  VARIABLE_NOT_FOUND: 'Error interno, por favor intente más tarde',
  COMPANY_TRANSACTION_FAILED: 'No es posible realizar este pago',
  UNAUTHORIZED: 'Vuelve a iniciar sesión',
  CURRENT_PASSWORD_CANNOT_MATCH_THE_NEW_PASSWORD:
    'La contraseña nueva no puede ser igual a la actual. Recuerda que tu contraseña debe tener mínimo ocho (8) caracteres. Al menos una mayuscula, un caracter especial y numero.',
  MAX_MONTHLY_RECHARGES_REACHED:
    'RECUERDA que tienes un Depósito Electrónico de Bajo Monto y la suma total de los Depósitos, Saldo y Retiros por Mes no pueden superar los ocho (8) SMMLV.',
  AMOUNT_EXCEEDS_ORDINARY_BCC_ACCOUNT_MAXIMUM_AMOUNT:
    'El monto que intenta recargar excede el máximo permitido',
  INSUFFICIENT_FUNDS_BANK:
    'No cuenta con saldo suficientes para realizar este pago',
  INVALID_EMAIL: 'El correo sumistrado es inválido',
  COMPANY_ALREADY_REGISTERED:
    'Los datos de la empresa ya se encuentran registrados',
  COMPANY_NAME_IN_USE: 'El nombre de la empresa ya se encuentra registrado',
  PHONE_NUMBER_ALREADY_REGISTER: 'El número de teléfono ya esta registrado',
  OTP_NO_VALID: 'Código de validación ha expirado o no es válido',
  OK: '%type',
  NEW_PASSWORD_SAME_AS_CURRENT_PASSWORD: 'La contraseña nueva no puede ser igual a la actual. Recuerda que tu contraseña debe tener mínimo ocho (8) caracteres. Al menos una mayuscula, un caracter especial y numero.',
  INVALID_YIELD: 'Capital o interés invalido',
  RETENTIONS_GREATER_THAN_PROVISIONS: 'La sumatoria de todas las retenciones es mayor que la provisión',
  BALANCE_MUST_BE_GREATER_THAN_ZERO: 'No cuenta con saldo suficientes para realizar este pago',
  AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'La cantidad debe ser mayor a cero',
  BANKS_MUST_BE_THE_SAME: 'Los bancos deben coincidir',
  INVESTMENT_MUST_BE_GREATER_THAN_AMOUNT: 'La inversión debe ser mayor al tope mínimo, por favor intente con un valor superior o igual al tope.',
  INVALID_OPERATION: 'Operación inválida',
  ACCOUNT_NOT_FOUND: 'No se ha encontrado la cuenta',
  BANK_ACCOUNT_ALREADY_EXISTS: 'La cuenta de banco ya existe',
  USER_TYPE_NOT_SUPPORTED: 'El tipo de cuenta para esta transferencia, no es valido',
  INTERBANK_TRANSACTION_FAILED: 'Ha ocurrido un error, por favor inténtalo más tarde',
  TRANSACTION_FAILED: 'Ha ocurrido un error, por favor inténtalo más tarde',
  EMBARGOES_ACCOUNT_NOT_FOUND: 'No se ha encontrado la cuenta',
  MINT_ACCOUNT_NOT_FOUND: 'No se ha encontrado la cuenta',
  MAX_MONTHLY_WITHDRAW_REACHED: 'Lo sentimos, se ha alcanzado el tope máximo de retiro mensual',
  DOCUMENT_MISMATCH: 'El documento ingresado no coincide con el propietario de la cuenta',
  UNFREEZE_CALCULATION_FAIL: 'Error al usar los fondos de la cuenta, por favor comuníquese con servicio al cliente o intente nuevamente más tarde',
  INVESTMENT_AMOUNT_LOWER_THAN_MINIMUM: 'La inversión debe ser mayor al tope mínimo, por favor intente con un valor superior o igual al tope.',
  AMOUNT_NOT_DIVISIBLE_BY_10000: 'El valor de la inversión debe ser divisible en 10.000',
  CAN_NOT_INVEST_IN_THIS_PROJECT: 'Lo sentimos, no es posible invertir en este proyecto',
  INVALID_NODE_AMOUNT: 'El valor de inversión no es válido, por favor intente con un valor diferente',
  INVALID_NODE_TO_WITHDRAW: 'El valor del retiro que se intenta hacer no es válido, por favor intente con un valor diferente',
  INVALID_INVESTMENT_NODE_TO_WITHDRAW: 'El valor del retiro que se intenta hacer no es válido, por favor intente con un valor diferente',
  COMPANY_ALREADY_BLOCKED_BY_LOGIN_TRIES:
    'Su empresa ha excedido el número de intentos autorizados, para desbloquearlo, solicite recuperar contraseña seleccionando la pregunta ¿Has olvidado tu contraseña?',
  SAME_LEGAL_REP_EMAIL: 'El correo del representante legal no puede ser el mismo que el de la empresa',
  default: 'Ocurrió un problema, intenta de nuevo.',
};

function getMessageBCC(data) {
  if (data?.codError && data?.desError) {
    const { codError, desError } = data;
    const error = desError.match(/\[(.*)\]/)[1];
    const errorMessage = `BCC_${codError}_${error}`;
    return errorMessage;
  }
  return null;
}

export function getMessageType(type, data = undefined) {
  if (config.dev) {
    return type;
  }
  let message;
  const errorBCC = getMessageBCC(data);
  if (errorBCC) {
    message = errorsBCC[errorBCC] || errorsBCC.BCC_INTERNAL_ERROR;
    return message;
  }

  message = errorMessages[type] || errorMessages.default;
  message = message.replace('%data', data);
  message = message.replace('%type', type);
  return message;
}
