/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Auth } from 'components/Modals';
import { Text } from 'components/Texts';
import { colors, weight } from 'globalStyle';
import { useDispatch } from 'react-redux';
import { openPopUp } from 'store/popup';
import { Ghost } from 'components/Buttons';
import { Device } from './components/Device';
import RecoverPassword from './components/Password';

export const Recover = ({
  isOpen, recoverIndex, onClick, clientType, isDesktop,
}) => (
  <>
    {recoverIndex === 'recover-password'
      ? (
        <RecoverPassword
          open={isOpen}
          onClose={onClick}
          isDesktop={isDesktop}
          clientType={clientType}
        />
      )
      : <Device />}
  </>
);
