import { Fetch } from 'lib/Fetch';

export function getInvestors() {
  return new Fetch()
    .url('/company/company-info/company-list-investors')
    .method('get')
    .userToken()
    .send();
}

export function downloadInvestors({ projectId }) {
  return new Fetch('api_2')
    .url(`/company/company-info/download-company-list-investors/${projectId}`)
    .method('get')
    .userToken()
    .send();
}
