import React, { Suspense } from 'react';
import { withPrivateRoute } from 'hoc/withPrivateRoute';
import { withRoles } from 'hoc/withRoles';
import {
  Div, colors, BoxFullHeight, NAVBAR_HEIGHT,
} from 'globalStyle';
import { useMediaQuery, useWallet, useUser } from 'hooks';
import { useLocation } from 'hooks/useLocation';
import styled from 'styled-components';
import { routes } from 'routes';
import { userType } from 'types';
import { Menu } from './components/Menu';
import MainFolder from './components/Sections/MainFolder';

const options = [
  { name: 'Perfil', id: `${routes.profile.route}` },
  { name: 'Inversiones', id: `${routes.profile.invert}` },
  { name: 'Historial', id: `${routes.profile.wallet}` },
  { name: 'Información', id: `${routes.profile.information}` },
];

const optionsCompany = [
  { name: 'Perfil', id: `${routes.profileCompany.route}` },
  { name: 'Inversionistas', id: `${routes.profileCompany.inversionists}` },
  { name: 'Historial', id: `${routes.profileCompany.transactionHistory}` },
  { name: 'Información', id: `${routes.profileCompany.information}` },
  { name: 'Emisiones', id: `${routes.profileCompany.bonds}` },
];

export const withProfile = (
  Component,
  roles,
  fallback = <div style={{ height: '100vh' }} />,
) => withRoles(
  withPrivateRoute(() => {
    const isDesktop = useMediaQuery(1023);
    const { location } = useLocation();
    const { defaultWallet } = useWallet({
      useCache: () => location
          && location.current.includes('/perfil')
          && location.previous.includes('/perfil'),
    });
    const { type } = useUser();
    const defaultOption = type === userType.USER ? options : optionsCompany;
    if (document.location.pathname !== location?.current) {
      return <div />;
    }

    return (
      <>
        {isDesktop ? (
          <Container>
            <Div width="21%" style={{ minWidth: '21%' }}>
              <Menu />
            </Div>
            <ChildTwo>
              <Div height="100%" width="100%">
                <Suspense fallback={fallback}>
                  <MainFolder items={defaultOption}>
                    <Component isDesktop={isDesktop} />
                  </MainFolder>
                </Suspense>
              </Div>
            </ChildTwo>
          </Container>
        ) : (
          <Div mt={10}>
            <Suspense fallback={fallback}>
              <BoxFullHeight mt={15} minHeight={120}>
                <Component userWallet={defaultWallet} isDesktop={isDesktop} />
              </BoxFullHeight>
            </Suspense>
          </Div>
        )}
      </>
    );
  }),
  roles,
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.background.GRAY_3};
  height: calc(100vh - ${NAVBAR_HEIGHT});
  overflow: auto;
  padding: 15px 15px 15px 20px;
  column-gap: 20px;
  overflow: hidden;
  margin-top: ${NAVBAR_HEIGHT};
`;

const ChildTwo = styled.div`
  background-color: transparent;
  padding: 0 20px 0 0;
  min-width: calc(100% - 21%);
  width: 80%;
`;
