import { Fetch } from 'lib/Fetch';

export function dropdownRegisterService({ source }) {
  return new Fetch()
    .url('/company/register/map-dropdowns')
    .method('get')
    .portalToken()
    .setCancelToken(source)
    .send();
}

export function citiesDropdownService({
  department,
}) {
  return new Fetch()
    .url('/company/register/towns')
    .method('post')
    .portalToken()
    .data({ department })
    .send();
}
