import React from 'react';
import { weight, colors } from 'globalStyle';
import Tooltip from '@material-ui/core/Tooltip';
import { SyledText } from './styled';

const getSizeTitle = (number) => {
  switch (number) {
    case 1: return 60;
    case 2: return 30;
    case 3: return 24;
    case 4: return 21;
    case 5: return 18;
    case 6: return 15;
    case 7: return 55;
    default: return null;
  }
};

const getSizeSubTitle = (number) => {
  switch (number) {
    case 1: return 21;
    case 2: return 18;
    case 3: return 16;
    case 4: return 14;
    case 5: return 12;
    case 6: return 11;
    case 7: return 25;
    default: return null;
  }
};

const getSizeText = (number) => {
  switch (number) {
    case 1: return 21;
    case 2: return 18;
    case 3: return 16;
    case 4: return 13;
    case 5: return 9;
    case 6: return 10;
    case 7: return 12;
    case 8: return 20;
    case 9: return 28;
    default: return null;
  }
};

export const Title = ({
  number, color, children, isOverflow, ...rest
}) => (
  <Tooltip
    disableHoverListener={!isOverflow}
    disableFocusListener
    disableTouchListener
    title={<span style={{ fontSize: '16px' }}>{children}</span>}
  >
    <SyledText
      size={getSizeTitle(number)}
      weight={weight.BOLD}
      color={color || colors.GRAY_1}
      isOverflow={isOverflow}
      {...rest}
    >
      {children}
    </SyledText>
  </Tooltip>

);

export const SubTitle = ({
  number, color, children, isOverflow, weightStyle, ...rest
}) => (
  <Tooltip
    disableHoverListener={!isOverflow}
    disableFocusListener
    disableTouchListener
    title={<span style={{ fontSize: '16px' }}>{children}</span>}
  >
    <SyledText
      size={getSizeSubTitle(number)}
      weight={weightStyle || weight.SEMI_BOLD}
      color={color || colors.GRAY_1}
      isOverflow={isOverflow}
      {...rest}
    >
      {children}
    </SyledText>
  </Tooltip>

);

export const Text = ({
  number, color, children, isOverflow, ...rest
}) => (
  <Tooltip
    disableHoverListener={!isOverflow}
    disableFocusListener
    disableTouchListener
    title={<span style={{ fontSize: '16px' }}>{children}</span>}
  >
    <SyledText
      weight={weight.MEDIUM}
      size={getSizeText(number)}
      color={color || colors.GRAY_1}
      isOverflow={isOverflow}
      {...rest}
    >
      {children}
    </SyledText>
  </Tooltip>
);

export const TextLight = ({
  number, color, children, isOverflow, ...rest
}) => (
  <Tooltip
    disableHoverListener={!isOverflow}
    disableFocusListener
    disableTouchListener
    title={<span style={{ fontSize: '16px' }}>{children}</span>}
  >
    <SyledText
      size={getSizeText(number)}
      color={color || colors.GRAY_1}
      isOverflow={isOverflow}
      {...rest}
    >
      {children}
    </SyledText>
  </Tooltip>
);

export const Bold = ({ children, style }) => (
  <span style={{ fontWeight: weight.BOLD, ...style }}>{children}</span>
);
