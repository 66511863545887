import React, { useState } from 'react';
import { Figure, Div } from 'globalStyle';
import logoWhite from 'assets/logo-white.svg';
import close from 'assets/icons/close-white.svg';
import { useUser } from 'hooks';
import { userType } from 'types';
import { ChangePassword } from 'Layout/components/Menu/components/ChangePassword';
import { ChangePasswordCompany } from 'Layout/components/Menu/components/ChangePasswordCompany';
import { SiderbarIsLogged } from './SiderbarIsLogged';
import { SiderbarIsNotLogged } from './SiderbarIsNotLogged';
import { SidebarContainer } from './SidebarElements';

export const Sidebar = ({ open, closeSidebar = () => {} }) => {
  const { isLogin, type } = useUser();
  const [modal, setModal] = useState(false);
  const handleModal = () => {
    setModal(!modal);
  };
  const handleChangePassword = () => {
    handleModal();
    closeSidebar();
  };
  if (modal) {
    return (
      <>
        {type === userType.USER ? (
          <ChangePassword isOpen={modal} onClick={handleModal} />
        ) : (
          <ChangePasswordCompany isOpen={modal} onClick={handleModal} />
        )}
      </>
    );
  }
  return (
    <SidebarContainer isOpen={open}>
      <Div isFlex justify="space-between" margin="0 0 30px">
        <Figure width="80px" height="30px " style={{ cursor: 'pointer' }}>
          <img src={logoWhite} alt="logo" />
        </Figure>
        <Figure
          width="80px"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            closeSidebar();
          }}
        >
          <img src={close} alt="cerrar" />
        </Figure>
      </Div>
      {isLogin ? (
        <SiderbarIsLogged closeSidebar={closeSidebar} handleChangePassword={handleChangePassword} />
      ) : (
        <SiderbarIsNotLogged closeSidebar={closeSidebar} />
      )}
    </SidebarContainer>
  );
};
