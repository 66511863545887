import React from 'react';
import { colors } from 'globalStyle';
import { useMediaQuery } from 'hooks';
import { Box, Grid } from '@material-ui/core';
import { Title, Text, Bold } from 'components/Texts';
import CustomDialog from 'components/Modals/CustomDialog';
import ChecklistIcon from 'assets/persons/error-woman.svg';
import { Hollow, hollowColorType } from 'components/Buttons';

export default function RegisterFailed({ open, onClose }) {
  const isDesktop = useMediaQuery(1023);
  return (
    <CustomDialog
      pt="10px"
      pb="45px"
      open={open}
      onClose={onClose}
      px={isDesktop ? '70px' : '30px'}
      width={isDesktop ? '615px' : '284px'}
    >
      <Grid container direction="column" alignItems="center">
        <Box
          width="172px"
          maxWidth="90%"
          component="img"
          src={ChecklistIcon}
        />
        <Title size={isDesktop ? 20 : 16} mt={34} mb={22} color={colors.primary}>
          ¡Lo sentimos!
        </Title>
        <Text size={14}>
          Se ha producido un inconveniente al intentar crear tu cuenta.
          Te recomendamos que te pongas en
          <Bold>{' contacto con nuestro servicio al cliente '}</Bold>
          para obtener asistencia y aclarar cualquier duda relacionada con el proceso.
        </Text>
        <Hollow
          mt={40}
          isSubmit
          onClick={onClose}
          width={isDesktop ? 69.5 : 100}
          onColorType={hollowColorType.primary}
        >
          Cerrar
        </Hollow>
      </Grid>
    </CustomDialog>
  );
}
