/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';

import styled from 'styled-components';
import { Title } from 'components/Texts';
import { ToolTip } from 'components/ToolTip';

import { InputOnlyNumbers } from './InputOnlyNumbers';
import { Label } from './Label';
import { Input } from './Input';

export const InputNitValidation = ({
  register,
  dirtyFields,
  required,
  errors,
  digitProps,
  errorToolTip,
  label,
  text,
  isDesktop = true,
  ...rest
}) => {
  const [currentValue, setCurrentValue] = useState('');
  return (
    <div style={{ position: 'relative' }}>
      <Label
        title={label}
        subTitle={text}
        required={required}
        {...rest}
      />
      <Grid isDesktop={isDesktop}>
        <Input
          noLabel
          type="number"
          name="document"
          errorToolTip={errorToolTip}
          placeholder="256358657"
          required={required}
          dirtyFields={dirtyFields}
          register={register}
          errors={errors}
          validate={{
            length: (value) => value.length === 9
              || 'El NIT debe tener 9 dígitos.',
          }}
          onInput={(e) => {
            const { value } = e.target;
            if (value === `${parseInt(value, 10)}` || !value) {
              setCurrentValue(value);
            } else {
              e.target.value = currentValue;
            }
          }}
          {...rest}
        />
        <Grid style={{
          gridTemplateColumns: '8px 1fr 20px',
          paddingBottom: errors[rest.name]?.message ? '26px' : '0px',
        }}
        >
          <Title size={18} isCenter mt={isDesktop ? -20 : -18}>-</Title>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <InputOnlyNumbers
              noLabel
              errors={errors}
              type="number"
              maxLength="1"
              placeholder="8"
              register={register}
              required={required}
              errorToolTip={errorToolTip}
              name="verificationDigit"
              onInput={(e) => {
                const { value } = e.target;
                if (value === `${parseInt(value, 10)}`) {
                  if (value !== value % 10) {
                    e.target.value = `${value}`.charAt(0);
                  }
                } else {
                  e.target.value = '';
                }
              }}
              {...digitProps}
            />
          </div>
          <ToolTip isCenter onClick={(event) => event.preventDefault()} mt={isDesktop ? -15 : -13}>
            El Dígito de Verificación se puede obtener desde
            la página de la DIAN y te brindará la seguridad
            necesaria para la información de tu empresa.
          </ToolTip>
        </Grid>
      </Grid>
    </div>
  );
};

const Grid = styled.div`
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: ${({ isDesktop }) => (isDesktop ? '675fr 325fr' : '575fr 325fr')};
`;
