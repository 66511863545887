import moment from 'moment';
import { useState, useEffect } from 'react';

export default function useCountdown() {
  const [timeSettings, setTimeSettings] = useState({
    interval: 1000,
    format: 'mm:ss',
  });
  useEffect(() => {
    if (timeSettings.time) {
      const timer = setInterval(() => {
        setTimeSettings(
          (currentTimeSet) => {
            const newTime = (currentTimeSet.time > 0
              ? currentTimeSet.time - currentTimeSet.interval : 0);
            return {
              ...currentTimeSet,
              time: newTime,
              formattedTime: moment.utc(newTime).format(currentTimeSet.format),
            };
          },
        );
      }, timeSettings.interval);
      return () => {
        clearInterval(timer);
      };
    }
    setTimeSettings(
      (currentTimeSet) => ({
        ...currentTimeSet,
        formattedTime: undefined,
      }),
    );
    return undefined;
  }, [timeSettings.time]);

  const setSettings = (newOptions) => {
    setTimeSettings((currentTimeSet) => ({ ...currentTimeSet, ...newOptions }));
  };

  const setTime = (newTime) => setSettings({ time: newTime });

  const cancelTimer = () => setSettings({ time: undefined });

  return {
    ...timeSettings,
    setSettings,
    cancelTimer,
    setTime,
  };
}
