/* eslint-disable no-console */
import moment from 'moment';
import 'moment/locale/es';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from 'routes/App';
import { persistor, store } from 'store';
import * as Sentry from '@sentry/react';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

if (
  process.env.REACT_APP_DEPLOY_ENVIROMENT
  && process.env.REACT_APP_DEPLOY_ENVIROMENT === 'prod'
  && process.env.NODE_ENV === 'production'
) {
  Sentry.init({
    dsn: 'https://657ad629a9d84210bac704862c698796@o4505001898409985.ingest.sentry.io/4505042186731520',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

moment.locale('es');

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals(console.log);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  },
});
