import { Fetch } from 'lib/Fetch';

export function requestExtractCertificatiónCompany({
  certification,
  statementDate,
}) {
  return new Fetch()
    .url('/company/certification/create-request')
    .method('post')
    .userToken()
    .data({
      certification,
      statementDate,
    })
    .send();
}
