/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Row, colors } from 'globalStyle';
import { Text } from 'components/Texts';
import { StyledInputRadio } from './styled';

export const InputRadio = ({
  label, required, register, internalProps = {}, ...rest
}) => (
  <div>
    <Row hCenter direction="row">
      <div>
        <StyledInputRadio
          type="radio"
          ref={register({
            required: !!required,
          })}
          {...rest}
        />
      </div>
      <Text pl={internalProps.pl || 8} color={colors.GRAY_2}>{label}</Text>
    </Row>
  </div>
);
