import { Fetch } from 'lib/Fetch';

export function getInfoWalletCompany({ source }) {
  return new Fetch()
    .url('/company/login/wallet')
    .method('get')
    .userToken()
    .setCancelToken({ source })
    .send();
}

export function getCompanyInfo({ source }) {
  return new Fetch()
    .url('/company/company-info')
    .method('get')
    .userToken()
    .setCancelToken({ source })
    .send();
}

export function updateFinancialCompany({
  source,
  income,
  otherIncome,
  otherIncomeDescription,
}) {
  return new Fetch()
    .url('/company/company-info/company-financial-update')
    .method('post')
    .userToken()
    .data({
      source,
      income,
      otherIncome,
      otherIncomeDescription,
    })
    .setCancelToken(source)
    .send();
}
export function getProjectsCompany({ source }) {
  return new Fetch()
    .url('/company/company-info/company-list-projects')
    .method('get')
    .userToken()
    .setCancelToken(source)
    .send();
}

export function updatesNews({ news }) {
  return new Fetch()
    .url('/company/company-info/updates-news')
    .method('post')
    .userToken()
    .data(news)
    .send();
}

export function voluntaryBlock() {
  return new Fetch()
    .url('/company/company-info/voluntary-block')
    .method('post')
    .userToken()
    .send();
}

export function postulateProject() {
  return new Fetch()
    .url('/company/company-info/postulate-project')
    .method('get')
    .userToken()
    .send();
}
