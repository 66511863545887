import { Box } from '@material-ui/core';
import { Title } from 'components/Texts';
import React from 'react';

export default function RegisterHeader({ title, width }) {
  return (
    <Box
      height="63px"
      bgcolor="#41B7BF"
      paddingTop="26px"
      marginTop="-20.64px"
      width={width || '812px'}
      borderRadius="0px 0px 20px 20px"
    >
      <Title size={20} isCenter color="#fff">
        {title}
      </Title>
    </Box>
  );
}
