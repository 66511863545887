/* eslint-disable no-unused-vars */
import React from 'react';
import { Redirect } from 'wouter';
import { useUser } from 'hooks/useUser';
import { routes } from 'routes';
import { userType } from 'types';
import { config } from 'config';

export const withRoles = (Component, roles = [userType.NONE]) => () => {
  const { type } = useUser();
  if (!config.dev && (!type || !roles.includes(type))) return <Redirect to={routes.home} />;
  return <Component />;
};
