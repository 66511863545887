import styled, { css } from 'styled-components';
import { colors, spaces } from 'globalStyle';
import {
  FaSistrix,
  FaBars,
  FaFacebookSquare,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaChevronLeft,
  FaChevronRight,
  FaTimes,
  FaEnvelopeOpenText,
  FaEye,
  FaEyeSlash,
  FaChevronDown,
  FaChevronUp,
  FaRegClock,
  FaWhatsapp,
  FaLinkedinIn,
  FaBell,
  FaRegQuestionCircle,
} from 'react-icons/fa';
import { FiAlertCircle, FiDownload } from 'react-icons/fi';
import { AiOutlineRise } from 'react-icons/ai';
import { IoIosArrowRoundBack, IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

import {
  BsArrowRight,
  BsCheck,
  BsDownload,
  BsCheckCircle,
  BsArrowLeft,
  BsStar,
} from 'react-icons/bs';

import { VscChromeClose } from 'react-icons/vsc';

const Props = css`
  ${spaces}
  height: ${(props) => props.height && props.height};
  width: ${(props) => props.width && props.width};
  color: ${(props) => props.color || colors.GRAY_1};

  ${(props) => props.isClick
    && css`
      cursor: pointer;
    `};

  ${(props) => props.size
    && css`
      height: ${(prop) => prop.size};
      width: ${(prop) => prop.size};
    `};
`;

export const StarIcon = styled(BsStar)`
  ${Props}
`;
export const CheckInputIcon = styled(BsCheckCircle)`
  ${Props}
`;
export const IncreaseArrow = styled(AiOutlineRise)`
  ${Props}
`;
export const SearchIcon = styled(FaSistrix)`
  ${Props}
`;
export const HamburgerIcon = styled(FaBars)`
  ${Props}
`;
export const FacebookIcon = styled(FaFacebookSquare)`
  ${Props}
`;
export const WhatsappIcon = styled(FaWhatsapp)`
  ${Props}
`;
export const InstagramIcon = styled(FaInstagram)`
  ${Props}
`;
export const LinkedinInIcon = styled(FaLinkedinIn)`
  ${Props}
`;
export const TwitterIcon = styled(FaTwitter)`
  ${Props}
`;
export const YoutubeIcon = styled(FaYoutube)`
  ${Props}
`;
export const ArrowLeft = styled(FaChevronLeft)`
  ${Props}
`;
export const ArrowRight = styled(FaChevronRight)`
  ${Props}
`;
export const ArrowUp = styled(FaChevronUp)`
  ${Props}
`;
export const ArrowDown = styled(FaChevronDown)`
  ${Props}
`;
export const CloseIcon = styled(FaTimes)`
  ${Props}
`;
export const SecondaryCloseIcon = styled(VscChromeClose)`
  ${Props}
`;
export const EmailIcon = styled(FaEnvelopeOpenText)`
  ${Props}
`;
export const ShowIcon = styled(FaEye)`
  ${Props}
`;
export const HiddenIcon = styled(FaEyeSlash)`
  ${Props}
`;
export const ClockIcon = styled(FaRegClock)`
  ${Props}
`;
export const BellIcon = styled(FaBell)`
  ${Props}
`;
export const QuestionIcon = styled(FaRegQuestionCircle)`
  ${Props}
`;
export const ExtendedArrowLeft = styled(BsArrowLeft)`
  ${Props}
`;
export const ExtendedArrowRight = styled(BsArrowRight)`
  ${Props}
`;
export const CheckIcon = styled(BsCheck)`
  ${Props}
`;
export const DownloadIcon = styled(BsDownload)`
  ${Props}
`;
export const Alert = styled(FiAlertCircle)`
  ${Props}
`;
export const Download = styled(FiDownload)`
  ${Props}
`;
export const Back = styled(IoIosArrowRoundBack)`
  ${Props}
`;
export const RightArrow = styled(IoIosArrowForward)`
  ${Props}
`;
export const LeftArrow = styled(IoIosArrowBack)`
  ${Props}
`;
