import React from 'react';
import { colors, Div, Figure } from 'globalStyle';
// import { Container, colors, Figure } from 'globalStyle';
import styled from 'styled-components';

// import Box from '@material-ui/core/Box';
import { SocialNetworks } from 'components/Networks';
import { routes } from 'routes';
import { Ghost, colorType } from 'components/Buttons';
import { Text, Title } from 'components/Texts';
import logo from 'assets/logo-white.svg';
import bTransparent from 'assets/b-transparent.svg';
import {
  LOGO_SUPERINTENDENCIA_BLANCO,
  POLITICA_TRATAMIENTO_AND_PROTECCION_DATOS_PERSONALES_BLOOM,
  TERMINOS_CONDICIONES_BLOOM,
} from 'types/fileType';
import { Box } from '@material-ui/core';
import ImageLazyLoad from 'page/components/LazyLoadingImage';

export const Desktop = () => (
  <FooterContainer>
    <Div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        height: '99%',
        overflow: 'hidden',
      }}
    >
      <img
        src={bTransparent}
        alt="logo Bloom"
        style={{
          height: '110%',
          position: 'relative',
          left: '10px',
          bottom: '18px',
          width: '100%',
        }}
      />
    </Div>
    <ContainerInfo>
      <Box width="40%">
        <Div
          isFlex
          direction="column"
          justify="space-between"
          height="100%"
          align="start"
          width="80%"
        >
          <Figure width="157px" height="36px">
            <img src={logo} alt="logo" />
          </Figure>
          <Text size={13} color={colors.WHITE} style={{ width: '40ch' }}>
            Bloom no actúa como una entidad de crédito o intermediario de
            valores, y tampoco desempeña el papel de asesor en relación con las
            inversiones.
          </Text>
          <Div width="62%">
            <SocialNetworks
              direction="row"
              color={colors.WHITE}
              content="space-between"
            />
          </Div>
        </Div>
      </Box>

      <Box style={{ width: '20%' }}>
        <Title size={18} color={colors.WHITE} mb={20}>
          Explora
        </Title>

        <Div isFlex direction="column" align="start">
          <Ghost
            href={routes.aboutUs}
            onColorType={colorType.GRAY}
            color={colors.WHITE}
            style={{ 'font-size': '13px' }}
            mb={15}
          >
            Acerca de nosotros
          </Ghost>
          <Ghost
            href={routes.frequentQuestions}
            onColorType={colorType.GRAY}
            color={colors.WHITE}
            style={{ 'font-size': '13px' }}
            mb={15}
          >
            Preguntas frecuentes
          </Ghost>

          <Ghost
            href={routes.message.team}
            onColorType={colorType.GRAY}
            color={colors.WHITE}
            style={{ 'font-size': '13px' }}
            mb={15}
          >
            Empleos
          </Ghost>
          <Ghost
            href={routes.resources}
            onColorType={colorType.GRAY}
            color={colors.WHITE}
            style={{ 'font-size': '13px' }}
            mb={15}
          >
            Productos y servicios
          </Ghost>
          <Ghost
            href={routes.correspondents}
            onColorType={colorType.GRAY}
            color={colors.WHITE}
            style={{ 'font-size': '13px' }}
            mb={15}
          >
            Nuestros corresponsales
          </Ghost>
        </Div>
      </Box>
      <Box style={{ width: '15%' }}>
        <Title size={18} color={colors.WHITE} mb={20}>
          Contacto
        </Title>
        <Div isFlex direction="column" align="start">
          <Ghost
            href={routes.message.contactUs}
            onColorType={colorType.GRAY}
            color={colors.WHITE}
            style={{ 'font-size': '13px' }}
          >
            Centro de ayuda
          </Ghost>
        </Div>
      </Box>
      <Box
        style={{ width: '25%' }}
        display="flex"
        flexDirection="column"
        alignItems="start"
        padding="0px 0 0 95px"
        zIndex={1}
      >
        <Title size={18} color={colors.WHITE} mb={20}>
          Legal
        </Title>

        <Div isFlex direction="column" align="start">
          <Ghost
            onClick={() => window.open(
              POLITICA_TRATAMIENTO_AND_PROTECCION_DATOS_PERSONALES_BLOOM,
            )}
            onColorType={colorType.GRAY}
            color={colors.WHITE}
            style={{ 'font-size': '13px' }}
            mb={15}
            isSubmit
          >
            Política de privacidad
          </Ghost>
          <Ghost
            onClick={() => window.open(
              TERMINOS_CONDICIONES_BLOOM,
            )}
            onColorType={colorType.GRAY}
            color={colors.WHITE}
            style={{ 'font-size': '13px' }}
            mb={15}
            isSubmit
          >
            Términos y condiciones
          </Ghost>
        </Div>
      </Box>
    </ContainerInfo>
    <Div
      isFlex
      width="100%"
      justify="space-between"
      height="25%"
      padding="0 60px"
    >
      <Div margin="0 134px 0 0">
        <Text size={10} color={colors.WHITE}>
          © 2023 Bloom Crowdfunding S.A. SOFICO, todos los derechos reservados
        </Text>
      </Div>

      <Div isFlex margin="0 80px 0 0" style={{ maxHeight: '100%' }}>
        <LogoSuperintendencia>
          <ImageLazyLoad
            src={LOGO_SUPERINTENDENCIA_BLANCO}
            alt="vigilado por la superintendencia financiera de colombia"
            width="100%"
          />
        </LogoSuperintendencia>
      </Div>
    </Div>
  </FooterContainer>
);

const FooterContainer = styled.div`
  width: 100%;
  min-height: 325px;
  height: 325px;
  z-index: 6;
  background: ${colors.GRAY_9};
  position: relative;
`;
const ContainerInfo = styled.div`
  border-bottom: 1px solid ${colors.WHITE};
  height: 75%;
  display: flex;
  padding: 30px 60px;
`;
const LogoSuperintendencia = styled.div`
  transform: rotate(90deg);
  width: 42px; 
  position: relative;
    left: -60px; 
`;
