const UPDATE_WALLET = 'UPDATE_WALLET';
const UPDATE_VISIBILITY = 'UPDATE_VISIBILITY';

const initialState = {
  wallet: {},
  visible: false,
};

const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_WALLET:
      return {
        ...state,
        wallet: action.payload,
      };
    case UPDATE_VISIBILITY:
      return {
        ...state,
        visible: action.payload,
      };
    default:
      return state;
  }
};

export default walletReducer;

export const updateWallet = (wallet) => ({
  type: UPDATE_WALLET,
  payload: wallet,
});

export const updateVisibility = (visible) => ({
  type: UPDATE_VISIBILITY,
  payload: visible,
});
