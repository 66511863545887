import React from 'react';
import { Div } from 'globalStyle';
import { SubTitle, Text } from 'components/Texts';

export const Label = ({
  mb,
  title,
  subTitle,
  required,
  isCenter,
  color,
  number,
}) => (
  <Div mb={mb || 10}>
    {title && (
    <SubTitle color={color} isCenter={isCenter} number={number || 4}>
      {title}
      &nbsp;
      {required && '*'}
    </SubTitle>
    )}
    {subTitle && <Text isCenter={isCenter} number={number || 4}>{subTitle}</Text>}
  </Div>
);
