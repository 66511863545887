import { Fetch } from 'lib/Fetch';

export function userRecoverDeviceSendEmailService({ email }) {
  return new Fetch()
    .url('/portal/login/validate/generate-code')
    .method('post')
    .data({ email })
    .send();
}

export function userRecoverDeviceValidateCodeService({ email, code }) {
  return new Fetch()
    .url('/portal/login/validate/validate-code')
    .method('post')
    .data({ email, code })
    .send();
}

export function userRecoverDeviceAddMacService({ token, userId, seed }) {
  return new Fetch()
    .url(`/portal/login/add-mac/${userId}/${seed}`)
    .method('post')
    .data({ token })
    .send();
}
