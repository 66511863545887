import { Fetch } from 'lib/Fetch';

function fromApiResponseToToken({ data }) {
  const { token, expirationDate } = data.sessionItem;
  return { token, expirationDate };
}

export function usePortalAuthenticate() {
  return new Fetch()
    .url('/portal/portal')
    .method('post')
    .data({
      wholesalerId: '273eba98-272a-4429-aecc-2216edec4d59',
      wholesalerName: 'bloom',
      publicKey: '69C5973C32ABFF4781B3939ABC493',
    })
    .send()
    .then(fromApiResponseToToken);
}
