/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { colors, weight, Div } from 'globalStyle';

export const TabPanel = (props) => {
  const {
    children, value, index, ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Div ptb={25}>{children}</Div>}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export const AntTabs = withStyles({
  root: {
    paddingBottom: '1.2em',
  },
  flexContainer: {
    borderBottom: `1px solid ${colors.GRAY_3}`,
  },
  indicator: {
    backgroundColor: colors.primary,
  },
})(Tabs);

export const AntTab = withStyles(() => ({
  root: {
    fontFamily: '"Montserrat", sans-serif',
    fontWeight: weight.MEDIUM,
    fontSize: (props) => props.fontSize || 21,
    textTransform: 'none',
    color: colors.GRAY_3,

    '&:hover': {
      color: colors.primary,
      borderBottom: `2px solid ${colors.primary}`,
      fontWeight: weight.MEDIUM,
    },
    '&$selected': {
      color: colors.primary,
      fontWeight: weight.BOLD,
    },
    '&:focus': {
      color: colors.primary,
      fontWeight: weight.BOLD,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...a11yProps(props.number)} {...props} />);

export const Sections = ({ children, handleChange, value }) => (
  <AntTabs
    value={value}
    onChange={handleChange}
    variant="fullWidth"
    aria-label="Tabs"
  >
    {children}
  </AntTabs>
);
