import React from 'react';
import ReactLoading from 'react-loading';
import { colors } from 'globalStyle';

export const Loading = ({ type = 'spin', size = '40px' }) => (
  <ReactLoading
    type={type}
    color={colors.primary}
    height={size}
    width={size}
  />
);
