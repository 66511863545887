import React, { useState } from 'react';
import { CloseIcon } from 'icons';
import {
  spaces, colors, Row, Div, Figure,
} from 'globalStyle';
import { useUser, useWallet } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { closePopUp, openPopUp } from 'store/popup';
import { Title, Text } from 'components/Texts';
import { Hollow, colorType } from 'components/Buttons/';
import { Recharge } from 'Layout/components/Menu/components/AvailableMoney/Recharge';
import { PayInterbank } from 'Layout/components/Menu/components/AvailableMoney/PayInterbank';
import RegisterChecker from 'Layout/components/Navbar/components/Register/components/RegisterChecker';
import arrowLeft from 'assets/icons/arrowLeftHeader.svg';
import eye from 'assets/icons/eye.svg';
import noEye from 'assets/icons/no-eye.svg';
import styled, { css } from 'styled-components';
import { formatNumber } from 'util/formatNumber';
import { useWalletCache } from 'hooks/useWalletCache';
import { Modal } from './Modal';

export const Balance = ({
  isOpen,
  width,
  height,
  isBackground,
  radius,
  onClick,
  scroll,
  children,
  zIndex,
  buttons,
  onBack,
  isBackArrow,
  addonOnClick,
  addonIcon: AddonIcon,
}) => {
  const [modal, setModal] = useState(false);
  const { visible, setValuesVisibility } = useWalletCache();
  const { user } = useUser();
  const { defaultWallet } = useWallet();
  const handelDrop = () => {
    setValuesVisibility(!visible);
  };

  const dispatch = useDispatch();
  const isOpenInterbank = useSelector((store) => store.popup.isOpen);
  const handleClose = () => dispatch(closePopUp());

  const handleInterbank = () => {
    dispatch(openPopUp({ type: 'interbank' }));
  };
  return (
    <RegisterChecker check={isOpen} onClose={onClick}>
      <Modal
        isBackground={isBackground}
        isOpen={isOpen}
        zIndex={zIndex}
        handleClick={onClick}
      >
        <Row fluid isCenter direction="column">
          <Box
            isBackground={isBackground}
            width={width}
            height={height}
            radius={radius}
            mb={20}
            style={{ position: 'relative' }}
          >
            <Content
              radius={radius}
              scroll={scroll}
              onClick={(e) => e.stopPropagation()}
            >
              <Div isFlex justify="space-between" pt={15} pb={15} pr={20} pl={20}>
                <Figure size={20} onClick={onBack} style={{ cursor: 'pointer' }}>
                  {isBackArrow ? <img src={arrowLeft} alt="arrowLeft" /> : null}
                </Figure>
                <div>
                  {AddonIcon && (
                    <AddonIcon
                      onClick={addonOnClick}
                      style={{
                        cursor: 'pointer',
                        marginRight: '15px',
                        padding: '1px',
                      }}
                      width="20px"
                      height="20px"
                    />
                  )}
                  <CloseIcon
                    onClick={onClick}
                    style={{ cursor: 'pointer' }}
                    width="20px"
                    height="20px"
                  />
                </div>
              </Div>
              <Div mr={20} ml={20}>
                {children}
              </Div>
            </Content>
          </Box>
          <Box
            isBackground={isBackground}
            width={width}
            height={height}
            radius={radius}
            mb={20}
          >
            <MoneyContainer isDrop={visible} onClick={(e) => e.stopPropagation()}>
              <TopMoney>
                <Title pl={18} size={20} width="auto" color="#fff">
                  Saldo
                </Title>
                <Div isFlex>
                  <ContentCheckBox>
                    <input
                      type="checkbox"
                      onClick={handelDrop}
                      checked={visible}
                    />
                    <i />
                  </ContentCheckBox>
                  <Img src={visible ? eye : noEye} alt="icon" />
                </Div>
              </TopMoney>
              <DownContainer isDrop={visible}>
                <TextContainer>
                  <Text size={14} color="white" width="auto">
                    Saldo en Entidad: _ _ _ _ _ _
                  </Text>
                  <Text size={14} color="white" width="auto">
                    {formatNumber(defaultWallet?.displayValue || 0)}
                  </Text>
                </TextContainer>
                <TextContainer>
                  <Text size={14} color="white" width="auto">
                    Por confirmar: _ _ _ _ _ _ _ _
                  </Text>
                  <Text size={14} color="white" width="auto">
                    {formatNumber(defaultWallet?.unconfirmedAvailableAmount || 0)}
                  </Text>
                </TextContainer>
                <TextContainer>
                  <Text size={14} color="white" width="auto">
                    Saldo Total: _ _ _ _ _ _ _ _ _ _
                  </Text>
                  <Text size={14} color="white" width="auto">
                    {formatNumber(
                      (defaultWallet?.displayValue || 0)
                      + (defaultWallet?.unconfirmedAvailableAmount || 0),
                    )}
                  </Text>
                </TextContainer>
                {!buttons && (
                  <Div width="100%" isFlex justify="space-between">
                    <Hollow
                      isSubmit
                      onColorType={colorType.BLUE}
                      onClick={() => {
                        handleInterbank();
                      }}
                      padding="5px 40px"
                      size="13px"
                    >
                      Retirar
                    </Hollow>
                    <Hollow
                      isSubmit
                      onColorType={colorType.BLUE}
                      onClick={() => setModal(!modal)}
                      padding="5px 34px"
                      size="13px"
                    >
                      Recargar
                    </Hollow>
                  </Div>
                )}
              </DownContainer>
            </MoneyContainer>
          </Box>
        </Row>
        <PayInterbank
          wallet={defaultWallet}
          isOpen={isOpenInterbank}
          userData={user?.info}
          onClick={() => handleClose()}
        />
        <Recharge
          isOpen={modal}
          userData={user?.info}
          onClick={() => {
            setModal(false);
          }}
        />
      </Modal>
    </RegisterChecker>
  );
};

const Box = styled.div`
  ${spaces}
  background-color: ${colors.WHITE};
  width: ${(props) => props.width && props.width}px;
  height: ${(props) => props.height && props.height}px;
  border-radius: ${(props) => props.radius || 20}px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  max-width: 100%;
  ${(props) => !props.isBackground
    && css`
      -webkit-box-shadow: 0px 0px 23px -3px rgba(0, 0, 0, 0.49);
      -moz-box-shadow: 0px 0px 23px -3px rgba(0, 0, 0, 0.49);
      box-shadow: 0px 0px 23px -3px rgba(0, 0, 0, 0.49);
    `}
  overflow: hidden;
`;

const MoneyContainer = styled.div`
  background-color: ${colors.primary};
  max-height: ${(props) => (props.isDrop ? '100%' : '61px')};
  padding: 18px 0;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding: 18px;
  position: relative;
`;

const DownContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 28px;
  transition: 0.1s ease-in-out;

  opacity: ${({ isDrop }) => (isDrop ? 1 : '0')};
  top: ${({ isDrop }) => (isDrop ? '0' : '-100%')};
`;
const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;
const TopMoney = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Img = styled.img`
  width: 24px;
  height: 22px;
`;
export const ContentCheckBox = styled.label`
  position: relative;
  margin-bottom: 20px;
  padding: 5px 0 5px 60px;
  display: flex;
  input {
    visibility: hidden;
    position: absolute;
    right: 0;
  }
  input + i {
    background: #e4e4e4;
    position: absolute;
    left: 15px;
    top: 6px;
  }

  input[type='checkbox'] + i {
    width: 37px;
    height: 17px;
    border-radius: 9px;
  }
  input[type='checkbox'] + i:before {
    content: '';
    width: 17px;
    height: 17px;
    background: #8cc63f;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    left: -2px;
    top: 0px;
    -webkit-box-shadow: 3px 0 3px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 3px 0 3px 0 rgba(0, 0, 0, 0.2);
  }
  input[type='checkbox']:checked + i:before {
    left: 22px;
    background: #e4e4e4;
    -webkit-box-shadow: -3px 0 3px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 3px 0 -3px 0 rgba(0, 0, 0, 0.2);
  }
  input[type='checkbox']:checked + i {
    background: #8cc63f;
  }
`;

export const Content = styled.div`
  &::-webkit-scrollbar {
    background: ${colors.GRAY_3};
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${colors.GRAY_3};
    border-radius: ${(props) => props.radius || 20}px;
  }
  &::-webkit-scrollbar-track {
    background: ${colors.WHITE};
  }
  ${(props) => props.scroll
    && css`
      height: 100%;
      border-radius: 8px;
      overflow-y: scroll;
      overflow-x: hidden;
    `}
`;
