import { Fetch } from 'lib/Fetch';

export function modifyMaximumsPerChannel({
  processingChannel,
  maxDailyTransaction,
  maxDailyAmount,
}) {
  return new Fetch()
    .url('/company/company-info/modify-maximums-per-channel')
    .method('PATCH')
    .userToken()
    .data({
      processingChannel,
      maxDailyTransaction,
      maxDailyAmount,
    })
    .send();
}
export function getMaximumsPerChannel({ processingChannel }) {
  return new Fetch()
    .url('/company/company-info/list-maximums-per-channel')
    .method('post')
    .userToken()
    .data({ processingChannel })
    .send();
}
