import React from 'react';
import { Grid } from '@material-ui/core';
import { usePhonePrefixes } from 'hooks/usePhonePrefixes';
import { Label } from './Label';
import { Select } from './Select';
import { InputPhone } from './InputPhone';

export const InputPrefixPhone = ({
  label, prefixName, phoneName, required, mt, ...props
}) => {
  const { prefixes } = usePhonePrefixes();
  const { watch } = props;
  const selectedPrefix = watch(prefixName);
  return (
    <Grid container spacing={1} style={{ rowGap: '0px', marginTop: mt }}>
      <Grid item xs={12}>
        <Label title={label} mt={mt} />
      </Grid>
      <Grid item xs={3}>
        <Select
          noLabel
          pl="0.5em"
          required={required}
          name={prefixName}
          mt={-20}
          {...props}
        >
          {Object.entries(prefixes).map((v) => (
            <option key={v[0]} value={v[0]}>
              {selectedPrefix === v[0] ? v[1].split(' ')[0] : v[1]}
            </option>
          ))}
        </Select>
      </Grid>
      <Grid item xs={9}>
        <InputPhone
          noLabel
          label=" "
          required={required}
          name={phoneName}
          mt={-20}
          {...props}
        />
      </Grid>
    </Grid>
  );
};
