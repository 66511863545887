import React from 'react';
import { ErrorText } from 'components/Form';
import { Box, Grid } from '@material-ui/core';
import MainIcon from 'assets/persons/computer-2.svg';
import CustomDialog from 'components/Modals/CustomDialog';
import { Hollow, hollowColorType } from 'components/Buttons';

export default function RegisterErrorModal({
  open, error, onClose, isDesktop,
}) {
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      pt={isDesktop ? '46px' : '26px'}
      width={isDesktop ? '615px' : '285px'}
      height={isDesktop ? '530px' : '467px'}
    >
      <Grid container direction="column" alignItems="center">
        <Box
          src={MainIcon}
          component="img"
          width={isDesktop ? '204px' : '139px'}
          height={isDesktop ? '204px' : '139px'}
          marginBottom={isDesktop ? '45px' : '56px'}
        />
        <Box minHeight="70px">
          <ErrorText size={isDesktop ? 20 : 15} mb={isDesktop ? 44 : 40} text={error} />
        </Box>
        <Box width={isDesktop ? '330px' : '220px'}>
          <Hollow
            isSubmit
            fullWidth
            onClick={onClose}
            onColorType={hollowColorType.primary}
          >
            Entendido
          </Hollow>
        </Box>
      </Grid>
    </CustomDialog>
  );
}
