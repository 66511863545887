import { useState, useEffect } from 'react';

export const useSearchParam = (param) => {
  const [search, update] = useState(window.location.search);

  useEffect(() => {
    const events = ['popstate', 'pushState', 'replaceState'];
    const handler = () => update(window.location.search);

    events.map((e) => window.addEventListener(e, handler));
    return () => events.map((e) => window.removeEventListener(e, handler));
  }, []);

  return new URLSearchParams(search).get(param) || null;
};
