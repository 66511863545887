const SUCCESS = 'SUCCESS_REGIONS';

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

export const setRegions = ({ regions = [] }) => ({
  type: SUCCESS,
  payload: regions,
});
