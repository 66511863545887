/* eslint-disable no-console */

export function useLocalStorage(key, initialValue) {
  const localValue = () => {
    const item = window.localStorage.getItem(key);

    try {
      if (item === null) {
        return initialValue;
      }

      if (typeof value === 'object') {
        return JSON.parse(item);
      }

      return item;
    } catch (e) {
      return initialValue;
    }
  };

  const setLocalStorage = (value) => {
    try {
      if (typeof value === 'object') {
        window
          .localStorage
          .setItem(key, JSON.stringify(value));
      } else {
        window
          .localStorage
          .setItem(key, value);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return { localValue, setLocalStorage };
}
