import React, { useState, useEffect } from 'react';
import { useFormProps, useStep, useListsInterbank } from 'hooks';
import { useFavoriteAccounts } from 'hooks/user/useFavoriteAccounts';
import { WithdrawMoney } from './WithdrawMoney';
import { PaySuccess } from './PaySuccess';
import Favorite from './Favorite';
import EditFavorite from './Favorite/EditFavorite';

export const PayInterbank = ({ isOpen, onClick }) => {
  const [dataFavorite, setDataFavorite] = useState(null);
  const [dataFavoriteEdit, setDataFavoriteEdit] = useState(null);
  const [step, setStep] = useState('');
  const { listBanks } = useListsInterbank();
  const {
    favoriteAccounts,
    favoriteAccountsLoading,
    fetch,
    fetchUpdate,
  } = useFavoriteAccounts();
  useEffect(() => () => {
    setStep('');
  }, []);
  useEffect(() => {
    if (step === 'favoritesAccounts') {
      fetch();
    }
  }, [step]);

  const {
    formProps,
    stateFormProps: {
      isLoading, setLoading, setError, errorMessage,
    },
  } = useFormProps({ errorToolTip: true, required: true });
  const {
    next, current, data, addData,
  } = useStep();

  const props = {
    formProps,
    isLoading,
    setLoading,
    setError,
    errorMessage,
    data,
    current,
    next,
    addData,
  };

  const handleDataFavorite = (info) => {
    setDataFavorite(info);
  };
  const handleStep = (stepGo) => {
    setStep(stepGo);
    setError('');
  };

  if (step === 'transferSuccess') {
    return (
      <PaySuccess
        isOpen={isOpen}
        handleClose={onClick}
        goToBack={() => {
          handleStep('');
        }}
        {...props}
      />
    );
  }

  if (step === 'favoritesAccounts') {
    return (
      <Favorite
        onClick={onClick}
        handleDataFavorite={handleDataFavorite}
        setDataFavoriteEdit={setDataFavoriteEdit}
        goToback={() => {
          handleStep('');
        }}
        handleClose={onClick}
        goToEdit={() => {
          handleStep('editFavorite');
        }}
        isOpen={isOpen}
        favoriteAccounts={favoriteAccounts}
        favoriteAccountsLoading={favoriteAccountsLoading}
        fetch={fetchUpdate}
        {...props}
      />
    );
  }
  if (step === 'editFavorite') {
    return (
      <EditFavorite
        defaultValue={dataFavoriteEdit}
        listBanks={listBanks}
        goToback={() => {
          handleStep('favoritesAccounts');
        }}
        handleClose={onClick}
        isOpen={isOpen}
        fetch={fetchUpdate}
        {...props}
      />
    );
  }

  return (
    <WithdrawMoney
      defaultValue={dataFavorite}
      listBanks={listBanks}
      goToFavorite={() => {
        handleStep('favoritesAccounts');
      }}
      handleClose={onClick}
      handleSuccess={() => {
        handleStep('transferSuccess');
      }}
      isOpen={isOpen}
      fetch={fetchUpdate}
      {...props}
    />
  );
};
