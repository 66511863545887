/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useFormProps, useLocalStorage } from 'hooks';
import { colors, Div } from 'globalStyle';
import { InputOnlyNumbers, InputPassword, Input } from 'components/Form';
import { Submit, Ghost } from 'components/Buttons';
import { SubTitle, Text } from 'components/Texts';
import { useLocation } from 'wouter';
import { routes } from 'routes';
import { userType } from 'types';
import { userLoginCompanyService } from 'services';
import { getNavigator } from 'util/getNavigatorBrowser';

import { useDispatch } from 'react-redux';
import { openPopUp } from 'store/popup';

export const LoginCompany = ({ login }) => {
  const dispatch = useDispatch();
  const [, setLocation] = useLocation();
  const { localValue, setLocalStorage } = useLocalStorage('seed');
  const [openOtp, setOpenOtp] = useState(false);
  const [, setBrowserId] = useState('');
  const {
    formProps,
    stateFormProps: {
      isLoading,
      setLoading,
      setError,
      errorMessage,
    },
  } = useFormProps({ errorToolTip: true });
  const onSubmit = async (form) => {
    setLoading(true);
    const otpConverted = form?.otp?.toUpperCase() || '';
    userLoginCompanyService({
      nit: form.document,
      password: form.password,
      seed: localValue() || '',
      browserId: ' ',
      otp: otpConverted,
    })
      .then(({ data }) => {
        setLoading(false);
        login({ data, userType: userType.COMPANY });
        if (data.sessionItem.information.seed) {
          setLocalStorage(data.sessionItem.information.seed);
        }
        setLocation(routes.profileCompany.route);
      }).catch(({ message, messageFormat }) => {
        setLoading(false);
        if (message === 'NEW_DEVICE_EMAIL_SENT') {
          setLocalStorage('');
          setOpenOtp(true);
          setError(true, 'Se ha enviado un OTP a su correo electrónico, por favor revísalo.');
        } else if (message === 'MAC_IS_NOT_REGISTERED_TRIES_LEFT') {
          dispatch(openPopUp({ type: 'recover-device' }));
        } else {
          setError(true, messageFormat);
        }
      });
  };
  useEffect(() => {
    setBrowserId(getNavigator());
  }, [openOtp]);
  return (
    <Div mt={30}>
      <SubTitle
        number={2}
        mb={30}
        weight="500"
      >
        Inicia sesión como
        {' '}
        <b>empresa</b>
      </SubTitle>
      <form onSubmit={formProps.handleSubmit(onSubmit)}>
        <InputOnlyNumbers
          label="NIT y Dígito de Verificación"
          name="document"
          {...formProps}
        />
        <InputPassword {...formProps} />
        {openOtp && (
          <Input
            label="Código de validación"
            name="otp"
            placeholder="Escribe aquí el código que te llegó al correo"
            {...formProps}
          />
        )}
        {errorMessage && (
          <Text number={4} color="#ff4343" mb={10}>
            {errorMessage}
          </Text>
        )}
        <Submit
          isLoading={isLoading}
          width={100}
          disabled={formProps.valid}
          mb={20}
          padding="5px"
        >
          Iniciar sesión
        </Submit>
      </form>
      <Ghost
        mb={10}
        isSubmit
        onClick={() => dispatch(openPopUp({ type: 'recover-password' }))}
        underline
        color={colors.GRAY_2}
        size={13}
      >
        ¿Has olvidado tu contraseña?
      </Ghost>
      <Text mb={30} number={4} color={colors.GRAY_2}>
        ¿No tienes una cuenta?
        {' '}
        <Ghost
          underline
          color={colors.primary}
          isSubmit
          onClick={() => dispatch(openPopUp({ type: 'register' }))}
          size={13}
        >
          Créala aquí
        </Ghost>
      </Text>
    </Div>
  );
};
