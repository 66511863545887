import React, { useMemo } from 'react';
import { colors } from 'globalStyle';
import { Text } from 'components/Texts';
import { Box, Grid } from '@material-ui/core';
import CheckIcon from 'assets/icons/check-white.svg';

export default function Stepper({
  nSteps, titles, steps = [], activeStep, variant = 'standard', isDesktop,
}) {
  const currentSteps = useMemo(() => {
    const newSteps = [];
    for (let i = 1; i <= nSteps; i += 1) {
      newSteps.push({
        name: i,
        active: i <= activeStep,
        checkIcon: i < activeStep,
        title: titles ? titles[i - 1] : undefined,
      });
    }
    steps.forEach((v) => {
      newSteps.push(v);
    });
    return newSteps;
  }, [nSteps, steps, activeStep, titles]);
  return (
    <>
      {titles && (
        <Grid container wrap="nowrap" alignItems="center">
          {currentSteps.map(({ title, active }) => (
            <Text
              mb={4}
              size={12}
              color={active ? colors.primary : colors.GRAY_11}
            >
              {title}
            </Text>
          ))}
        </Grid>
      )}
      <Grid container wrap="nowrap" alignItems="center">
        {currentSteps.map(({ name, active, checkIcon }, index, array) => (
          <>
            {variant === 'preline' && (
              <Box
                width="100%"
                height={isDesktop ? '3px' : '4px'}
                bgcolor={active ? colors.primary : colors.GRAY_10}
              />
            )}
            {variant === 'standard' && index === 0 && (
              <Box
                width="100%"
                height={isDesktop ? '3px' : '4px'}
              />
            )}
            <Box
              display="flex"
              padding="4.2px"
              borderRadius="100%"
              alignItems="center"
              alignContent="center"
              justifyContent="center"
              height={isDesktop ? '23px' : '26px'}
              minWidth={isDesktop ? '23px' : '26px'}
              bgcolor={active ? colors.primary : colors.GRAY_10}
            >
              {checkIcon
                ? (
                  <Box
                    width="11px"
                    height="11px"
                    component="img"
                    src={CheckIcon}
                    alt="Paso completado"
                  />
                )
                : (
                  <Text
                    isCenter
                    size={15}
                    color={colors.WHITE}
                  >
                    {name}
                  </Text>
                )}
            </Box>
            {variant === 'standard' && index !== array.length - 1 && (
              <Box
                width="100%"
                height={isDesktop ? '3px' : '4px'}
                bgcolor={active ? colors.primary : colors.GRAY_10}
              />
            )}
            {(index !== array.length - 1 || variant === 'preline') && (
              <Box
                width="100%"
                height={isDesktop ? '3px' : '4px'}
                bgcolor={
                  (active && index < array.length - 1 && array[index + 1].active)
                    ? colors.primary : colors.GRAY_10
                  }
              />
            )}
            {variant === 'standard' && index === array.length - 1 && (
              <Box
                width="100%"
                height={isDesktop ? '3px' : '4px'}
              />
            )}
          </>
        ))}
      </Grid>
    </>
  );
}
