import { Fetch } from 'lib/Fetch';

export function sendCodeEmailCompany() {
  return new Fetch()
    .url('/company/company-info/change-password-send-otp')
    .method('GET')
    .userToken()
    .send();
}

export function otpVerificationChangePassCompany({ otp }) {
  return new Fetch()
    .url('/company/company-info/change-password-verify-otp')
    .method('POST')
    .userToken()
    .data(otp)
    .send();
}

export function passwordChangeCompany({ changePassword }) {
  return new Fetch()
    .url('/company/company-info/change-password')
    .method('PATCH')
    .userToken()
    .data(changePassword)
    .send();
}
