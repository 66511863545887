import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMountedState } from 'react-use';
import { updateVisibility, updateWallet } from 'store/wallet';

export const useWalletCache = () => {
  const isMounted = useMountedState();
  const dispatch = useDispatch();
  const walletCache = useSelector((store) => store.wallet.wallet);
  const visible = useSelector((store) => store.wallet.visible);

  const setWalletInfo = useCallback((walletInfo) => {
    if (isMounted()) {
      dispatch(updateWallet(walletInfo));
    }
  }, []);

  const setValuesVisibility = useCallback((visibility) => {
    if (isMounted()) {
      dispatch(updateVisibility(visibility));
    }
  }, []);

  return {
    walletCache,
    visible,
    setWalletInfo,
    setValuesVisibility,
  };
};
