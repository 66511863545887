import styled, { css } from 'styled-components';
import { spaces, colors, FlexCenter } from 'globalStyle';

export const inputBox = css`
    ${spaces}
    width: 100%;
    height: ${(props) => (props.inputHeight ? props.inputHeight : '35px')};
    padding-left: ${(props) => (props.pl ? props.pl : '2em')};
    padding-right: ${(props) => (props.inputPr ? props.inputPr : '0px')};
    outline: none;
    display: block;
    border-radius: ${({ borderRadius }) => borderRadius || '5px'};
    font-size: 13px;

    color: ${(props) => (props.isError ? colors.error : props.colorIn || colors.GRAY_2)};
    ${({ background }) => background && `background: ${background}`};

    text-align: ${(props) => props.isCenter && 'center'};
    border: 1px solid ${(props) => (props.isError ? colors.error : colors.input.inactive)};

    &::placeholder {
        font-style: italic;
        color: ${colors.GRAY_3};
    }

    &:focus {
        border: 1px solid ${(props) => (props.isError ? colors.error : colors.input.active)};
    }
    
    ${({ background, optionColor, optionBackground }) => (
    background
      ? `option {
        color: ${optionColor || colors.GRAY_2};
        background: ${optionBackground || colors.WHITE};
        &:hover {
          ${optionBackground || colors.WHITE};
        }
      }`
      : '')}
`;

export const InputBox = styled.div`
    ${inputBox}
    ${FlexCenter}
    justify-content: flex-start;
    cursor: pointer;
`;

export const StyledInputProfile = styled.input`
    ${inputBox}
    &:disabled{
        background-color: transparent;
        border: none;
    }
    &:focus {
        border: 1px solid ${(props) => (props.isError ? colors.error : colors.input.active)};
    }
`;
export const StyledInput = styled.input`
    ${inputBox}
`;

export const StyledTextarea = styled.textarea`
    ${inputBox}
    padding: 1em;
    height: ${(props) => props.height}px;
`;

export const StyledCheckBox = styled.input`
    ${inputBox}
`;

export const ContentCheckBox = styled.label`
    position: relative;
    margin-bottom: 30px;
    padding:${(props) => (props.contentCheckBoxPadding || '0 0 28px 60px')};
    display: flex;
    
    input {
        visibility: hidden;
        position: absolute;
        right: 0;
    }
    input + i{
        background: #E4E4E4;
        position: absolute; 
        left: 15px;
        top: 6px;
    }

    input[type=checkbox ] + i {
        width: 37px;
        height: 17px;
        border-radius: 9px;

    }
    input[type=checkbox] + i:before {
        content: ''; 
        width: 17px;
        height: 17px;
        background: ${(props) => (props.background || colors.background.GRAY_1)};
        border-radius: 50%;
        position: absolute;
        transition: all 0.3s ease-in-out;
        z-index: 1;
        left: -2px;
        top: 0px;
        -webkit-box-shadow: 3px 0 3px 0 rgba(0,0,0,0.2);
        box-shadow: 3px 0 3px 0 rgba(0,0,0,0.2);
    }
    input[type=checkbox]:checked + i:before{
        left: 22px;
  
        background: #E4E4E4;
        -webkit-box-shadow: -3px 0 3px 0 rgba(0,0,0,0.2);
        box-shadow: 3px 0 -3px 0 rgba(0,0,0,0.2);
    }
    input[type=checkbox]:checked + i{
        background: ${(props) => (props.background || '#41B6BE')};
    }

   
`;

export const StyledInputRadio = styled.input.attrs(() => ({
  type: 'radio',
}))`
   
    -webkit-appearance: none;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    border: 1px solid ${colors.input.inactive};
    margin: 0;
    cursor: pointer;
    outline: none;

    &::before{
        content: '';
        display: block;
        width: 11px;
        height: 11px;
        margin: 20% auto;
        border-radius: 50%;
    }

    &:checked::before{
        transition: all .5s ease;
        background-color: ${colors.primary};
    }

    &:checked  {
        border: 1px solid ${colors.input.active};
    }

`;

export const StyledSelect = styled.select`
    ${inputBox}
    position: ${(props) => props.position};
    appearance: ${(props) => props.appearance};
`;
