import { useReducer, useCallback } from 'react';

const initial = {
  next: 1,
  data: {},
};

function reducer(state, action) {
  switch (action.type) {
    case 'next':
      return {
        ...state,
        next: action.payload,
      };
    case 'data':
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    default:
      throw new Error('error action');
  }
}

export const useStep = (config) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initial,
    ...config,
  });

  const next = (step) => {
    const nextStep = step || Math.floor(state.next) + 1;
    dispatch({ type: 'next', payload: nextStep });
  };

  const prev = useCallback((step) => {
    const prevStep = step || state.next - 1;

    if (prevStep >= 0) {
      dispatch({ type: 'next', payload: prevStep });
    }
  }, [state.next]);

  const addData = useCallback((data) => {
    dispatch({ type: 'data', payload: data });
  }, []);

  return {
    state,
    data: state.data,
    current: state.next,
    next,
    prev,
    addData,
  };
};
