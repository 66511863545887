import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMountedState } from 'react-use';
import { update } from 'store/location';

export const useLocation = () => {
  const isMounted = useMountedState();
  const dispatch = useDispatch();
  const location = useSelector((store) => store.location);

  const setLocation = useCallback(({ previous, current }) => {
    if (isMounted()) {
      dispatch(update({
        previous,
        current,
      }));
    }
  }, []);

  return {
    location,
    setLocation,
  };
};
