export const rejectedMessage = {
  1: 'Fondos Insuficientes',
  2: 'Cuenta Cerrada',
  3: 'Cuenta no Abierta',
  4: 'Cuenta Invalida',
  6: 'Dev Solicitud por EFO',
  7: 'Autorización Revocada',
  8: 'Orden no Pago',
  9: 'Fondos no Disponibles',
  10: 'No Existe Prenotificacion',
  12: 'Cliente Originador no Autoriza',
  13: 'Devol Solicitud Cliente Recep',
  14: 'Muerte Delegado',
  15: 'Muerte Beneficiario',
  16: 'Cuenta Inactiva o Bloqueada',
  17: 'Id. no Coincide',
  20: 'Cuenta no Habilitada',
  23: 'Dev Tran Cre Cliente Rec',
  28: 'Devolución transaccion debito por contingencia',
  29: 'Dev Tran Deb Cliente Rec',
  30: 'Cliente no habilitado para recibir depósito electrónico',
  31: 'Prenotificacion no procesada por la Entidad Receptora',
  32: 'Transaccion no procesada por el banco Cooperativo Coopcentral',
  33: 'Transaccion de depósito electrónico excede los limites',
  35: 'Tipo de cuenta errada',
  NOT_APPLY: 'No aplica',
};
