import React, { useEffect, useState, useCallback } from 'react';
import { routes } from 'routes';
import { useFormProps } from 'hooks';
import { Line, colors } from 'globalStyle';
import { openPopUp } from 'store/popup';
import { useDispatch } from 'react-redux';
import { ToolTip } from 'components/ToolTip';
import { Title, Text } from 'components/Texts';
import { BasicInput } from 'components/Form/Basic';
import { Box, Grid, styled } from '@material-ui/core';
import ModalContent from 'components/Modals/ModalContent';
import { documentsTypeUser, documentFieldType } from 'types';
import { POLITICA_TRATAMIENTO_AND_PROTECCION_DATOS_PERSONALES_BLOOM } from 'types/fileType';
import {
  Hollow, Ghost, colorType, hollowColorType,
} from 'components/Buttons';
import {
  InputEmail, InputNumber, InputPassword, Select, CheckBox, InputRadio,
} from 'components/Form';
import TabPanel from 'components/TabPanel';
import RegisterHeader from './RegisterHeader';
import RegisterVerification from './RegisterVerification';
import RegisterOTP from './RegisterOTP';

const TitleHeader = ({ isDesktop }) => {
  if (isDesktop) {
    return (
      <Title isCenter size={16} mb={20.7}>
        Queremos conocerte y crear una experiencia pensada en ti.
      </Title>
    );
  }
  return (
    <Box marginBottom="56px">
      <Title size={16} isCenter color={colors.primary}>Persona natural</Title>
      <Text size={14} mt={12} isCenter>
        Queremos conocerte y crear una experiencia pensada en ti.
      </Text>
      <Line color={colors.primary} />
    </Box>
  );
};

const MODALS = Object.freeze({
  NONE: 0,
  VERIFICATION: 1,
  OTP: 2,
});

export default function RegisterForm({
  isDesktop, onClose, onBack, onFinish,
}) {
  const dispatch = useDispatch();
  const [modalValue, setModalValue] = useState(MODALS.NONE);
  const { formProps } = useFormProps({ errorToolTip: true, required: true });
  const commonInputProps = {
    errorToolTip: false,
    errorText: true,
  };
  const [formValues, setFormValues] = React.useState({});
  const [documentInputType, setDocumentInputType] = useState();
  const updateDocumentInputType = useCallback(() => {
    setDocumentInputType(documentFieldType(formProps.getValues()?.documentType));
  }, []);
  const verify = useCallback((v) => {
    setFormValues(v);
    setModalValue(MODALS.VERIFICATION);
  }, []);
  const hideModal = () => setModalValue();
  const goBack = () => setModalValue((v) => v - 1);
  const goForward = () => setModalValue((v) => v + 1);
  useEffect(() => {
    if (formValues) {
      formProps.setValue('isQualifiedInvestor', false);
      Object.entries(formValues).forEach(([key, value]) => {
        formProps.setValue(key, value);
      });
    }
  }, []);
  return (
    <>
      <ModalContent
        pt="12.72px"
        onBack={onBack}
        onClose={onClose}
        fullSize={!isDesktop}
        px={isDesktop ? '120px' : '40px'}
        width={isDesktop ? '1012px' : '100%'}
        height={isDesktop ? '665px' : '100%'}
        borderRadius={isDesktop ? '20px' : '0px'}
        header={isDesktop && <RegisterHeader title="Inversionista" />}
      >
        <TitleHeader isDesktop={isDesktop} />
        <form onSubmit={formProps.handleSubmit(verify)}>
          <Grid container direction="column">
            <Grid container justify="space-between">
              <InputContainer>
                <BasicInput
                  required
                  name="names"
                  label="Nombres"
                  {...formProps}
                  {...commonInputProps}
                />
              </InputContainer>
              <InputContainer>
                <BasicInput
                  required
                  name="firstLastName"
                  label="Primer apellido"
                  {...formProps}
                  {...commonInputProps}
                />
              </InputContainer>
              <InputContainer>
                <BasicInput
                  required
                  name="secondLastName"
                  label="Segundo apellido"
                  {...formProps}
                  {...commonInputProps}
                />
              </InputContainer>
              <InputContainer>
                <InputEmail
                  required
                  name="email"
                  label="Correo electrónico"
                  {...formProps}
                  {...commonInputProps}
                />
              </InputContainer>
              <InputContainer>
                <Select
                  required
                  defaultValue=""
                  name="documentType"
                  label="Tipo de documento"
                  onInput={updateDocumentInputType}
                  {...formProps}
                >
                  {documentsTypeUser.map((document) => (
                    <option key={document.idType} value={document.name}>
                      {document.detail}
                    </option>
                  ))}
                </Select>
              </InputContainer>
              <InputContainer>
                {documentInputType === 'number'
                  ? (
                    <InputNumber
                      required
                      name="documentNumber"
                      label="Número de documento"
                      {...formProps}
                      {...commonInputProps}
                    />
                  )
                  : (
                    <BasicInput
                      required
                      name="documentNumber"
                      label="Número de documento"
                      {...formProps}
                      {...commonInputProps}
                    />
                  )}
              </InputContainer>
              <InputContainer>
                <InputPassword
                  required
                  name="password"
                  label="Contraseña"
                  {...formProps}
                  {...commonInputProps}
                />
              </InputContainer>
              <InputContainer>
                <InputPassword
                  required
                  noTooltip
                  name="passwordConfirm"
                  label="Confirmar contraseña"
                  validate={{
                    samePassword: (value) => value === formProps.getValues().password || 'Las contraseñas no coinciden',
                  }}
                  {...formProps}
                  {...commonInputProps}
                />
              </InputContainer>
            </Grid>
            <Grid
              container
              style={{
                marginTop: '10px',
              }}
            >
              <Text width="fit-content" size={14} mr={7} mb={10}>
                ¿Eres corredor de bolsa o inversionista calificado?
                <Box display="inline-block" style={{ verticalAlign: 'middle' }}>
                  <ToolTip
                    ml={7}
                    mr={30}
                    arrow={false}
                    placement="top-start"
                    verticalAlign="middle"
                    onClick={(event) => event.preventDefault()}
                  >
                    <Box padding="0px">
                      <Title size={14}>
                        Eres inversionista calificado si cumples
                        con al menos una de las siguientes opciones:
                      </Title>
                      <Text size={14}>
                        <ol type="1" style={{ marginLeft: '-20px' }}>
                          <li>
                            Un patrimonio igual o superior a diez mil (10.000)
                            salarios mínimos mensuales legales vigentes (SMMLV).
                          </li>
                          <li>
                            Ser titular de un portafolio de inversión en valores,
                            distintos a valores de financiación colaborativa,
                            igual o superior a cinco mil (5.000)
                            salarios mínimos mensuales legales vigentes (SMMLV).
                          </li>
                          <li>
                            Tener la certificación de profesional del mercado como
                            operador expedida por un organismo autorregulador
                            del mercado de valores.
                          </li>
                        </ol>
                      </Text>
                    </Box>
                  </ToolTip>
                </Box>
              </Text>
              <Grid container style={{ width: 'fit-content' }}>
                <InputRadio
                  label="Sí"
                  name="isQualifiedInvestor"
                  value
                  {...formProps}
                  {...commonInputProps}
                />
                <div style={{ marginRight: '32px' }} />
                <InputRadio
                  label="No"
                  name="isQualifiedInvestor"
                  value={false}
                  {...formProps}
                  {...commonInputProps}
                />
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                height: '22px',
                marginTop: '14px',
                marginLeft: '-13px',
                marginBottom: '18px',
              }}
            >
              <CheckBox
                required
                size={14}
                lineHeight="22px"
                textPlacement="right"
                name="isPolicyAccepted"
                contentCheckBoxPadding="0 0 0 60px"
                style={{ cursor: 'pointer', textAlign: 'start' }}
                {...formProps}
              >
                <span style={{ fontSize: '14px' }}>
                  {'Al seleccionar, aceptas nuestra '}
                </span>
                <Ghost
                  isSubmit
                  size={14}
                  underline
                  type="button"
                  align="start"
                  color={colors.primary}
                  onColorType={colorType.primary}
                  onClick={() => window.open(
                    POLITICA_TRATAMIENTO_AND_PROTECCION_DATOS_PERSONALES_BLOOM,
                  )}
                >
                  Política de tratamiento de datos
                </Ghost>
              </CheckBox>
            </Grid>
            {isDesktop && (
              <Text size={14} mb={10} isCenter>
                {'¿Ya tienes una cuenta? '}
                <Ghost
                  ml={5}
                  isSubmit
                  size={14}
                  underline
                  type="button"
                  align="start"
                  color={colors.primary}
                  onColorType={colorType.primary}
                  onClick={() => dispatch(openPopUp({ type: 'login' }))}
                >
                  Inicia sesión
                </Ghost>
              </Text>
            )}
          </Grid>
          <Grid container direction="column" justify="center" alignItems="center">
            <Box
              width="372px"
              maxWidth="100%"
              marginTop={isDesktop ? '0px' : '28px'}
              marginBottom={isDesktop ? '0px' : '50px'}
            >
              <Hollow
                mb={8}
                isSubmit
                isCenter
                fullWidth
                size={14}
                type="submit"
                weigth="372px"
                onColorType={hollowColorType.primary}
                disabled={
                  formProps.getValues().isQualifiedInvestor === null
                  || !formProps.getValues().isPolicyAccepted
                }
              >
                Crear cuenta
              </Hollow>
            </Box>
            {isDesktop && (
              <Ghost
                isCenter
                size={14}
                underline
                type="button"
                align="start"
                width="fit-content"
                href={routes.message.contactUs}
              >
                ¿Necesitas ayuda?
              </Ghost>
            )}
          </Grid>
        </form>
      </ModalContent>
      <TabPanel value={modalValue} index={MODALS.VERIFICATION}>
        <RegisterVerification
          open
          onBack={hideModal}
          onClose={hideModal}
          onFinish={goForward}
          isDesktop={isDesktop}
          formValues={formValues}
        />
      </TabPanel>
      <TabPanel value={modalValue} index={MODALS.OTP}>
        <RegisterOTP
          open
          onBack={goBack}
          onClose={hideModal}
          onFinish={onFinish}
          isDesktop={isDesktop}
          formValues={formValues}
        />
      </TabPanel>
    </>
  );
}

const InputContainer = styled(Grid)({
  width: '330px',
  marginTop: '-3.25px',
});
