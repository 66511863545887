import { useCallback, useReducer } from 'react';
import { useForm } from 'react-hook-form';

const initial = {
  isLoading: false,
  error: false,
  errorMessage: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'error':
      return {
        ...state,
        isLoading: false,
        error: action.payload.bool,
        errorMessage: action.payload.errorMessage,
      };
    default:
      throw new Error('error action');
  }
}

export const useFormProps = (config) => {
  const [stateFormProps, dispatch] = useReducer(reducer, initial);
  const {
    register, handleSubmit, errors, formState, control, setValue, getValues, reset, watch,
  } = useForm({ mode: 'onChange' });

  const setError = useCallback((bool, errorMessage = null) => {
    dispatch({ type: 'error', payload: { bool, errorMessage } });
  }, []);

  const setLoading = useCallback((bool) => {
    dispatch({ type: 'loading', payload: bool });
  }, []);

  return {

    stateFormProps: {
      ...stateFormProps,
      setError,
      setLoading,
    },

    formProps: {
      formState,
      register,
      reset,
      handleSubmit,
      errors,
      dirtyFields: formState.dirtyFields,
      valid: !formState.isValid,
      control,
      setValue,
      getValues,
      watch,
      ...config,
    },
  };
};
