import React from 'react';
import { colors } from 'globalStyle';
import { Grid, styled } from '@material-ui/core';
import { Text } from 'components/Texts';

export default function FinancialChatMessage({ messages, received = false, noMinWidth }) {
  if (Array.isArray(messages)) {
    return (
      <MessagesContainer>
        {messages.map((message) => (
          <ChatMessageBox received={received} noMinWidth={noMinWidth} key={message[0]}>
            <Text color="inherit" size={15}>
              {message}
            </Text>
          </ChatMessageBox>
        ))}
      </MessagesContainer>
    );
  }
  return (
    <MessagesContainer>
      <ChatMessageBox received={received}>
        {messages}
      </ChatMessageBox>
    </MessagesContainer>
  );
}

const ChatMessageBox = styled(Grid)({
  marginBottom: '5px',
  width: 'fit-content',
  padding: '10px 15px 15px 15px',
  minWidth: (props) => !props.noMinWidth && '93px',
  color: (props) => (props.received ? colors.WHITE : colors.BLACK),
  background: (props) => (props.received ? colors.primary : colors.background.GRAY_3),
  borderRadius: (props) => (props.received ? '0px 20px 20px 20px' : '20px 0px 20px 20px'),
});

const MessagesContainer = styled(Grid)({
  marginTop: '12.5px',
  marginBottom: '25px',
  justifySelf: 'flex-end',
});
