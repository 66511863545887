import { useState, useEffect, useCallback } from 'react';
import { getDropDownsForFront } from 'services';
import { useDispatch, useSelector } from 'react-redux';
import { useMountedState } from 'react-use';
import { updatebccDropdowns } from 'store/bccUserDropdowns';
import { useCancelToken } from '../useCancelToken';

export const useBccDropdownsCache = () => {
  const isMounted = useMountedState();
  const dispatch = useDispatch();
  const bccDropdownsCache = useSelector(
    (store) => store.bccDropdowns.bccDropdowns,
  );
  const setBccDropdowns = useCallback((bccDropdowns) => {
    if (isMounted()) {
      dispatch(updatebccDropdowns(bccDropdowns));
    }
  }, []);

  return {
    bccDropdownsCache,
    setBccDropdowns,
  };
};

function mapData(object) {
  return Object.keys(object).map((regularCorporateType) => ({
    name: object[regularCorporateType],
    value: regularCorporateType,
  }));
}

export const useUserDropdowns = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const { isMounted, source, logCallback } = useCancelToken();
  const { bccDropdownsCache, setBccDropdowns } = useBccDropdownsCache();

  const fetch = () => {
    if (Object.keys(bccDropdownsCache).length === 0) {
      getDropDownsForFront({ source })
        .then(({ data }) => {
          if (isMounted) {
            setLoading(false);
            const mapValue = {
              countries: mapData(data.countries),
              bccInternationalOperationType: mapData(
                data.bccInternationalOperationType,
              ),
              bccInternationalCurrencyTypes: mapData(
                data.bccInternationalCurrencyTypes,
              ),
            };
            setValue(mapValue);
            setBccDropdowns(mapValue);
          }
        })
        .catch((err) => {
          logCallback(err, () => {
            setValue(bccDropdownsCache);
            setLoading(false);
          });
        });
    }
    setLoading(true);
  };

  useEffect(() => {
    fetch();
  }, []);

  return {
    bccUserDropdowns: value || bccDropdownsCache,
    isLoadingDropdowns: loading,
  };
};
