export const companyStatusType = Object.freeze({
  PENDING_PHONE: new Set(['PENDING_PHONE_VALIDATION']),
  WAITING_IDENTITY_DOCUMENTS: new Set(['WAITING_EXTERNAL_PROVIDER']),
  LEGAL_REP_INFO: new Set(['LEGAL_REP_INFO']),
  WAITING_EXPEDITION_CITY: new Set(['PENDING_ID_EXPEDITION_CITY']),
  WAITING_IDENTITY_VALIDATION: new Set(['WAITING_VALIDATION']),
  WAITING_ACCEPT_POLICIES: new Set(['WAITING_TERMS_ACCEPTANCE', 'PENDING_ACCEPTANCE_TERMS']),
  WAITING_EXTERNAL_HELP: new Set(['WAITING_RISK_LEVEL']),
  ACTIVE: new Set(['ACTIVE']),
});

export function companyIsInStatus(statusSet = new Set(), status) {
  if (Array.isArray(statusSet)) {
    let isInStatus = false;
    statusSet.forEach((set) => {
      if (set.has(status)) {
        isInStatus = true;
      }
    });
    return isInStatus;
  }
  return statusSet.has(status);
}
