import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useMediaQuery } from 'hooks';
import { Layout } from './style';

export const Modal = ({
  children,
  isOpen,
  isBackground,
  handleClick: onClick,
  wrap,
  modalType,
  zIndex,
  isTransparent,
  top,
}) => {
  if (!isOpen) { return null; }
  const [drag, setDrag] = useState();
  const handleClick = () => {
    if (onClick) {
      if (wrap && drag) {
        setDrag(false);
      } else {
        onClick();
      }
    }
  };
  const isDesktop = useMediaQuery();
  const dragging = () => setDrag(true);
  const draggingStop = () => setDrag(false);
  return ReactDOM.createPortal(
    <Layout
      isBackground={isBackground}
      onClick={handleClick}
      zIndex={zIndex}
      onDrag={dragging}
      isTransparent={isTransparent}
      top={isDesktop ? top : '56.5px'}
    >
      {wrap ? (
        <div role="presentation" id="modal-content" onClick={(e) => e.stopPropagation()} onMouseDown={dragging} onMouseUp={draggingStop}>
          {children}
        </div>
      )
        : children }
    </Layout>,
    document.getElementById(modalType || 'modal'),
  );
};
