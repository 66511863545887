import { useState } from 'react';
import { transactionBankUser, transactionBankCompany } from 'services';
import { userType } from 'types';
import { useUser } from 'hooks/useUser';
import { useCancelToken } from './useCancelToken';

export const useTransactionHistoryBank = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState([]);
  const { isMounted, source, logCallback } = useCancelToken();
  const { type } = useUser();

  const fetch = (initialDate, finalDate) => {
    setLoading(true);
    const service = type === userType.USER
      ? transactionBankUser
      : transactionBankCompany;
    service({ source, initialDate, finalDate })
      .then(({ data }) => {
        if (isMounted) {
          const parseData = JSON.parse(data);
          if (Array.isArray(parseData)) {
            setValue(parseData);
          } else {
            setValue([parseData]);
          }
        }
      })
      .catch((err) => {
        logCallback(err, () => {
          setValue([]);
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    fetch,
    transactionHistory: value || [],
    isLoadingTransactionHistoryBank: loading,
  };
};
