import { Fetch } from 'lib/Fetch';
// import { findDocumentByID } from 'types';
// import { config } from 'config';

// function fromApiResponseToUserData({ data }) {
//   return {
//     ...data,
//     wallet: data.wallets.find((wallet) => wallet.isoName === config.isoMoney),
//     fullName: `${data.firstName} ${data.firstLastName}`,
//     documentName: findDocumentByID(data.typeDocument)?.detail,
//   };
// }

export function userDataService() {
  return new Fetch()
    .url('/user/characteristic-client/info-user')
    .method('get')
    .userToken()
    .send();
}

export function userUpdateDataService({ userData }) {
  return new Fetch()
    .url('/user/login/update-user-data')
    .method('post')
    .userToken()
    .data(userData)
    .send();
}

export function userDeleteDeviceService({ userId, seedId }) {
  return new Fetch()
    .url('/user/seed')
    .method('delete')
    .userToken()
    .data({ userId, seedId })
    .send();
}
