import { Fetch } from 'lib/Fetch';

export function getOTP() {
  return new Fetch()
    .url('/user/register/user/otp')
    .method('post')
    .userToken()
    .send();
}

export function verifyOTP(confirmInfo) {
  return new Fetch()
    .url('/user/register/user/otp/verify')
    .method('post')
    .data(confirmInfo)
    .userToken()
    .send();
}
