import React from 'react';
import {
  Figure, Row,
} from 'globalStyle';
import { Hollow } from 'components/Buttons';
import { Title } from 'components/Texts';
import imgGoal from 'assets/persons/goal.svg';

import { useDispatch } from 'react-redux';
import { openPopUp } from 'store/popup';

export const Goal = ({
  title,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <Row isCenter>
        <Figure width="200px" height="200px">
          <img src={imgGoal} alt="goal" />
        </Figure>
      </Row>
      <Title number={3} isCenter>
        {title}
        {' '}
        <br />
      </Title>
      <Row isCenter>
        <Hollow
          medium
          isSubmit
          onClick={() => dispatch(openPopUp({ type: 'login' }))}
        >
          Iniciar sesión
        </Hollow>
      </Row>
    </>
  );
};
