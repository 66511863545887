import React from 'react';
import { ErrorText } from 'components/Form';
import { Title, Text } from 'components/Texts';
import MainIcon from 'assets/persons/computer-2.svg';
import CustomDialog from 'components/Modals/CustomDialog';
import { Hollow, hollowColorType } from 'components/Buttons';
import {
  Box, Grid, ListItem, styled,
} from '@material-ui/core';

export default function RegisterVerificationModal({
  fields = [], onBack, onClose, isDesktop, open, onNext, error, title = 'Verifica tus datos',
  loading, noImage, headerTitle,
}) {
  return (
    <CustomDialog
      pt="24px"
      open={open}
      onBack={onBack}
      onClose={onClose}
      width={isDesktop ? '615px' : '284px'}
      height={isDesktop ? '530px' : '466px'}
      header={headerTitle && (
        <Title size={24} width="fit-content">
          {title}
        </Title>
      )}
    >
      <Grid container direction="column" alignItems="center">
        {isDesktop && !noImage && (
          <Box
            width="162px"
            height="162px"
            src={MainIcon}
            component="img"
          />
        )}
        {!headerTitle && (
          <Title mt={21} size={isDesktop ? 24 : 16} isCenter>
            {title}
          </Title>
        )}
        { error && (
          <ErrorText size={16} text={error} />
        )}
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          marginTop={isDesktop ? '18px' : '50px'}
          gridRowGap={isDesktop ? '0px' : '20px'}
        >
          {fields.map((field) => (
            <DataPair
              key={field.name}
              value={field.value}
              isDesktop={isDesktop}
              title={`${field.name}:`}
            />
          ))}
        </Box>
      </Grid>
      <Box
        display="flex"
        flexWrap="wrap"
        marginTop="50px"
        gridRowGap="20px"
        gridColumnGap="34px"
        justifyContent="center"
        flexDirection={isDesktop ? 'row' : 'column-reverse'}
      >
        <Box
          width={isDesktop ? '220px' : '100%'}
        >
          <Hollow
            size={14}
            isSubmit
            fullWidth
            onClick={onBack}
          >
            No, modificar
          </Hollow>
        </Box>
        <Box
          width={isDesktop ? '220px' : '100%'}
        >
          <Hollow
            size={14}
            fullWidth
            onClick={onNext}
            isLoading={loading}
            onColorType={hollowColorType.primary}
          >
            Sí, continuar
          </Hollow>
        </Box>
      </Box>
    </CustomDialog>
  );
}

export const DataPair = ({
  title, value, isDesktop, variant,
}) => {
  if (variant === 'mobileList') {
    return (
      <StyledListItem disableGutters isDesktop={isDesktop}>
        <Grid
          container
          justify="center"
          direction={!isDesktop && 'column'}
          alignItems={isDesktop ? 'baseline' : 'center'}
        >
          <Text
            size={16}
            align="start"
            mb={isDesktop ? -10 : 10}
            width={isDesktop && '50%'}
            lineHeight={isDesktop ? '35px' : '18px'}
          >
            {title}
          </Text>
          <Title
            size={16}
            align="start"
            overflowWrap="anywhere"
            width={isDesktop && '50%'}
            lineHeight={isDesktop ? '35px' : '18px'}
          >
            {value}
          </Title>
        </Grid>
      </StyledListItem>
    );
  }
  return (
    <Grid container spacing={isDesktop ? 2 : 1} justify="center" alignItems="center">
      <Grid item xs={5} md={6}>
        <Text
          size={isDesktop ? 16 : 13}
          align={isDesktop ? 'end' : 'start'}
          lineHeight={isDesktop ? '35px' : '18px'}
        >
          {title}
        </Text>
      </Grid>
      <Grid item xs={7} md={6}>
        <Title size={isDesktop ? 16 : 13} lineHeight={isDesktop ? '35px' : '18px'} align="start">
          {value}
        </Title>
      </Grid>
    </Grid>
  );
};

const StyledListItem = styled(ListItem)(({ isDesktop }) => ({
  display: 'list-item',
  paddingTop: isDesktop && '0px',
  paddingBottom: isDesktop && '0px',
}));
