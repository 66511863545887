/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { SubTitle } from 'components/Texts';
import { ToolTip } from 'components/ToolTip';

import styled from 'styled-components';
import { InputOnlyNumbers } from './InputOnlyNumbers';

export const InputNit = ({
  register,
  dirtyFields,
  required,
  errors,
  errorToolTip,
  ...rest
}) => (
  <div style={{ position: 'relative' }}>
    <Grid>
      <InputOnlyNumbers
        name="document"
        errorToolTip={errorToolTip}
        placeholder="256358657"
        required={required}
        dirtyFields={dirtyFields}
        register={register}
        errors={errors}
        {...rest}
      />
      <div>
        <div style={{ position: 'absolute', bottom: '26px' }}>
          <SubTitle number={4}>-</SubTitle>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <InputOnlyNumbers
          required={required}
          errorToolTip={errorToolTip}
          style={{ paddingLeft: '25px' }}
          placeholder="8"
          name="verificationDigit"
          register={register}
          errors={errors}
        />
      </div>
    </Grid>
    <div style={{ position: 'absolute', right: '-26px', bottom: '21px' }}>
      <ToolTip maxW={152} isCenter onClick={(event) => event.preventDefault()}>
        El Dígito de Verificación se puede obtener desde
        la página de la DIAN y te brindará la seguridad necesaria para la información de tu empresa.
      </ToolTip>
    </div>
  </div>
);

const Grid = styled.div`
    display: grid;
    grid-template-columns: 4fr 10px 1fr;
    grid-column-gap: 10px;
`;
