import React from 'react';
import { Figure, weight, Div } from 'globalStyle';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { routes } from 'routes';
import { Ghost } from 'components/Buttons';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import arrowLeft from 'assets/icons/arrowLeftHeader.svg';
import closeIcon from 'assets/icons/closeIcon.svg';

export const DialogComponent = ({
  isOpen,
  children,
  handleBack = () => {},
  handleClose = () => {},
  width = '420px',
  hasContactUs = true,
  title,
}) => {
  const isDesktop = useMediaQuery(1023);

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        style: { borderRadius: '20px', padding: isDesktop ? '30px' : '20px', minWidth: isDesktop ? '320px' : '80%' },
      }}
      maxWidth="md"
    >
      <Div isFlex justify="space-between">
        <Figure
          style={{
            width: `${isDesktop ? '25px' : '22px'}`,
            height: '25px',
            cursor: 'pointer',
          }}
          onClick={() => {
            handleBack();
          }}
        >
          <img
            src={arrowLeft}
            alt="atrás"
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </Figure>
        {title && title}

        <Figure
          style={{
            width: `${isDesktop ? '18px' : '15px'}`,
            height: '18px',
            cursor: 'pointer',
          }}
          onClick={() => {
            handleClose();
          }}
        >
          <img
            src={closeIcon}
            alt="cerrar"
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </Figure>
      </Div>

      <DialogContent
        style={{
          maxWidth: '600px',
          padding: '0',
          width: isDesktop ? width : '100%',
          overflowX: 'hidden',
        }}
        align="center"
      >
        <Div isDesktop={isDesktop}>
          {children}
          {hasContactUs && (
            <Div isCenter width="100%">
              <Ghost
                size={12}
                italic
                weight={weight.MEDIUM}
                underline
                href={routes.message.contactUs}
                mt={15}
              >
                Centro de ayuda
              </Ghost>
            </Div>
          )}
        </Div>
      </DialogContent>
    </Dialog>
  );
};
