import React, { useState, useMemo } from 'react';
import { useSendOTP } from 'services';

import RegisterVerificationModal from './modals/RegisterVerificationModal';

export default function RegisterVerification({
  formValues, onFinish, onBack, onClose, isDesktop, open,
}) {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const fields = useMemo(() => [
    {
      name: 'Correo electrónico',
      value: formValues.email,
    },
    {
      name: 'Número de documento',
      value: formValues.documentNumber,
    },
  ], [formValues]);
  const sendOTP = useMemo(() => () => {
    const { email, documentType, documentNumber } = formValues;
    setLoading(true);
    useSendOTP({
      email, documentType, documentNumber, phoneNumber: '',
    })
      .then(() => onFinish())
      .catch((v) => setError(v.messageFormat))
      .finally(() => setLoading(false));
  }, [formValues]);
  return (
    <RegisterVerificationModal
      open={open}
      error={error}
      fields={fields}
      onBack={onBack}
      onNext={sendOTP}
      onClose={onClose}
      loading={loading}
      isDesktop={isDesktop}
    />
  );
}
