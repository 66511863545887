import { useState, useCallback } from 'react';
import { listBankFavorites, listBankFavoritesCompanies } from 'services';
import { useDispatch, useSelector } from 'react-redux';
import { useMountedState } from 'react-use';
import { updateFavoriteAccounts } from 'store/favoriteAccounts';
import { useUser } from 'hooks';
import { userType } from 'types';
import { useCancelToken } from '../useCancelToken';

export const useUserFavoriteAccountsCache = () => {
  const isMounted = useMountedState();
  const dispatch = useDispatch();
  const favoriteAccounts = useSelector(
    (store) => store.favoriteAccounts.favoriteAccounts,
  );

  const setFavoriteAccounts = useCallback((favoriteAccount) => {
    if (isMounted()) {
      dispatch(updateFavoriteAccounts(favoriteAccount));
    }
  }, []);

  return {
    favoriteAccounts,
    setFavoriteAccounts,
  };
};

export const useFavoriteAccounts = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState([]);
  const { isMounted, logCallback } = useCancelToken();
  const {
    favoriteAccounts,
    setFavoriteAccounts,
  } = useUserFavoriteAccountsCache();
  const { type } = useUser();

  const forceUpdate = () => {
    setLoading(true);
    if (type === userType.USER) {
      listBankFavorites()
        .then(({ data }) => {
          if (isMounted) {
            setLoading(false);
            setFavoriteAccounts(data);
            setValue(data);
          }
        })
        .catch((err) => {
          logCallback(err, () => {
            setLoading(false);
          });
        });
    }

    if (type === userType.COMPANY) {
      listBankFavoritesCompanies()
        .then(({ data }) => {
          setLoading(false);
          setValue(data ?? []);
          setFavoriteAccounts(data ?? []);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const fetch = () => {
    if (favoriteAccounts?.length === 0) {
      forceUpdate();
    } else {
      setValue(favoriteAccounts);
      setLoading(false);
    }
  };

  return {
    favoriteAccounts: value,
    favoriteAccountsLoading: loading,
    fetch,
    fetchUpdate: forceUpdate,
  };
};
