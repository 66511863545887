import { useState } from 'react';
import { userTransactionHistory, transactionHistoryCompany } from 'services';
import { userType } from 'types';
import { useUser } from 'hooks/useUser';
import { useCancelToken } from './useCancelToken';

export const useTransactionHistory = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState([]);
  const { type } = useUser();
  const { isMounted, source, logCallback } = useCancelToken();

  const fetch = (initialDate, finalDate) => {
    const service = type === userType.USER
      ? userTransactionHistory
      : transactionHistoryCompany;
    setLoading(true);
    service({ source, initialDate, finalDate })
      .then((data) => {
        if (isMounted) {
          setLoading(false);
          setValue(data);
        }
      })
      .catch((err) => {
        logCallback(err, () => {
          setValue([]);
          setLoading(false);
        });
      });
  };

  return {
    fetch,
    transactionHistory: value || [],
    isLoadingTransactionHistory: loading,
  };
};
