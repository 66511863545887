const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
const REMOVE_NOTIFICATIONS = 'REMOVE_NOTIFICATIONS';

const initialState = {
  notifications: [],
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case REMOVE_NOTIFICATIONS:
      return {
        notifications: [],
      };

    default:
      return state;
  }
};

export default notificationsReducer;

export const updateNotifications = (notifications) => ({
  type: UPDATE_NOTIFICATIONS,
  payload: notifications,
});

export const removeNotifications = () => ({
  type: REMOVE_NOTIFICATIONS,
});
