/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Title, Text } from 'components/Texts';
import { Div } from 'globalStyle';
import { ErrorText } from './ErrorText';

const Checkbox = ({
  mb,
  required,
  label,
  name,
  isCenter,
  register,
  errors,
  children,
  errorText,
  background,
  color,
  ref,
  value,
  checked,
  className,
  ...rest
}) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox
      checked={checked}
      name={name}
      value={value}
      ref={register({
        required: required ? 'Este campo es obligatorio' : false,
      })}
      {...rest}
    />
    <Container>
      <StyledCheckbox
        checked={checked}
        {...rest}
      >
        {' '}
      </StyledCheckbox>
    </Container>
  </CheckboxContainer>
);

export const MultipleCheckBox = ({
  mb,
  required,
  label,
  name,
  isCenter,
  register,
  errors,
  children,
  errorText,
  background,
  color,
  array = [],
  title,
  sizeTitle,
  ...rest
}) => {
  const [checked, setChecked] = useState(false);
  const [currentName, setCurrentName] = useState(null);
  const handleChanged = (current) => {
    setChecked(!checked);
    setCurrentName(current);
  };
  return (
    <>
      <Title size={sizeTitle || 30} mb={10} isCenter>
        {title}
      </Title>
      <Div mb={25} {...rest}>
        {array.length > 0 && array.map((obj) => (
          <Div mb={18}>
            <label>
              <Checkbox
                type="checkbox"
                onChange={() => handleChanged(obj.name)}
                checked={currentName === obj.name}
                register={register}
                name={name}
                value={obj.value}
                required={required}
                {...rest}
              />
              <i />
              <Text pl={5} color={color} number={4}>{obj.label || children}</Text>
            </label>
            {errorText && <ErrorText text={errors[name]?.message} />}
          </Div>
        ))}
      </Div>
    </>
  );
};

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
const StyledCheckbox = styled.div`
  display: inline-block;
  margin: 2px;
  width: 13px;
  height: 13px;
  background: ${(props) => (props.checked ? '#41B6BE' : 'white')};
  border-radius: 50%;
  transition: all 150ms;
  /* border:1px solid black; */
  ${HiddenCheckbox}:focus + & {
box-shadow: 0 0 0 3px pink;
}

`;
const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding: 1px;
  padding-bottom: 6px;
  `;

const Container = styled.div`
  contain: '';
  width: 19px;
  height: 19px;
  position: relative;
  border-radius:50%;
  border:1px solid black;
  `;
