export const config = {
  dev: process.env.NODE_ENV === 'development',
  api: process.env.REACT_APP_URL_BACKEND,
  api_2: process.env.REACT_APP_URL_BACKEND_2,
  api_4: process.env.REACT_APP_URL_BACKEND_4,
  key: process.env.REACT_APP_API_KEY,
  cardPaymentKey: process.env.REACT_APP_CARD_PAYMENT_KEY,
  url: process.env.REACT_APP_URL_FRONT,
  name: process.env.REACT_APP_WHOLESALER,
  fullName: process.env.REACT_APP_WHOLESALER_FULL_NAME,
  email: process.env.REACT_APP_WHOLESALER_EMAIL,
  contactEmail: process.env.REACT_APP_WHOLESALER_EMAIL_CONTACT,
  emailHelp: process.env.REACT_APP_WHOLESALER_EMAIL_HELP,
  country: process.env.REACT_APP_COUNTRY || 'Colombia',
  locale: process.env.REACT_APP_LOCALE || 'es-CO',
  isoMoney: process.env.REACT_APP_ISO_MONEY || 'COP',
  facebook: process.env.REACT_APP_NETWORK_FACEBOOK,
  youtube: process.env.REACT_APP_NETWORK_YOUTUBE,
  linkedin: process.env.REACT_APP_NETWORK_LINKEDIN,
  instagram: process.env.REACT_APP_NETWORK_INSTAGRAM,
  metaMapFlowId: process.env.REACT_APP_METAMAP_FLOWID,
  metaMapClientId: process.env.REACT_APP_METAMAP_CLIENTID,
  whatsappNumber: process.env.REACT_APP_WHATSAPP,
};
