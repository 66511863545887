/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import {
  Div, colors, weight, Figure,
} from 'globalStyle';
import styled from 'styled-components';
import { Text, TextLight } from 'components/Texts';
import { useLocation } from 'wouter';
import { Ghost } from 'components/Buttons';
import logOut from 'assets/icons/log-out.svg';
import { useUser } from 'hooks';

const BasicFolder = ({ active, name, id }) => {
  if (active === false) {
    return (
      <Div
        isCenter
        backgroundColor={colors.background.GRAY_3}
        radius="15px 15px 0px 0px"
        border={`1px solid ${colors.input.inactive}`}
        width="190px"
        style={{ borderBottomColor: 'transparent', height: '35px' }}
        margin="5px 0 0"
      >
        <Ghost size={16} href={id} color={colors.GRAY_2} isCenter>
          {name}
        </Ghost>
      </Div>
    );
  }

  return (
    <Div>
      <Div
        backgroundColor={colors.background.WHITE}
        width="160px"
        radius="15px 15px 0px 0px"
        height="40px"
        isCenter
      >
        <Text size={16} color={colors.primary} isCenter weight={weight.BOLD}>
          {name}
        </Text>
      </Div>
    </Div>
  );
};

const MainFolder = ({ items, children }) => {
  const [active, setActive] = useState('');
  const [location] = useLocation();
  const { logout } = useUser();

  useEffect(() => {
    if (location !== active) {
      setActive(location);
    }
  }, []);

  if (items.length === 0 || !items) <></>;

  return (
    <>
      <Container>
        {items.map((item) => (
          <BasicFolder {...item} active={item.id === active} key={item.id}>
            {children}
          </BasicFolder>
        ))}
        <Div
          isFlex
          justify="flex-end"
          width="calc(100% - 640px)"

        >
          <TextLight
            size={14}
            weight={weight.SEMI_BOLD}
            color={colors.error}
            width="auto"
            onClick={() => {
              logout();
            }}
            style={{ cursor: 'pointer', marginRight: '5px' }}
          >
            Cerrar sesión
          </TextLight>
          <Figure
            style={{ cursor: 'pointer' }}
            width="24px"
            height="24px"
            onClick={() => {
              logout();
            }}
          >
            <img src={logOut} alt="cerrar sesión" />
          </Figure>
        </Div>
      </Container>
      <Div
        backgroundColor={colors.background.WHITE}
        radius="0px 15px 15px 15px"
        height="100%"
        p="25px 5px 10px 40px"
        style={{
          height: 'calc(100% - 40px)',
          overflowY: 'hidden',
        }}
      >
        <Div
          height="100%"
          p="0 35px 0 0"
          style={{
            overflowY: 'auto',
          }}
        >
          {children}
        </Div>
      </Div>
    </>
  );
};

export default MainFolder;

const Container = styled.div`
  display: flex;
  align: flex-start;
  justify-content: flex-start;
  column-gap: 15px;
`;
