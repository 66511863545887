/* eslint-disable no-lonely-if */
import React from 'react';
import { routes } from 'routes';
import { userType } from 'types';
import { useLocation } from 'wouter';
import { Bold } from 'components/Texts';
import { Modal } from 'components/Modals';
import { useUser, useMediaQuery } from 'hooks';
import { companyIsInStatus, companyStatusType } from 'types/companyStateType';

import RegisterQR from './Navbar/components/Register/components/RegisterQR';
import RegisterAcceptDocsModal from './Navbar/components/Register/components/company/modals/RegisterAcceptDocsModal';
import RegisterWaitingVerificationModal from './Navbar/components/Register/components/company/modals/RegisterWaitingVerificationModal';
import RegisterCompanySuccessful from './Navbar/components/Register/components/company/modals/RegisterCompanySuccessful';
import RegisterLegalRepresentativeModal from './Navbar/components/Register/components/company/modals/RegisterLegalRepresentativeModal';

export default function BlockingModals() {
  const isDesktop = useMediaQuery(1023);
  const { user, type, logout } = useUser();
  const [location, setLocation] = useLocation();
  if (location !== routes.message.contactUs) {
    if (user) {
      if (type === userType.COMPANY) {
        if (
          companyIsInStatus(
            [
              companyStatusType.PENDING_PHONE,
              companyStatusType.WAITING_EXPEDITION_CITY,
              companyStatusType.WAITING_IDENTITY_DOCUMENTS,
            ],
            user.info.status,
          )
        ) {
          if (isDesktop) {
            return (
              <Modal
                wrap
                isOpen
                top="0px"
                isBackground
                zIndex={1001}
              >
                <RegisterQR
                  pt="20px"
                  logout={logout}
                  type={userType.COMPANY}
                  text={(
                    <span style={{ fontSize: '15px', minWidth: '494px' }}>
                      Para continuar con el proceso de registro de la empresa
                      <Bold>{' el representante legal '}</Bold>
                      debe realizar la validación de identidad usando la cámara del celular
                      o abriendo el lector de código QR.
                    </span>
                  )}
                />
              </Modal>
            );
          }
          if (document.location.pathname !== routes.dataCompletion.idVerification) {
            setLocation(routes.dataCompletion.idVerification);
          }
        }
        if (companyIsInStatus(companyStatusType.WAITING_IDENTITY_VALIDATION, user.info.status)) {
          return (
            <RegisterWaitingVerificationModal />
          );
        }
        if (companyIsInStatus(companyStatusType.WAITING_ACCEPT_POLICIES, user.info.status)) {
          return (
            <RegisterAcceptDocsModal
              isDesktop={isDesktop}
            />
          );
        }
        if (companyIsInStatus(companyStatusType.WAITING_EXTERNAL_HELP, user.info.status)) {
          return (
            <RegisterCompanySuccessful
              isDesktop={isDesktop}
            />
          );
        }
        if (companyIsInStatus(companyStatusType.LEGAL_REP_INFO, user.info.status)) {
          return (
            <RegisterLegalRepresentativeModal
              open
              logout={logout}
              isDesktop={isDesktop}
            />
          );
        }
      } else {
        if (isDesktop && user.info?.status === 'PENDING_PHONE_VALIDATION') {
          return (
            <Modal
              wrap
              isOpen
              top="0px"
              isBackground
              zIndex={1001}
            >
              <RegisterQR
                logout={logout}
                type={userType.USER}
                title="Continua tu proceso de registro"
              />
            </Modal>
          );
        }
      }
    }
  }
  return (
    <></>
  );
}
