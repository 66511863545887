import React from 'react';
// import { Container, colors, Figure } from 'globalStyle';
import { colors, Div, Figure } from 'globalStyle';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { SocialNetworks } from 'components/Networks';
import { routes } from 'routes';
import { Ghost, colorType } from 'components/Buttons';
import { TextLight, Title, Text } from 'components/Texts';
import logo from 'assets/logo-white.svg';
import logoSuper from 'assets/logos/LogoSuperintendencia.svg';
import bTransparent from 'assets/b-transparent.svg';
import {
  POLITICA_TRATAMIENTO_AND_PROTECCION_DATOS_PERSONALES_BLOOM,
  TERMINOS_CONDICIONES_BLOOM,
} from 'types/fileType';
import ImageLazyLoad from 'page/components/LazyLoadingImage';

export const Mobile = () => (
  <>
    <FooterContainer>
      <Div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <ImageLazyLoad
          src={bTransparent}
          alt="logo Bloom"
          style={{
            position: 'relative',
            bottom: '10px',
          }}
        />
      </Div>

      <ContainerInfo>
        <Div margin="0 0 30px">
          <Figure width="130px">
            <ImageLazyLoad src={logo} alt="logo" />
          </Figure>
          <TextLight size={13} color={colors.WHITE} mt={15}>
            Bloom no actúa como una entidad de crédito o intermediario de
            valores, y tampoco desempeña el papel de asesor en relación con las
            inversiones.
          </TextLight>
        </Div>

        <Div>
          <Title size={18} color={colors.WHITE} mb={12}>
            Explora
          </Title>

          <Div isFlex direction="column" align="start">
            <Ghost
              href={routes.aboutUs}
              onColorType={colorType.GRAY}
              color={colors.WHITE}
              style={{ 'font-size': '13px' }}
              mb={10}
            >
              Acerca de nosotros
            </Ghost>
            <Ghost
              href={routes.frequentQuestions}
              onColorType={colorType.GRAY}
              color={colors.WHITE}
              style={{ 'font-size': '13px' }}
              mb={10}
            >
              Preguntas frecuentes
            </Ghost>

            <Ghost
              href={routes.message.team}
              onColorType={colorType.GRAY}
              color={colors.WHITE}
              style={{ 'font-size': '13px' }}
              mb={10}
            >
              Empleos
            </Ghost>
            <Ghost
              href={routes.resources}
              onColorType={colorType.GRAY}
              color={colors.WHITE}
              style={{ 'font-size': '13px' }}
              mb={10}
            >
              Productos y servicios
            </Ghost>
            <Ghost
              href={routes.correspondents}
              onColorType={colorType.GRAY}
              color={colors.WHITE}
              style={{ 'font-size': '13px' }}
            >
              Nuestros corresponsales
            </Ghost>
          </Div>
        </Div>

        <Box marginTop="30px">
          <Title size={18} color={colors.WHITE} mb={12}>
            Contacto
          </Title>
          <Div isFlex direction="column" align="start">
            <Ghost
              href={routes.message.contactUs}
              onColorType={colorType.GRAY}
              color={colors.WHITE}
              style={{ 'font-size': '13px' }}
            >
              Centro de ayuda
            </Ghost>
          </Div>
        </Box>

        <Box marginTop="30px">
          <Title size={18} color={colors.WHITE} mb={12}>
            Legal
          </Title>

          <Div isFlex direction="column" align="start">
            <Ghost
              onClick={() => window.open(
                POLITICA_TRATAMIENTO_AND_PROTECCION_DATOS_PERSONALES_BLOOM,
              )}
              onColorType={colorType.GRAY}
              color={colors.WHITE}
              style={{ 'font-size': '13px' }}
              mb={10}
              isSubmit
            >
              Política de privacidad
            </Ghost>
            <Ghost
              onClick={() => window.open(TERMINOS_CONDICIONES_BLOOM)}
              onColorType={colorType.GRAY}
              color={colors.WHITE}
              style={{ 'font-size': '13px' }}
              isSubmit
            >
              Términos y condiciones
            </Ghost>
          </Div>
        </Box>
      </ContainerInfo>

      <Div
        isFlex
        justify="space-evenly"
        style={{ height: '10%', padding: '0 10px' }}
      >
        <Div width="60%">
          <Figure height="30px" width="100%">
            <ImageLazyLoad
              src={logoSuper}
              alt="vigilado por la superintendencia financiera de colombia"
              width="100%"
            />
          </Figure>
        </Div>
      </Div>
    </FooterContainer>
    <Div
      style={{ height: '100px', padding: '10px 0' }}
      isFlex
      direction="column"
    >
      <SocialNetworks
        direction="row"
        color={colors.GRAY_9}
        content="space-between"
        marginTop="0"
      />
      <Text
        size={9}
        color={colors.GRAY_9}
        isCenter
        width="70%"
        style={{ height: '50%' }}
      >
        © 2023 Bloom Crowdfunding S.A. SOFICO, todos los derechos reservados
      </Text>
    </Div>
  </>
);

const FooterContainer = styled.div`
  width: 100%;
  height: 590px;
  min-height: 590px;
  background: ${colors.GRAY_9};
  position: relative;
`;

const ContainerInfo = styled.div`
  padding: 35px 50px 20px;
  height: 90%;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid ${colors.WHITE};
`;
