import React from 'react';
import {
  Step, StepIcon, Stepper, styled,
} from '@material-ui/core';
import { colors } from 'globalStyle';
import { Title, Text } from 'components/Texts';
import { Hollow, hollowColorType } from 'components/Buttons';
import { ContainerStepperSm as ContainerStepper, StyledStepLabel as StepLabel, VerticalStepper } from './StepCard';

export default function RegisterStepsMobile({
  activeStep, data = [], onNext, onClose, padding,
}) {
  return (
    <>
      <StyledStepper activeStep={activeStep} orientation="vertical" style={{ padding: '0px' }}>
        {data.map((v, i) => (
          <Step completed={activeStep > i} key={v.title}>
            <StyledStepLabel
              StepIconComponent={(props) => (
                <StepIcon
                  icon={props.icon}
                  completed={false}
                  active={props.completed}
                />
              )}
            >
              <ContainerStepperSm
                container
                padding={padding}
                direction="column"
                next={i === activeStep}
                active={i < activeStep}
                onClick={activeStep === i && onNext}
              >
                <Title isCenter size={15}>
                  <Text>{v.title}</Text>
                </Title>
                { v.items && v.items.length > 0 && (
                  <VerticalStepper
                    mt={20}
                    items={v.items}
                    isActive={i < activeStep}
                  />
                )}
              </ContainerStepperSm>
            </StyledStepLabel>
          </Step>
        ))}
      </StyledStepper>
      <Hollow
        mt={40}
        isSubmit
        fullWidth
        onClick={onNext}
        onColorType={hollowColorType.primary}
      >
        Continuar
      </Hollow>
      <Hollow
        mt={20}
        mb={40}
        isSubmit
        fullWidth
        onClick={onClose}
        onColorType={hollowColorType.WhiteBorder}
      >
        Omitir
      </Hollow>
    </>
  );
}

const StyledStepper = styled(Stepper)({
  '& .MuiStepConnector-vertical': {
    marginTop: '-50px',
    marginBottom: '-82px',
  },
  '& .MuiStepConnector-lineVertical': {
    minHeight: '150px',
  },
  '& .MuiStepConnector-completed .MuiStepConnector-lineVertical': {
    borderColor: colors.primary,
  },
});

const StyledStepLabel = styled(StepLabel)({
  '& .MuiStepIcon-root': {
    width: '26px',
    height: '26px',
    marginRight: '28px',
  },
  '& .MuiStepConnector-completed .MuiStepConnector-line': {
    borderColor: colors.WHITE,
  },
});

const ContainerStepperSm = styled(ContainerStepper)({
  width: 'auto',
  height: 'auto',
  paddingTop: '15px',
  '& .MuiStepper-root': {
    padding: (props) => props.padding && props.padding,
  },
  '& .MuiStepIcon-text': {
    fontSize: '0px',
  },
  '& .MuiStepIcon-root': {
    width: '11px',
    height: '11px',
    fill: colors.input.inactive,
  },
  '& .MuiStepLabel-iconContainer': {
    width: '20px',
  },
  '& .MuiStepConnector-vertical': {
    marginTop: '-4px',
    marginBottom: '-8px',
  },
  '& .MuiStepConnector-lineVertical': {
    minHeight: 'calc(20px + 0.5rem)',
  },
});
