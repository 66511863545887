import React from 'react';

const TabPanel = ({
  children, value, index, ...other
}) => (
  <div
    hidden={value !== index}
    {...other}
  >
    {value === index && (
    <div>
      {children}
    </div>
    )}
  </div>
);

export default TabPanel;
