/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useLocation, Route } from 'wouter';
import { Helmet } from 'react-helmet-async';
import { config } from 'config';
// import { Fetch } from 'lib/Fetch';
import { Div } from 'globalStyle';
import { useMediaQuery } from 'hooks';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import BlockingModals from './components/BlockingModals';

export const Page = ({
  component: Component,
  title,
  description,
  noFooter,
  noNavBar,
  path,
}) => {
  const [location] = useLocation();
  const isDesktop = useMediaQuery(1023);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Route
      path={path}
      component={(props) => (
        <div>
          <Helmet>
            {title ? (
              <title>
                {title}
                {' '}
                |
                {' '}
                {config.name}
              </title>
            ) : (
              <title>{config.name}</title>
            )}
            {description && <meta name="description" content={description} />}
          </Helmet>
          {!noNavBar && <Navbar />}
          <Div style={{ paddingTop: !isDesktop && '57.5px' }}>
            <Component {...props} />
          </Div>
          <BlockingModals />
          {!noFooter && <Footer />}
        </div>
      )}
    />
  );
};
