import { useState, useEffect } from 'react';
import { listBanksForUser, listBanksForCompany } from 'services';
import { useUser } from 'hooks/useUser';
import { userType } from 'types';
import { useCancelToken } from '../useCancelToken';

export const useListsInterbank = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const { type } = useUser();

  const { isMounted, logCallback } = useCancelToken();

  const service = type === userType.USER ? listBanksForUser : listBanksForCompany;

  useEffect(() => {
    setLoading(true);
    service()
      .then(({ data }) => {
        if (isMounted) {
          setLoading(false);
          setValue(data.sort());
        }
      })
      .catch((err) => {
        logCallback(err, () => {
          setLoading(false);
        });
      });
  }, []);

  return {
    listBanks: value || [],
    isLoadingLists: loading,
  };
};
