import React from 'react';
import { colors } from 'globalStyle';
import { Title, Text } from 'components/Texts';
import ModalContent from 'components/Modals/ModalContent';
import { Hollow, hollowColorType } from 'components/Buttons';
import {
  Box,
  Grid, Step, StepIcon, Stepper,
} from '@material-ui/core';

import RegisterHeader from '../RegisterHeader';
import { StepCard, StyledStepLabel } from './StepCard';
import RegisterStepsMobile from './RegisterStepsMobile';

const STEPS = Array.from(Array(3).keys());

export default function RegisterSteps({
  isDesktop, onClose, onFinish, step = 0,
}) {
  if (isDesktop) {
    return (
      <ModalContent
        width="1012px"
        height="650px"
        onClose={onClose}
        header={isDesktop && <RegisterHeader title="Persona natural" width="880px" />}
      >
        <Grid container direction="column" alignItems="center">
          <Title size={15} mt={14} mb={42} isCenter>
            Para que puedas invertir necesitamos unos datos más ¿Deseas completar esta información?
          </Title>
          <Text size={12} isCenter color={colors.GRAY_3}>
            ¿Listo para el siguiente paso?
          </Text>
          <Stepper
            style={{
              width: '680px',
              height: '40px',
              marginTop: '6px',
              marginBottom: '40px',
            }}
            activeStep={step}
          >
            {STEPS.map((v) => (
              <Step completed={step > v} key={`step-${v}`}>
                <StyledStepLabel
                  StepIconComponent={(props) => (
                    <StepIcon
                      icon={props.icon}
                      completed={false}
                      active={props.completed}
                    />
                  )}
                />
              </Step>
            ))}
          </Stepper>
          <Grid container justify="space-between" style={{ width: '784px' }}>
            <StepCard
              index={0}
              active={step}
              onFinish={onFinish}
              title="Crear cuenta"
              items={['Nombre', 'Correo', 'Documento']}
            />
            <StepCard
              index={1}
              active={step}
              onFinish={onFinish}
              title="Validación de identidad"
              items={['Teléfono', 'Foto del documento', 'Video selfie']}
            />
            <StepCard
              index={2}
              active={step}
              onFinish={onFinish}
              title="Datos adicionales"
              items={['Información de contacto', 'Información laboral', 'Información financiera']}
            />
          </Grid>
          <Grid container justify="space-between" style={{ width: '784px', marginTop: '58px' }}>
            <Box width="372px">
              <Hollow
                isSubmit
                fullWidth
                onClick={onClose}
                onColorType={hollowColorType.WhiteBorder}
              >
                Omitir
              </Hollow>
            </Box>
            <Box width="372px">
              <Hollow
                isSubmit
                fullWidth
                onClick={onFinish}
                onColorType={hollowColorType.primary}
              >
                Continuar
              </Hollow>
            </Box>
          </Grid>
        </Grid>
      </ModalContent>
    );
  }
  return (
    <ModalContent
      mx="30px"
      fullSize
      height="100%"
      borderRadius="0"
      onClose={onClose}
    >
      <Title isCenter size={16} mt={25} mb={15}>
        Completa tu perfil
      </Title>
      <Text size={14} isCenter mb={47}>
        Para poder invertir necesitamos unos datos.
        ¿Deseas completar esta información?
      </Text>
      <RegisterStepsMobile
        activeStep={step}
        onNext={onFinish}
        onClose={onClose}
        data={[
          {
            title: 'Crear una cuenta',
            items: ['Datos básicos'],
          },
          {
            title: 'Validación de identidad',
            items: ['Teléfono', 'Foto de documento', 'Video selfie'],
          },
          {
            title: 'Datos adicionales',
            items: ['Información de contacto', 'Información laboral', 'Información financiera'],
          },
        ]}
      />
    </ModalContent>
  );
}
