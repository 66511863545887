import { Fetch } from 'lib/Fetch';

export function userLoginService(data) {
  return new Fetch()
    .url('/portal/login/user')
    .method('post')
    .portalToken()
    .data(data)
    .send();
}

export function userLoginCompanyService({
  nit,
  password,
  seed,
  browserId,
  otp,
}) {
  return new Fetch()
    .url('/portal/login/company')
    .method('post')
    .portalToken()
    .data({
      nit,
      password,
      seed,
      browserId,
      otp,
    })
    .send();
  // .then(fromApiResponseToUserCompany);
}
