export const countType = [
  {
    idType: 1,
    name: 'Ahorros',
  },
  {
    idType: 2,
    name: 'Corriente',
  },
  {
    idType: 3,
    name: 'Deposito electronico',
  },
];

export const accountTypes = {
  CORRIENTE: 'Cuenta Corriente',
  AHORROS: 'Cuenta de Ahorros',
  DEPOSITO_ELECTRONICO: 'Depósito Electrónico',
};
