import { Fetch } from 'lib/Fetch';

export function changePasswordUserService({ oldPassword, password1, password2 }) {
  return new Fetch()
    .url('/user/login/change-password')
    .method('post')
    .userToken()
    .data({
      oldPassword,
      password1,
      password2,
    })
    .send();
}
