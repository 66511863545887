import React from 'react';
import { Figure, Div, colors } from 'globalStyle';
import { useMediaQuery } from 'hooks/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import arrowLeft from 'assets/icons/arrowLeftHeader.svg';
import closeIcon from 'assets/icons/closeIcon.svg';

export const ColorBackgroundTitle = ({
  isOpen,
  children,
  handleBack = () => {},
  handleClose = () => {},
  width = '600px',
  bgColor = colors.primary,
  title,
  pd,
  maxWidth = '600px',
}) => {
  const isDesktop = useMediaQuery(1023);
  const padding = pd || (isDesktop ? '20px 30px 30px' : '20px');

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        style: { borderRadius: '20px', padding },
      }}
      maxWidth="md"
    >
      <Div isFlex justify="space-between" align="flex-start">
        <Figure
          style={{
            width: `${isDesktop ? '25px' : '22px'}`,
            height: '25px',
            cursor: 'pointer',
          }}
          onClick={() => {
            handleBack();
          }}
        >
          <img
            src={arrowLeft}
            alt="atrás"
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </Figure>
        <Div
          backgroundColor={bgColor}
          isFlex
          style={{
            position: 'relative',
            top: '-20px',
            borderRadius: '0 0 20px 20px',
            width: '90%',
            height: 'auto',
            maxWidth: width,
          }}
        >
          {title}
        </Div>
        <Figure
          style={{
            width: `${isDesktop ? '18px' : '15px'}`,
            height: '18px',
            cursor: 'pointer',
          }}
          onClick={() => {
            handleClose();
          }}
        >
          <img
            src={closeIcon}
            alt="cerrar"
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </Figure>
      </Div>

      <DialogContent
        style={{
          maxWidth,
          padding: '0',
          width: isDesktop ? width : '100%',
          overflowX: 'hidden',
          margin: 'auto',
        }}
        align="center"
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};
