import React from 'react';
import { CloseIcon } from 'icons';
import {
  spaces, colors, Row, Div,
} from 'globalStyle';
// import { Title, Text } from 'components/Texts';
// import { Hollow, hollowColorType } from 'components/Buttons/Hollow';
import styled, { css } from 'styled-components';
import { Modal } from './Modal';

export const Invest = ({
  isOpen,
  width,
  height,
  isBackground,
  radius,
  onClick,
  scroll,
  children,
  zIndex,
}) => (
  <Modal wrap isBackground={isBackground} isOpen={isOpen} zIndex={zIndex}>
    <Box isBackground={isBackground} width={width} height={height} radius={radius} mb={20}>
      <Content radius={radius} scroll={scroll}>
        <Row vCenter content="flex-end" pt={15} pb={15} pr={20}>
          <CloseIcon onClick={onClick} style={{ cursor: 'pointer' }} width="20px" height="20px" />
        </Row>
        <Div mr={40} ml={40}>
          {children}
        </Div>
      </Content>
    </Box>
  </Modal>
);

const Box = styled.div`
    ${spaces}
    background-color: ${colors.WHITE};
    width: ${(props) => props.width && props.width}px;
    height: ${(props) => props.height && props.height}px;
    border-radius: ${(props) => props.radius || 20}px;
    margin-top: 20px;
    ${(props) => !props.isBackground && css`
        -webkit-box-shadow: 0px 0px 23px -3px rgba(0,0,0,0.49);
        -moz-box-shadow: 0px 0px 23px -3px rgba(0,0,0,0.49);
        box-shadow: 0px 0px 23px -3px rgba(0,0,0,0.49);
    `}
    overflow: hidden;
`;
export const Content = styled.div`
    &::-webkit-scrollbar {
        background: ${colors.GRAY_3};
        width: 8px;     
        height: 8px;  
    }
    &::-webkit-scrollbar-thumb {
        background: ${colors.GRAY_3};
        border-radius: ${(props) => props.radius || 20}px;
    }
    &::-webkit-scrollbar-track {
        background: ${colors.WHITE};
    }
    ${(props) => props.scroll && css`
        height: 100%;
        border-radius: 8px;
        overflow-y: scroll;
        overflow-x: hidden;
    `}

`;
