import React, {
  useState, useCallback, useEffect, useRef,
} from 'react';
import Stepper from 'components/Stepper';
import TabPanel from 'components/TabPanel';
import { Line, colors } from 'globalStyle';
import { Title, Text } from 'components/Texts';
import CustomDialog from 'components/Modals/CustomDialog';
import { companyStatusType, companyIsInStatus } from 'types/companyStateType';
import { getCompanyInfo, getCompanyDropDownsForFront, registerLegalRepresentative } from 'services';

import RegisterLRWait from './RegisterLRWait';
import RegisterLRLegal from './RegisterLRLegal';
import RegisterLoading from './RegisterLoading';
import RegisterHeader from '../../RegisterHeader';
import RegisterLRReference from './RegisterLRReference';
import RegisterLRFinancialInfo from './RegisterLRFinancialInfo';
import useUpdateUserStatus from '../../../util/useUpdateUserStatus';

const STEP_NAMES = Object.freeze([
  'Información financiera',
  'Información legal',
  'Referencia personal',
]);

export default function RegisterLegalRepresentativeModal({
  open, isDesktop, onClose, logout,
}) {
  const modalRef = useRef();
  const [step, setStep] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();
  const [companyInfo, setCompanyInfo] = useState();
  const { updateUserStatus } = useUpdateUserStatus();
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [dropdownsInfo, setDropdownsInfo] = useState();
  const [showWaitModal, setShowWaitModal] = useState(false);
  const next = useCallback(() => setStep((prevStep) => prevStep + 1), []);
  const back = useCallback(() => setStep((prevStep) => prevStep - 1), []);
  const onInfoCompletedValidation = useCallback((completed) => {
    if (isFirstTime) {
      if (completed) {
        next();
      } else {
        setIsFirstTime(false);
      }
    }
  }, [isFirstTime]);
  useEffect(() => {
    getCompanyInfo({})
      .then(({ data }) => {
        if (companyIsInStatus(companyStatusType.LEGAL_REP_INFO, data.status)) {
          setCompanyInfo(data);
          setStep(0);
        } else {
          updateUserStatus()
            .then(() => {
              document.location.reload();
            });
        }
      });
  }, []);
  useEffect(() => {
    getCompanyDropDownsForFront()
      .then(({ data }) => {
        setDropdownsInfo(data);
      });
  }, []);
  const sendData = useCallback((data) => {
    setError();
    setIsLoading(true);
    registerLegalRepresentative(data)
      .then(() => {
        getCompanyInfo({})
          .then(({ data: companyData }) => {
            next();
            setCompanyInfo(companyData);
            modalRef?.current?.scroll(0, 0);
          })
          .catch(({ messageFormat }) => {
            setError(messageFormat);
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch(({ message, messageFormat }) => {
        setIsLoading(false);
        if (message === 'CONTACT_SUPPORT') {
          setShowWaitModal(true);
        } else {
          setError(messageFormat);
        }
      });
  }, []);
  const sendLastData = useCallback((data) => {
    setIsLoading(true);
    registerLegalRepresentative(data)
      .then(() => {
        updateUserStatus()
          .then(() => {
            document.location.reload();
          });
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);
  if (showWaitModal) {
    return (
      <RegisterLRWait
        open
        onClose={logout}
        isDesktop={isDesktop}
      />
    );
  }
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      onBack={step && back}
      containerRef={modalRef}
      fullSizeNavBar={!isDesktop}
      width={isDesktop && '1012px'}
      pt={isDesktop ? '10px' : '25px'}
      px={isDesktop ? '100px' : '30px'}
      height={isDesktop ? '660px' : '466px'}
      header={isDesktop && (
        <RegisterHeader
          width="812px"
          title="Información del representante legal"
        />
      )}
    >
      {!isDesktop && (
        <>
          <Title size={16} color={colors.primary}>
            Completa tu perfil
          </Title>
          <Text size={15} mt={9} mb={32}>
            Información del Representante Legal
          </Text>
        </>
      )}
      {isDesktop && (
        <Text size={13} mb={13}>
          Para completar el registro del representante legal,
          necesitamos cierta información adicional
        </Text>
      )}
      <Stepper
        nSteps={3}
        isDesktop={isDesktop}
        activeStep={step + 1}
        titles={isDesktop && STEP_NAMES}
        variant={isDesktop ? 'standard' : 'preline'}
      />
      <Title
        mt={isDesktop ? 18 : 30}
        size={isDesktop ? 16 : 15}
      >
        {STEP_NAMES[step]}
      </Title>
      {isDesktop && (
        <Line width="100%" color={colors.GRAY_10} height="10px" bottom="30px" />
      )}
      <TabPanel value={step} index={undefined}>
        <RegisterLoading
          logout={logout}
          isDesktop={isDesktop}
        />
      </TabPanel>
      <TabPanel value={step} index={0}>
        <RegisterLRFinancialInfo
          onNext={next}
          error={error}
          logout={logout}
          info={companyInfo}
          sendData={sendData}
          isDesktop={isDesktop}
          isLoading={isLoading}
          dropdownsInfo={dropdownsInfo}
          onInfoCompletedValidation={onInfoCompletedValidation}
        />
      </TabPanel>
      <TabPanel value={step} index={1}>
        <RegisterLRLegal
          onNext={next}
          error={error}
          logout={logout}
          info={companyInfo}
          sendData={sendData}
          isDesktop={isDesktop}
          isLoading={isLoading}
          dropdownsInfo={dropdownsInfo}
          onInfoCompletedValidation={onInfoCompletedValidation}
        />
      </TabPanel>
      <TabPanel value={step} index={2}>
        <RegisterLRReference
          onNext={next}
          error={error}
          logout={logout}
          info={companyInfo}
          isDesktop={isDesktop}
          isLoading={isLoading}
          sendData={sendLastData}
          dropdownsInfo={dropdownsInfo}
          onInfoCompletedValidation={onInfoCompletedValidation}
        />
      </TabPanel>
    </CustomDialog>
  );
}
