import { Fetch } from 'lib/Fetch';

export function listConditions({ projectId }) {
  return new Fetch('api_2')
    .url(`/external/global/project-condition/${projectId}`)
    .method('get')
    .userToken()
    .data()
    .send();
}

export function listRisks({ projectId }) {
  return new Fetch('api_2')
    .url(`/external/global/project-risk/${projectId}`)
    .method('get')
    .userToken()
    .data()
    .send();
}
