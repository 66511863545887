import { Fetch } from 'lib/Fetch';

export function getDropDownsForFront() {
  return new Fetch()
    .url('/user/login/user-dropdowns')
    .method('get')
    .userToken()
    .send();
}

export function getCompanyDropDownsForFront() {
  return new Fetch()
    .url('/portal/register/company/map-dropdowns')
    .method('get')
    .portalToken()
    .send();
}
