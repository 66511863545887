import { config } from 'config';

export function numberFormatReturn(number, digits, locale) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: config.isoMoney,
    maximumFractionDigits: digits,
  }).format(number);
}

export function formatNumber(number, fractionDigits = 0) {
  const numberString = number?.toString();
  return numberFormatReturn(numberString, fractionDigits);
}

export function formatBankNumber(number) {
  const decimal = number?.slice(-2);
  const integer = number?.slice(0, -2);
  const value = `${integer}.${decimal}`;
  return formatNumber(value);
}
