/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  colors,
  Container,
} from 'globalStyle';
import styled from 'styled-components';
import { Loading } from 'components/Loading';
import { basicButton, anatomy } from './styled';

export const Submit = ({
  disabled,
  isLoading,
  children,
  background,
  color,
  ...rest
}) => {
  if (isLoading) {
    return (
      <Container isCenter>
        <Loading />
      </Container>
    );
  }
  return (
    <Button
      disabled={disabled}
      background={background}
      color={color}
      {...rest}
    >
      {children}
    </Button>
  );
};

const Button = styled.button`

    
    border: none;

    background-color: ${(props) => (props.background ? props.background : colors.primary)};
    padding: 1em 1.813em;
    border-radius: 1.734em;
    
    color: ${(props) => (props.color ? props.color : colors.WHITE)};

    &:disabled  {
        background-color: ${colors.input.inactive};
        color: ${colors.GRAY_1};
        cursor: default;
    }

    &:hover:not([disabled]){
      background-color: ${colors.BLUE};
    }

    
    ${basicButton}
    ${anatomy}
`;
