export function capitalizeFirstLetter(obj) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (typeof value === 'string' && value.length > 0) {
        const newValue = value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
        return [key, newValue];
      }
      return [key, value];
    }),
  );
}

export function capitalizeFirstLetterString(string) {
  if (!string || string === '') return string;

  const first = string?.charAt(0).toUpperCase();
  const second = string?.slice(1).toLowerCase();
  return first + second;
}

export function capitalizeFirstLetterPhrase(phrase) {
  const words = phrase.split(' ');
  const capitalizedWords = words.map((word) => {
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1).toLowerCase();
    return firstLetter + restOfWord;
  });
  const capitalizedPhrase = capitalizedWords.join(' ');
  return capitalizedPhrase;
}
