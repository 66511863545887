import React from 'react';
import { colors } from 'globalStyle';
import { Box } from '@material-ui/core';
import Container from 'components/Container';
import { Title, Text } from 'components/Texts';
import CustomDialog from 'components/Modals/CustomDialog';
import MainImage from 'assets/persons/filling-out-form.svg';
import { Hollow, hollowColorType } from 'components/Buttons';

export default function WaitQualifiedInvestor({ open = true, onClose, isDesktop }) {
  return (
    <CustomDialog
      open={open}
      width="615px"
      onBack={onClose}
      onClose={onClose}
      color={colors.primary}
      px={isDesktop && '63px'}
      height={isDesktop && '530px'}
    >
      <Container>
        <Box
          width="183px"
          height="163px"
          src={MainImage}
          component="img"
          marginTop="35px"
          marginBottom="19px"
        />
        <Title size={isDesktop ? 24 : 20} color={colors.primary}>
          Validando tu información
        </Title>
        <Text size={isDesktop ? 16 : 14} mt={20} mb={43}>
          Uno de nuestros agentes se comunicará contigo para completar el proceso de registro,
          una vez completado tu perfil,
          podrás acceder a todos los proyectos e invertir en el que desees.
        </Text>
        <Box
          width={isDesktop ? '330px' : '90%'}
          marginBottom={!isDesktop && '45px'}
        >
          <Hollow
            isSubmit
            fullWidth
            onClick={onClose}
            padding={isDesktop && '12px'}
            onColorType={hollowColorType.primary}
          >
            Explorar proyectos
          </Hollow>
        </Box>
      </Container>
    </CustomDialog>
  );
}
