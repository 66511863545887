import { Fetch } from 'lib/Fetch';

function fromApiResponseToCategories({ data }) {
  return data.map((obj) => ({
    ...obj,
  }));
}

export function categorieService() {
  return new Fetch()
    .url('/portal/categories')
    .method('get')
    .portalToken()
    .send()
    .then(fromApiResponseToCategories);
}

// function fromApiResponseToData({ data }) {
//   let imagen = data.image.replace('data:image/png;', '');
//   const extension = data.extension.replace('.', '');
//   imagen = `data:image/${extension};${imagen}`;
//   return imagen;
// }

export function categorieImage(imageId) {
  return new Fetch()
    .url(`/portal/categories/image/${imageId}`)
    .method('get')
    .portalToken()
    .setCancelToken()
    .send();
}
