/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  sendCodeEmailCompany,
  otpVerificationChangePassCompany,
} from 'services';
import { Text, TextLight } from 'components/Texts';
import { ErrorText, CodeInput } from 'components/Form';
import { Hollow } from 'components/Buttons';
import { DialogComponent } from 'components/Modals';
import { colors, weight } from 'globalStyle';
import { useMediaQuery } from 'hooks';

export const Code = ({
  isOpen,
  handleOtp,
  onClick,
  setError,
  setLoading,
  isLoading,
  handleTabs,
  formProps,
}) => {
  const [code, setCode] = useState('');
  const isDesktop = useMediaQuery(1023);

  const handleCode = () => {
    setLoading(true);
    sendCodeEmailCompany()
      .then(() => {
        setLoading(false);
      })
      .catch(({ messageFormat }) => {
        setLoading(false);
        setError(messageFormat);
      });
  };
  console.log(setError);
  useEffect(() => {
    handleCode();
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    otpVerificationChangePassCompany({ otp: code })
      .then(() => {
        setLoading(false);
        handleOtp(code);
        handleTabs();
      })
      .catch(({ messageFormat }) => {
        setLoading(false);
        setError(messageFormat);
      });
  };
  return (
    <DialogComponent isOpen={isOpen} handleClose={onClick} handleBack={onClick}>
      <TextLight size={18} mb={30} color={colors.primary} weight={weight.BOLD}>
        Ingresa código de validación
      </TextLight>
      <TextLight size={14} mb={30}>
        Hemos enviado un código de seguridad al correo electrónico de la
        empresa.
      </TextLight>
      <form onSubmit={formProps.handleSubmit(handleSubmit)}>
        <CodeInput fields={6} value={code} onChange={(e) => setCode(e)} />
        <Text
          size={10}
          mb={40}
          isCenter
          fontStyle="italic"
          weight={700}
          style={{ cursor: 'pointer' }}
          onClick={() => handleCode()}
        >
          Reenviar código
        </Text>

        <Hollow
          size="14"
          isSubmit
          isLoading={isLoading}
          disabled={code.length !== 6}
          style={{
            width: `${isDesktop ? '330px' : '100%'}`,
            margin: '0 0 10px',
            padding: '10px 0',
            height: '45px',
          }}
          onColorType="primary"
        >
          Continuar
        </Hollow>
      </form>
    </DialogComponent>
  );
};
