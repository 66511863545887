import React from 'react';
import { Figure, Div } from 'globalStyle';
import { Title, SubTitle, Text } from 'components/Texts';
import { Button } from 'components/Buttons';
import logo from 'assets/logo.svg';
import connecting from 'assets/persons/connecting-plug.svg';
import { Link } from 'wouter';

export const Error = () => (
  <div>
    <Div isCenter mt={20}>
      <Link href="/">
        <Figure height="54px" width="178px">
          <img src={logo} alt="logo" />
        </Figure>
      </Link>
    </Div>
    <Div isCenter>
      <div>
        <Title number={1}>Lo sentimos,</Title>
        <SubTitle number={1} mb={30}>no logramos encontrar la página que necesitas</SubTitle>
        <Div isCenter>
          <Figure height="314px" width="467px">
            <img src={connecting} alt="persons" />
          </Figure>
        </Div>
        <Text number={1} mt={55} mb={50}>La página que estas buscando no esta disponible</Text>
        <Div isCenter mb={50}>
          <Button>ir a página principal</Button>
        </Div>
      </div>
    </Div>
  </div>
);
