import React from 'react';
import { colors } from 'globalStyle';
import { Text } from 'components/Texts';
import { Box } from '@material-ui/core';
import ErrorIcon from 'assets/icons/warning-error.svg';

export const ErrorText = ({
  isCenter, text = null, showIcon, mb, mt, ...props
}) => {
  if (!text) return null;
  return (
    <Text
      isCenter={isCenter}
      mt={mt || 5}
      mb={mb || 5}
      number={4}
      color={colors.error}
      {...props}
    >
      {showIcon && (
        <Box
          alt=""
          height="1.25ch"
          src={ErrorIcon}
          component="img"
          marginRight="8px"
        />
      )}
      {text}
    </Text>
  );
};
