import React from 'react';
import { colors } from 'globalStyle';
import { Box, Grid, styled } from '@material-ui/core';
import NoIcon from 'assets/icons/error-red.svg';
import YesIcon from 'assets/icons/check-green.png';
import { Text } from 'components/Texts';

export default function BooleanChat({ update, trueOptions = {}, falseOptions = {} }) {
  const deny = () => update(false);
  const confirm = () => update(true);
  return (
    <RootContainer container justify="space-between">
      <ItemContainer item onClick={confirm}>
        <Text isCenter size={14}>
          <Box
            alt=""
            width="20px"
            src={YesIcon}
            component="img"
            marginRight="6px"
          />
          {trueOptions.text || 'Sí'}
        </Text>
      </ItemContainer>
      <ItemContainer item onClick={deny}>
        <Text isCenter size={14}>
          <Box
            alt=""
            width="20px"
            src={NoIcon}
            component="img"
            marginRight="6px"
          />
          {falseOptions.text || 'No'}
        </Text>
      </ItemContainer>
    </RootContainer>
  );
}

const RootContainer = styled(Grid)({
  marginTop: '5px',
  marginBottom: '25px',
});

const ItemContainer = styled(Grid)({
  width: '46.6%',
  cursor: 'pointer',
  paddingTop: '13.5px',
  borderRadius: '12px',
  paddingBottom: '13.5px',
  border: `1px solid ${colors.primary}`,
});
