import { useMemo } from 'react';
import { projectStateType } from 'types';
import { useCategories } from './useCategories';
import { useProjects } from './useProjects';

function getMapCategories(categories, projects) {
  return categories.map((category) => {
    const data = projects.filter((project) => project.category === category.categoryName);
    return {
      name: category.categoryName,
      projects: data,
    };
  });
}

function categoriesWithProjects(categories, projects) {
  if (projects.length <= 0) return [];
  const mapCategories = getMapCategories(categories, projects);
  return mapCategories
    .filter((category) => category.projects.length >= 1);
}

export const useCategoriesWithOpenProjects = () => {
  const { isLoadingCategories, categories } = useCategories();
  const {
    isLoadingProjects,
    projectsBanner, projects,
  } = useProjects({ type: projectStateType.OPEN });

  const memoizedValue = useMemo(
    () => categoriesWithProjects(categories, projects),
    [categories.length, projects.length],
  );

  console.log(memoizedValue);

  return {
    isLoadingCategories,
    isLoadingProjects,
    projectsBanner,
    categoriesWithOpenProjects: memoizedValue,
  };
};
