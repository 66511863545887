import { Fetch } from 'lib/Fetch';

export function transactionBankUser({
  initialDate,
  finalDate,
}) {
  return new Fetch()
    .url('/user/financial/bcc-transaction-history-user-front')
    .method('post')
    .userToken()
    .data({
      initialDate,
      finalDate,
    })
    .send();
}
