export const paymentPeriodType = [
  {
    id: 1,
    key: 'MONTHLY',
    value: 'Mensual',
  },
  {
    id: 2,
    key: 'BIMONTHLY',
    value: 'Bimestral',
  },
  {
    id: 3,
    key: 'QUARTERLY',
    value: 'Trimestral',
  },
  {
    id: 6,
    key: 'BIANNUAL',
    value: 'Semestral',
  },
];

export const findPaymentPeriodWithKey = (key) => paymentPeriodType
  .find((payment) => payment.key === key) || { id: 1, value: 'Mensual' };
