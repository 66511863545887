import React from 'react';
import { colors } from 'globalStyle';
import { Box, Grid } from '@material-ui/core';
import Container from 'components/Container';
import { Title, Text } from 'components/Texts';
import MainImage from 'assets/persons/computer-2.svg';
import CustomDialog from 'components/Modals/CustomDialog';
import { Hollow, hollowColorType } from 'components/Buttons';

export default function RegisterLRWait({ open, onClose, isDesktop }) {
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      px={isDesktop ? '94px' : '35px'}
      pt={isDesktop ? '27px' : '4.5px'}
      width={isDesktop ? '615px' : '290px'}
      height={isDesktop ? '530px' : '409px'}
    >
      <Container>
        <Box
          alt=""
          component="img"
          src={MainImage}
          width={isDesktop ? '204px' : '154px'}
          height={isDesktop ? '188px' : '142px'}
          marginBottom={isDesktop ? '14px' : '17px'}
        />
        <Title
          size={isDesktop ? 24 : 16}
          color={!isDesktop && colors.primary}
        >
          Proceso de registro
        </Title>
        <Text
          mt={isDesktop ? 10 : 6}
          mb={isDesktop ? 42 : 34}
          size={isDesktop ? 15 : 13}
        >
          {isDesktop
            ? 'Gracias por tu información, nos comunicaremos contigo lo más pronto posible para informarte sobre el proceso de  tu registro.'
            : 'Nos comunicaremos contigo para informarte sobre el proceso de  tu registro. '}
        </Text>
        <Grid container justify="center">
          <Box
            width={isDesktop ? '330px' : '220px'}
          >
            <Hollow
              isSubmit
              size={14}
              fullWidth
              onClick={onClose}
              onColorType={hollowColorType.primary}
            >
              Entendido
            </Hollow>
          </Box>
        </Grid>
      </Container>
    </CustomDialog>
  );
}
