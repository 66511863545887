import { Fetch } from 'lib/Fetch';

export function registerLegalRepresentative(data) {
  return new Fetch('api_2')
    .url('/company/register/company/legal-representative')
    .method('post')
    .data(data)
    .userToken()
    .send();
}
